import React from 'react';
import PropTypes from 'prop-types';

import './ProgressRing.css';


export default class ProgressRing extends React.PureComponent {
    static propTypes = {
        color: PropTypes.string.isRequired,
        radius: PropTypes.number.isRequired,
        stroke: PropTypes.number.isRequired,
        progress: PropTypes.number.isRequired,
    }

    constructor(props) {
        super(props)

        this.normalizedRadius = this.props.radius - this.props.stroke * 2
        this.circumference = this.normalizedRadius * 2 * Math.PI
    }

    render() {
        const { radius, stroke, progress } = this.props
        const strokeDashoffset = this.circumference - progress / 100 * this.circumference

        return (
            <div className="ProgressRing_base">
                <svg height={radius * 2} width={radius * 2}>
                    <circle
                        stroke={this.props.color}
                        fill="transparent"
                        strokeWidth={stroke}
                        strokeDasharray={`${this.circumference} ${this.circumference}`}
                        style={{ strokeDashoffset }}
                        r={this.normalizedRadius}
                        cx={radius}
                        cy={radius}
                    />
                </svg>
            </div>
        )
    }
}
