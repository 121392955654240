import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import settings from '../../../settings';

import { formatDate, formatFileSize } from '../../../utils/formatting';

import ButtonBlueSmall from '../../Buttons/ButtonBlueSmall/ButtonBlueSmall';

import { 
    MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION, 
    MOD_DETAILS_MOD_VERSION, 
    MOD_DETAILS_GAME_VERSION, 
    MOD_DETAILS_GAME_CREATED_AT, 
    MOD_DETAILS_GAME_UPDATED_AT
} from '../translations';

import './ModDetailsFixedBar.css';

//const PAGE_OFFSET = 420


export default class ModDetailsFixedBar extends React.PureComponent {
    static propTypes = {
        modName: PropTypes.string,
        modSize: PropTypes.number,
        modVersion: PropTypes.string,
        gameVersion: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        onDownloadClick: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isVisible: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        const windowPositionBottom = window.scrollY + document.documentElement.clientHeight;
        var mod_download = document.getElementById('mod_download');
        const isVisible = mod_download.offsetTop > windowPositionBottom// window.scrollY >= PAGE_OFFSET

        if (isVisible !== this.state.isVisible) {
            this.setState({ isVisible })
        }
    }

    renderModVersion() {
        return this.props.modVersion && (
            <div className="ModDetailsFixedBar_label">{MOD_DETAILS_MOD_VERSION(this.props.modVersion)}</div>
        )
    }

    renderModDate() {
        if (this.props.updatedAt) {
            return (
                <div className="ModDetailsFixedBar_label">
                    {MOD_DETAILS_GAME_UPDATED_AT(formatDate(this.props.updatedAt))}
                </div>
            )
        }

        if (this.props.createdAt) {
            return (
                <div className="ModDetailsFixedBar_label">
                    {MOD_DETAILS_GAME_CREATED_AT(formatDate(this.props.createdAt))}
                </div>
            )
        }

        return null
    }

    renderGameVersion() {
        if (this.props.gameVersion) {
            let isNewVersion = false
            if (this.props.gameVersion && settings.gameVersions.length) {
                isNewVersion = settings.gameVersions[0].version === this.props.gameVersion
            }
            return (
                <div className="ModDetailsFixedBar_label">{MOD_DETAILS_GAME_VERSION(this.props.gameVersion, isNewVersion)}</div>
            )
        }

        return null
    }

    render() {
        const classNameBase = classNames("ModDetailsFixedBar_base", {
            "ModDetailsFixedBar_isVisible": this.state.isVisible,
        })

        return (
            <div className={classNameBase}>
                <div className="ModDetailsFixedBar_inner">
                    <div className="ModDetailsFixedBar_name" title={this.props.modName}>
                        {this.props.modName}
                    </div>
                    <div className="ModDetailsFixedBar_details">
                        {this.renderModVersion()}
                        {this.renderModDate()}
                        {this.renderGameVersion()}
                        <ButtonBlueSmall onClick={this.props.onDownloadClick} gtmTag={'ev_click-download1'}>
                            {MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION(formatFileSize(this.props.modSize))}
                        </ButtonBlueSmall>
                    </div>
                </div>
            </div>
        )
    }
}
