import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image/Image';
//import Image from 'react-image-resizer';
//import Lightbox from 'react-image-lightbox';
//import Lightbox from 'lightbox-react';

import './Screenshots.css';


export default class Screenshots extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            height: PropTypes.number,
            id: PropTypes.number.isRequired,
            position: PropTypes.number.isRequired,
            source: PropTypes.string.isRequired,
            sourceFullSize: PropTypes.string.isRequired,
            sourceViewPage: PropTypes.string.isRequired,
            width: PropTypes.number,
        })),
    }

    constructor(props) {
        super(props)

        this.state = {
            photoIndex: 0,
            isVisible: false,
        }
    }

    handleScreenshotClick = (photoIndex) => {
        photoIndex !== this.state.photoIndex && this.setState({ photoIndex })
    }

    handleFullScreenshotClick = () => {
        this.setState({ isVisible: true })
    }

    handleCloseRequest = () => {
        this.setState({ isVisible: false })
    }

    handleMovePrevRequest = () => {
        const images = this.props.items
        const photoIndex = this.state.photoIndex

        this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
        })
    }

    handleMoveNextRequest = () => {
        const images = this.props.items
        const photoIndex = this.state.photoIndex

        this.setState({
            photoIndex: (photoIndex + 1) % images.length,
        })
    }

    renderPreviews() {
        const itemsLength = this.props.items.length

        return itemsLength > 1 && (
            <div className="Screenshots_previews">
                <div className="Screenshots_counter">
                    <span>{this.state.photoIndex + 1}</span> / {itemsLength}
                </div>
                {this.renderPreview()}
            </div>
        )
    }

    renderPreview() {
        return (
            this.props.items.sort((a, b) => { return a.position - b.position }).map((item, idx) => {
                const classNamePreview = classNames("Screenshots_preview", {
                    "Screenshots_isActive": idx === this.state.photoIndex,
                    'ev_click-screenshot': true,
                })

                return (
                    <div
                        key={`items-${idx}`}
                        className={classNamePreview}
                        onClick={() => this.handleScreenshotClick(idx)}
                    >
                        <Image 
                            src={this.props.items[idx].sourceFullSize}
                            width={160}
                            height={85}
                            alt={`Screen_`+this.props.items[idx].position}
                        />
                    </div>
                )
            })
        )
    }

    renderView() {
        if (this.props.items.length === 1) {
            return (
                <img
                    className="Screenshots_image"
                    src={this.props.items[this.state.photoIndex].source} // sourceFullSize
                    alt="Screen"
                />
            )
        }
        return (
            <div className="Screenshots_view" onClick={this.handleFullScreenshotClick}>
                {/* this.props.items.sort((a, b) => { return a.position - b.position }).map((item, idx) => { */}
                <Image 
                    src={this.props.items[this.state.photoIndex].source}
                    width={930}
                    height={525}
                    alt={`Screen_`+this.props.items[this.state.photoIndex].position}
                />
            </div>
        )
    }

    render() {
        // const images = this.props.items
        // const photoIndex = this.state.photoIndex

        return (
            <div className="Screenshots_base">
                {this.renderView()}
                {this.renderPreviews()}
                {this.state.isVisible && (
                    <React.Fragment>
                        {/* {NativeFancybox.bind('[data-fancybox="gallery"]', options)} */}
                        {/* <Lightbox
                            enableZoom={false}
                            imageTitle={(
                                <div className="Screenshots_lightboxCounter">
                                    <span>{this.state.photoIndex + 1}</span> / {images.length}
                                </div>
                            )}
                            imagePadding={170}

                            mainSrc={images[photoIndex].sourceFullSize}
                            nextSrc={images[(photoIndex + 1) % images.length].sourceFullSize}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length].sourceFullSize}
                            onCloseRequest={this.handleCloseRequest}
                            onMovePrevRequest={this.handleMovePrevRequest}
                            onMoveNextRequest={this.handleMoveNextRequest}
                        /> */}
                    </React.Fragment>
                )}
            </div>
        )
    }
}
