import React from 'react';
import PropTypes from 'prop-types';

import ScrollToTop from '../ScrollToTop/ScrollToTop.js';

import styles from './Main.module.css';

export default class Main extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                {this.props.children}
                <ScrollToTop />
            </div>
        )
    }
}
