import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MOD_STATE, MOD_STATE_MAPPING, MOD_VERSION_STATUS_MAPPING } from '../../utils/constants';
import { MOD_STATE_TITLES, MOD_VERSION_STATUS_TITLES } from '../../translations';

import './ModState.css';


export default class ModState extends React.PureComponent {
    static propTypes = {
        isHidden: PropTypes.bool,
        state: PropTypes.number.isRequired,
        target: PropTypes.oneOf(['mod', 'modVersion']),
        isBlock: PropTypes.bool,
    }

    static defaultProps = {
        target: 'mod',
    }

    render() {
        const state = this.props.isHidden && this.props.target === 'mod' ? MOD_STATE.HIDDEN : this.props.state
        const classNameMapping = this.props.target === 'mod' ? MOD_STATE_MAPPING : MOD_VERSION_STATUS_MAPPING
        const classNameTitles = this.props.target === 'mod' ? MOD_STATE_TITLES : MOD_VERSION_STATUS_TITLES
        const classNameBase = classNames("ModState_base", [`ModState_base__${classNameMapping[state]}`], {
            "ModState_isBlock": this.props.isBlock,
        })

        return (
            <span className={classNameBase}>{classNameTitles[state]}</span>
        )
    }
}
