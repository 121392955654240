import React, { Component } from 'react';

import { urls } from '../../utils/routing';

import Main from '../../components/Main/Main';
import Back from '../../components/Buttons/Back/Back';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';

import styles from './Eula.module.css';


export const EULA_MAIN_TITLE = `Правила сайта`;

export default class Eula extends Component {
  componentDidMount() {
    document.title = `Правила сайта. TANKIMODS.NET - моды для Мир танков`
  }

  render() {
    if (window.__URLS__.pages.eula) {
    return (
      <Main>
        <div className={styles.base}>
            <div className={styles.head}>
                <Back />
                <TitleMajor>{EULA_MAIN_TITLE}</TitleMajor>
            </div>
            <div className={styles.body}>
                <div className={styles.inner}>
                    <div className={styles.Content_base}>
                        <div>
                          <p>Добро пожаловать на Сайт <a href="https://tankimods.net">tankimods.net</a>, ресурс посвящённый пользовательским модификациям для игры Мир танков. 
                          Отсюда Вы можете загружать Игровые модификации (далее - Моды) для установки на свой клиент, комментировать размещенные материалы на данном ресурсе, а также делиться своими Модами. 
                          При размещении Модов, описаний к ним, а также комментариев к другим материалам на данном Сайте, просим Вас вести себя цивилизованно и соблюдать Правила сайта. 
                          Эти Правила являются обязательными для всех зарегистрированных Пользователей Сайта Модов <a href="https://tankimods.net">tankimods.net</a>.</p>
                            <ol>
                              <li>
                                <h2>ОБЩИЕ ПОЛОЖЕНИЯ</h2>
                                <p>
                                  <strong>1.1 Учётная запись</strong>
                                  <br />
                                  Авторизоваться на Сайте Модов <a href="https://tankimods.net">tankimods.net</a> может любой, у кого имеется аккаунт <a href="https://lesta.ru">Lesta</a>. 
                                  Возраст Пользователя Сайта Модов должен превышать 12 лет. Пользователь Сайта Модов <a href="https://tankimods.net">tankimods.net</a> не имеет права передавать свой логин и пароль третьим лицам. 
                                  Автором всех размещённых материалов учётной записи является Пользователь этой учётной записи. 
                                  Данный Сайт получает доступную информацию по Вашему игровому Аккаунту <a href="https://lesta.ru">Lesta</a>, никакие пароли, ни логин, не передаются данному ресурсу или каким-либо третьим лицам.
                                </p>
                                <p>
                                  <strong>1.2 Тематика Сайта</strong>
                                  <br />
                                  Основными материалами Сайта Модов <a href="https://tankimods.net">tankimods.net</a> считаются пользовательские Моды, созданные для игры Мир танков. Данный Сайт не является официальным ресурсом компании <a href="https://lesta.ru">Lesta</a>.
                                </p>
                                <p>
                                  <strong>1.3 Материалы и комментарии на Сайте</strong>
                                  <br />
                                  Под размещаемыми на Сайте Модов <a href="https://tankimods.net">tankimods.net</a> материалами подразумеваются: пользовательские Моды, не нарушающие Правила модов. Пользователь может оставить комментарий по теме комментируемого материала в соответствии с данными Правилами сайта.
                                </p>
                                <p>
                                  <strong>1.4 Ответственность за информацию размещенную на Сайте</strong>
                                  <br />
                                  Сайт Модов <a href="https://tankimods.net">tankimods.net</a> является премодерируемым. 
                                  Материалы, размещаемые на Сайте, предварительно проверяются и могут быть удалены (в случае выявления каких-либо нарущений Правил модов или Правил сайта). 
                                  Прошедшие модерацию и размещённые на Сайте материалы и комментарии могут быть отредактированы или удалены позднее. 
                                  Администрация Сайта не несёт ответственности за содержание Модов и комментариев к ним, публикуемых Пользователями. 
                                  Ответственность за содержание Модов и комментариев несёт только его автор. 
                                  <br />
                                  Используя Сайт, вы соглашаетесь с тем, что не будете использовать данный ресурс для размещения оскорбительных, непристойных материалов, а также информации, нарушающей право других Пользователей Сайта на личную жизнь, или любые иные материалы, нарушающие действующее законодательство. 
                                  Также Вы обязуетесь не размещать любые материалы, защищённые авторским правом, без получения разрешения у правообладателя. 
                                  В противном случае Администрация оставляет за собой право как удалить материалы, нарушающие авторские права правообладателей, так и применить к учётной записи, с которой они опубликованы, соответствующие ограничения.
                                </p>
                                <p>
                                  <strong>1.5 Администраторы и модераторы</strong>
                                  <br />
                                  Администраторы Сайта — не исключено, что могут быть сотрудники компании Lesta.
                                  Модераторы — пользователи, принимающие участие в премодерации материалов и комментариев, а также их постмодерации (редактировании и удалении) на Сайте Модов <a href="https://tankimods.net">tankimods.net</a>.
                                </p>
                                <p>
                                  <strong>1.6 Права Администраторов и Модераторов</strong>
                                  <br />
                                  Администраторы и Модераторы Сайта Модов <a href="https://tankimods.net">tankimods.net</a> имеют право на вынесение Пользователю предупреждений и применение наказаний в случае нарушения Пользователем Правил сайта. 
                                  Действия Модераторов и Администраторов обжалованию не подлежат. В ряде случаев, не укладывающихся в рамки правил, Администраторы и Модераторы могут принять решение о применении к Пользователю ограничений, даже если его действия формально не попадают под действующие запреты.
                                </p>
                              </li>
                              <li>
                                <h2>Запреты и ограничения</h2>
                                <p>
                                  <strong>2.1 Комментарии на Сайте</strong>
                                  <br />
                                  При общении в комментариях на Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещаются: мат, завуалированный мат, оскорбления, провоцирование и преследование собеседников. 
                                  Также запрещается распространение личных сведений о Пользователе без его согласия. 
                                  Помимо этого, запрещена клевета и размещение заведомо ложной информации о Пользователях Сайта. 
                                  Этот запрет распространяется как на комментарии, так и на размещаемые материалы на ресурсе (цитаты, изображения, видео).
                                  <br />
                                  Пользователям запрещено оскорблять собеседников по расовому, национальному, половому или религиозному признаку, а также использовать в комментариях и размещаемых на ресурсе материалах унизительные клички и прозвища.
                                </p>
                                <p>
                                  <strong>2.2 Запрещённые Моды</strong>
                                  <br />
                                  На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещена публикация любого вида пользовательских Модов, относящихся к запрещённым. 
                                  В случае, если Мод прямо не относится к запрещённым, однако его действие наносит ущерб игре и игровому процессу, Администрация Сайта оставляет за собой право окончательного решения на публикацию или удаление такого Мода.
                                  Со списком Запрещенных Модов можно ознакомиться на странице Сайта <a href={ urls.termsofuse }>Правила модов</a> или на официальном форуме Игры Мир танков.
                                </p>
                                <p>
                                  <strong>2.3 Офтопик и флуд</strong>
                                  <br />
                                  На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещено размещение материалов, не связанных с тематикой ресурса. 
                                  Также запрещено размещение комментариев, не связанных с тематикой комментируемого материала (офтопик) и направленные на отвлечение Пользователей от темы обсуждения конкретного Мода (флуд).
                                </p>
                                <p>
                                  <strong>2.4 Политика</strong>
                                  <br />
                                  На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещено размещение материалов и комментариев, затрагивающих современную политику, как заведомо провоцирующих конфликты. 
                                  Также запрещено проведение аналогий между военно-историческими темами и актуальными политическими темами.
                                </p>
                                <p>
                                  <strong>2.5 Нарушение правил Игр</strong>
                                  <br />
                                  На Сайте запрещено размещение информации о способах нарушения Пользовательского соглашения, взломе Игр, троянских и вредоносных программах, а также ссылок на них. 
                                  Если вы хотите сообщить о наличии такой информации, Вам необходимо известить Администрацию Сайта, оформив заявку в Центре поддержки.
                                </p>
                                <p>
                                  <strong>2.6 Обсуждение действий Администрации</strong>
                                  <br />
                                  На Сайте запрещено размещение комментариев, в рамках которых идёт обсуждение действий Администрации и/или ограничений, наложенных на Пользователя на данном Сайте и/или в Играх, на официальных ресурсах игры Мир танков.
                                </p>
                                <p>
                                  <strong>2.7 Нарушения законодательства</strong>
                                  <br />
                                  На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> строжайше запрещены и будут категорически пресекаться любые материалы и комментарии, нарушающие действующее законодательство. 
                                  Запрещена реклама психотропных, наркотических и других веществ, запрещённых законодательством. 
                                  Строжайше запрещены и будут пресекаться любые попытки создания никнеймов или размещения материалов, которые каким-либо образом связаны с организациями, нарушающими и нарушавшими международное законодательство (например, использование в никнеймах нацистских символик, сокращений и обозначений, например «СС», а также имён и фамилий нацистских деятелей и военных преступников).
                                </p>
                              </li>
                              <li>
                                <h2>Наказания за нарушение правил</h2>
                                <p>
                                  <strong>ВНИМАНИЕ:</strong>
                                  <br />
                                  На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> действует премодерация и постмодерация, поэтому Модератор не обязан предупреждать Пользователя при нарушении последним правил. 
                                  Данное действие Модератор может выполнять по своему усмотрению.
                                  <br />
                                  Ограничение может быть смягчено или ужесточено в зависимости от предшествовавшего поведения Пользователя на Сайте Модов <a href="https://tankimods.net">tankimods.net</a>.
                                  <br />
                                  Администрация оставляет за собой право редактировать, а также удалять любые материалы и комментарии, которые, по мнению Администрации, неуместны на данном ресурсе.
                                </p>
                                <p>
                                  <strong>3.1 Виды ограничений</strong>
                                  <br />
                                  3.1.1 Включение режима «read only» — невозможность комментировать и размещать материалы (цитаты, изображения, видео) на Сайте.
                                </p>
                                <p>
                                  <strong>3.2 На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещены следующие действия, 
                                  а в случае их совершения применяются следующие ограничения</strong>
                                  <br />
                                  3.2.1 Нецензурная брань в материалах и комментариях – включение режима «read only» на срок от 1 дня.
                                  <br />
                                  3.2.2 Оскорбление Пользователей, троллинг (размещение на Сайте провокационных материалов и комментариев с целью вызвать гнев, 
                                  обиду, раздражение у Пользователей и/или разногласия, конфликты между ними) — включение режима «read only» на срок от 3 дней.
                                  <br />
                                  3.2.3 Спам, флуд, офтопик — включение режима «read only» на срок от 1 дня.
                                  <br />
                                  3.2.4 Обсуждение действий Администрации в явной или неявной форме на Сайте запрещено — включение режима «read only» на срок от 14 дней 
                                  или перманентно (т.е. насовсем).
                                  <br />
                                  3.2.5 Оскорбление Проекта, Администрации, Разработчиков, Модераторов (игровых ресурсов) в явной или неявной форме — включение режима «read only» на срок от 14 дней или перманентно (т.е. насовсем).
                                  <br />
                                  3.2.6 Разжигание ненависти по расовому, национальному, половому или религиозному признаку — включение режима «read only» на срок от 14 дней или перманентно.
                                  <br />
                                  3.2.7 Угроза жизни и/или здоровью Пользователей Сайта — включение режима «read only» перманентно.
                                  <br />
                                  3.2.8 Обсуждение вопросов современной политики — включение режима «read only» на срок от 7 дней или перманентно.
                                  <br />
                                  3.2.9 Распространение троянских и вредоносных программ, а также мошенничество — включение режима «read only» перманентно.
                                  <br />
                                  3.2.10 Копирование и размещение материалов и комментариев, уже существующих на Сайте — удаление материала или комментария.
                                  <br />
                                  3.2.11 Размещение материалов и комментариев, имеющих ярко выраженное эротическое или порнографическое содержание, 
                                  содержащих материалы со сценами насилия над людьми или животными, — удаление материала или комментария и включение режима «read only» на срок от 14 дней.
                                  <br />
                                  3.2.12 Размещение материалов и комментариев, содержащих пропаганду психотропных, наркотических и других запрещенных веществ, — удаление материала или комментария и включение режима «read only» на срок от 14 дней.
                                  <br />
                                  3.2.13 Размещение материалов и комментариев, которые каким-либо образом связаны с организациями, нарушающими и нарушавшими белорусское 
                                  и международное законодательство (например, использование нацистских символик, сокращений и обозначений, например, «СС», 
                                  а также имена и фамилии нацистских деятелей и военных преступников) — удаление материала или комментария и включение режима «read only» на срок от 14 дней.
                                  <br />
                                  3.2.14 Призывы к нарушению Пользовательского Соглашения — включение режима «read only» на срок от 7 дней.
                                  <br />
                                  3.2.15 На Сайте Модов <a href="https://tankimods.net">tankimods.net</a> запрещено размещение любой информации, связанной с продажей, 
                                  обменом и покупкой Игровых аккаунтов, кланов и любого другого имущества, которое имеет отношение к Проекту, 
                                  т.е. с передачей игрового имущества от одного Пользователя другому — полная блокировка доступа к учётной записи, 
                                  сообщение об этом Администрации Проекта (вследствие, блокировка доступа к Играм учётной записи продавца).
                                </p>
                              </li>
                              <li>
                                <h2>Требования</h2>
                                <p>
                                  <strong>4.1 Размещение Модов</strong>
                                  <br />
                                  Чтобы разместить Мод на Сайте, необходимо авторизоваться при помощи своего LESTA ID.
                                </p>
                                <p>
                                  <strong>4.2 Оформление комментариев</strong>
                                  <br />
                                  Комментарии можно добавлять к материалам, предварительно авторизовавшись на Сайте Модов <a href="https://tankimods.net">tankimods.net</a>. 
                                  Каждый комментарий ограничен 1000 знаками. Комментарий должен быть информативен и кратко отображать содержание темы. 
                                </p>
                              </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </Main>
    )
    } else {
      return <UnavailablePage />
    }
  }
}
