import React from "react"

export const SUBSCRIPTIONS_ITEM_VERSION = (version) => { 
    //return <span dangerouslySetInnerHTML={{__html: `Версия мода <mark>${version}</mark>`}}></span> 
    return <React.Fragment>Версия мода <mark>{version}</mark></React.Fragment>
}

export const SUBSCRIPTIONS_ITEM_UDATED_DATE = (date) => { 
    //return <span dangerouslySetInnerHTML={{__html: `Обновлён: <mark>${date}</mark>`}}></span> 
    return <React.Fragment>Обновлён: <mark>{date}</mark></React.Fragment>
}

export const SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL = (size) => { 
    //return <span dangerouslySetInnerHTML={{__html: `Cкачать мод <mark>(${size})</mark>`}}></span> 
    return <React.Fragment>Cкачать мод <mark>({size})</mark></React.Fragment>
}
