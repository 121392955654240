import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import styles from './Divider.module.css';

export default class Divider extends React.PureComponent {
    static propTypes = {
        isDashed: PropTypes.bool,
    }

    render() {
        const classNameBase = className(styles.base, {
            [styles.isDashed]: this.props.isDashed,
        })

        return (
            <div className={classNameBase} />
        )
    }
}
