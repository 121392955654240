import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';

import { DIALOG_EDGE_ALERT_CONTENT } from './translations'

import {
    ATTENTION,
    CLOSE,
} from '../../../translations'

import styles from './DialogEdgeAlert.module.css';

export default class DialogEdgeAlert extends React.PureComponent {
    static propTypes = {
        language: PropTypes.string.isRequired,
        onCloseDialog: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{ATTENTION}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_EDGE_ALERT_CONTENT}</p>
                </div>
                <div>
                    <div className={styles.button}>
                        <ButtonBlueLarge onClick={this.props.onCloseDialog}>
                            {CLOSE}
                        </ButtonBlueLarge>
                    </div>
                </div>
            </div>
        )
    }
}
