import { connect } from 'react-redux';

import { closeDialog } from '../../actions/Dialog';
import {
    setEdgeAlertAccepted,
} from '../../actions/CurrentAccount';

import DialogEdgeAlert from '../../components/Dialogs/DialogEdgeAlert/DialogEdgeAlert'

const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(setEdgeAlertAccepted())
        dispatch(closeDialog())
    },
})

export default connect(null, mapDispatchToProps)(DialogEdgeAlert)
