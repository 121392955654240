import { batchActions } from 'redux-batched-actions';

import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import { processNews } from '../utils/responseProcessing';
import urls from '../apiUrls';
import { BREAKPOINT, NEWS_ITEMS_LIMIT } from '../utils/constants';

export const SET_ERROR_FETCHING_NEWS = 'SET_ERROR_FETCHING_NEWS';
export const START_FETCHING_NEWS = 'START_FETCHING_NEWS';
export const STOP_FETCHING_NEWS = 'STOP_FETCHING_NEWS';
export const UPDATE_ITEMS_NEWS = 'UPDATE_ITEMS_NEWS';
export const INCREMENT_FETCHING_NEWS_OFFSET = 'INCREMENT_FETCHING_NEWS_OFFSET';
export const CHANGE_NEWS_PAGE = 'CHANGE_NEWS_PAGE';


export const getNewsItemsCountPerRow = () => {
    return window.innerWidth > BREAKPOINT ? 4 : 3
}
export const NEWS_ITEMS_COUNT_PER_ROW = getNewsItemsCountPerRow()
export const NEWS_ITEMS_PER_PAGE = 3 * NEWS_ITEMS_COUNT_PER_ROW

const startFetchingNews = () => ({
    type: START_FETCHING_NEWS,
})

const stopFetchingNews = () => ({
    type: STOP_FETCHING_NEWS,
})

const updateItemsNews = (rawResponseData, state) => {
    return ({
        type: UPDATE_ITEMS_NEWS,
        payload: {
            items: processNews(rawResponseData.results),
            count: rawResponseData.count,
        },
    })
}

export const incrementFetchingNewsOffset = (itemsType) => ({
    type: INCREMENT_FETCHING_NEWS_OFFSET,
    payload: { itemsType },
})

export const changeNewsPage = (page) => ({
    type: CHANGE_NEWS_PAGE,
    payload: {
        page,
    },
})


const setErrorFetchingNews = (errorData) => ({
    type: SET_ERROR_FETCHING_NEWS,
    payload: {
        errorData,
    },
})

const prepareQueryParamsForNews = (data, state) => {
    let query = {
        limit: NEWS_ITEMS_PER_PAGE,
        offset: state.news.offset,
        
        count: data.count, 
        results: data.results.slice(state.news.offset, NEWS_ITEMS_PER_PAGE+state.news.offset), 
    }

    return query
}

export const fetchNews = () => {
    return (dispatch, getState) => {
        const url = 'https://tankimods.net'+urls.news
        dispatch(startFetchingNews())

        const state = getState()
        const query = {
            limit: NEWS_ITEMS_LIMIT,
            offset: (state.news.page - 1) * NEWS_ITEMS_LIMIT,
        }

        const fetchPromise = fetch(url, { query }).promise
            .then((rawResponseData) => {
                let rawResponse = prepareQueryParamsForNews(rawResponseData, state)
                dispatch(batchActions([
                    updateItemsNews(rawResponse), // rawResponseData
                    stopFetchingNews(),
                ], UPDATE_ITEMS_NEWS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingNews(errorData),
                    stopFetchingNews(),
                ], SET_ERROR_FETCHING_NEWS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const fetchNewsItemsByType = (withSpinner = true) => { // withSpinner = true
    //
    return (dispatch, getState) => {
        const state = getState()
        if (state.news.isFetchedAll) {
            return
        }

        const url = 'https://tankimods.net'+urls.news
        dispatch(startFetchingNews())

        const fetchPromise = fetch(url).promise
            .then((rawResponseData) => {
                let rawResponse = prepareQueryParamsForNews(rawResponseData, state)
                dispatch(batchActions([
                    updateItemsNews(rawResponse, state), // (itemsType, rawResponseData, state)
                    stopFetchingNews(),
                ], UPDATE_ITEMS_NEWS))
            }, (errorData) => {
            dispatch(batchActions([
                setErrorFetchingNews(errorData),
                stopFetchingNews(),
            ], SET_ERROR_FETCHING_NEWS))
        })
        return fetchWithSpinner(dispatch, fetchPromise)
        //return withSpinner ? fetchWithSpinner(dispatch, fetchPromise) : fetchPromise
    }
}
