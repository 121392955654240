import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';
import { StickyContainer } from 'react-sticky'
import scrollToComponent from 'react-scroll-to-component'
import {
    cloneDeep,
//    difference, // modder cant change desc lang
    each,
    first,
    get,
    intersection,
    isEmpty,
    keys,
    omit,
    set,
    values,
    without,
} from 'lodash';

import {
    MOD_STATE,
    FIELD_VALIDATION_ERROR_TYPES,
    MIN_DESCRIPTION_LENGTH,
    MIN_MOD_VERSION_LENGTH,
    MIN_TITLE_LENGTH,
    MOD_STATUS_TYPES,
    MOD_VERSION_COMMENT_TYPES,
    SCROLL_TO_COMPONENTS_PARAMETERS,
} from '../../utils/constants';
import { getUserId, getUserRole } from '../../utils/functions';
import { browserName } from '../../utils/helpers';
import { AVAILABLE_LANGUAGES } from '../../utils/i18n';
import { pushHistoryWithErrorNotFound, pushHistoryWithModderSection, urls } from '../../utils/routing';
import { getStringLengthFromHtml } from '../../utils/strings';
import { formatCounter, formatWithSeparatedThousands } from '../../utils/formatting';

import settings from '../../settings';

import {
    REVIEW_MOD_DRAFT_PAGE_TITLE,
    REVIEW_MOD_INFO,
    messages,
} from './translations';
import { 
    COMMON_ERROR, 
    CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT, 
    CREATE_NOTIFICATION_REQUIRED_CONTENT, 
    PUBLISH_ATTENTION_1, 
    PUBLISH_ATTENTION_2, 
    PUBLISHED, 
    REJECTED, 
//    SAVE, 
    SAVE_AS_DRAFT, 
    SAVE_CHANGES, 
//    SEND_TO_CHECK_BUTTON, 
    TO_MODDER_SECTION 
} from '../../translations';

import Main from '../Main/Main';
import Back from '../Buttons/Back/Back';
import TitleMajor from '../TitleMajor/TitleMajor';
import LinesClamp from '../LinesClamp/LinesClamp';
import Error from '../Error/Error';
import NotificationBlockedUser from '../NotificationBlockedUser/NotificationBlockedUser';
import ErrorBlocked from '../ErrorBlocked/ErrorBlocked';
import Divider from '../Divider/Divider';
import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import ButtonBlueOutlineLarge from '../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import ButtonRedOutlineLarge from '../Buttons/ButtonRedOutlineLarge/ButtonRedOutlineLarge';
import ActionRemoveMod from '../ActionRemoveMode/ActionRemoveMod';
import EditState from '../Edit/EditState/EditState';
import EditUploadUpdateList from '../Edit/EditUploadUpdateList/EditUploadUpdateList';
//import EditUploadUpdateAdd from '../Edit/EditUploadUpdateAdd/EditUploadUpdateAdd'; // this delete because we as modder dont use download new version for mod
import EditUploadMod from '../Edit/EditUploadMod/EditUploadMod';
import EditGameVersion from '../Edit/EditGameVersion/EditGameVersion';
import EditModVersion from '../Edit/EditModVersion/EditModVersion';
import Notification from '../Notification/Notification';
import EditCoverUpload from '../Edit/EditCoverUpload/EditCoverUpload';
import EditLanguageForm from '../Edit/EditLanguageForm/EditLanguageForm';
//import EditLanguageAdd from '../Edit/EditLanguageAdd/EditLanguageAdd'; // remove because modder cant change desc
import EditChangelog from '../Edit/EditChangelog/EditChangelog';
import EditChangelogList from '../Edit/EditChangelogList/EditChangelogList';
import EditChangelogAdd from '../Edit/EditChangelogAdd/EditChangelogAdd';
import EditCategories from '../Edit/EditCategories/EditCategories';
import EditScreenshots from '../Edit/EditScreenshots/EditScreenshots';
import EditAuthorName from '../Edit/EditAuthorName/EditAuthorName';
import EditComment from '../Edit/EditComment/EditComment';
import EditComments from '../../containers/EditCommentsWrapper/EditCommentsWrapper';
import EditNotifications from '../Edit/EditNotifications/EditNotifications';
import EditVoiceover from '../Edit/EditVoiceover/EditVoiceover';
import Caption from '../Caption/Caption';

import styles from './ReviewMod.module.css';
import UnavailablePageWrapper from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';


export default class ReviewMod extends Component {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        })).isRequired,
        changelogHistory: PropTypes.arrayOf(PropTypes.shape({
            version: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        })).isRequired,
        comments: PropTypes.arrayOf(PropTypes.shape({
            authorName: PropTypes.string,
            content: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            gameVersion: PropTypes.string,
            modVersion: PropTypes.string,
            modVersionId: PropTypes.number,
            status: PropTypes.string,
        })),
        data: PropTypes.shape({
            ownerName: PropTypes.string,
            ownerId: PropTypes.number,
            mark: PropTypes.number,
            authorName: PropTypes.string,
            byLanguages: PropTypes.objectOf(PropTypes.shape({
                description: PropTypes.string.isRequired,
                installationGuide: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            })),
            categories: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                caption: PropTypes.string.isRequired,
            })),
            changelog: PropTypes.shape({
                modVersion: PropTypes.string,
                content: PropTypes.string,
            }),
            isHidden: PropTypes.bool,
            screenshots: PropTypes.arrayOf(PropTypes.shape({
                position: PropTypes.number.isRequired,
                screenshotId: PropTypes.number.isRequired,
                source: PropTypes.string.isRequired,
            })),
            voiceovers: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                voiceoverId: PropTypes.number,
                url: PropTypes.string,
                source: PropTypes.string,
                position: PropTypes.number,
            })), // if mod voiceover - check for download audio for prelistening
            state: PropTypes.oneOf(values(MOD_STATE)),
            status: PropTypes.number,
            title: PropTypes.string,
            versions: PropTypes.arrayOf(PropTypes.shape({
                gameVersion: PropTypes.string.isRequired,
                gameVersionId: PropTypes.number.isRequired,
                id: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                versionFile: PropTypes.string,
                versionFileOriginalName: PropTypes.string,
                versionFileSize: PropTypes.number,
            })),
        }).isRequired,
        gameVersions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                version: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        isError: PropTypes.bool.isRequired,
        isErrorComments: PropTypes.bool.isRequired,
        isErrorForbidden: PropTypes.bool.isRequired,
        isErrorNotFound: PropTypes.bool.isRequired,
        isFetched: PropTypes.bool.isRequired,
        isFetchedComments: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        isEdgeAlertAccepted: PropTypes.bool.isRequired,
        isIEAlertAccepted: PropTypes.bool.isRequired,
        isModVersionUploadFormVisible: PropTypes.bool.isRequired,
        isUserBanned: PropTypes.bool.isRequired,
        isUserLoggedIn: PropTypes.bool.isRequired,
        isUserStatisticsSufficient: PropTypes.bool.isRequired,
        language: PropTypes.string.isRequired,
        realm: PropTypes.string.isRequired,

        history: ReactRouterPropTypes.history.isRequired,
        //match: ReactRouterPropTypes.match.isRequired,

        //intl: intlShape,

        onChangelogConfirmationDialogShow: PropTypes.func.isRequired,
        onChangelogHistoryItemAdd: PropTypes.func.isRequired,
        onChangelogHistoryItemEdit: PropTypes.func.isRequired,
        onChangelogHistoryItemsReordered: PropTypes.func.isRequired,
        onComponentWillMount: PropTypes.func.isRequired,
        onFormSubmit: PropTypes.func.isRequired,
        onModRemove: PropTypes.func.isRequired,
        onModStatusChange: PropTypes.func.isRequired,
        onModVersionRemove: PropTypes.func.isRequired,
        onModVersionVisibilityChange: PropTypes.func.isRequired,
        onModVisibilityChange: PropTypes.func.isRequired,
        onSetModVersionUploadFormVisibility: PropTypes.func.isRequired,
        onShowConfirmationHistoryDialog: PropTypes.func.isRequired,
        onShowEdgeConfirmationDialog: PropTypes.func.isRequired,
        onShowIEConfirmationDialog: PropTypes.func.isRequired,

        onGetUserData: PropTypes.func.isRequired,

        onModPublished: PropTypes.func.isRequired,
        onModRejected: PropTypes.func.isRequired,
        onShowDialogError: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            descriptionLanguages: [],
            fieldErrors: {
                ownerName: null,
                downloads: null,
                mark: null,
                authorName: null,
                categories: null,
                changelog: null,
                comment: null,
                modFile: null,
                modVersion: null,
                byLanguages: {
                    en: {
                        title: null,
                        description: null,
                        installationGuide: null,
                    },
                    ru: {
                        title: null,
                        description: null,
                        installationGuide: null,
                    },
                },
            },
            hasError: false,
        }

        this.formErrors = {
            categories: FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
            comment: null,
            byLanguages: {
                en: {
                    title: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                    description: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                },
                ru: {
                    title: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                    description: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                },
            },
            modFile: FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
            modVersion: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
        }

        this.formData = {
            byLanguages: {
                en: {
                    title: '',
                    description: '',
                    installationGuide: '',
                },
                ru: {
                    title: '',
                    description: '',
                    installationGuide: '',
                },
            },
            authorName: null,
            categories: [],
            changelog: null,
            comment: null,
            coverFileId: null,
            coverFileToken: null,
            coverOriginalSource: null,
            gameVersionId: null,
            modFileId: null,
            modFileToken: null,
            modVersion: null,
            originalFileName: null,
            originalFileSize: null,
            screenshots: [],
            voiceovers: [],
            status: null,
        }

        this.isFormDataInitialized = false

        this.isModVoiceover = false;

        this.refsWithErrors = [
            { field: 'modFile', ref: React.createRef(), hasError: false },
            { field: 'modVersion', ref: React.createRef(), hasError: false },
            { field: 'ruLanguage', ref: React.createRef(), hasError: false },
            { field: 'enLanguage', ref: React.createRef(), hasError: false },
            { field: 'categories', ref: React.createRef(), hasError: false },
        ]
    }

    componentDidMount() {
        if ((browserName.ie11 || browserName.ie10) && this.props.isIEAlertAccepted === false) {
            this.props.onShowIEConfirmationDialog()
        }

        if (browserName.edge && this.props.isEdgeAlertAccepted === false) {
            this.props.onShowEdgeConfirmationDialog()
        }

        if (getUserRole() === null) {
            pushHistoryWithErrorNotFound(this.props.history)
            return
        }

        // пока эту функцию отключаем
        /*if (!this.props.isUserLoggedIn) {
            pushHistoryWithErrorNotFound(this.props.history)
            return
        }*/

        this.modId = +this.props.params.modId.toString().replace(/\/$/, '') // +this.props.match.params.modId.toString().replace(/\/$/, '')
        this.props.onComponentWillMount(this.modId, getUserId(), getUserRole())
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isErrorForbidden) {
            pushHistoryWithModderSection(this.props.history)
        } else if (nextProps.isErrorNotFound) {
            pushHistoryWithErrorNotFound(this.props.history)
        }

        this.setState({
            descriptionLanguages: keys(nextProps.data.byLanguages),
        })

        if (!this.isFormDataInitialized && nextProps.isFetched && !isEmpty(nextProps.data)) {
            this.initializeFormValues(nextProps)
        }

        if (this.props.data.state === MOD_STATE.IN_REVIEW && nextProps.data.state === MOD_STATE.DRAFT) {
            this.initializeFormValues(nextProps)
        }

        this.setDocumentTitle(nextProps)
    }

    setDocumentTitle(props) {
        if (props.data.title) {
            document.title = messages(props.data.title)
        }
    }

    initializeFormValues(props) {
        this.setFormValues('authorName', props.data.authorName, null)

        const categories = this.getSelectedCategoriesFromProps(props)
        const categoriesError = categories && categories.length ? null : FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES
        this.setFormValues('categories', categories, categoriesError)

        this.setFormValues('changelog', get(props.data.changelog, 'content', ''), null)
        this.setFormValues('coverOriginalSource', props.data.cover, null)
        this.setFormValues('screenshots', props.data.screenshots, null)
        this.setFormValues('voiceovers', props.data.voiceovers, null)
        this.setFormValues('status', props.data.status, null)
        
        each(props.data.byLanguages, (item, language) => {
            const titleError = item.title.length < MIN_TITLE_LENGTH ? FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT : null
            this.setFormValuesByLanguages('title', language, item.title, titleError)

            const descriptionError = getStringLengthFromHtml(item.description) < MIN_DESCRIPTION_LENGTH ?
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT : null
            this.setFormValuesByLanguages('description', language, item.description, descriptionError)

            this.setFormValuesByLanguages('installationGuide', language, item.installationGuide, null)
        })

        if ([MOD_STATE.REJECTED, MOD_STATE.DRAFT].includes(props.data.state)) {
            const lastVersion = first(props.data.versions)
            if (lastVersion) {
                const modVersionError = lastVersion.modVersion.length < MIN_MOD_VERSION_LENGTH ?
                    FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT : null

                this.setFormValues('gameVersionId', lastVersion.gameVersionId, null)
                this.setFormValues('modVersionId', lastVersion.id, null)
                this.setFormValues('modVersion', lastVersion.modVersion, modVersionError)
                this.setFormValues('originalFileName', lastVersion.versionFileOriginalName, null)
                this.setFormValues('originalFileSize', lastVersion.versionFileSize, null)
                this.setFormValues('comment', lastVersion.comment, null)

                if (lastVersion.versionFileOriginalName && lastVersion.versionFileSize) {
                    set(this.formErrors, 'modFile', null)
                }
            }
        }
        this.isFormDataInitialized = true
    }

    setFormValues(field, value, errorType) {
        set(this.formData, field, value)
        set(this.formErrors, field, errorType)
        if (!errorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, field, null)
            this.setState({ fieldErrors: stateFieldErrors })
        }
        this.clearAllRefsErrors()
    }

    clearAllRefsErrors() {
        each(this.refsWithErrors, (item => {
            item.hasError = false
        }))
    }

    scrollToErrorField() {
        const refObject = this.refsWithErrors.find(item => item.hasError)
        if (refObject) {
            scrollToComponent(refObject.ref, SCROLL_TO_COMPONENTS_PARAMETERS)
        }
    }

    setFormValuesByLanguages(field, language, value, errorType) {
        set(this.formData, `byLanguages.${language}.${field}`, value)
        set(this.formErrors, `byLanguages.${language}.${field}`, errorType)

        if (!errorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, `byLanguages.${language}.${field}`, null)
            this.setState({ fieldErrors: stateFieldErrors })
        }
        this.clearAllRefsErrors()
    }

    handleModVisibilityChange = (isVisible) => {
        this.props.onModVisibilityChange(this.modId, isVisible)
    }

    handleUpdateCancel = () => {
        if (this.props.data.state === MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW) {
            const modVersionId = first(this.props.data.versions).id
            this.props.onModVersionRemove(modVersionId)
        } else if (this.props.data.state === MOD_STATE.IN_REVIEW) {
            this.props.onModStatusChange(this.modId, MOD_STATUS_TYPES.DRAFT)
        }
    }

    handleSendToCheckClick = () => {
        const fieldRules = {
            modFile: [
                FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
                FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR,
            ],
            modVersion: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            categories: [
                FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
            ],
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            authorName: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const fieldByLanguagesRules = {
            title: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            description: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            installationGuide: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }


        const stateValue = this.getValidationErrors(fieldRules, fieldByLanguagesRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.scrollToErrorField()
            this.setState(stateValue)
            return
        }

        set(this.formData, 'status', MOD_STATUS_TYPES.PENDING_REVIEW)
        this.logSubmittingOk()
        this.props.onFormSubmit(this.modId, this.formData)
    }

    handlePublishedClick = () => {
        return 
    }

    handleRejectedClick = () => {
        let rejectedComment
        if (this.formData.comment === null || this.formData.comment === '') {
            rejectedComment = false;
        } else { rejectedComment = true; }

        return rejectedComment ? this.props.onModRejected(this.modId, this.formData.comment) : this.props.onShowDialogError('Внимание', 'Неуказана причина отказа. Пожалуйста заполните соответствующее поле "Информация для мододела"')
    }

    handleSaveAsDraftClick = () => {
        const fieldRules = {
            modVersion: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            authorName: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const fieldByLanguagesRules = {
            title: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            description: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            installationGuide: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const stateValue = this.getValidationErrors(fieldRules, fieldByLanguagesRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.scrollToErrorField()
            this.setState(stateValue)
            return
        }

        set(this.formData, 'status', MOD_STATUS_TYPES.DRAFT)
        this.logSubmittingOk()
        this.props.onFormSubmit(this.modId, this.formData)
    }

    handleDraftRemove = () => {
        const isDraftOrRejected = true
        this.props.onModRemove(this.modId, isDraftOrRejected)
    }

    handleModRemove = () => {
        const isDraftOrRejected = false
        this.props.onModRemove(this.modId, isDraftOrRejected)
    }

    handleFileChange = (data, errorType) => {
        let combinedErrorType = null
        if (errorType) {
            combinedErrorType = errorType
        } else if (data === null) {
            combinedErrorType = FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED
        }

        set(this.formData, 'modFileId', data ? data.id : null)
        set(this.formData, 'modFileToken', data ? data.access_token : null)
        set(this.formErrors, 'modFile', combinedErrorType)
        if (!combinedErrorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, 'modFile', null)
            this.setState({ fieldErrors: stateFieldErrors })
        }

        set(this.formData, 'originalFileName', null)
        set(this.formData, 'originalFileSize', null)
    }

    handleGameVersionChange = (gameVersionId) => {
        set(this.formData, 'gameVersionId', gameVersionId)
    }

    handleModVersionChange = (modVersion, errorType) => {
        this.setFormValues('modVersion', modVersion, errorType)
    }

    handleSaveChangesClick = () => {
        const fieldRules = {
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            categories: [
                FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES,
            ],
            authorName: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const fieldByLanguagesRules = {
            title: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            description: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
            ],
            installationGuide: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const stateValue = this.getValidationErrors(fieldRules, fieldByLanguagesRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.scrollToErrorField()
            this.setState(stateValue)
            return
        }

        this.logSubmittingOk()
        this.props.onFormSubmit(this.modId, this.formData)
    }

    handleDescriptionChange = (language, description, errorType) => {
        this.setFormValuesByLanguages('description', language, description, errorType)
    }
    handleInstallationGuideChange = (language, installationGuide, errorType) => {
        this.setFormValuesByLanguages('installationGuide', language, installationGuide, errorType)
    }
    handleTitleChange = (language, title, errorType) => {
        this.setFormValuesByLanguages('title', language, title, errorType)
    }
    handleAddLanguageClick = (language) => {
        if (this.state.descriptionLanguages.includes(language)) {
            return
        }
        this.setState({
            descriptionLanguages: [
                ...this.state.descriptionLanguages,
                language,
            ],
        })
        set(this.formErrors, `byLanguages.${language}.title`, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT)
        set(this.formErrors, `byLanguages.${language}.description`, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT)
    }
    handleRemoveLanguageClick = (language) => {
        if (!this.state.descriptionLanguages.includes(language)) {
            return
        }

        const stateFieldErrors = cloneDeep(this.state.fieldErrors)

        each(['title', 'description', 'installationGuide'], (field) => {
            set(stateFieldErrors, `byLanguages.${language}.${field}`, null)
            this.setFormValuesByLanguages(field, language, '', null)
        })

        this.setState({
            descriptionLanguages: without(this.state.descriptionLanguages, language),
            fieldErrors: stateFieldErrors,
        })
    }

    handleChangelogChange = (changelog, errorType) => {
        this.setFormValues('changelog', changelog, errorType)
    }

    handleCategoriesChange = (categories, errorType) => {
        this.setFormValues('categories', categories, errorType)
    }

    handleScreenshotsChange = (items) => {
        if (items) {
            set(this.formData, 'screenshots', items)
        }
    }

    handleVoiceoversChange = (items) => {
        if (items) {
            set(this.formData, 'voiceovers', items)
        }
    }

    handleAuthorNameChange = (authorName, errorType) => {
        this.setFormValues('authorName', authorName, errorType)
    }

    handleCommentChange = (comment, errorType) => {
        this.setFormValues('comment', comment, errorType)
    }

    handleCoverChange = (data) => {
        if (data === null) {
            set(this.formData, 'coverFileId', null)
            set(this.formData, 'coverFileToken', null)
        } else {
            set(this.formData, 'coverFileId', data.id)
            set(this.formData, 'coverFileToken', data.access_token)
        }
        set(this.formData, 'coverOriginalSource', null)
    }

    handleGetUserDataClick = () => {
        this.props.onGetUserData(this.props.data.ownerId)
    }

    getSelectedCategoriesFromProps(props) {
        return props.data.categories ?
            props.data.categories.map(((item) => item.id)) : []
    }

    getValidationErrors(fieldRules, fieldByLanguagesRules) {
        const stateValue = { hasError: true }
        let hasError = false

        each(fieldRules, (ruleset, field) => {
            if (ruleset.includes(this.formErrors[field])) {
                set(stateValue, `fieldErrors.${field}`, this.formErrors[field])
                this.setRefObjectWithError(field)
                hasError = true
            }
        })

        each(fieldByLanguagesRules, (ruleset, field) => {
            each(AVAILABLE_LANGUAGES, (language) => {
                const errorType = this.formErrors.byLanguages[language][field]
                if (this.state.descriptionLanguages.includes(language) && ruleset.includes(errorType)) {
                    set(stateValue, `fieldErrors.byLanguages.${language}.${field}`, errorType)
                    this.setRefObjectWithErrorsByLanguage(language)
                    hasError = true
                } else {
                    set(stateValue, `fieldErrors.byLanguages.${language}.${field}`, null)
                }
            })
        })

        return hasError ? stateValue : null
    }

    setRefObjectWithError(field) {
        const refItem = this.refsWithErrors.find(item => item.field === field)
        if (refItem) {
            refItem.hasError = true
        }
    }

    setRefObjectWithErrorsByLanguage(language) {
        this.setRefObjectWithError(`${language}Language`)
    }

    logSubmittingFailed(stateValue) {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[FAILED] submitting, stateValue: ', 'color: red;', stateValue)
        // eslint-disable-next-line no-console
        console.log('form errors', this.formErrors)
    }

    logSubmittingOk() {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[OK] submitting', 'color: green;', this.formData)

        // eslint-disable-next-line no-console
        console.log('form errors', this.formErrors)
    }

    renderModState() {
        return (this.props.isFetched && !this.props.isError) ? (
            <EditState
                isHidden={this.props.data.isHidden}
                state={this.props.data.state}
                onModVisibilityChange={this.handleModVisibilityChange}
                onUpdateCancel={this.handleUpdateCancel}
            />
        ) : null
    }

    renderDownloader() {
        return (
            <div className={styles.ownerName_base}>
                <div className={styles.ownerName_label}>
                    <Caption isLarge isError={!!this.state.fieldErrors.ownerName}>
                        Загрузил:
                    </Caption>
                    {/* {this.renderError()} */}
                </div>
                <div className={classNames(styles.ownerName_field)} title={this.props.data.ownerName} onClick={this.handleGetUserDataClick}>
                    {this.props.data.ownerName}
                </div>
            </div>
        )
    }

    renderAllDownloads() {
        return (
            <div className={styles.downloads_base}>
                <div className={styles.downloads_label}>
                    <Caption isLarge isError={!!this.state.fieldErrors.downloads}>
                        Количество скачиваний:
                    </Caption>
                </div>
                <div className={classNames(styles.downloads_field)} title={formatCounter(this.props.data.downloads)}>
                    {formatWithSeparatedThousands(this.props.data.downloads)}
                </div>
            </div>
        )
    }

    renderRaiting() {
        return (
            <div className={styles.raiting_base}>
                <div className={styles.raiting_label}>
                    <Caption isLarge isError={!!this.state.fieldErrors.mark}>
                        Рейтинг мода:
                    </Caption>
                </div>
                <div className={classNames(styles.raiting_field)}>
                    <p title={this.props.data.mark}>{this.props.data.mark}</p>
                    <p title={formatCounter(this.props.data.ownerId)}>Голосов пользователей: {formatWithSeparatedThousands(this.props.data.ownerId)}</p>
                </div>
            </div>
        )
    }

    renderErrorState() {
        if (!this.props.isError) {
            return null
        }

        return (
            <Error>{COMMON_ERROR}</Error>
        )
    }
    
    renderTitle() {
        if (getUserRole() === 'user') {
            return null
        }

        if (this.props.isFetching || this.props.isError) {
            return null
        }

        return this.props.data.state !== MOD_STATE.DRAFT ? (
            <TitleMajor>
                <div title={this.props.data.title}>
                    <LinesClamp
                        className={styles.clampLines}
                        text={'Проверка мода: '+this.props.data.title || ''}
                        lines={2}
                        buttons={false}
                    />
                </div>
            </TitleMajor>
        ) : (
            <TitleMajor>{REVIEW_MOD_DRAFT_PAGE_TITLE}</TitleMajor>
        )
    }

    renderModVersions() {
        const canRender = this.props.isFetched && !this.props.isError &&
            this.props.isFetchedComments && !this.props.isErrorComments &&
            ![MOD_STATE.REJECTED, MOD_STATE.DRAFT].includes(this.props.data.state)

        /*const canRenderAddControl =
            ![MOD_STATE.IN_REVIEW, MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW].includes(this.props.data.state)*/

        
        return canRender ? (
            <React.Fragment>
                <div className={styles.divider}><Divider /></div>
                <EditUploadUpdateList
                    comments={this.props.comments}
                    versions={this.props.data.versions}
                    onModVersionRemove={this.props.onModVersionRemove}
                    onModVersionVisibilityChange={this.props.onModVersionVisibilityChange}
                />
                {/* {canRenderAddControl && (
                    <React.Fragment>
                        <div className={styles.divider}><Divider isDashed /></div>
                        <EditUploadUpdateAdd
                            isModVersionUploadFormVisible={this.props.isModVersionUploadFormVisible}
                            modId={this.modId}
                            onSetModVersionUploadFormVisibility={this.props.onSetModVersionUploadFormVisibility}
                        />
                    </React.Fragment>
                )} */}
            </React.Fragment>
        ) : null
    }

    renderSubcontentForRejectedState() {
        const canRender = this.props.isFetched && !this.props.isError &&
            this.props.isFetchedComments && !this.props.isErrorComments &&
            [MOD_STATE.REJECTED, MOD_STATE.DRAFT].includes(this.props.data.state)

        if (!canRender) {
            return null
        }

        const commentWithRejection = this.props.comments.find(
            (comment) => comment.status === MOD_VERSION_COMMENT_TYPES.REJECTED,
        )

        return (
            <React.Fragment>
                {commentWithRejection ? (
                    <div className={styles.notificationModerator}>
                        <Notification type="warning">
                            <p>{commentWithRejection.content}</p>
                        </Notification>
                    </div>
                ) : (
                    <div className={styles.divider}><Divider /></div>
                )}

                <EditUploadMod
                    externalError={this.state.fieldErrors.modFile}
                    externalFileName={this.formData.originalFileName}
                    externalFileSize={this.formData.originalFileSize}
                    ref={(component) => {
                        this.refsWithErrors.find(item => item.field === 'modFile').ref = component
                    }}
                    onUploadCompleted={this.handleFileChange}
                />

                <div className={styles.divider}><Divider /></div>
                <EditGameVersion
                    externalGameVersionId={this.formData.gameVersionId}
                    gameVersions={this.props.gameVersions}
                    onGameVersionChange={this.handleGameVersionChange}
                />

                <div className={styles.divider}><Divider /></div>
                <EditModVersion
                    externalError={this.state.fieldErrors.modVersion}
                    externalValue={this.formData.modVersion}
                    ref={(component) => {
                        this.refsWithErrors.find(item => item.field === 'modVersion').ref = component
                    }}
                    onValueChange={this.handleModVersionChange}
                />
            </React.Fragment>
        )
    }

    renderCoverUpload() {
        return (
            <EditCoverUpload
                externalError={this.state.fieldErrors.coverFile}
                externalImageSource={this.formData.coverOriginalSource}
                onUploadCompleted={this.handleCoverChange}
            />
        )
    }

    renderLanguages() {
        const isRemoveLanguageEnabled = this.state.descriptionLanguages.length > 1
        const languageBlocks = this.state.descriptionLanguages.map((language) => {
            return (
                <React.Fragment key={`edit-language-${language}`}>
                    <div className={styles.divider}>
                        <Divider />
                    </div>
                    <EditLanguageForm
                        externalTitle={this.formData.byLanguages[language].title}
                        externalDescription={this.formData.byLanguages[language].description}
                        externalInstallationGuide={this.formData.byLanguages[language].installationGuide}
                        externalTitleError={this.state.fieldErrors.byLanguages[language].title}
                        externalDescriptionError={this.state.fieldErrors.byLanguages[language].description}
                        externalInstallationGuideError={this.state.fieldErrors.byLanguages[language].installationGuide}
                        isRemoveLanguageEnabled={isRemoveLanguageEnabled}
                        language={language}
                        ref={(component) => {
                            this.refsWithErrors.find(item => item.field === `${language}Language`).ref = component
                        }}

                        onDescriptionChange={this.handleDescriptionChange}
                        onInstallationGuideChange={this.handleInstallationGuideChange}
                        onRemoveLanguageClick={this.handleRemoveLanguageClick}
                        onTitleChange={this.handleTitleChange}
                    />
                </React.Fragment>
            )
        })

        /*const availableLanguages = difference(AVAILABLE_LANGUAGES, this.state.descriptionLanguages)
        const addLanguageControl = !isEmpty(availableLanguages) ? (
            <React.Fragment>
                <div className={styles.divider}><Divider isDashed /></div>
                <EditLanguageAdd
                    isDisabled
                    selectedValue={availableLanguages[0]}
                    onAddLanguageClick={this.handleAddLanguageClick}
                />
            </React.Fragment>
        ) : null*/

        return (
            <React.Fragment>
                {languageBlocks}
                {/* {addLanguageControl} */}
            </React.Fragment>
        )
    }

    renderChangelog() {
        return (
            <React.Fragment>
                {[MOD_STATE.DRAFT, MOD_STATE.REJECTED].includes(this.props.data.state) && (
                    <EditChangelog
                        externalError={this.state.fieldErrors.changelog}
                        externalValue={this.formData.changelog}
                        onValueChange={this.handleChangelogChange}
                    />
                )}

                {this.props.changelogHistory.length !== 0 && (
                    <EditChangelogList
                        isLoadMoreEnabled
                        hasCaption
                        historyItems={this.props.changelogHistory}
                        onHistoryItemEdit={this.props.onChangelogHistoryItemEdit}
                        onHistoryItemsReordered={this.props.onChangelogHistoryItemsReordered}
                        onConfirmationDialogShow={this.props.onChangelogConfirmationDialogShow}
                    />
                )}
                <EditChangelogAdd
                    isModEdit
                    hasHistory
                    hasCaption={this.props.changelogHistory.length === 0}
                    onHistoryItemAdd={this.props.onChangelogHistoryItemAdd}
                />
            </React.Fragment>
        )
    }

    renderCategories() {
        return (
            <EditCategories
                categories={this.props.categories}
                checkedItems={this.getSelectedCategoriesFromProps(this.props)}
                externalError={this.state.fieldErrors.categories}
                ref={(component) => {
                    this.refsWithErrors.find(item => item.field === 'categories').ref = component
                }}
                onValueChange={this.handleCategoriesChange}
            />
        )
    }

    renderVoiceover() {
        // check for Set tag - Voiceovers
        let modIsVoiceover = this.isModVoiceover//false;
        let idVoiceoverTag = this.getSelectedCategoriesFromProps(this.props)[0];
        if (idVoiceoverTag) {
            if (idVoiceoverTag === 14) {
                modIsVoiceover = true;
            } else {
                modIsVoiceover = false;
            }
        }

        return modIsVoiceover ? (
            <React.Fragment>
                <div className={styles.divider}><Divider /></div>
                <EditVoiceover
                    externalItems={this.props.data.voiceovers}
                    onVoiceoversChange={this.handleVoiceoversChange}
                />
            </React.Fragment>
        ) : null
    }

    renderScreenshots() {
        return this.props.data.screenshots ? (
            <React.Fragment>
                <div className={styles.divider}><Divider /></div>
                <EditScreenshots
                    externalItems={this.props.data.screenshots}
                    onScreenshotsChange={this.handleScreenshotsChange}
                />
            </React.Fragment>
        ) : null
    }

    renderAuthorName() {
        return (
            <EditAuthorName
                externalError={this.state.fieldErrors.authorName}
                externalValue={this.formData.authorName}
                onValueChange={this.handleAuthorNameChange}
            />
        )
    }

    renderCommentForModerator() {
        return (
            <React.Fragment>
                <div className={styles.divider}><Divider /></div>
                <EditComment
                    externalError={this.state.fieldErrors.comment}
                    externalValue={this.formData.comment}
                    onValueChange={this.handleCommentChange}
                />
            </React.Fragment>
        )
    }

    renderSubmitButtons() {
        const isDraftOrRejected = [MOD_STATE.REJECTED, MOD_STATE.DRAFT].includes(this.props.data.state)
        const isDraft = [MOD_STATE.DRAFT].includes(this.props.data.state)

        return isDraftOrRejected ? (
            <div className={styles.buttons}>
                <div className={styles.button}>
                    <ButtonBlueLarge onClick={this.handlePublishedClick} gtmTag={'ev_click-submit-to-review'}>
                        {PUBLISHED}
                        {/* {SEND_TO_CHECK_BUTTON} handleSendToCheckClick */}
                    </ButtonBlueLarge>
                </div>

                <div className={styles.button}>
                    <ButtonBlueOutlineLarge onClick={this.handleSaveAsDraftClick}>
                        {isDraft ? SAVE_CHANGES : SAVE_AS_DRAFT}
                    </ButtonBlueOutlineLarge>
                </div>

                <div className={styles.actionRemoveMod}>
                    <ActionRemoveMod onClick={isDraft ? this.handleDraftRemove : this.handleModRemove} />
                </div>
            </div>
        ) : (
            <div className={styles.buttons}>
                <div className={styles.button}>
                    <ButtonBlueOutlineLarge onClick={this.handlePublishedClick} gtmTag={'ev_click-submit-to-review'}>
                        {PUBLISHED}
                    </ButtonBlueOutlineLarge>
                </div>

                <div className={styles.button}>
                    <ButtonRedOutlineLarge onClick={this.handleRejectedClick}>
                        {REJECTED}
                    </ButtonRedOutlineLarge>
                </div>
                
                <div className={styles.actionRemoveMod}>
                    <ActionRemoveMod onClick={this.handleModRemove} />
                </div>
            </div>
        )
    }

    renderComments() {
        if (this.modId) {
            return this.props.data.state !== MOD_STATE.DRAFT ? (
                <EditComments
                    modId={this.modId}
                    isDisabled={!this.props.isUserStatisticsSufficient}
                />
            ) : null
        } else {
            return null
        }
    }

    renderNotifications() {
        if (this.props.data.state !== MOD_STATE.DRAFT) {
            return null
        }
        
        if (!this.state.hasError) {
            return (
                <EditNotifications>
                    <div className={styles.notification}>
                        <Notification type="info">
                            <p className={classNames(styles.notificationContent, styles['notificationContent__info'])}>
                                {CREATE_NOTIFICATION_REQUIRED_CONTENT}
                            </p>
                        </Notification>
                    </div>
                </EditNotifications>
            )
        }

        const errorTypeSet = new Set()
        each(omit(this.state.fieldErrors, 'byLanguages'), (errorType) => {
            errorTypeSet.add(errorType)
        })
        each(this.state.fieldErrors.byLanguages, (subObject) => {
            each(subObject, (errorType) => {
                errorTypeSet.add(errorType)
            })
        })

        const isRequiredNotificationVisible = !isEmpty(intersection(
            [FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES, FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED, FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT],
            [...errorTypeSet],
        ))

        const isMaxLengthNotificationVisible = !isEmpty(intersection(
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT],
            [...errorTypeSet],
        ))

        const requiredNotification = isRequiredNotificationVisible ? (
            <div className={styles.notification}>
                <Notification type="warning">
                    <p className={classNames(styles.notificationContent, styles['notificationContent__warning'])}>
                        {CREATE_NOTIFICATION_REQUIRED_CONTENT}
                    </p>
                </Notification>
            </div>
        ) : null

        const maxLengthNotification = isMaxLengthNotificationVisible ? (
            <div className={styles.notification}>
                <Notification type="warning">
                    <p className={classNames(styles.notificationContent, styles['notificationContent__warning'])}>
                        {CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT}
                    </p>
                </Notification>
            </div>
        ) : null

        return (
            <EditNotifications>
                {requiredNotification}
                {maxLengthNotification}
            </EditNotifications>
        )
    }

    renderContent() {
        if (getUserRole() === 'user') {
            return <Error>Что-то пошло не так. Перейдите на главную страницу</Error>
        }
        
        if (this.props.isFetching || this.props.isError) {
            return null
        }

        if (this.props.isUserBanned) {
            return <ErrorBlocked history={this.props.history} />
        }

        const classNameForm = classNames(styles.form, {
            [styles.isDisabled]: !this.props.isUserStatisticsSufficient,
        })

        return (
            <div className={styles.body}>
                <div className={styles.inner}>
                    <div className={styles.content}>
                        {!this.props.isUserStatisticsSufficient && (
                            <div className={styles.notificationBlockedUser}>
                                <NotificationBlockedUser history={this.props.history} />
                            </div>
                        )}

                        {this.props.data.state === MOD_STATE.DRAFT && (
                            <p className={styles.info}>{REVIEW_MOD_INFO}</p>
                        )}

                        <div className={classNameForm}>
                            {this.renderModState()}

                            {this.renderDownloader()}

                            {this.renderAllDownloads()}

                            {this.renderRaiting()}

                            {this.renderSubcontentForRejectedState()}
                            {this.renderModVersions()}

                            <div className={styles.divider}><Divider /></div>
                            {this.renderCoverUpload()}

                            {this.renderLanguages()}

                            <div className={styles.divider}><Divider /></div>
                            {this.renderChangelog()}

                            <div className={styles.divider}><Divider /></div>
                            {this.renderCategories()}

                            {this.renderVoiceover()}

                            {this.renderScreenshots()}

                            <div className={styles.divider}><Divider /></div>
                            {this.renderAuthorName()}

                            {this.renderCommentForModerator()}

                            <div className={styles.divider}><Divider /></div>

                            {this.renderNotifications()}

                            <div className={styles.attentions}>
                                <p className={styles.attention}>
                                    {PUBLISH_ATTENTION_1}
                                </p>
                                <p className={styles.attention}>
                                    {PUBLISH_ATTENTION_2}
                                </p>
                            </div>

                            {this.renderSubmitButtons()}
                        </div>

                        {this.renderComments()}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (!window.__URLS__.pages.review) {
            return <UnavailablePageWrapper />
        } else {
            return (
                <Main>
                    <div className={styles.base}>
                        <div className={styles.head}>
                            <div className={styles.content}>
                                <Back caption={TO_MODDER_SECTION} to={urls.modderSection} />
                                {this.renderTitle()}
                            </div>
                        </div>
                        <StickyContainer>
                            {this.renderContent()}
                        </StickyContainer>
                        {this.renderErrorState()}
                    </div>
                </Main>
            )
        }
    }
}
