import React from 'react';
import { formatWithSeparatedThousands } from './utils/formatting';
import { MOD_STATE, MOD_VERSION_STATUS } from "./utils/constants";

export const TO_LANDING = "На главную страницу"

export const TO_MODDER_SECTION = "К моим модам"

export const LOAD_MORE = "Показать больше"

export const ORDERING_BY_RATING_FILTERED_MODS = "По популярности"

export const ORDERING_BY_CHANGED_AT_FILTERED_MODS = "По дате"

export const COMMON_ERROR = "Что-то пошло не так. Попробуйте обновить страницу."

export const GO_TO_LANDING = "На главную страницу"

const published = "Опубликован"

const draft = "Черновик"

const rejected = "Отклонён"

const inReview = "Проверяется"

const hidden = "Скрыт"

const publishedUpdateInReview = "Обновление проверяется"

const publishedUpdateRejected = "Обновление отклонено"

export const RESET_CAPTION = "Сбросить"

export const CANCEL_CAPTION = "Отменить"

export const SAVE_BUTTON_CAPTION = "Сохранить"

export const MOD_STATE_TITLES = {
    [MOD_STATE.PUBLISHED]: published,
    [MOD_STATE.DRAFT]: draft,
    [MOD_STATE.REJECTED]: rejected,
    [MOD_STATE.IN_REVIEW]: inReview,
    [MOD_STATE.HIDDEN]: hidden,
    [MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW]: publishedUpdateInReview,
    [MOD_STATE.PUBLISHED_UPDATE_REJECTED]: publishedUpdateRejected,
}

export const MOD_VERSION_STATUS_TITLES = {
    [MOD_VERSION_STATUS.PENDING_REVIEW]: "Проверяется",
    [MOD_VERSION_STATUS.PUBLISHED]: "Опубликовано",
    [MOD_VERSION_STATUS.REJECTED]: "Отклонено"
}


export const SEND_TO_CHECK_BUTTON = "Отправить на проверку"

export const PUBLISHED = "Опубликовать"

export const REJECTED = "Отклонить"

export const SAVE = "Сохранить"

export const SAVE_AS_DRAFT = "Сохранить как черновик"

export const SAVE_CHANGES = "Сохранить изменения"

export const EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR = "Вы загружаете файл неверного формата"

export const EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR = "Вы загружаете слишком большой файл"

export const EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR = "Загружаемое изображение должно иметь разрешение не менее 302x170"

export const EDIT_UPLOAD_COMMON_ERROR = "Не удалось загрузить файл. Попробуйте ещё раз."

export const FIELD_MAX_LIMIT_EXCEED_ERROR = (maxChars) => {
    return (
        <React.Fragment>
            Превышен допустимый лимит количества символов: {formatWithSeparatedThousands(maxChars)}
        </React.Fragment>
        //`Превышен допустимый лимит количества символов: ${formatWithSeparatedThousands(maxChars)}`
    )
}

export const FIELD_MIN_LIMIT_EXCEED_ERROR = (minChars) => {
    return (
        <React.Fragment>
            Вы не ввели минимальное количество символов: {minChars}
        </React.Fragment>
        //`Вы не ввели минимальное количество символов: ${minChars}`
    )
}

export const PUBLISH_ATTENTION_1 = (link) => {
    return <React.Fragment>В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, за исключением поля «Автор мода», так как мы не сможем защитить её в соответствии с Политикой конфиденциальности.</React.Fragment>
}

export const PUBLISH_ATTENTION_2 = (link) => {
    return <React.Fragment>Размещая этот мод, вы соглашаетесь с Правилами использования сайта модов</React.Fragment>
}

export const MESSAGE_IS_NOT_SENT = "Не удалось отправить сообщение"

export const MOD_WAS_DELETED = "Этот мод был удалён с нашего сайта"

export const ALREADY_SENT_REPORT_TITLE = "Вы уже отправили сообщение о проблеме"

export const ALREADY_SENT_REPORT_DETAILS = <React.Fragment>Вы уже отправили сообщение о проблеме с этим модом.<br />Мы обязательно проверим мод на наличие проблем.<br />Спасибо вам большое!</React.Fragment>
    //<span dangerouslySetInnerHTML={{__html: `Вы уже отправили сообщение о проблеме с этим модом.${br}Мы обязательно проверим мод на наличие проблем.${br}Спасибо вам большое!`}}></span>

export const ALREADY_SENT_SUPPORT_TICKET_TITLE = "Вы уже отправили тикет"

export const ALREADY_SENT_SUPPORT_TICKET_DETAILS = <React.Fragment>Вы уже отправили тикет в поддержку.<br />Следующий тикет можно отправить через 2 минуты. Пожалуйста дождитесь ответа от Агента поддержки.<br />Спасибо вам большое!</React.Fragment>

export const ATTENTION = "Обратите внимание:"

export const CLOSE = "Закрыть"

export const CREATE_NOTIFICATION_REQUIRED_CONTENT = <React.Fragment>
Для публикации мода, вам необходимо заполнить все поля, отмеченные (<mark>*</mark>)<br /><br />Для сохранения черновика достаточно заполнить поле «НАЗВАНИЕ МОДА».
</React.Fragment>
/*(
    <FormattedMessage
        id="create.notification.required.content"
        defaultMessage="Для публикации мода, вам необходимо заполнить все поля, отмеченные {required}{br}{br}Для сохранения черновика достаточно заполнить поле «НАЗВАНИЕ МОДА»."
        values={{
            required: (<mark>*</mark>),
            br: <br />,
        }}
    />
)*/

export const CREATE_NOTIFICATION_ERROR_LIMIT_CONTENT = "Превышен допустимый лимит количества символов при заполнении некоторых полей"

export const ALL_CAPTION = "Все"

export const ALL_GAME_VERSIONS = { id: 'Все', version: ALL_CAPTION }
