import React from 'react';
import PropTypes from 'prop-types';
import { first } from 'lodash';

import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';

import {
    DIALOG_INFORMATION_TITLE,
    DIALOG_INFORMATION_CONTENT,
    DIALOG_INFORMATION_WELCOME,
    DIALOG_INFORMATION_OK_BUTTON,
} from './translations';

import styles from './DialogInformation.module.css';


export default class DialogInformation extends React.PureComponent {
    static propTypes = {
        versions: PropTypes.arrayOf(PropTypes.shape({
            versionFile: PropTypes.string.isRequired,
            versionFileOriginalName: PropTypes.string.isRequired,
        })).isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    handleDownloadClick = () => {
        const shouldOpenDialogDownload = this.props.versions.length > 1

        if (!shouldOpenDialogDownload) {
            this._downloadLinkRef.click()
        }

        this.props.onSubmitDialog(shouldOpenDialogDownload)
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_INFORMATION_TITLE}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_INFORMATION_CONTENT}</p>
                    <p>{DIALOG_INFORMATION_WELCOME}</p>
                </div>
                <div className={styles.footer}>
                    <ButtonBlueLarge onClick={this.handleDownloadClick}>
                        {DIALOG_INFORMATION_OK_BUTTON}
                    </ButtonBlueLarge>
                    <a
                        className={styles.hidden}
                        ref={c => (this._downloadLinkRef = c)}
                        href={first(this.props.versions).versionFile}
                        download={first(this.props.versions).versionFileOriginalName}
                    > </a>
                </div>
            </div>
        )
    }
}
