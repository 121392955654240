import React from 'react';
import PropTypes from 'prop-types';

import ButtonRedLarge from '../../Buttons/ButtonRedLarge/ButtonRedLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';
import {
    DIALOG_REPORT_MOD_COMPLETE_TITLE,
    DIALOG_REPORT_MOD_COMPLETE_CONTENT,
    DIALOG_REPORT_MOD_COMPLETE_OK_BUTTON,
} from './translations';

import styles from './DialogReportModComplete.module.css';

export default class DialogReportModComplete extends React.PureComponent {
    static propTypes = {
        onCloseDialog: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_REPORT_MOD_COMPLETE_TITLE}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_REPORT_MOD_COMPLETE_CONTENT}</p>
                </div>
                <div className={styles.footer}>
                    <ButtonRedLarge onClick={this.props.onCloseDialog}>
                        {DIALOG_REPORT_MOD_COMPLETE_OK_BUTTON}
                    </ButtonRedLarge>
                </div>
            </div>
        )
    }
}
