import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Notification.module.css';

export default class Notification extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        type: PropTypes.oneOf(['warning', 'info']),
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles[`base__${this.props.type}`]]: this.props.type,
        })

        return (
            <div className={classNameBase}>
                {this.props.children}
            </div>
        )
    }
}
