//import React from 'react';
import { connect } from 'react-redux';

//import { incrementDownloadCount } from 'actions/Details'
import { fetchSubscriptions, ignoreModUpdate, unsubscribeMod } from '../../actions/Followed';
import Followed from '../../components/Followed/Followed'
import { openDialog } from '../../actions/Dialog';
import DialogLogin from '../../components/Dialogs/DialogLogin/DialogLogin';
import { checkAccData } from '../../utils/functions';

const mapStateToProps = (state) => ({
  lang: state.currentAccount.lang,
  isLoggedIn: !!state.currentAccount.spaId,
  isFetching: state.followed.isFetching,
  items: state.followed.items,
  isError: state.followed.isError,
})

const mapDispatchToProps = (dispatch) => ({
  fetchItems: (userName) => {
    dispatch(fetchSubscriptions(userName))
  },
  onShowLoginDialog: () => {
    dispatch( checkAccData(openDialog(<DialogLogin />)) )
  },
  onIncrementDownloadCount: (modId) => {
    //return dispatch(incrementDownloadCount(modId))
    console.log('incrementDownloadCount '+modId)
  },
  onIgnoreUpdate: (modId, userName, id) => {
    Promise.resolve(
        dispatch(ignoreModUpdate(modId, userName, id))
    ).then(() => dispatch(fetchSubscriptions(userName)))
  },
  onUnsubscribe: (modId, userName) => {
    Promise.resolve(
        dispatch(unsubscribeMod(modId, userName))
    ).then(() => dispatch(fetchSubscriptions(userName)))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Followed)
