import { get, cloneDeep, orderBy } from 'lodash';

import settings from '../settings';
import * as actions from '../actions/EditMod';

const getInitialState = () => ({
    comments: [],
    data: {},
    id: null,
    isError: false,
    isErrorComments: false,
    isErrorForbidden: false,
    isErrorNotFound: false,
    isFetched: false,
    isFetchedComments: false,
    isFetching: false,
    isFetchingComments: false,
    isModVersionUploadFormVisible: false,
})

const getSlicedVersionsArray = (state, payload) => {
    const initialArray = orderBy(state.data.versions, ['id'], ['asc'])
    const newVersion = payload.version
    const result = [newVersion, ...initialArray]
    return result.length > settings.modMaxVersions ? result.slice(result.length - settings.modMaxVersions) : result
}

const getUpdatedVisibilityVersionsArray = (state, payload) => {
    const result = cloneDeep(state.data.versions)
    const targetVersion = result.find(item => item.id === payload.modVersionId)
    if (targetVersion) {
        targetVersion.isVisible = payload.isVisible
    }
    return result
}

export const editMod = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.START_FETCHING_EDIT_DETAILS:
            return {
                ...state,
                isError: false,
                isErrorForbidden: false,
                isErrorNotFound: false,
                isFetching: true,
                isFetched: false,
            }
        case actions.STOP_FETCHING_EDIT_DETAILS:
            return {
                ...state,
                isFetching: false,
                isFetched: true,
            }
        case actions.SET_ERROR_FETCHING_EDIT_DETAILS:
            return {
                ...state,
                isError: true,
                isErrorForbidden: get(action.payload, 'errorData.status') === 403,
                isErrorNotFound: get(action.payload, 'errorData.status') === 404,
            }
        case actions.UPDATE_EDIT_DETAILS:
            return {
                ...state,
                data: action.payload.data,
                id: action.payload.id,
                isError: false,
                isErrorForbidden: false,
                isErrorNotFound: false,
                isModVersionUploadFormVisible: false,
            }
        case actions.UPDATE_EDIT_MOD_STATE:
            return {
                ...state,
                data: {
                    ...state.data,
                    state: action.payload.modState,
                },
            }
        case actions.UPDATE_EDIT_MOD_VISIBILITY:
            return {
                ...state,
                data: {
                    ...state.data,
                    isHidden: action.payload.isHidden,
                },
            }
        default:
            return manageVersions(state, action)
    }
}

const manageVersions = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.REMOVE_MOD_VERSION_COMPLETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    versions: state.data.versions.filter((version) => version.id !== action.payload.modVersionId),
                },
            }
        case actions.ADD_MOD_VERSION_COMPLETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    versions: getSlicedVersionsArray(state, action.payload),
                },
                isModVersionUploadFormVisible: false,
            }
        case actions.CHANGE_MOD_VERSION_VISIBILITY_COMPLETED:
            return {
                ...state,
                data: {
                    ...state.data,
                    versions: getUpdatedVisibilityVersionsArray(state, action.payload),
                },
            }
        default:
            return editComments(state, action)
    }
}

const editComments = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.START_FETCHING_EDIT_COMMENTS:
            return {
                ...state,
                isErrorComments: false,
                isFetchingComments: true,
                isFetchedComments: false,
            }
        case actions.STOP_FETCHING_EDIT_COMMENTS:
            return {
                ...state,
                isFetchingComments: false,
                isFetchedComments: true,
            }
        case actions.SET_ERROR_FETCHING_EDIT_COMMENTS:
            return {
                ...state,
                isErrorComments: true,
            }
        case actions.UPDATE_EDIT_COMMENTS:
            return {
                ...state,
                comments: action.payload.comments,
                isErrorComments: false,
            }
        default:
            return modVersionUploadFormVisibility(state, action)
    }
}

const modVersionUploadFormVisibility = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.SET_MOD_VERSION_UPLOAD_FROM_VISIBILITY:
            return {
                ...state,
                isModVersionUploadFormVisible: action.payload.isVisible,
            }
        default:
            return state
    }
}
