import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
//import Blur from 'react-blur';
import { first, uniqueId } from 'lodash';
import classNames from 'classnames';

//import { formatFileSize } from '../../utils/formatting';
import { 
    //getCookie, 
    //parseJwt,  
    getUsername,
    getUserRole
} from '../../utils/functions';
import { pushHistoryWithErrorNotFound, pushHistoryWithOwner, pushHistoryWithReview, pushHistoryWithTags, urls } from '../../utils/routing';
import { getStringLengthFromHtml } from '../../utils/strings';
import { COMMON_ERROR, TO_LANDING } from '../../translations';
//import { pushHistoryWithTags } from '../../components/Categories/Categories';
import store from '../../store'; // загружается виджет с комментами

import CommentsWidget from '../../components/CommentsWidget/CommentsWidget';
import LinesClamp from '../LinesClamp/LinesClamp';
import Main from '../../components/Main/Main';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';
//import { ButtonBlueLarge } from '../../components/Buttons/ButtonBlueLarge/ButtonBlueLarge';
import Back from '../../components/Buttons/Back/Back';
import Error from '../../components/Error/Error';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import TitleMinor from '../../components/TitleMinor/TitleMinor';
import Caption from '../../components/Caption/Caption';
import Tag from '../../components/Tag/Tag';
import Content from '../../components/Content/Content';
import Screenshots from '../../components/Screenshots/Screenshots';
import ButtonBlueOutlineLarge from '../../components/Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import ActionSubscribe from '../../components/ActionSubscribe/ActionSubscribe';
import ActionReport from '../../components/ActionReport/ActionReport';
import ActionReviewMod from '../ActionReviewMod/ActionReviewMod';
import ModDetails from '../../components/ModDetails/ModDetails';
import Voting from '../../components/Voting/Voting';
import Mplayer from '../../components/Mplayer/Mplayer';
import LanguageSelectMini from '../../components/LanguageSelectMini/LanguageSelectMini';

import { 
    DETAILS_TAGS_TITLE,
    DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS,
    DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS,
    DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS,
    DETAILS_AUTHORED_BY,
    DETAILS_UPLOADED_BY,
    DETAILS_DESCRIPTION_TITLE,
    DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION,
    DETAILS_INSTALLATION_GUIDE_TITLE,
    DETAILS_CHANGELOG_TITLE,
    DETAILS_CHANGELOG_VERSION,
    //DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION,
    DETAILS_BUTTON_LOAD_CAPTION,
    DETAILS_COMMENTS_TITLE,
    messages,
    notOnlyDigits
} from './translations';

import styles from './ModPage.module.css';

export default class ModPage extends Component {
    static propTypes = {
        data: PropTypes.shape({
            authorName: PropTypes.string,
            changelogs: PropTypes.arrayOf(PropTypes.shape({
                modVersion: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            createdAt: PropTypes.string,
            cover: PropTypes.string,
            descriptions: PropTypes.arrayOf(PropTypes.shape({
                language: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            downloads: PropTypes.number,
            gameVersion: PropTypes.string,
            installationGuides: PropTypes.arrayOf(PropTypes.shape({
                language: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })),
            isReported: PropTypes.bool,
            isSubscribed: PropTypes.bool,
            mark: PropTypes.number,
            markVotesCount: PropTypes.number,
            modVersion: PropTypes.string,
            ownerId: PropTypes.number,
            ownerName: PropTypes.string,
            screenshots: PropTypes.arrayOf(PropTypes.shape({
                //height: PropTypes.number,
                id: PropTypes.number.isRequired,
                position: PropTypes.number.isRequired,
                source: PropTypes.string.isRequired,
                sourceFullSize: PropTypes.string.isRequired,
                sourceViewPage: PropTypes.string.isRequired,
                //width: PropTypes.number,
            })),
            voiceovers: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                url: PropTypes.string,
            })), // если мод озвучки то возможно загруженно аудио для предпрослушивания
            tags: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                caption: PropTypes.string.isRequired,
            })),
            title: PropTypes.string,
            updatedAt: PropTypes.string,
            userVote: PropTypes.oneOf([-1, 0, 1]),
            versions: PropTypes.arrayOf(PropTypes.shape({
                downloadUrl: PropTypes.string.isRequired,
                gameVersion: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                modVersion: PropTypes.string.isRequired,
                versionFileOriginalName: PropTypes.string.isRequired,
                versionFileSize: PropTypes.number,
            })),
        }).isRequired,
        realm: PropTypes.string.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        isError: PropTypes.bool.isRequired,
        isErrorNotFound: PropTypes.bool.isRequired,
        isFetched: PropTypes.bool.isRequired,
        isFetching: PropTypes.bool.isRequired,
        isModDownloadAccepted: PropTypes.bool,
        language: PropTypes.string.isRequired,

        history: ReactRouterPropTypes.history.isRequired,
        //match: ReactRouterPropTypes.match.isRequired,

        //intl: intlShape,

        fetchDetails: PropTypes.func.isRequired,
        onDownloadClick: PropTypes.func.isRequired,
        onFollowMod: PropTypes.func.isRequired,
        onLoginLinkClick: PropTypes.func.isRequired,
        onReportButtonClick: PropTypes.func.isRequired,
        onUnsubscribeMod: PropTypes.func.isRequired,
        voteForMod: PropTypes.func.isRequired,
    }
    
    constructor() {
        super()

        this.state = {
            commentsLanguage: null,
            descriptionsLanguage: null,
            isChangelogExpanded: false,
            modId: this.modId, // JSON.parse(window.location.pathname.replace(/[^\w]/gi, ''))
        }
    }
    
    componentDidMount() {
        this.modId = +this.props.location.pathname.replace(/[^\w]/gi, '')// +this.props.match.params.modId.toString().replace(/\/$/, '')
        if (notOnlyDigits(this.modId)) { pushHistoryWithErrorNotFound(this.props.history) } // если содержит что-то кроме цифр, ошибка
        let userName = getUsername()
        this.props.fetchDetails(this.modId, userName)
        
        //this.setDocumentTitle()
    }

    UNSAFE_componentWillReceiveProps(nextProps) { //  componentDidUpdate
        if (notOnlyDigits(window.location.pathname.replace(/[^\w]/gi, ''))) { pushHistoryWithErrorNotFound(this.props.history) } // если содержит что-то кроме цифр, ошибка
        if (nextProps.isErrorNotFound) {
            if (this.props.history !== null && (typeof this.props.history !== 'undefined') ) {
                pushHistoryWithErrorNotFound(this.props.history)
            } else {
                window.location.href = urls.errorNotFound;
            }
        }
        this.setDocumentTitle(nextProps)
    }
    
    setDocumentTitle(props) { // props
        if (props.data.title) {
            //document.title = messages(this.props.data.title)
            document.title = messages(props.data.title)
        }
    }

    handleTagClick = (tagId) => {
        /*if ( this.props.history !== null) {
            pushHistoryWithTags(this.props.history, [tagId])
        } else {
            window.location.href = `/?tags=${tagId}`
        }*/
        pushHistoryWithTags(this.props.history, [tagId])
        //window.location.href = `/search/?tags=${tagId}`
    }

    handleOwnerClick = () => {
        pushHistoryWithOwner(this.props.history, this.props.data.ownerId, this.props.data.ownerName)
        //window.location.href = `/search/?owner=${this.props.data.ownerId}`
    }

    handleLoadMoreChangelogItems = () => {
        this.setState({
            isChangelogExpanded: true,
        })
    }

    handleDescriptionsLanguageChange = (descriptionsLanguage) => {
        this.setState({
            descriptionsLanguage,
        })
    }

    handleAuthenticateLinkClick = (event) => {
        event.preventDefault()
        this.props.onLoginLinkClick()
    }

    handleDownloadClick = (isModDownloaded) => {
        this.props.onDownloadClick(
            this.props.isModDownloadAccepted, isModDownloaded, this.modId, this.props.data.title, this.props.data.versions,
        ) // this.state.modId
    }

    handleReportButtonClick = () => {
        this.props.onReportButtonClick(this.modId) // this.state.modId
    }

    handleReviewButtonClick = () => {
        return getUserRole() === 'admin' || getUserRole() === 'moderator' || getUserRole() === 'robot' ? 
            pushHistoryWithReview(this.props.history, this.modId) : null
    }

    handleVoteAction = (value) => {
        let userName = getUsername();
        this.props.voteForMod(this.modId, value, userName) // this.state.modId
    }

    handleFollowMod = () => {
        let userName = getUsername();
        this.props.onFollowMod(this.modId, userName) // this.state.modId
    }

    handleUnsubscribeMod = () => {
        let userName = getUsername();
        this.props.onUnsubscribeMod(this.modId, userName) // this.state.modId
    }

    /*getUsername = () => {
        // получаем данные из локального хранилища и из куков
        if (getCookie('mtm_jwt')) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie('mtm_jwt').split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie('mtm_jwt'))

                return {'spaUsername': payloadJWT_client['spaUsername'], 'spaId': payloadJWT_client['spaId']}
            } else {
                return {'spaUsername': null, 'spaId': null}
            }
        } else {
            return {'spaUsername': null, 'spaId': null}
        }
    }*/

    renderErrorState() {
        if (!this.props.isError) {
            return null
        }
        return (
            <Error>{COMMON_ERROR}</Error>
        )
    }

    renderBlur() {
        return null
        /*return this.props.data.cover ? (
            <Blur className={styles.reactBlur} img={this.props.data.cover} blurRadius={40} />
        ) : null*/
    }

    renderCover() {
        return null
        /*return this.props.data.cover ? (
            <div className={styles.cover}>
                {this.renderBlur()}
            </div>
        ) : null*/
    }

    renderTags() {
        return this.props.data.tags.map((tag, index) => (
            <div className={styles.tag} key={`styles.mod-tag-${tag.id}-${index}`}>
                <Tag
                    id={tag.id}
                    caption={tag.caption}
                    type="light"
                    onClick={this.handleTagClick}
                />
            </div>
        ))
    }

    renderActions() {
        return this.props.isAuthenticated ? 
            getUserRole() === 'admin' || getUserRole() === 'moderator' || getUserRole() === 'robot' ? (
            <div className={styles.actions}>
                <div className={styles.action}>
                    <ActionSubscribe
                        isSubscribed={this.props.data.isSubscribed}
                        onFollowMod={this.handleFollowMod}
                        onUnsubscribeMod={this.handleUnsubscribeMod}
                    />
                </div>
                <div className={styles.action}>
                    <ActionReport
                        isCompleted={this.props.data.isReported}
                        onClick={this.handleReportButtonClick}
                    />
                </div>
                <div className={styles.action}>
                    <ActionReviewMod
                        //isCompleted={this.props.data.isReported}
                        //isDisabled
                        onClick={this.handleReviewButtonClick}
                    />
                </div>
            </div>
        ) : (
            <div className={styles.actions}>
                <div className={styles.action}>
                    <ActionSubscribe
                        isSubscribed={this.props.data.isSubscribed}
                        onFollowMod={this.handleFollowMod}
                        onUnsubscribeMod={this.handleUnsubscribeMod}
                    />
                </div>
                <div className={styles.action}>
                    <ActionReport
                        isCompleted={this.props.data.isReported}
                        onClick={this.handleReportButtonClick}
                    />
                </div>
            </div>
        ) : (
            <div className={classNames(styles.actions, [styles.actions__disabled])}>
                <div className={styles.action}>
                    <ActionSubscribe isDisabled />
                </div>
                <div className={styles.action}>
                    <ActionReport isDisabled />
                </div>
                <p className={styles.actionsMessage}>
                    {DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS(this.handleAuthenticateLinkClick)}
                </p>
            </div>
        )
    }

    renderScreenshots() {
        if (this.props.data.screenshots.length === 0) {
            return null
        }
        return (
            <Screenshots items={this.props.data.screenshots} />
        )
    }

    renderMplayer = () => {
        if ( this.props.data.tags.filter(el => {return el.id === 14}).length === 0 ) {
            return null
        } else if ( this.props.data.voiceovers.length === 0 ) {
            return null
        } else {
            return <Mplayer
                    title={this.props.data.title}
                    src={this.props.data.voiceovers} // https://justsherlock.ru/mods/audio/olegbrain.mp3
                    />
        }
    }

    renderDescriptions() {
        if (this.props.data.descriptions.length === 0) {
            return null
        }
        const language = this.state.descriptionsLanguage || this.props.language
        const description = this.props.data.descriptions.find((item) => item.language === language) || this.props.data.descriptions[0]
        if (!description.content) {
            return null
        }
        const toggler = this.props.data.descriptions.length === 2 ? (
            <div className={styles.descriptionLanguageSelect}>
                <Caption>{DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION}</Caption>
                <LanguageSelectMini
                    checkedLanguage={language}
                    onLanguageChange={this.handleDescriptionsLanguageChange}
                />
            </div>
        ) : null
        return (
            <React.Fragment>
                <div className={styles.title} key="descriptions-toggler">
                    <TitleMinor>{DETAILS_DESCRIPTION_TITLE}</TitleMinor>
                    {toggler}
                </div>
                <Content isEditor key="descriptions-content">
                    <div dangerouslySetInnerHTML={{ __html: description.content }} />
                </Content>
            </React.Fragment>
        )
    }

    renderInstallationGuides() {
        if (this.props.data.installationGuides.length === 0) {
            return null
        }
        const language = this.state.descriptionsLanguage || this.props.language
        const installationGuide = this.props.data.installationGuides.find((item) => item.language === language) ||
        this.props.data.installationGuides[0]

        if (!installationGuide.content || !getStringLengthFromHtml(installationGuide.content)) {
            return null
        }
        return (
            <React.Fragment>
                <div className={styles.title} key="installation-guides-title">
                    <TitleMinor key="installation-guides-title">
                        {DETAILS_INSTALLATION_GUIDE_TITLE}
                    </TitleMinor>
                </div>
                <Content isEditor key="installation-guides-content">
                    <div dangerouslySetInnerHTML={{ __html: installationGuide.content }} />
                </Content>
            </React.Fragment>
        )
    }

    renderChangelogs() {
        if (this.props.data.changelogs.length === 0) {
            return null
        }
        const items = this.state.isChangelogExpanded ?
            this.props.data.changelogs :
            this.props.data.changelogs.slice(0, 5)
        const isButtonEnabled = this.props.data.changelogs.length > items.length
        return (
            <React.Fragment>
                <div className={styles.title}>
                    <TitleMinor>
                        {DETAILS_CHANGELOG_TITLE}
                    </TitleMinor>
                </div>
                <Content>
                    {items.map((item, idx) => (
                        <React.Fragment key={uniqueId(`changlelog-item-${idx}`)}>
                            <h4>{DETAILS_CHANGELOG_VERSION(item.modVersion)}</h4>
                            <p className={styles.changelogs}>{item.content}</p>
                            <hr />
                        </React.Fragment>
                    ))}
                </Content>
                {isButtonEnabled && !this.state.isChangelogExpanded ? (
                    <div className={styles.loadMore}>
                        <ButtonBlueOutlineLarge onClick={this.handleLoadMoreChangelogItems}>
                            {DETAILS_BUTTON_LOAD_CAPTION}
                        </ButtonBlueOutlineLarge>
                    </div>
                ) : null}
            </React.Fragment>
        )
    }

    renderCommentsWidget() {
        let language = this.state.commentsLanguage || this.props.language

        if (this.props.data.descriptions.length === 1) {
            language = this.props.data.descriptions[0].language
        }

        return (
            <CommentsWidget
                lang={language}
                modId={this.state.modId}
                {...store.comments}
            />
        )
    }

    renderContent() {
        if (this.props.isError) {
            return null
        }
        //const classNameComments = classNames(styles.comments, styles[`comments__${this.props.language}`])
        return (
            <React.Fragment>
                {this.renderCover()}
                <div className={styles.base}>
                    <div className={styles.voting}>
                        <Voting
                            isAuthenticated={this.props.isAuthenticated}
                            mark={this.props.data.mark}
                            vote={this.props.data.markVotesCount}
                            userVote={this.props.data.userVote}
                            onVoteAction={this.handleVoteAction}
                        />
                    </div>

                    <div className={styles.header}>
                        <TitleMajor>
                            <div title={this.props.data.title}>
                                <LinesClamp
                                    text={this.props.data.title}
                                    lines={2}
                                />
                                {/* <div className="clamp-lines-2">
                                    {this.props.data.title}
                                </div> */}
                                {/* <ClampLines
                                    className={styles.clampLines}
                                    text={this.props.data.title}
                                    lines={2}
                                    buttons={false}
                                /> */}
                            </div>
                        </TitleMajor>

                        <div className={styles.details}>
                            <div className={styles.summary}>
                                <div className={styles.tags}>
                                    <Caption>{DETAILS_TAGS_TITLE}</Caption>
                                    {this.renderTags()}
                                </div>
                                {this.props.data.authorName ? (
                                    <div className={styles.author}>
                                        <Caption>{DETAILS_AUTHORED_BY}</Caption>
                                        <span title={this.props.data.authorName}>{this.props.data.authorName}</span>
                                    </div>
                                ) : null}
                                <div className={styles.owner}>
                                    <Caption>{DETAILS_UPLOADED_BY}</Caption>
                                    <span onClick={this.handleOwnerClick} title={this.props.data.ownerName}>
                                        {this.props.data.ownerName}
                                    </span>
                                </div>
                            </div>

                            {/* <div className={styles.modDetails} id="mod_download">
                                <ModDetails
                                    modName={prosp.data.title}
                                    createdAt={prosp.data.createdAt}
                                    downloads={prosp.data.downloads}
                                    gameVersion={prosp.data.gameVersion}
                                    modSize={first(prosp.data.versions).versionFileSize}
                                    modVersion={first(prosp.data.versions).modVersion}
                                    modVersionFile={first(prosp.data.versions).downloadUrl}
                                    modVersionFileOriginalName={first(prosp.data.versions).versionFileOriginalName}
                                    updatedAt={prosp.data.updatedAt}
                                    isModDownloadAccepted={prosp.isModDownloadAccepted}
                                    isSingleVersionAvailable={prosp.data.versions.length === 1}
                                    onDownloadClick={this.handleDownloadClick}
                                />
                            </div> */}
                        </div>
                    </div>

                    <div className={styles.body}>
                        {this.renderActions()}
                        {this.renderScreenshots()}
                        {this.renderMplayer()}
                        {this.renderDescriptions()}
                        {this.renderInstallationGuides()}
                        <div className={styles.modDetails} id="mod_download">
                            <ModDetails
                                modName={this.props.data.title}
                                createdAt={this.props.data.createdAt}
                                downloads={this.props.data.downloads}
                                gameVersion={this.props.data.gameVersion}
                                modSize={first(this.props.data.versions).versionFileSize}
                                modVersion={first(this.props.data.versions).modVersion}
                                modVersionFile={first(this.props.data.versions).downloadUrl}
                                modVersionFileOriginalName={first(this.props.data.versions).versionFileOriginalName}
                                updatedAt={this.props.data.updatedAt}
                                isModDownloadAccepted={this.props.isModDownloadAccepted}
                                isSingleVersionAvailable={this.props.data.versions.length === 1}
                                onDownloadClick={this.handleDownloadClick}
                            />
                        </div>
                        {this.renderChangelogs()}
                        <div className={styles.commentsTitle}>
                            <div className={styles.commentsTitleText}>{DETAILS_COMMENTS_TITLE}</div>
                            {this.props.data.descriptions.length === 2 && (
                                <div className={styles.commentsTitleLanguageSelect}>
                                    {/* <Caption>{DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION}</Caption>
                                    <LanguageSelectMini
                                        checkedLanguage={this.state.commentsLanguage || prosp.language}
                                        onLanguageChange={this.handleCommentsLanguageChange}
                                    /> */}
                                </div>
                            )}
                        </div>
                        {this.props.isAuthenticated ? (
                            <p className={styles.commentsInfo}>
                                {/* {DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS(getPrivacyPolicyUrlByRealm(prosp.realm))} */}
                                {DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS()}
                            </p>
                        ) : (
                            <p className={styles.commentsMessage}>
                                {/* {DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS(this.handleAuthenticateLinkClick)} */}
                                {DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS(this.handleAuthenticateLinkClick)}
                            </p>
                        )}
                        {/*<div className={classNameComments}>
                            {this.renderCommentsWidget()} // {this.renderHyperCommentsWidget()}
                        </div>*/}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        if (window.__URLS__.pages.details) {
            if (!this.props.isFetched || this.props.isFetching) {
                return null
            }
            return (
                <Main>
                    <Back caption={TO_LANDING} to={urls.landing} />
                    {this.renderContent()}
                    {this.renderErrorState()}
                </Main>
            )
        } else {
            return <UnavailablePage />
        }
    }
}
