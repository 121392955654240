import { connect } from 'react-redux';

import { closeDialog } from '../../actions/Dialog';

import DialogReportModComplete from '../../components/Dialogs/DialogReportModComplete/DialogReportModComplete';

const mapStateToProps = (state) => ({
    versions: state.details.data.versions,
})

const mapDispatchToProps = (dispatch) => ({
    onCloseDialog: () => {
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogReportModComplete)
