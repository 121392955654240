import React from 'react';

import styles from './Spinner.module.css';

export default class Spinner extends React.PureComponent {
    render() {
        return (
            <div className={styles.base}>
                <div className={styles.spinner} />
                <div className={styles.logo} />
            </div>
        )
    }
}
