import React from 'react';
import PropTypes from 'prop-types';
import { first } from 'lodash';

import { formatFileSize } from '../../../utils/formatting';

import Alert from '../../Alert/Alert';
import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import Caption from '../../Caption/Caption';
import TitleDialog from '../../TitleDialog/TitleDialog';
import Select from '../../Select/Select';

import {
    DIALOG_DOWNLOAD_ALERT,
    DIALOG_DOWNLOAD_ATTENTION,
    DIALOG_DOWNLOAD_CAPTION,
    DIALOG_DOWNLOAD_OK_BUTTON,
    DIALOG_DOWNLOAD_SELECT_CAPTION,
    DIALOG_DOWNLOAD_VERSION_VALUE,
} from './translations';

import styles from './DialogDownload.module.css';


export default class DialogDownload extends React.PureComponent {
    static propTypes = {
        lastGameVersionId: PropTypes.number.isRequired,
        modId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        versions: PropTypes.arrayOf(PropTypes.shape({
            downloadUrl: PropTypes.string.isRequired,
            gameVersion: PropTypes.string.isRequired,
            gameVersionId: PropTypes.number.isRequired,
            id: PropTypes.number.isRequired,
            modVersion: PropTypes.string.isRequired,
            versionFileOriginalName: PropTypes.string.isRequired,
            versionFileSize: PropTypes.number,
        })),

        onSubmitDialog: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            gameVersionId: null,
            modVersionId: null,
            modVersionFile: null,
            modVersionFileOriginalName: null,
            modVersionFileSize: null,
        }
    }

    componentDidMount() {
        const lastModVersion = first(this.props.versions)
        this.setState({
            gameVersionId: lastModVersion.gameVersionId,
            modVersionId: lastModVersion.id,
            modVersionFile: lastModVersion.downloadUrl,
            modVersionFileOriginalName: lastModVersion.versionFileOriginalName,
            modVersionFileSize: lastModVersion.versionFileSize,
        })
    }

    handleModVersionChange = (modVersionId) => {
        const selectedVersion = this.props.versions.find((item) => (item.id === modVersionId))
        this.setState({
            modVersionId,
            gameVersionId: selectedVersion.gameVersionId,
            modVersionFile: selectedVersion.downloadUrl,
            modVersionFileOriginalName: selectedVersion.versionFileOriginalName,
            modVersionFileSize: selectedVersion.versionFileSize,
        })
    }

    handleDownloadClick = () => {
        this._downloadLinkRef.click()
        this.props.onSubmitDialog(this.props.modId)
    }

    renderAlert() {
        return this.state.gameVersionId !== this.props.lastGameVersionId ? (
            <Alert content={DIALOG_DOWNLOAD_ALERT} />
        ) : null
    }

    render() {
        const itemsForSelect = this.props.versions.map((item) => ({
            caption: DIALOG_DOWNLOAD_VERSION_VALUE(item.modVersion, item.gameVersion),
            value: item.id,
        }))

        return (
            <div className={styles.base}>
                <TitleDialog>
                    <span title={this.props.title}>
                        {DIALOG_DOWNLOAD_CAPTION(this.props.title)}
                    </span>
                </TitleDialog>
                <div className={styles.modVersion}>
                    <Caption>{DIALOG_DOWNLOAD_SELECT_CAPTION}</Caption>
                    <div className={styles.select}>
                        <Select
                            gtmTag={'ev_click-change-version'}
                            items={itemsForSelect}
                            selectedValue={this.state.modVersionId}
                            isDisabled={itemsForSelect.length === 1}
                            onItemChange={this.handleModVersionChange}
                        />
                    </div>
                </div>
                {this.renderAlert()}
                <hr className={styles.hr} />
                <p className={styles.attention}>{DIALOG_DOWNLOAD_ATTENTION}</p>
                <ButtonBlueLarge onClick={this.handleDownloadClick} gtmTag={'ev_click-download2'}>
                    {DIALOG_DOWNLOAD_OK_BUTTON(formatFileSize(this.state.modVersionFileSize))}
                </ButtonBlueLarge>
                <a
                    className={styles.hidden}
                    ref={c => (this._downloadLinkRef = c)}
                    href={this.state.modVersionFile}
                    download={this.state.modVersionFileOriginalName}
                > </a>
            </div>
        )
    }
}
