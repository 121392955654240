import React from 'react';

import { formatWithSeparatedThousands } from '../../../utils/formatting';

export const EDIT_LANGUAGE_FORM_LABEL = (lang) => {
    return (
        <React.Fragment>
            Язык описания: {lang}
        </React.Fragment>
    )
}

export const EDIT_LANGUAGE_FORM_RU = `Русский`

export const EDIT_LANGUAGE_FORM_EN = "Английский"

export const EDIT_LANGUAGE_FORM_CANCEL_CAPTION = "Удалить описание"

export const EDIT_LANGUAGE_FORM_NAME_LABEL = "Введите название мода"

export const EDIT_LANGUAGE_FORM_DESCRIPTION_LABEL = "Введите описание"

export const EDIT_LANGUAGE_FORM_DESCRIPTION_TEXT = (maxChars) => {
    let maxCharss = formatWithSeparatedThousands(maxChars)
    return (
        <React.Fragment>
            Не более {maxCharss} символов
        </React.Fragment>
    )
}

export const EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_LABEL = "Инструкция  по установке"

export const EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_TEXT = (maxChars) => {
    let maxCharss = formatWithSeparatedThousands(maxChars)
    return (
        <React.Fragment>
            Не более {maxCharss} символов
        </React.Fragment>
    )
}
