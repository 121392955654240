import React from "react";

export const EDIT_COMMENTS_TITLE = (count) => {
    let counts = <sup>{count}</sup>
    return (
        <React.Fragment>
            Комментарии {counts}
        </React.Fragment>
    )
}

export const EDIT_MOD_INPUT_PLACEHOLDER = "Введите текст сообщения..."
