import React from 'react';
import PropTypes from 'prop-types';

import {
    ACTION_REMOVE_DRAFT,
} from './translations';

import styles from './ActionRemoveMod.module.css';

export default class ActionRemoveMod extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
    }

    render() {
        return (
            <div className={styles.base} onClick={this.props.onClick}>
                {ACTION_REMOVE_DRAFT}
            </div>
        )
    }
}
