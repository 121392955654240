import React from 'react';
import PropTypes from 'prop-types';

import TitleDialog from '../../TitleDialog/TitleDialog';
import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import Dialog from '../Dialog/Dialog';

import styles from './DialogError.module.css'

export const DIALOG_ERROR_TITLE = "Что-то пошло не так..."
export const DIALOG_ERROR_CONTENT = "Произошла ошибка. Попробуйте ещё раз."
export const DIALOG_ERROR_CLOSE_BUTTON = "Закрыть"

export default class DialogError extends React.PureComponent {
    static propTypes = {
        content: PropTypes.node,
        isOpened: PropTypes.bool.isRequired,
        title: PropTypes.node,
        onCloseDialog: PropTypes.func.isRequired,
    }

    renderContent() {
        const title = this.props.title || DIALOG_ERROR_TITLE
        const content = this.props.content || DIALOG_ERROR_CONTENT
        return (
            <div className={styles.base}>
                <TitleDialog>{title}</TitleDialog>
                <div className={styles.content}>
                    <p>{content}</p>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <ButtonBlueLarge onClick={this.props.onCloseDialog}>
                        {DIALOG_ERROR_CLOSE_BUTTON}
                    </ButtonBlueLarge>
                </div>
            </div>
        )
    }

    render () {
        return (
            <Dialog
                content={this.renderContent()}
                isOpened={this.props.isOpened}
                onCloseDialog={this.props.onCloseDialog}
            />
        )
    }
}
