import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import apiUrls from '../../../apiUrls';
import { toggleList } from '../../../utils/filtration';
import {
    FIELD_VALIDATION_ERROR_TYPES,
    HELP_DESCRIPTION_MAX_LENGTH,
} from '../../../utils/constants';
//import { getPrivacyPolicyUrlByRealm } from 'utils/routing'
import { getUserId } from '../../../utils/functions';

import Caption from '../../Caption/Caption';
import EditLabelError from '../../Edit/EditLabelError/EditLabelError';
//import Select from '../../Select/Select';
import Tag from '../../Tag/Tag';
import TitleDialog from '../../TitleDialog/TitleDialog';
import Textarea from '../../Textarea/Textarea';
//import HtmlEditor from '../../HtmlEditor/HtmlEditor';
import FileUpload from '../../FileUpload/FileUpload';
import ButtonBlueOutlineLarge from '../../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';

import {
    EDIT_UPLOAD_COMMON_ERROR,
    EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
    EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
    FIELD_MAX_LIMIT_EXCEED_ERROR,
    FIELD_MIN_LIMIT_EXCEED_ERROR,
} from '../../../translations';

import { 
    DIALOG_HELP_ATTENTION,
    DIALOG_HELP_BUTTON_OK,
    DIALOG_HELP_FILE_FORMAT,
    DIALOG_HELP_FILE_SIZE,
    DIALOG_HELP_PROBLEMS_CAPTION,
    DIALOG_HELP_PROBLEMS_DESCRIPTION_CAPTION,
    DIALOG_HELP_TITLE,
    MAX_ITEMS_TO_CHECK,
    HELP_CATEGORIES,
} from './translations';

import styles from './DialogHelp.module.css';

export default class DialogHelp extends React.Component {
    static propTypes = {
        realm: PropTypes.string,

        onShowDialogError: PropTypes.func,
        onSubmitDialog: PropTypes.func,
    }

    constructor() {
        super()

        this.state = {
            descriptionErrorMessage: null,
            fileErrorType: null,
            fileErrorMessage: null,
            fileId: null,
            fileToken: null,
            isErrorProblemType: false,
            problemDescription: null,
            problemIds: [],
            isCheckedAll: false,
        }
    }

    componentDidMount() {
    }

    handleProblemTypeClick = (problemId) => {
        const checkedItems = toggleList(this.state.problemIds, problemId)
        //const errorType = checkedItems.length === 0 ? FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES : false
        this.setState({
            problemIds: checkedItems,
            isCheckedAll: checkedItems.length >= MAX_ITEMS_TO_CHECK,
            isErrorProblemType: false,
            isErrorProblemDescription: false,
        })
    }

    handleProblemDescriptionChange = (problemDescription) => {
        this.setState({
            problemDescription,
            isErrorProblemDescription: false,
            descriptionErrorMessage: null,
        })
    }

    handleFileChange = (data, fileErrorType) => {
        let fileErrorMessage = null
        if (fileErrorType) {
            fileErrorMessage = EDIT_UPLOAD_COMMON_ERROR
            if (fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR) {
                fileErrorMessage = EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR
            } else if (fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR){
                fileErrorMessage = EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR
            }
        }

        if (data === null) {
            this.setState({
                fileId: null,
                fileToken: null,
                fileErrorType,
                fileErrorMessage,
            })
        } else {
            this.setState({
                fileId: data[0].id,
                fileToken: data[0].access_token,
                fileErrorType,
                fileErrorMessage,
            })
        }
    }

    handleDialogSubmit = () => {
        let hasError = false
        if (isEmpty(this.state.problemIds)) {
            this.setState({
                isErrorProblemType: true,
            })
            hasError = true
        }
        
        if (!this.state.problemDescription || this.state.problemDescription.length > HELP_DESCRIPTION_MAX_LENGTH) {
            this.setState({
                isErrorProblemDescription: true,
            })
            if (!this.state.problemDescription) {
                this.setState({
                    descriptionErrorMessage: FIELD_MIN_LIMIT_EXCEED_ERROR(1),
                })
            } else if (this.state.problemDescription.length > HELP_DESCRIPTION_MAX_LENGTH) {
                this.setState({
                    descriptionErrorMessage: FIELD_MAX_LIMIT_EXCEED_ERROR(HELP_DESCRIPTION_MAX_LENGTH),
                })
            }
            hasError = true
        }

        if (hasError) {
            return
        }
        
        const data = { ...this.state, userId: getUserId() }
        this.props.onSubmitDialog(data)
        
    }

    renderFileErrorMessage() {
        return this.state.fileErrorMessage ? (
            <div className={styles.fileError}>
                <EditLabelError message={this.state.fileErrorMessage} />
            </div>
        ) : null
    }

    renderTags() {
        return HELP_CATEGORIES.map((item, index) => {
            const isDisabled = this.state.isCheckedAll && !this.state.problemIds.includes(item.id)
            return (
                <div className={styles.tag} key={`problem-tag-${item.id}-${index}`}>
                    <Tag
                        caption={item.caption}
                        id={item.id}
                        isChecked={this.state.problemIds.includes(item.id)}
                        isDisabled={isDisabled}
                        isError={this.state.isErrorProblemType}
                        type="warning"
                        onClick={this.handleProblemTypeClick}
                    />
                </div>
            )
        })
    }

    renderDescriptionErrorMessage() {
        return this.state.descriptionErrorMessage ? (
            <EditLabelError message={this.state.descriptionErrorMessage} />
        ) : null
    }

    handleModVersionChange = () => {
        return
    }

    render() {
        /*const itemsForSelect = [
            {
                caption: "Windows 10",
                value: 10,
            },
            {
                caption: "Windows 11",
                value: 11,
            },
            {
                caption: "Other",
                value: 99,
            }
        ]*/
        const isErrorFileFormat = this.state.fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        const isErrorFileSize = this.state.fileErrorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR

        const url = `https://tankimods.net${apiUrls.reportFileUpload}/`;

        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_HELP_TITLE}</TitleDialog>
                <div className={styles.form}>
                    <div className={styles.caption}>
                        <Caption isRequired isError={this.state.isErrorProblemType}>
                            {DIALOG_HELP_PROBLEMS_CAPTION}
                        </Caption>
                    </div>
                    <div className={styles.tags}>
                        {this.renderTags()}
                    </div>
                    {/* <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption>Выберите ОС:</Caption>
                    </div>
                    <div className={styles.select}>
                        <Select
                            items={itemsForSelect}
                            selectedValue={null}
                            onItemChange={this.handleModVersionChange}
                        />
                    </div> */}
                    <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption isRequired isError={this.state.isErrorProblemDescription}>
                            {DIALOG_HELP_PROBLEMS_DESCRIPTION_CAPTION}
                        </Caption>
                    </div>
                    {/* <HtmlEditor
                        isError={this.state.isErrorProblemDescription}
                        value={this.state.problemDescription}
                        onChange={this.handleProblemDescriptionChange}
                    /> */}
                    <Textarea
                        rows={4}
                        isError={this.state.isErrorProblemDescription}
                        value={this.state.problemDescription}
                        onChange={this.handleProblemDescriptionChange}
                    />
                    {this.renderDescriptionErrorMessage()}
                    <hr className={styles.hr} />
                    <div className={styles.fileUpload}>
                        <FileUpload
                            isSupport={true} // parameter for support/help files
                            url={url}
                            fieldName="source"
                            summary={(
                                <span>
                                    <span className={styles.fileUploadSummary}>
                                        {DIALOG_HELP_FILE_FORMAT(isErrorFileFormat)}
                                    </span>
                                    <span className={styles.fileUploadSummary}>
                                        {DIALOG_HELP_FILE_SIZE(isErrorFileSize)}
                                    </span>
                                </span>
                            )}
                            onUploadCompleted={this.handleFileChange}
                        />
                        {this.renderFileErrorMessage()}
                    </div>
                    <hr className={styles.hr} />
                    <p className={styles.attention}>
                        {DIALOG_HELP_ATTENTION}
                        {/* {DIALOG_HELP_ATTENTION(getPrivacyPolicyUrlByRealm(this.props.realm))} */}
                    </p>
                    <ButtonBlueOutlineLarge onClick={this.handleDialogSubmit}>
                        {DIALOG_HELP_BUTTON_OK}
                    </ButtonBlueOutlineLarge>
                </div>
            </div>
        )
    }
}
