import {
    useLocation,
    //useMatches,
    useNavigate,
    useParams,
} from "react-router-dom";

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        //let matches = useMatches();
        let all = { location, navigate, params };
        return (
        <Component
            {...props}
            //router={{ location, navigate, params }}
            {...all}
        />
        );
    }

    return ComponentWithRouterProp;
}
