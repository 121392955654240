import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNil } from 'lodash';

import styles from './FilterSelect.module.css';

export default class FilterSelect extends React.PureComponent {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                caption: PropTypes.node.isRequired,
            }),
        ).isRequired,
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onItemChange: PropTypes.func.isRequired,
        isLanguage: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            isOpened: false,
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleOutsideClick, true)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, true)
    }

    handleChange(selectedValue) {
        this.props.selectedValue !== selectedValue && this.props.onItemChange(selectedValue)
        this.hide()
    }

    handleOutsideClick = (event) => {
        if (!this._base.contains(event.target)) {
            this.hide()
        }
    }

    show = () => {
        this.setState({ isOpened: true })
    }

    hide = () => {
        this.setState({ isOpened: false })
    }

    handleToggle = () => {
        this.state.isOpened ? this.hide() : this.show()
    }

    getSelectText() {
        const selectedValue = this.props.selectedValue

        if (!isNil(selectedValue)) {
            return this.props.items.find(item => (item.value === selectedValue)).caption
        }

        return ''
    }

    renderItems() {
        return this.props.items.map((item, idx) => {
            const classNameOption = classNames(styles.option, {
                [styles[`option__${item.value}`]]: this.props.isLanguage,
            })

            return (
                <div
                    key={`item-${idx}`}
                    className={classNameOption}
                    onClick={() => this.handleChange(item.value)}
                >
                    {item.caption}
                </div>
            )

        })
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isOpened]: this.state.isOpened,
            [styles.isLanguage]: this.props.isLanguage,
        })

        const classNameValue = classNames(styles.value, {
            [styles.isOpened]: this.state.isOpened,
            [styles[`value__${this.props.selectedValue}`]]: this.props.isLanguage,
        })

        return (
            <div
                ref={c => (this._base = c)}
                className={classNameBase}
                onClick={this.handleToggle}
            >
                <div className={classNameValue}>{this.getSelectText()}</div>
                <div className={styles.dropdown}>
                    {this.renderItems()}
                </div>
            </div>
        )
    }
}
