import { batchActions } from 'redux-batched-actions';

import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import { processModderSectionItems } from '../utils/responseProcessing';
import urls from '../apiUrls';


export const START_FETCHING_MODDER_SECTION = 'START_FETCHING_MODDER_SECTION';
export const SET_ERROR_FETCHING_MODDER_SECTION = 'SET_ERROR_FETCHING_MODDER_SECTION';
export const STOP_FETCHING_MODDER_SECTION = 'STOP_FETCHING_MODDER_SECTION';
export const UPDATE_ITEMS_MODDER_SECTION = 'UPDATE_ITEMS_MODDER_SECTION';
export const SET_IS_FORBIDDEN = 'SET_IS_FORBIDDEN';

export const startFetchingModderSection = () => ({
    type: START_FETCHING_MODDER_SECTION,
})

export const stopFetchingModderSection = () => ({
    type: STOP_FETCHING_MODDER_SECTION,
})

export const updateItemsModderSection = (rawResponseData, state) => ({
    type: UPDATE_ITEMS_MODDER_SECTION,
    payload: {
        items: processModderSectionItems(rawResponseData, state),
    },
})

export const setErrorFetchingMododel = (errorData) => ({
    type: SET_ERROR_FETCHING_MODDER_SECTION,
    payload: {
        errorData,
    },
})

export const setIsForbidden = () => ({
    type: SET_IS_FORBIDDEN,
})

export const fetchItemsModderSection = (userData) => {
    return (dispatch, getState) => {
        const state = getState()
        const url = `https://tankimods.net/${urls.modsOwner}` //urls.modsOwner

        dispatch(startFetchingModderSection())

        const fetchPromise = fetch(url, {method: 'POST', body: userData}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorFetchingMododel(rawResponseData.context),
                        stopFetchingModderSection(),
                    ], SET_ERROR_FETCHING_MODDER_SECTION))
                } else {
                    dispatch(batchActions([
                        updateItemsModderSection(rawResponseData, state),
                        stopFetchingModderSection(),
                    ], UPDATE_ITEMS_MODDER_SECTION))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingMododel(errorData),
                    stopFetchingModderSection(),
                ], SET_ERROR_FETCHING_MODDER_SECTION))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}