import { connect } from 'react-redux';

import { openDialogError } from '../../actions/DialogError';
import { closeDialog } from '../../actions/Dialog';
import { reportMod } from '../../actions/Details';

import DialogReportMod from '../../components/Dialogs/DialogReportMod/DialogReportMod';

const mapStateToProps = (state) => ({
    realm: state.currentAccount.realm,
    versions: state.details.data.versions,
})

const mapDispatchToProps = (dispatch) => ({
    onShowDialogError: (content) => {
        dispatch(openDialogError(content))
    },

    onSubmitDialog: (data) => {
        dispatch(reportMod(data))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogReportMod)
