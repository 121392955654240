import React from "react";
import FileUploadCaption from "../../FileUploadCaption/FileUploadCaption";

export const DIALOG_HELP_ATTENTION = `В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с Правилами сайта`;

export const DIALOG_HELP_BUTTON_OK = `Отправить тикет`;

export const DIALOG_HELP_FILE_FORMAT = (isError) => {
    let ext0 = <FileUploadCaption isError={isError} caption="zip" />
    let ext1 = <FileUploadCaption isError={isError} caption="rar" />
    let ext2 = <FileUploadCaption isError={isError} caption="7z" />
    let ext3 = <FileUploadCaption isError={isError} caption="png" />
    let ext4 = <FileUploadCaption isError={isError} caption="jpg" />
    return (
        <React.Fragment>
            Формат файла: {ext0}, {ext1}, {ext2}, {ext3} или {ext4}
        </React.Fragment>
    )
}

export const MAX_ITEMS_TO_CHECK = 1;

export const DIALOG_HELP_FILE_SIZE_NO_MORE_THAN = `не более 10MB`

export const DIALOG_HELP_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption={DIALOG_HELP_FILE_SIZE_NO_MORE_THAN} />
    return <React.Fragment>Размер: {size}</React.Fragment>
} 

export const DIALOG_HELP_PROBLEMS_CAPTION = `Выберите тип проблемы:`;

export const DIALOG_HELP_PROBLEMS_DESCRIPTION_CAPTION = `Подробно опишите проблему:`;

export const DIALOG_HELP_TITLE = `Связаться со службой поддержки`;

export const DIALOG_HELP_TYPE_URL = `Портал модов`;

export const DIALOG_HELP_TYPE_TECHNICAL_ISSUE = `Технические проблемы`;

export const HELP_CATEGORIES = [
    {
        id: 1,
        caption: DIALOG_HELP_TYPE_TECHNICAL_ISSUE,
    },
    {
        id: 2,
        caption: DIALOG_HELP_TYPE_URL,
    },
];
