import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import ButtonBlueOutlineLarge from '../../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';

import {
    DIALOG_CONFIRMATION_MOD_VERSION_CANCEL_BUTTON,
    DIALOG_CONFIRMATION_MOD_VERSION_CONTENT,
    DIALOG_CONFIRMATION_MOD_VERSION_OK_BUTTON,
    DIALOG_CONFIRMATION_MOD_VERSION_TITLE,
} from './translations'

import styles from './DialogConfirmationModVersionRemove.module.css';

export default class DialogConfirmationModVersionRemove extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,

        onCloseDialog: PropTypes.func.isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    handleSubmitDialog = () => {
        this.props.onSubmitDialog(this.props.id)
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_CONFIRMATION_MOD_VERSION_TITLE}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_CONFIRMATION_MOD_VERSION_CONTENT}</p>
                </div>
                <div className={styles.button}>
                    <ButtonBlueLarge onClick={this.props.onCloseDialog}>
                        {DIALOG_CONFIRMATION_MOD_VERSION_CANCEL_BUTTON}
                    </ButtonBlueLarge>
                </div>
                <div className={styles.button}>
                    <ButtonBlueOutlineLarge onClick={this.handleSubmitDialog}>
                        {DIALOG_CONFIRMATION_MOD_VERSION_OK_BUTTON}
                    </ButtonBlueOutlineLarge>
                </div>
            </div>
        )
    }
}
