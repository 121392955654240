import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { KEY_CODES } from '../../utils/key_codes'
import { MAX_SEARCH_LENGTH } from '../../utils/constants'

import { SEARCH_PLACEHOLDER } from './translations'

import styles from './Search.module.css'

export default class Search extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string, // isRequired
        onSearchContolClick: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            value: '',
            isPlaceholderVisible: true,
        }
    }

    componentWillReceiveProps(nextProps) { // componentWillReceiveProps(nextProps)  componentDidUpdate
        if (this.state.value !== nextProps.value) {
            this.setState({
                value: nextProps.value,
                isPlaceholderVisible: !nextProps.value,
            })
        }
    }

    handleSearchControlChange = (event) => {
        const value = event.target.value
        const sliced = value.slice(0, MAX_SEARCH_LENGTH)

        if (sliced.length <= MAX_SEARCH_LENGTH) {
            this.setState({ value: sliced })
        }
    }

    handleSearchContolClick = () => {
        this.props.onSearchContolClick(this.state.value)
    }

    handleKeyDown = (event) => {
        if (event.keyCode === KEY_CODES.ENTER) {
            this.props.onSearchContolClick(this.state.value)
        }
    }

    setFocused = (isFocused) => {
        this.setState({
            isPlaceholderVisible: !isFocused && !this.state.value,
        })
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isActive]: this.state.value,
        })

        const classNamePlaceholder = classNames(styles.placeholder, {
            [styles.isVisible]: this.state.isPlaceholderVisible,
        })

        return (
            <div className={classNameBase}>
                <div className={classNamePlaceholder}>{SEARCH_PLACEHOLDER}</div>
                <input
                    className={styles.input}
                    value={this.state.value}
                    onFocus={() => this.setFocused(true)}
                    onBlur={() => this.setFocused(false)}
                    onChange={this.handleSearchControlChange}
                    onKeyDown={this.handleKeyDown}
                />
                <div
                    className={styles.button}
                    onClick={this.handleSearchContolClick}
                />
            </div>
        )
    }
}
