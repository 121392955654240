import React, { Component } from 'react';

import styles from './Back.module.css';

export default class Back extends Component {
  render() {
    return (
        <a className={styles.base} href="/"><span>На главную страницу</span></a>
    )
  }
}
