import React from 'react';
import PropTypes from 'prop-types';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { chunk, flatten, isEmpty } from 'lodash';


import { CHANGELOG_FIRST_PAGE_MAX_ITEMS } from '../../../utils/constants';

import EditChangelogListItem from './components/EditChangelogListItem/EditChangelogListItem';

import ButtonGrayOutlineSmall from '../../Buttons/ButtonGrayOutlineSmall/ButtonGrayOutlineSmall'; // ButtonGrayOutlineSmall
import Caption from '../../Caption/Caption';
import Divider from '../../Divider/Divider';

import {
    EDIT_CHANGE_LOG_ADD_HISTORY_LABEL,
} from '../EditChangelogAdd/translations';

import { LOAD_MORE } from '../../../translations';

import styles from './EditChangelogList.module.css';

export default class EditChangelogList extends React.Component {
    static propTypes = {
        historyItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            position: PropTypes.number.isRequired,
            content: PropTypes.string.isRequired,
            version: PropTypes.string.isRequired,
        })).isRequired,
        hasCaption: PropTypes.bool,
        isLoadMoreEnabled: PropTypes.bool,

        onConfirmationDialogShow: PropTypes.func.isRequired,
        onHistoryItemEdit: PropTypes.func.isRequired,
        onHistoryItemsReordered: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            activeChunk: 0,
            chunkedItems: [],
        }
    }

    UNSAFE_componentWillMount() {
        this.splitItemsIntoChunks(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.splitItemsIntoChunks(nextProps)
    }

    splitItemsIntoChunks(props) {
        if (isEmpty(props.historyItems)) {
            return
        }

        const chunkedItems = chunk(props.historyItems, CHANGELOG_FIRST_PAGE_MAX_ITEMS)
        this.setState({ chunkedItems })
    }

    handleSort = ({ oldIndex, newIndex }) => {
        const orderedItems = arrayMove(this.props.historyItems, oldIndex, newIndex)
        this.props.onHistoryItemsReordered(orderedItems)
    }

    handleLoadMoreClick = () => {
        const activeChunk = this.state.activeChunk + 1
        this.setState({ activeChunk })
    }

    renderItems() {
        const SortableItem = sortableElement(({ value }) => (
            <div className={styles.item}>{value}</div>
        ))

        const SortableContainer = sortableContainer(({ children }) => (
            <div>{children}</div>
        ))

        const historyItems = flatten(this.state.chunkedItems.slice(0, this.state.activeChunk + 1))

        const prerenderedItems = historyItems.map((item) => (
            <EditChangelogListItem
                content={item.content}
                id={item.id}
                position={item.position}
                key={`content-item-${item.position}`}
                version={item.version}
                isDraggable={this.props.historyItems.length > 1}
                onConfirmationDialogShow={this.props.onConfirmationDialogShow}
                onHistoryItemEdit={this.props.onHistoryItemEdit}
            />
        ))

        return (
            <SortableContainer
                helperClass={styles.dragged} // "dragged"
                useDragHandle
                onSortEnd={this.handleSort}
            >
                {prerenderedItems.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                        disabled={this.state.isFormVisible || prerenderedItems.length === 1}
                    />
                ))}
            </SortableContainer>
        )
    }

    renderLoadMoreButton() {
        const isButtonVisible = this.props.isLoadMoreEnabled && this.state.activeChunk < this.state.chunkedItems.length - 1

        return isButtonVisible ? (
            <div className={styles.loadMore}>
                <ButtonGrayOutlineSmall onClick={this.handleLoadMoreClick}>
                    {LOAD_MORE}
                </ButtonGrayOutlineSmall>
            </div>
        ) : null
    }

    render() {
        return (
            <div className={styles.base}>
                {this.props.hasCaption ? (
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Caption isLarge>
                                {EDIT_CHANGE_LOG_ADD_HISTORY_LABEL}
                            </Caption>
                        </div>
                    </div>
                ) : (
                    <div className={styles.divider}><Divider isDashed /></div>
                )}

                {this.renderItems()}
                {this.renderLoadMoreButton()}
                <div className={styles.divider}><Divider isDashed /></div>
            </div>
        )
    }
}
