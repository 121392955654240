import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { Link } from 'react-router-dom';
//import ClampLines from 'react-clamp-lines';
//import Image from 'react-image-file-resizer';
import { first, get } from 'lodash';

import { getUsername } from '../../utils/functions';
import { formatDate, formatFileSize } from '../../utils/formatting';
import { getDetailsUrl } from '../../utils/routing';

import LinesClamp from '../LinesClamp/LinesClamp';
import ButtonBlueSmall from '../Buttons/ButtonBlueSmall/ButtonBlueSmall';
import { ActionMuteSubscribe } from '../ActionMuteSubscribe/ActionMuteSubscribe';
import { ActionUnsubscribe } from '../ActionUnsubscribe/ActionUnsubscribe';

import {
    SUBSCRIPTIONS_ITEM_VERSION,
    SUBSCRIPTIONS_ITEM_UDATED_DATE,
    SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL
} from './translations'

import styles from './FollowedItem.module.css';

export default class FollowedItem extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        mod: PropTypes.shape({
            cover: PropTypes.string,
            id: PropTypes.number.isRequired,
            mark: PropTypes.number.isRequired,
            modVersion: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            versions: PropTypes.arrayOf(PropTypes.shape({
                createdAt: PropTypes.string.isRequired,
                downloadUrl: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                versionFileOriginalName: PropTypes.string.isRequired,
                versionFileSize: PropTypes.number,
            })).isRequired,
        }),
        isUpdated: PropTypes.bool,
        isNew: PropTypes.bool,

        fetchItems: PropTypes.func.isRequired,
        onIncrementDownloadCount: PropTypes.func.isRequired,
        onIgnoreUpdate: PropTypes.func.isRequired,
        onUnsubscribe: PropTypes.func.isRequired,
    }

    handleDownloadClick = () => {
        this._downloadLinkRef.click()
        if (this.props.isUpdated) {
            Promise.resolve(
                this.props.onIncrementDownloadCount(this.props.mod.id),
            ).then(() => {
                this.props.fetchItems()
            })
        }
    }

    handleIgnoreUpdate = () => {
        let userName = getUsername()
        this.props.onIgnoreUpdate(this.props.mod.id, userName, this.props.id)
    }

    handleUnsubscribe = () => {
        let userName = getUsername()
        this.props.onUnsubscribe(this.props.mod.id, userName)
    }

    renderMuteButton() {
        if (!this.props.isUpdated) {
            return
        }

        return (
            <span className={styles.button}>
                <ActionMuteSubscribe onClick={this.handleIgnoreUpdate} />
            </span>
        )
    }

    render() {
        const classNameModDataItem = className(styles.modDataItem, {
            [styles.isNew]: this.props.isNew,
        })
        const version = first(this.props.mod.versions)
        return (
            <div className={styles.base}>
                <Link to={getDetailsUrl(this.props.mod.id)} className={styles.mod}>
                    <div className={styles.cover}>
                        {this.props.mod.cover ? (
                            /*<Image
                                src={prosp.mod.cover}
                                width={151}
                                height={85}
                            />*/
                            <img src={this.props.mod.cover} width={151} height={85} alt="Cover" />
                        ) : null}
                    </div>
                    <div className={styles.title} title={this.props.mod.title}>
                        <LinesClamp 
                            text={this.props.mod.title} 
                            lines={2}
                        />
                        {/* <ClampLines
                            className="styles.clampLines"
                            text={this.props.mod.title}
                            lines={2}
                            buttons={false}
                        /> */}
                    </div>
                </Link>
                {/* <div className="styles.rate"><Rating mark={this.props.mod.mark} isLight /></div> */}
                {/* <div className="styles.rate"><span>{this.props.mod.mark}</span></div> */}
                <div className={styles.modData}>
                    <span className={styles.modDataItem}>
                        {SUBSCRIPTIONS_ITEM_VERSION(get(version, 'modVersion', ''))}
                    </span>
                    <span className={classNameModDataItem}>
                        {SUBSCRIPTIONS_ITEM_UDATED_DATE(formatDate(get(version, 'createdAt', null), 'DD.MM.YY'))}
                    </span>
                </div>
                <div className={styles.download}>
                    <div className={styles.downloadButton}>
                        <ButtonBlueSmall
                            isWide
                            onClick={this.handleDownloadClick}
                        >
                            {SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL(formatFileSize(get(version, 'versionFileSize', 0)))}
                        </ButtonBlueSmall>
                        <a
                            className={styles.hidden}
                            ref={c => (this._downloadLinkRef = c)}
                            href={get(version, 'downloadUrl')}
                            download={get(version, 'versionFileOriginalName')}
                        > </a>
                    </div>
                </div>
                <div className={styles.buttons}>
                    {this.renderMuteButton()}
                    <span className={styles.button}>
                        <ActionUnsubscribe onClick={this.handleUnsubscribe} />
                    </span>
                </div>
            </div>
        )
    }
}
