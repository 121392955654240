import React from "react"

const NBSP = '\u00a0'

export const MOD_SUMMARY_VERSION = (modVersion, gameVersion, isNewVersion) => {
    let game = `Мир танков` /* <mark>Мир танков</mark> */
    let modVersions = <mark>{modVersion}</mark>
    // если версия мода не соответсвует версии игры на портале - цвет будет красный
    let gameVersions = isNewVersion ? (<mark style={{color: 'green'}}>{gameVersion}</mark>) : (<i>{gameVersion}</i>)

    return (
        <React.Fragment>
            {modVersions}{NBSP}для{NBSP}{game}{NBSP}{gameVersions}
        </React.Fragment>
    )
}

export const MOD_VIEW_LABEL = `Предпросмотр мода`

export const MOD_DOWNLOAD_BUTTON_CAPTION = (size) => {
    let BUTTON_CAPTION
    if (size !== null) {
        BUTTON_CAPTION = <React.Fragment>Скачать мод <mark>{size}</mark></React.Fragment>
    } else {
        BUTTON_CAPTION = <React.Fragment>Скачать мод <mark>XXX</mark></React.Fragment>
    }

    return BUTTON_CAPTION
}