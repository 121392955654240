import { get } from 'lodash'

import * as actions from '../actions/SectionModder';

const initialState = {
    isError: false,
    isFetching: false,
    isErrorForbidden: false,
    items: [],
    count: 0,
}

export const sectionModder = (state = initialState, action) => {
    const payload = get(action, 'payload')
    switch (action.type) {
        case actions.START_FETCHING_MODDER_SECTION:
            return {
                ...state,
                isError: false,
                isErrorForbidden: false,
                isFetching: true,
            }
        case actions.SET_ERROR_FETCHING_MODDER_SECTION:
            return {
                ...state,
                isFetching: false,
                isError: get(action.payload, 'errorData.status') !== 403,
                isErrorForbidden: get(action.payload, 'errorData.status') === 403,
            }
        case actions.STOP_FETCHING_MODDER_SECTION:
            return {
                ...state,
                isFetching: false,
            }
        case actions.UPDATE_ITEMS_MODDER_SECTION:
            return {
                ...state,
                items: payload.items,
            }
        case actions.SET_IS_FORBIDDEN:
            return {
                ...state,
                isErrorForbidden: true,
            }
        default:
            return state
    }
}
