import React from 'react';

import FileUploadCaption from '../../FileUploadCaption/FileUploadCaption';

export const EDIT_VOICEOVER_LABEL = "Аудио"

export const EDIT_VOICEOVER_LABEL_TEXT = "Не более пяти"

export const EDIT_VOICEOVER_FILE_FORMAT = (isError) => {
    let ext0 = <FileUploadCaption isError={isError} caption="MP3" />
    let ext1 = <FileUploadCaption isError={isError} caption="WAV" />
    return (
        <React.Fragment>
            Формат файла: {ext0} или {ext1}
        </React.Fragment>
    )
}

export const EDIT_VOICEOVER_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption="Не более 4MB для одного файла" />
    return (
        <React.Fragment>
            Размер: {size}
        </React.Fragment>
    )
}
