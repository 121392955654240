
import React from "react"
import FileUploadCaption from "../../FileUploadCaption/FileUploadCaption"

const NBSP = `\u00a0`

export const EDIT_COVER_UPLOAD_LABEL = "Загрузка обложки"

export const EDIT_COVER_UPLOAD_ALERT = `Разрешение обложки в списке модов${NBSP}— 302${NBSP}x${NBSP}170${NBSP}px. Картинка была автоматически спозиционирована и обрезана.`

export const EDIT_COVER_UPLOAD_FILE_FORMAT = (isError) => {
    let ext0 = <FileUploadCaption isError={isError} caption="PNG" />
    let ext1 = <FileUploadCaption isError={isError} caption="JPG" />
    return (
        <React.Fragment>
            Формат файла: {ext0} или {ext1}
        </React.Fragment>
    )
}

export const EDIT_COVER_UPLOAD_FILE_DIMENSIONS = (isError) => {
    let dimensions = <FileUploadCaption isError={isError} caption={"Не менее 302 x 170"} />
    return (
        <React.Fragment>
            Разрешение: {dimensions}
        </React.Fragment>
    )
}

export const EDIT_COVER_UPLOAD_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption={"Не более 4MB"} />
    return (
        <React.Fragment>
            Размер: {size}
        </React.Fragment>
    )
}
