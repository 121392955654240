import { connect } from 'react-redux';

import { authorization } from '../../actions/CurrentAccount';

import Prolongate from '../../components/Prolongate/Prolongate';


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    isFetching: state.currentAccount.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
    authorization: (rawResponseData) => {
        dispatch(authorization(rawResponseData))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Prolongate)
