import React from "react"

export const EDIT_CATEGORIES_LABEL = "Выберите категории"

export const REVIEW_CATEGORIES_LABEL = "Выбранные категории"

export const EDIT_CATEGORIES_LABEL_TEXT = (count) => {
    let textCount
    if (count === 1) {
        textCount = `одного`
    } else if (count === 2) {
        textCount = `двух`
    } else if (count === 3) {
        textCount = `трёх`
    }

    return (
        <React.Fragment>
            Не более {textCount}
        </React.Fragment>
    )
}
