import React from "react";
import FileUploadCaption from "../../FileUploadCaption/FileUploadCaption";
import { Link } from "react-router-dom";
import { urls } from "../../../utils/routing";

export const DIALOG_REPORT_MOD_ATTENTION = <React.Fragment>В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с <Link to={urls.eula} target="_blank">Правилами сайта</Link></React.Fragment>

export const DIALOG_REPORT_MOD_BUTTON_OK = `Сообщить о проблеме`;

export const DIALOG_REPORT_MOD_FILE_FORMAT = (isError) => {
    let ext0 = <FileUploadCaption isError={isError} caption="zip" />
    let ext1 = <FileUploadCaption isError={isError} caption="rar" />
    let ext2 = <FileUploadCaption isError={isError} caption="7z" />
    let ext3 = <FileUploadCaption isError={isError} caption="png" />
    let ext4 = <FileUploadCaption isError={isError} caption="jpg" />
    return (
        <React.Fragment>
            Формат файла: {ext0}, {ext1}, {ext2}, {ext3} или {ext4}
        </React.Fragment>
    )
}

export const DIALOG_REPORT_MOD_FILE_SIZE_NO_MORE_THAN = `не более 10MB`

export const DIALOG_REPORT_MOD_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption={DIALOG_REPORT_MOD_FILE_SIZE_NO_MORE_THAN} />
    return <React.Fragment>Размер: {size}</React.Fragment>
} 

export const DIALOG_REPORT_MOD_PROBLEMS_CAPTION = `Выберите типы проблем:`;

export const DIALOG_REPORT_MOD_PROBLEMS_DESCRIPTION_CAPTION = `Подробно опишите проблему:`;

export const DIALOG_REPORT_MOD_TITLE = `Сообщить о проблеме с модом`;

export const DIALOG_REPORT_MOD_TYPE_CHEAT = `Запрещенный мод`;

export const DIALOG_REPORT_MOD_TYPE_COPYWRIGHT = `Нарушение авторских прав`;

export const DIALOG_REPORT_MOD_TYPE_TECHNICAL_ISSUE = `Технические проблемы`;

export const DIALOG_REPORT_MOD_TYPE_UNACCEPTABLE_CONTENT = `Неприемлемый контент`;

export const DIALOG_REPORT_MOD_VERSION_CAPTION = `Выберите версию мода:`;

export const DIALOG_REPORT_MOD_VERSION_VALUE = (modVersion, gameVersion) => {
    return <React.Fragment>{modVersion} для Мир танков {gameVersion}</React.Fragment>
} 