export const REVIEW_MOD_DRAFT_PAGE_TITLE = `Редактирование черновика`

export const REVIEW_MOD_INFO = `Чтобы добавить мод, заполните поля ниже. Ваш мод будет опубликован после успешного прохождения проверки.`

export const REVIEW_MOD_NOTIFICATION_REQUIRED_CONTENT = () => {
    let required = (<mark>*</mark>)
    let br = <br />
    return `Для публикации мода, вам необходимо заполнить все поля, отмеченные ${required}${br}${br}Для сохранения черновика достаточно заполнить поле «НАЗВАНИЕ МОДА».`
}

export const messages = (modTitle) => {
    return `Редактирование мода ${modTitle} — Моды для Мир танков`
}