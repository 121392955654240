
export const EDIT_UPLOAD_UPDATE_FORM_LABEL = "Добавление обновления"

export const EDIT_UPLOAD_UPDATE_FORM_CANCEL_CAPTION = "Отменить обновление"

export const EDIT_UPLOAD_UPDATE_FORM_PUBLISH_LABEL = "Публикация"

export const EDIT_UPLOAD_UPDATE_FORM_PUBLISH_CHECKBOX_LABEL = "Опубликовать автоматически после успешной проверки"

export const EDIT_UPLOAD_UPDATE_BUTTON = "Отправить на проверку"
