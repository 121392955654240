import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Caption from '../../Caption/Caption';
import Select from '../../Select/Select';

import {
    EDIT_GAME_VERSION_LABEL,
} from './translations'

import styles from './EditGameVersion.module.css'

export default class EditGameVersion extends React.PureComponent {
    static propTypes = {
        externalGameVersionId: PropTypes.number,
        gameVersions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                version: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        isSecondary: PropTypes.bool,

        onGameVersionChange: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            gameVersionId: null,
        }
    }

    UNSAFE_componentWillMount() {
        const isExternalGameVersionValid = this.props.externalGameVersionId &&
            this.props.gameVersions.map((item) => item.id).includes(this.props.externalGameVersionId)
        const gameVersionId = isExternalGameVersionValid ?
            this.props.externalGameVersionId : get(this.props.gameVersions[0], 'id')
        this.setState({ gameVersionId })
        this.props.onGameVersionChange(gameVersionId)
    }

    handleGameVersionChange = (gameVersionId) => {
        this.setState({ gameVersionId })
        this.props.onGameVersionChange(gameVersionId)
    }

    render() {
        const gameVersionItems = this.props.gameVersions.map((item) => ({
            value: item.id,
            caption: item.version,
        }))

        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge={!this.props.isSecondary}>
                        {EDIT_GAME_VERSION_LABEL}
                    </Caption>
                </div>
                <div className={styles.field}>
                    <div className={styles.select}>
                        <Select
                            items={gameVersionItems}
                            selectedValue={this.state.gameVersionId}
                            onItemChange={this.handleGameVersionChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
