import React from 'react';
import { connect } from 'react-redux';

import { openDialog } from '../../actions/Dialog';
import { openMenu, closeMenu } from '../../actions/UserMenu';
import { authorization, prolongate } from '../../actions/CurrentAccount';
import ButtonUserMenu from '../../components/ButtonUserMenu/ButtonUserMenu';
import DialogLogin from '../DialogLoginWrapper/DialogLoginWrapper';
import UserMenuWrapper from '../UserMenuWrapper/UserMenuWrapper';


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    count: state.currentAccount.count,
    notifications: state.currentAccount.notifications,
    isFetching: state.currentAccount.isFetching,

    isOpenedMenu: state.userMenu.isOpened,
})

const mapDispatchToProps = (dispatch) => ({
    onLoginClick() {
        dispatch(openDialog(<DialogLogin />))
    },
    authorization(rawResponseData) {
        dispatch(authorization(rawResponseData))
    },
    prolongate(rawResponseData) {
        dispatch(prolongate(rawResponseData))
    },
    onOpenMenu() {
        dispatch(openMenu(<UserMenuWrapper />))
    },
    onCloseMenu() {
        dispatch(closeMenu())
    },
    fetchNotifications() {
        //dispatch(fetchNotifications())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonUserMenu)
