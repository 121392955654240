import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import ReactRouterPropTypes from 'react-router-prop-types';
import { first, values, isEmpty, keys, get } from 'lodash';
import classNames from 'classnames';
import { expressionsAll, allTags, SEARCH_RESULT_PAGE_TITLE_BY_UNKNOWN } from '../../utils/constants';
import { pushHistoryWithErrorNotFound, urls } from '../../utils/routing';

import Main from '../../components/Main/Main';
import Error from '../../components/Error/Error';
import CardMod from '../../components/CardMod/CardMod';
import ButtonBlueOutlineLarge from '../../components/Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import TitleMajor from '../../components/TitleMajor/TitleMajor';
import Back from '../../components/Buttons/Back/Back';
import Categories from '../../components/Categories/Categories';
import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';
import Toggle from '../Toggle/Toggle';

import { 
  SEARCH_RESULT_FILTER_TYPES,
  SEARCH_RESULT_EMPTY,
  SEARCH_RESULT_EMPTY_BY_OWNER,
  SEARCH_RESULT_PAGE_TITLE_BY_TITLE,
  SEARCH_RESULT_PAGE_TITLE_BY_OWNER,
  messages,
  SEARCH_RESULT_ITEMS_ORDERING,
} from '../../utils/constants';

import {
  COMMON_ERROR,
  LOAD_MORE,
  ORDERING_BY_CHANGED_AT_FILTERED_MODS,
  ORDERING_BY_RATING_FILTERED_MODS,
  TO_LANDING
} from '../../translations';

import styles from './SearchPage.module.css';


export default class SearchPage extends Component {
  static propTypes = {
    filtered: PropTypes.shape({
      count: PropTypes.number.isRequired,
      filterParams: PropTypes.shape({
        type: PropTypes.oneOf(values(SEARCH_RESULT_FILTER_TYPES)),
        argument: PropTypes.string,
        ordering: PropTypes.oneOf([
          SEARCH_RESULT_ITEMS_ORDERING.CHANGED_AT,
          SEARCH_RESULT_ITEMS_ORDERING.RATING,
        ]),
      }).isRequired,
      isError: PropTypes.bool.isRequired,
      isFetching: PropTypes.bool.isRequired,
      isFetchedAll: PropTypes.bool.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        cover: PropTypes.string,
        gameVersion: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        mark: PropTypes.number.isRequired,
        modVersion: PropTypes.string.isRequired,
        ownerId: PropTypes.number.isRequired,
        ownerName: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          caption: PropTypes.string.isRequired,
        })).isRequired,
        title: PropTypes.string.isRequired,
      })).isRequired,
    }),
    isModDownloadAccepted: PropTypes.bool,

    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      caption: PropTypes.string.isRequired,
    }).isRequired).isRequired,

    location: ReactRouterPropTypes.location.isRequired,

    changeItemsOrdering: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    loadMoreItems: PropTypes.func.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
    resetComponent: PropTypes.func.isRequired,
  }

  constructor() {
    super()

    this.state = {
      isFiltered: false,
    }
  }

  componentDidMount() {
    if (this.props.location.search === null || this.props.location.search === '') {
      pushHistoryWithErrorNotFound(this.props.history)
    } else {
      this.props.fetchItems(this.getFilterParams(this.props))

      //this.setDocumentTitle(this.props);
      //this.getSearchFromUrl();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) { //  componentDidUpdate
    if (nextProps.location.search !== this.props.location.search) {
      this.props.fetchItems(this.getFilterParams(nextProps))
    }

    this.setDocumentTitle(nextProps)
  }

  componentWillUnmount() {
    this.props.resetComponent()
  }

  setDocumentTitle(props) {
    let formattedMessage
    //const queryStrings = queryString.parse(props.location.search)
    const paramValue = props.location.search.replace('?','').split('=')
    if ( paramValue[0] === SEARCH_RESULT_FILTER_TYPES.TAGS ) {
      const tag = this.getTagName(paramValue)
      formattedMessage = messages(null,null,tag)[2].filterByTag
    } else if ( paramValue[0] === SEARCH_RESULT_FILTER_TYPES.OWNER ) {
      const ownerName = this.getOwnerName(props)
      formattedMessage = messages(null,ownerName,null)[1].filterByOwner
    } else if ( paramValue[0] === SEARCH_RESULT_FILTER_TYPES.TITLE ) {
      const searchTitle = decodeURIComponent(paramValue[1])
      formattedMessage = messages(searchTitle,null,null)[0].filterByTitle
    } else {
      formattedMessage = messages(paramValue,null,null)[0].filterByTitle 
    } 

    document.title = formattedMessage
  }

  getSearchFromUrl() {
    let errMsg = [];
    if (window.location.search.includes('=')) {
      var search = window.location.search.replace('?','').split('=')[0]; // сплит разделяет и выводит 1 значение (tags OR owner)
      if ( search ) {
        // получаем значение параметра после "?XXX="
        var getParamsUrl = window.location.search.replace('?','').split('=')[1].replace(expressionsAll[0].numbers,''); // сплит разделяет и выводит 2 значение ( число ), второй replaceAll удаляет все лишние символы 
        if ( getParamsUrl ) {
          if ( (search === 'tags') || (search === 'owner')) {
            return getParamsUrl; // tag id OR owner id
          } else if ( search === 'title' ) {
            return getParamsUrl; // title 
          } else {
            errMsg = {status: "error", context: {msg: "INVALID_SEARCH_ITEM", code: "407"}}
            return console.log(errMsg); // если все кроме тэгов и оунеров - ошибка
          }
        } else {
          errMsg = {status: "error", context: {msg: "INVALID_SEARCH_RESULT", code: "407"}}
          return console.log(errMsg); // если все кроме тэгов и оунеров - ошибка
        }
      } else {
        errMsg = {status: "error", context: {msg: "INVALID_SEARCH", code: "407"}} // если в запросе после "/search/" что угодно - ошибка
        return console.log(errMsg);
      }
    } else {
      errMsg = {status: "error", context: {msg: "INVALID_SEARCH_ADRESS", code: "404"}} 
      return console.log(errMsg);
    }
  }

  getFilterParams(props) {
    const queryStrings = queryString.parse(props.location.search)
    const type = keys(queryStrings)[0]
    
    return {
        argument: queryStrings[type],
        ordering: props.filtered.filterParams.ordering,
        type,
    }
  }

  getOwnerName(props) {
    return props.filtered.items.length ? (
        props.filtered.items[0].ownerName
      ) : get(props.history.location, 'state.ownerName') // props.location
  }

  getTagName(props) {
    let tagId = Number(props[1].replace(expressionsAll[0].numbers,''))
    let tagName = this.props.tags.find(item => item.id === tagId).caption || get(props.location, 'state.tagName') // tagName['caption']
    return tagName
  }

  getSearchTerm(props) {
    //let tagNames = props.tags.find(item => item.id === Number(this.props.filtered.filterParams.argument || get(props.location, 'state.searchTitle'))) // tagName['caption']
    return window.location.search.replace('?','').split('=')[0] === 'title' ?
      ( get(props.location, 'state.searchTitle') || decodeURIComponent(props.location.search.replace('?','').split('=')[1]) ) : 
      get(props.location, 'state.tagName') || this.props.filtered.filterParams.argument // tagNames
    //get(props.location, 'state.tagName') || get(props.location, 'state.searchTitle') || this.props.filtered.filterParams.argument // tagNames
  }

  handleLoadMoreClick = () => {
    this.props.loadMoreItems(this.props.filtered.filterParams)
  }

  handleOrderingChanged = (ordering) => {
    const filterParams = {
      ...this.props.filtered.filterParams,
      ordering,
    }
    this.props.changeItemsOrdering(filterParams)
    this.setState({isFiltered: !this.state.isFiltered})
  }

  handleDownloadClick = (modId, isModDownloaded) => {
    const { title, versions } = this.props.filtered.items.find((item) => (item.id === modId))
    this.props.onDownloadClick(this.props.isModDownloadAccepted, isModDownloaded, modId, title, versions)
  }

  renderTitleMajor() {
    /*let title
    if (window.location.search.includes('=')) {
      const count = this.props.filtered.count; // this.props.filtered
      const countTitle = count ? <mark>{count}</mark> : <span className={styles.muted}>{count}</span>

      if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.OWNER) {
          const ownerName = <mark>{this.getOwnerName(this.props)}</mark> // filtered.filterParams.argument
          title = SEARCH_RESULT_PAGE_TITLE_BY_OWNER(ownerName, countTitle)
      } else if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.TAGS) {
          //const searchTerm = `<mark>“${this.getSearchTerm(this.props)}”</mark>`
          let tagIds = Number(window.location.search.replace('?','').split('=')[1].replace(expressionsAll[0].numbers,''))
          let tagNames = this.props.tags.find(item => item.id === tagIds).caption // tagName['caption']
          const searchTerm = <mark>“{tagNames}”</mark>
          title = SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)
          //<span dangerouslySetInnerHTML={{__html: SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)}} />
      } else {
          let tagNames = window.location.search.replace('?','').split('=')[0]
          const searchTerm = <mark>“{tagNames}”</mark>
          title = SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)
      }

      return (
        <TitleMajor>{title}</TitleMajor>
      )
    } else {
      return null
    }*/
      let title

      const count = this.props.filtered.count
      const countTitle = count ? <mark>{count}</mark> : <span className={styles.muted}>{count}</span>

      if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.OWNER || get(this.props.location, 'state.ownerName')) {
        const ownerName = <mark>{this.getOwnerName(this.props)}</mark>
        title = SEARCH_RESULT_PAGE_TITLE_BY_OWNER(ownerName, countTitle)
      } else if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.TAGS || get(this.props.location, 'state.tagName')) {
        const searchTerm = <mark>“{this.getSearchTerm(this.props)}”</mark>
        title = SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)
      } else if (this.props.filtered.filterParams.type === SEARCH_RESULT_FILTER_TYPES.TITLE || get(this.props.location, 'state.searchTitle')) {
        const searchTerm = <mark>“{this.getSearchTerm(this.props)}”</mark>
        title = SEARCH_RESULT_PAGE_TITLE_BY_TITLE(searchTerm, countTitle)
      } else {
        title = SEARCH_RESULT_PAGE_TITLE_BY_UNKNOWN()
      }

      return ( 
          <TitleMajor>{title}</TitleMajor>
      )
  }

  renderItems() {
    if (this.props.filtered.isError) {
      return <Error>{COMMON_ERROR}</Error>
    }
    if (window.location.search.includes('=')) {
      let getTagFromUrl = window.location.search.replace('?','').split('=')[0];
      // filtered.items.length === 0 && get(location, 'state.ownerName') && !filtered.isFetching

      if ((this.props.filtered.items.length === 0 && !this.props.filtered.isFetching) && (getTagFromUrl === 'owner') ) {
        return <Error>{SEARCH_RESULT_EMPTY_BY_OWNER}</Error>
      }

      if (!this.state.isFiltered) { // false
        return this.props.filtered.items.length === 0 && !this.props.filtered.isFetching ? (
          <Error>{SEARCH_RESULT_EMPTY}</Error>
        ) : (
          <div className={styles.inner}>
              {this.renderItemsPopular()}
          </div>
        )
      } else { // true
        return this.props.filtered.items.length === 0 && !this.props.filtered.isFetching ? (
          <Error>{SEARCH_RESULT_EMPTY}</Error>
        ) : (
          <div className={styles.inner}>
              {this.renderItemsDate()}
          </div>
        )
      }

    } else {
      return <Error>{COMMON_ERROR}</Error>
    }
  }

  renderItemsPopular() {
    let popularSort = []
    popularSort = this.props.filtered.items.sort( (a,b) => b.downloads-a.downloads )

    return popularSort.map((item) => this.renderItem(item))
  }
  renderItemsDate() {
    let createdSort = []
    createdSort = this.props.filtered.items.sort( (a,b) => new Date(b.createdAt)-new Date(a.createdAt) )

    return createdSort.map((item) => this.renderItem(item))
  }

  renderItem(item) {
    const lastVersion = first(item.versions)
    return (
        <div className={styles.mod} key={`gallery-item-id-${item.id}`}>
            <CardMod
                authorName={item.authorName}
                cover={item.cover}
                gameVersion={item.gameVersion}
                id={item.id}
                mark={item.mark}
                modSize={lastVersion.versionFileSize}
                modVersion={item.modVersion}
                modVersionFile={lastVersion.downloadUrl}
                modVersionFileOriginalName={lastVersion.versionFileOriginalName}
                ownerId={item.ownerId}
                ownerName={item.ownerName}
                tags={item.tags}
                title={item.title}

                isModDownloadAccepted={this.props.isModDownloadAccepted}
                isSingleVersionAvailable={item.versions.length === 1}

                onDownloadClick={this.handleDownloadClick}

                history={this.props.history}
            />
        </div>
    )
  }

  renderLoadMoreButton() {
    if (this.props.filtered.isFetchedAll || this.props.filtered.items.length === 0) {
      return null
    }

    return (
      <div className={styles.button}>
        <ButtonBlueOutlineLarge onClick={this.handleLoadMoreClick}>
            {LOAD_MORE}
        </ButtonBlueOutlineLarge>
      </div>
    )
  }

  renderCategoriesBlock() {
    if (!this.props.filtered.isError) {
      if (window.location.search.includes('=')) {
        let getTagFromUrl = window.location.search.replace('?','').split('=')[0]
        if ( getTagFromUrl === 'tags' ) {
          return (
            <div className={styles.filter}>
              <Categories 
                history={this.props.history}
                isAllTagChecked={false} // отвечает за выбранный tag `ВСЕ` категории
                checkedTags={[this.getItemId()]} // {[12, 13]}
                tags={this.props.tags}
              />
            </div>
          )
        } else {
          return <div className={styles.PaddingBottom_48px}></div>
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }
  getItemId() {
    let getItemId
    if (window.location.search.includes('=')) {
      let getTagFromUrl = window.location.search.replace('?','').split('=')[0];
      if ( getTagFromUrl === 'tags' ) {
        let tagId = Number(window.location.search.replace('?','').split('=')[1].replace(expressionsAll[0].numbers,''))
        getItemId = allTags.find(item => item.id === tagId).id 
      } else if ( getTagFromUrl === 'owner' ) {
        getItemId = 0;
      } else {
        getItemId = 0;
      }
    } else {
      getItemId = 0;
    }
    return getItemId
  }

  renderFilterMods() {
    if (window.location.search.includes('=')) {
      const classNameFilter = classNames(styles.filter, {
        [styles.isDisabled]: this.props.filtered.isDisabled,
      })
  
      return !this.props.filtered.isError || !isEmpty(this.props.filtered.items) ? ( // this.props.filtered.isError || isEmpty(this.props.filtered.items) ? 
        <div className={classNameFilter}>
          <Toggle
            items={[
              {
                value: SEARCH_RESULT_ITEMS_ORDERING.RATING,
                caption: ORDERING_BY_RATING_FILTERED_MODS,
              },
              {
                value: SEARCH_RESULT_ITEMS_ORDERING.CHANGED_AT,
                caption: ORDERING_BY_CHANGED_AT_FILTERED_MODS,
              },
            ]}
            isDisabled={this.props.filtered.items.length === 0}
            checkedItem={this.props.filtered.filterParams.ordering}
            onItemChange={this.handleOrderingChanged}
          />
        </div>
      ) : null
    } else { 
      return null 
    }
}

  render() {
    if (window.__URLS__.pages.search) {
    return (
      <Main>
        <div className={styles.base}>
          <div className={styles.head}>
            <Back caption={TO_LANDING} to={urls.landing} />
            {this.renderTitleMajor()}
            {this.renderCategoriesBlock()}
            {this.renderFilterMods()}
          </div>
          <div className={styles.body}>
            <section className={styles.section}>
              {this.renderItems()}
              {this.renderLoadMoreButton()}
            </section>
          </div>
        </div>
      </Main>
    )
    } else {
      return <UnavailablePage />
    }
  }
}
