import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';

import './ScrollArea.css';

export default class ScrollArea extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        contentHeightMax: PropTypes.number.isRequired,
        isDropdown: PropTypes.bool,
    }

    renderTrack = () => {
        const scrollTrack = classNames("ScrollArea_scrollTrack", {
            "ScrollArea_isDropdown": this.props.isDropdown,
        })

        return (
            <div className={scrollTrack} />
        )
    }

    renderThumb({ ...props }) {
        return (
            <div
                className="ScrollArea_scrollThumb"
                {...props}
            />
        )
    }

    render() {
        return (
            <Scrollbars
                hideTracksWhenNotNeeded
                autoHeight
                autoHeightMax={this.props.contentHeightMax}
                renderTrackVertical={this.renderTrack}
                renderThumbVertical={this.renderThumb}
            >
                {this.props.children}
            </Scrollbars>
        )
    }
}
