import React from "react"

export const EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_LABEL = "Все обновления:"

export const EDIT_UPLOAD_UPDATE_LIST_ALL_UPDATE_TEXT = "Отметьте файлы для скачивания"

export const EDIT_UPLOAD_UPDATE_LIST_STATE_CAPTION = (state) => {
    let NBSP = `\u00a0`
    return (
        <React.Fragment>
            Статус обновления: {NBSP} {NBSP}
        </React.Fragment>
    )
}

export const EDIT_UPLOAD_UPDATE_LIST_UPLOADED_AT_VALUE = (uploadedAt) => {
    return (
        <React.Fragment>
            {uploadedAt}
        </React.Fragment>
    )
}


export const EDIT_UPLOAD_UPDATE_LIST_GAME_VERSION_VALUE = (gameVersion) => {
    return (
        <React.Fragment>
            МТ {gameVersion}
        </React.Fragment>
    )
}

