import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { concat, first } from 'lodash';

import { COMMON_ERROR } from '../../translations';

import CardMod from '../CardMod/CardMod';
//import { allVersions } from '../../utils/constants';
//import FilterGameVersion from '../FilterGameVersion/FilterGameVersion';
import ButtonGrayOutlineLarge from '../Buttons/ButtonGrayOutlineLarge/ButtonGrayOutlineLarge';
import Error from '../Error/Error';
import TitleMinor from '../TitleMinor/TitleMinor';
import Notification from '../Notification/Notification';

import {
    GALLERY_FILTERED_EMPTY,
    GALLERY_FILTERED_RECOMMENDED_EMPTY,
    GALLERY_FILTERED_NEW_EMPTY,
    GALLERY_POPULAR_MODS,
    GALLERY_NEW_MODS,
    GALLERY_LOAD_POPULAR_MODS,
    GALLERY_LOAD_NEW_MODS,
    //GALLERY_LOAD_FILTERED_MODS,
    GALLERY_ITEMS_TYPES,
    //GALLERY_ITEMS_FILTER_TYPES,
    //getGalleryItemsCountPerRow,
    //GALLERY_ITEMS_COUNT_PER_ROW,
    //GALLERY_ITEMS_PER_PAGE,
    //GALLERY_ITEMS_FILTERED_PER_PAGE,
    //GALLERY_ITEMS_FILTERED_ORDERING
} from './translations'

import './Gallery.css';


const GalleryItemPropTypes = PropTypes.shape({
    authorName: PropTypes.string,
    cover: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string.isRequired, // isRequired
    gameVersion: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    mark: PropTypes.number.isRequired,
    modVersion: PropTypes.string.isRequired,
    ownerId: PropTypes.number.isRequired,
    ownerName: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        caption: PropTypes.string.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(PropTypes.shape({
        downloadUrl: PropTypes.string.isRequired,
        gameVersion: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        modVersion: PropTypes.string.isRequired,
        versionFileOriginalName: PropTypes.string.isRequired,
        versionFileSize: PropTypes.number,
    })).isRequired,
})

const GallerySectionPropTypes = PropTypes.shape({
    isError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetchedAll: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(GalleryItemPropTypes).isRequired,
})


export default class Gallery extends Component {
    static propTypes = {
        [GALLERY_ITEMS_TYPES.RECOMMENDED]: GallerySectionPropTypes.isRequired,
        [GALLERY_ITEMS_TYPES.NEW]: GallerySectionPropTypes.isRequired,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,

        isModDownloadAccepted: PropTypes.bool,

        fetchAllItems: PropTypes.func.isRequired,
        fetchGalleryItemsMore: PropTypes.func.isRequired,
        filterGalleryMore: PropTypes.func.isRequired,
        onDownloadClick: PropTypes.func.isRequired,
    }

    componentDidMount() {
        //let location = [{search: ''}]
        this.props.fetchAllItems() // this.props.location
    }

    componentDidUpdate() {
        //
    }

    isErrorState = () => {
        return this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].isError || 
                this.props[GALLERY_ITEMS_TYPES.NEW].isError
    }

    renderError() {
        return this.isErrorState() ? (
            <Error>{COMMON_ERROR}</Error>
        ) : null
    }

    handleDownloadClick = (modId, isModDownloaded) => {
        const { title, versions } = concat(
            this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items,
            this.props[GALLERY_ITEMS_TYPES.NEW].items,
        ).find((item) => (item.id === modId))
        return this.props.onDownloadClick(this.props.isModDownloadAccepted, isModDownloaded, modId, title, versions)
    }
    
    renderItem(item) {
        const lastVersion = first(item.versions)
        return (
            <div className="Gallery_mod" key={`gallery-item-id-${item.id}`}>
            <CardMod 
                authorName={item.authorName}
                cover={item.cover}
                gameVersion={item.gameVersion}
                id={item.id}
                mark={item.mark}
                modSize={lastVersion.versionFileSize}
                modVersion={item.modVersion}
                modVersionFile={lastVersion.downloadUrl}
                modVersionFileOriginalName={lastVersion.versionFileOriginalName}
                ownerId={item.ownerId}
                ownerName={item.ownerName}
                tags={item.tags}
                title={item.title}

                isModDownloadAccepted={item.isModDownloadAccepted}
                isSingleVersionAvailable={item.versions.length === 1}

                onDownloadClick={this.handleDownloadClick} // window.location.href=item.modVersionFile
                
                history={this.props.history}
            />
            </div>
        )
    }

    renderRecommendedMods() {
        let recommended = []
        recommended = this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items
        //recommendedSort = recommended.sort( (a,b) => b.downloads-a.downloads )

        return recommended.map((item) => this.renderItem(item))
    }

    renderNewMods() {
        let created = []
        created = this.props[GALLERY_ITEMS_TYPES.NEW].items
        //createdSort = created.sort( (a,b) => new Date(b.createdAt)-new Date(a.createdAt) )

        return (
            <div className="Gallery_mods">
                {created.map((item) => this.renderItem(item))}
            </div>
        )
    }

    checkDate(dated) {
        //const arrCreatedAt = updated.map((item) => item.createdAt)
        //console.log( arrCreatedAt.sort( (a,b) => new Date(b)-new Date(a) ) ) // this.checkDate(lastVersion)
        // this.checkDate(formatDateTime(item))
        let dateView
        var thisDate = new Date(dated)
        dateView = thisDate.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' })
        return dateView;
    }

    renderLangingLoadMoreButton(itemsType) {
        if (this.props[itemsType].isFetchedAll) {
            return null
        }

        switch (itemsType) {
            case GALLERY_ITEMS_TYPES.RECOMMENDED: {
                return (
                    <div className="Gallery_button">
                        <ButtonGrayOutlineLarge
                            onClick={()=>{ this.props.fetchGalleryItemsMore(itemsType) }}
                            gtmTag={'ev_click-load-more'}
                        >
                            {GALLERY_LOAD_POPULAR_MODS}
                        </ButtonGrayOutlineLarge>
                    </div>
                )
            }
            case GALLERY_ITEMS_TYPES.NEW: {
                return (
                    <div className="Gallery_button">
                        <ButtonGrayOutlineLarge
                            onClick={()=>{ this.props.fetchGalleryItemsMore(itemsType) }}
                            gtmTag={'ev_click-load-more'}
                        >
                            {GALLERY_LOAD_NEW_MODS}
                        </ButtonGrayOutlineLarge>
                    </div>
                )
            }
            default:
                // do nothing
        }
    }

    renderLandingSections() {
        const isSomethingFetching = this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].isFetching ||
            this.props[GALLERY_ITEMS_TYPES.NEW].isFetching

        const isAllEmpty = !this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items.length &&
        !this.props[GALLERY_ITEMS_TYPES.NEW].items.length
        
        if (this.isErrorState()) {
            return null
        }

        if (isSomethingFetching && isAllEmpty) {
            return null
        }

        if (!isSomethingFetching && isAllEmpty) {
            return (
                <Error>{GALLERY_FILTERED_EMPTY}</Error>
            )
        }

        return (
            <React.Fragment>
                <section className="Gallery_section" key={`section-${GALLERY_ITEMS_TYPES.RECOMMENDED}`}>
                    <div className="Gallery_filteredHead">
                        <div className="Gallery_title">
                            <TitleMinor>{GALLERY_POPULAR_MODS}</TitleMinor>
                        </div>
                        {/* <div className="Gallery_filter">
                            <FilterGameVersion 
                                isDisabled={false}
                                gameVersions={allVersions}
                                selectedGameVersion={177}
                                isUsedForSearch={false}
                                onChange={ ()=>{} } /> 
                        </div> */}
                    </div>
                    {this.props[GALLERY_ITEMS_TYPES.RECOMMENDED].items.length ? (
                        <div className="Gallery_filteredBody">
                            <div className="Gallery_inner">
                                <div className="Gallery_mods">
                                    {this.renderRecommendedMods()}
                                </div>
                                {this.renderLangingLoadMoreButton(GALLERY_ITEMS_TYPES.RECOMMENDED)}
                            </div>
                        </div>
                    ) : (
                        <Notification type="info">
                            <p>{GALLERY_FILTERED_RECOMMENDED_EMPTY}</p>
                        </Notification>
                    )}
                </section>

                <section className="Gallery_section" key={`section-${GALLERY_ITEMS_TYPES.NEW}`}>
                    <div className="Gallery_title">
                        <TitleMinor>{GALLERY_NEW_MODS}</TitleMinor>
                    </div>
                    {this.props[GALLERY_ITEMS_TYPES.NEW].items.length ? (
                        <div className="Gallery_inner">
                                {this.renderNewMods()}
                            {this.renderLangingLoadMoreButton(GALLERY_ITEMS_TYPES.NEW)}
                        </div>
                    ) : (
                        <Notification type="info">
                            <p>{GALLERY_FILTERED_NEW_EMPTY}</p>
                        </Notification>
                    )}
                </section>
            </React.Fragment>
        )
    }
    
    render() {
    return (
        <div className="Gallery_base">
            { this.renderError() }
            { this.renderLandingSections() }
        </div>
    )
  }
}
