import React, { Component } from 'react';
//import PropTypes from 'prop-types';

import { getUsername } from '../../utils/functions';
import { pushHistoryWithErrorNotFound } from '../../utils/routing';

import Main from '../Main/Main';
//import AuthForm from '../Forms/AuthForm/AuthForm';
import InfoBlock from './InfoBlock/InfoBlock';
import ModderBlock from './ModderBlock/ModderBlock';
import CommentsBlock from './CommentsBlock/CommentsBlock';
import UnavailablePageWrapper from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';

export default class AdminPanel extends Component {
    componentDidMount() {
        this.props.onAuthAdmin()
    }

    renderError() {
        if (getUsername().spaId === null || getUsername().spaUsername === null) {
            return pushHistoryWithErrorNotFound(this.props.history);
        }
    }

    renderPanel() {
        if (this.props.isAuthAdmin || typeof(this.props.isAuthAdmin) !== 'undefined') {
            return <div>AdminPanel</div>
        } else {
            //return <AuthForm />
            return (
                <>
                    <div><p>Центр поддержки</p><p>Секция модератора</p><p>Комментарии</p><p>Пользователи</p></div>
                    <InfoBlock {...this.props} />
                    <ModderBlock {...this.props} />
                    <CommentsBlock {...this.props} />
                    <div>Users Block</div>
                </>
            )
        }
    }
    
    render() {
        if (!window.__URLS__.pages.moderatorSection) {
            return <UnavailablePageWrapper />
        } else {
            return (
                <Main>
                    {this.renderError()}
                    {this.renderPanel()}
                </Main>
            )
        }
    }
}
