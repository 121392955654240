import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { allVersions } from '../../utils/constants';
//import { pushHistoryWithTitle } from '../../utils/routing';

import Main from '../../components/Main/Main';
import Gallery from '../../containers/GalleryWrapper/GalleryWrapper';
import Categories from '../../containers/CategoriesWrapper/CategoriesWrapper';
// import GalleryCategories from '../../components/GalleryCategories/GalleryCategories';
import UnavailablePage from '../../containers/UnavailablePageWrapper/UnavailablePageWrapper';
//import Search from '../Search/Search';
//import SearchBlock from '../Search/SearchBlock/SearchBlock';
//import FilterGameVersion from '../../containers/FilterGVWrapper/FilterGVWrapper';

import styles from './Landing.module.css';


export default class Landing extends Component {
    static propTypes = {
        gameVersion: PropTypes.number,
    }

    componentDidMount() {
        document.title = 'Моды для Мир танков. Скачай и пользуйся!'
    }

    CheckSelectedGameVersion() {
        // this.props.gameVersion !== null
        if (this.props.gameVersion) {
            return this.props.gameVersion;
        } else {
            return allVersions[0].id;
        }
    }

    componentDidUpdate() {
        /*if (this.props.gameVersion !== this.CheckSelectedGameVersion()) {
          return false;
        }*/
    }
    componentWillUnmount() {
        // выполняется необходимый сброс: отмена таймеров, сетевых запросов и подписок, созданных в componentDidMount()
    }

    render() {
        // this.props.available
        if (window.__URLS__.pages.landing) {
        return (
            <div className={styles.base}>
                <div className={styles.head}>
                    <header className={styles.Header_base}>
                        <div className={styles.Header_inner}>
                            <a href="/" className={styles.Header_logo}> </a> 
                            {/*<div className={styles.searchPanel}>
                                <a href="/" className={styles.Header_logo}> </a> 
                                <div className={styles.Header_search}>   
                                    <Search
                                        value={this.props.searchTerm}
                                        onSearchContolClick={(value) => {
                                            value && pushHistoryWithTitle(this.props.history, value) // value && 
                                        }}
                                    />
                                </div>
                                <div className={styles.searchesLogo}>
                                    <SearchBlock {...this.props} />
                                </div>
                            </div>*/}
                            {/*<div className={styles.selectPanel}>
                                {/* Версия игры /}
                                <FilterGameVersion 
                                    // gameVersions={allVersions} // массив с версиями
                                    // isUsedForSearch={false}
                                    // selectedGameVersion={this.CheckSelectedGameVersion()} // id последней версии
                                    // onChange={ () => {} } // при нажатии сделать смену текста отображаемого в филbтре
                                />
                            </div>*/}
                            <div className={styles.categories}>
                                <Categories history={this.props.history} location={this.props.location} navigate={this.props.navigate} params={this.props.params} /> {/* history={this.props.history} */}
                                {/* <div style={{ textAlign: "center", alignContent: "center" }}><GalleryCategories /></div><br /> */}
                            </div>
                        </div>
                    </header>
                </div>
                <div className={styles.body}>
                    <Main>
                        <Gallery history={this.props.history} /> {/*  location={this.props.location}  */}
                    </Main>
                </div>
            </div>
        )
        } else {
            return <UnavailablePage />
        }
  }
}
