import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, set } from 'lodash';

import settings from '../../../settings';

import { FIELD_VALIDATION_ERROR_TYPES } from '../../../utils/constants';

import ButtonBlueSmall from '../../Buttons/ButtonBlueSmall/ButtonBlueSmall';
import Cancel from '../../Cancel/Cancel';
import Caption from '../../Caption/Caption';
import Checkbox from '../../Checkbox/Checkbox';
import EditChangelog from '../EditChangelog/EditChangelog';
import EditComment from '../EditComment/EditComment';
import EditUploadMod from '../EditUploadMod/EditUploadMod';
import EditGameVersion from '../EditGameVersion/EditGameVersion';
import EditModVersion from '../EditModVersion/EditModVersion';

import {
    EDIT_UPLOAD_UPDATE_BUTTON,
    EDIT_UPLOAD_UPDATE_FORM_CANCEL_CAPTION,
    EDIT_UPLOAD_UPDATE_FORM_LABEL,
    EDIT_UPLOAD_UPDATE_FORM_PUBLISH_CHECKBOX_LABEL,
    EDIT_UPLOAD_UPDATE_FORM_PUBLISH_LABEL,
} from './translations';

import styles from './ModVersionUploadForm.module.css';

export default class ModVersionUploadForm extends React.PureComponent {
    static propTypes = {
        gameVersions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                version: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        modId: PropTypes.number.isRequired,

        onFormClose: PropTypes.func.isRequired,
        onFormSubmit: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            fieldErrors: {
                changelog: null,
                comment: null,
                modFile: null,
                modVersion: null,
            },
            isPublished: true,
        }

        this.formData = {
            changelog: null,
            comment: null,
            gameVersionId: null,
            isPublished: true,
            modFileId: null,
            modFileToken: null,
            modVersion: '',
        }

        this.formErrors = {
            modFile: FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
            modVersion: FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
        }

    }

    handleUploadCompleted = (data, errorType) => {
        let combinedErrorType = null
        if (errorType) {
            combinedErrorType = errorType
        } else if (data === null) {
            combinedErrorType = FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED
        }

        set(this.formData, 'modFileId', data ? data.id : null)
        set(this.formData, 'modFileToken', data ? data.access_token : null)
        set(this.formErrors, 'modFile', combinedErrorType)
        if (!combinedErrorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, 'modFile', null)
            this.setState({ fieldErrors: stateFieldErrors })
        }
    }

    handleGameVersionChange = (gameVersionId) => {
        set(this.formData, 'gameVersionId', gameVersionId)
    }

    handleModVersionChange = (modVersion, errorType) => {
        this.setFormValues('modVersion', modVersion, errorType)
    }

    handleChangelogChange = (changelog, errorType) => {
        this.setFormValues('changelog', changelog, errorType)
    }

    handleCommentChange = (comment, errorType) => {
        this.setFormValues('comment', comment, errorType)
    }

    handlePublishChange = () => {
        const isPublished = !this.formData.isPublished
        set(this.formData, 'isPublished', isPublished)
        this.setState({ isPublished })
    }

    handleSubmitClick = () => {
        const fieldRules = {
            modFile: [
                FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
                FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
            ],
            modVersion: [
                FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            changelog: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
            comment: [
                FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            ],
        }

        const stateValue = this.getValidationErrors(fieldRules)
        if (stateValue) {
            this.logSubmittingFailed(stateValue)
            this.setState(stateValue)
            return
        }

        this.logSubmittingOk()
        set(this.formData, 'modId', this.props.modId)
        this.props.onFormSubmit(this.props.modId, this.formData)
    }

    getValidationErrors(fieldRules) {
        const stateValue = {}
        let hasError = false

        each(fieldRules, (ruleset, field) => {
            if (ruleset.includes(this.formErrors[field])) {
                set(stateValue, `fieldErrors.${field}`, this.formErrors[field])
                hasError = true
            }
        })

        return hasError ? stateValue : null
    }

    setFormValues(field, value, errorType) {
        set(this.formData, field, value)
        set(this.formErrors, field, errorType)
        if (!errorType) {
            const stateFieldErrors = cloneDeep(this.state.fieldErrors)
            set(stateFieldErrors, field, null)
            this.setState({ fieldErrors: stateFieldErrors })
        }
    }

    logSubmittingFailed(stateValue) {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[FAILED] submitting, stateValue: ', 'color: red;', stateValue)
        // eslint-disable-next-line no-console
        console.log('form errors', this.formErrors)
    }

    logSubmittingOk() {
        if (!settings.debug) {
            return
        }

        // eslint-disable-next-line no-console
        console.log('%c[OK] submitting', 'color: green;', this.formData)
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.caption}>
                    <Caption isLarge>{EDIT_UPLOAD_UPDATE_FORM_LABEL}</Caption>
                    <div className={styles.cancel}>
                        <Cancel
                            caption={EDIT_UPLOAD_UPDATE_FORM_CANCEL_CAPTION}
                            align="left"
                            onClick={this.props.onFormClose}
                        />
                    </div>
                </div>

                <EditUploadMod
                    externalError={this.state.fieldErrors.modFile}
                    isSecondary
                    onUploadCompleted={this.handleUploadCompleted}
                />
                <div className={styles.divider} />

                <EditGameVersion
                    gameVersions={this.props.gameVersions}
                    isSecondary
                    onGameVersionChange={this.handleGameVersionChange}
                />
                <div className={styles.divider} />

                <EditModVersion
                    externalError={this.state.fieldErrors.modVersion}
                    isSecondary
                    onValueChange={this.handleModVersionChange}
                />
                <div className={styles.divider} />

                <EditChangelog
                    externalError={this.state.fieldErrors.changelog}
                    isSecondary
                    onValueChange={this.handleChangelogChange}
                />
                <div className={styles.divider} />

                <EditComment
                    externalError={this.state.fieldErrors.comment}
                    isSecondary
                    onValueChange={this.handleCommentChange}
                />
                <div className={styles.divider} />

                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption>
                            {EDIT_UPLOAD_UPDATE_FORM_PUBLISH_LABEL}
                        </Caption>
                    </div>
                    <div className={styles.field}>
                        <Checkbox
                            caption={EDIT_UPLOAD_UPDATE_FORM_PUBLISH_CHECKBOX_LABEL}
                            isChecked={this.state.isPublished}
                            isDisabled={false}
                            onChange={this.handlePublishChange}
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.label} />
                    <div className={styles.field}>
                        <ButtonBlueSmall onClick={this.handleSubmitClick}>
                            {EDIT_UPLOAD_UPDATE_BUTTON}
                        </ButtonBlueSmall>
                    </div>
                </div>
            </div>
        )
    }
}
