import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import styles from './ButtonBlueOutlineLarge.module.css';

export default class ButtonBlueOutlineLarge extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        gtmTag: PropTypes.string,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = () => {
        /*if (this.gtmTagRef) {
            this.gtmTagRef.click()
        }*/
        this.props.onClick()
    }

    renderGtmTag() {
        return !isUndefined(this.props.gtmTag) ? (
            <div className={classNames(this.props.gtmTag)} ref={c => (this.gtmTagRef = c)} />
        ) : null
    }

    render() {
        return (
            <React.Fragment>
                {this.renderGtmTag()}
                <button className={styles.base} onClick={this.handleClick}>
                    {this.props.children}
                </button>
            </React.Fragment>
        )
    }
}
