import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Cancel.module.css';

export default class Cancel extends React.PureComponent {
    static propTypes = {
        caption: PropTypes.node,
        align: PropTypes.oneOf(['left', 'right']),
        onClick: PropTypes.func.isRequired,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles[`base__${this.props.align}`]]: this.props.align,
        })

        return (
            <div className={classNameBase} onClick={this.props.onClick}>{this.props.caption}</div>
        )
    }
}
