import { BREAKPOINT } from "../../utils/constants";

export const GALLERY_FILTERED_EMPTY = `В данный момент моды в выбранной категории отсутствуют. Выберите другую категорию.`;
export const GALLERY_FILTERED_RECOMMENDED_EMPTY = `Популярных модов для выбранной версии игры пока нет. Они обязательно появятся в ближайшее время.`;
export const GALLERY_FILTERED_NEW_EMPTY = `Новых модов нет`;
export const GALLERY_POPULAR_MODS = `Популярные моды`;
export const GALLERY_NEW_MODS = `Недавние добавления`; // Обновленные моды
export const GALLERY_LOAD_POPULAR_MODS = `Показать больше популярных`; // Показать больше популярных
export const GALLERY_LOAD_NEW_MODS = `Показать больше новых`;
export const GALLERY_LOAD_FILTERED_MODS = `Показать еще`;
export const GALLERY_ITEMS_TYPES = {
    RECOMMENDED: 'recommended', // null 
    NEW: 'new',
}
export const GALLERY_ITEMS_FILTER_TYPES = {
    TAGS: 'tags',
}

export const getGalleryItemsCountPerRow = () => {
    return window.innerWidth > BREAKPOINT ? 4 : 3
}
export const GALLERY_ITEMS_COUNT_PER_ROW = getGalleryItemsCountPerRow()
export const GALLERY_ITEMS_PER_PAGE = {
    [GALLERY_ITEMS_TYPES.RECOMMENDED]: 3 * GALLERY_ITEMS_COUNT_PER_ROW,
    [GALLERY_ITEMS_TYPES.NEW]: GALLERY_ITEMS_COUNT_PER_ROW,
}

export const GALLERY_ITEMS_FILTERED_PER_PAGE = 3 * GALLERY_ITEMS_COUNT_PER_ROW

export const GALLERY_ITEMS_FILTERED_ORDERING = {
    RATING: '-rating',
    CHANGED_AT: '-changed_at',
}