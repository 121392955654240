import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { includes } from 'lodash';

import settings from '../../settings';
import { formatFileSize } from '../../utils/formatting';
import { MOD_STATE } from '../../utils/constants';
import { getDetailsUrl, getEditUrl, pushHistoryWithOwner, pushHistoryWithTags } from '../../utils/routing';

import Tag from '../Tag/Tag';
import ButtonBlueSmall from '../Buttons/ButtonBlueSmall/ButtonBlueSmall';
import ModState from '../ModState/ModState';
import LinesClamp from '../LinesClamp/LinesClamp';

import {
    MOD_SUMMARY_VERSION,
    MOD_VIEW_LABEL,
    MOD_DOWNLOAD_BUTTON_CAPTION
} from './translations';

import styles from './CardMod.module.css';


export default class CardMod extends Component {
    static propTypes = {
        authorName: PropTypes.string,
        cover: PropTypes.string.isRequired,
        gameVersion: PropTypes.string,
        id: PropTypes.number.isRequired,
        isEditLinkEnabled: PropTypes.bool,
        isDownloadDisabled: PropTypes.bool,
        isModDownloadAccepted: PropTypes.bool,
        isSingleVersionAvailable: PropTypes.bool,
        isViewModLinkEnabled: PropTypes.bool,
        mark: PropTypes.number.isRequired,
        modSize: PropTypes.number,
        modVersion: PropTypes.string,
        modVersionFile: PropTypes.string,
        modVersionFileOriginalName: PropTypes.string,
        ownerId: PropTypes.number.isRequired,
        ownerName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        state: PropTypes.number,
        isDisabled: PropTypes.bool,
        onDownloadClick: PropTypes.func,

        history: ReactRouterPropTypes.history.isRequired,
    }
    constructor(props) {
        super(props)
        this.handleMouseDown = this.handleMouseDown.bind(this)
        this.handleModClick = this.handleModClick.bind(this)
    }
    // заголовок сайта модов
    setDocumentTitle(props) {
        let titlePage = '';
        if (props.title !== null) {
            titlePage = props.title+' - Скачать моды для Мир танков';
        } else {
            titlePage = props.ownerName+' - Скачать моды для Мир танков';
        }
        document.title = titlePage
    }

    // рендерим блок с обложкой, если в базе есть картинка, отображаем ее, если нет - с портала общую
    renderCover() {
        const cover = this.props.cover
        return cover ? (
            <img className={styles.cover} src={cover} alt="cover"/>
        ) : (
            <div className={styles.cover} />
        )
    }

    // рендерим блок автор и загрузил
    renderAuthor = () => {
        const isClickable = !this.props.authorName
        //!this.props.authorName ? isClickable = true : isClickable = false
        const classNameOwnerName = classNames(styles.ownerName, {
            [styles.isClickable]: isClickable,
        })
        var MOD_SUMMARY_AUTHOR_CAPTION = `Автор:`;

        return (
            <div className={styles.owner}>
                {MOD_SUMMARY_AUTHOR_CAPTION}
                &nbsp;
                <span
                    className={classNameOwnerName}
                    title={this.props.authorName || this.props.ownerName}
                    onClick={(event) => {
                        event.preventDefault()
                        if (isClickable) {
                            //useNavigates('/search/?owner='+this.props.ownerId, this.props.ownerName)
                            pushHistoryWithOwner(this.props.history, this.props.ownerId, this.props.ownerName)
                            //window.location.href = '/search/?owner='+this.props.ownerId;
                        }
                    }}
                >
                    {this.props.authorName || this.props.ownerName}
                </span>
            </div>
        )
    }

    // рендерим тэги категории
    renderTags() {
        return this.props.tags.map((tag, index) => (
            <div className={styles.tag} key={`mod-tag-${tag.id}-${index}`}>
                <Tag
                    id={tag.id} // from 10 to 17
                    caption={tag.caption} 
                    type={null} // light warning
                    isChecked={false} // true false
                    isError={false} // true false
                    isDisabled={false} // true false
                    onClick={ () => this.handlerTagClick(this.props.history, [tag.id]) }
                />
            </div>
        ))
    }

    // рендерим отображение статуса мода
    renderState() {
        const stateValues = Object.values(MOD_STATE)
        if (!includes(stateValues, this.props.state)) {
            return null
        }
        return (
            <div className={styles.status}>
                <ModState isBlock state={this.props.state} />
            </div>
        )
    }

    // рендерим блок версия мода
    renderVersions() {
        let isNewVersion = false
        if (this.props.gameVersion && settings.gameVersions.length) {
            isNewVersion = settings.gameVersions[0].version === this.props.gameVersion
        }

        return (this.props.gameVersion && this.props.modVersion) ? (
            <div className={styles.versions}>
                {MOD_SUMMARY_VERSION(this.props.modVersion, this.props.gameVersion, isNewVersion)}
            </div>
        ) : null
    }

    renderViewModLink() {
        if (!this.props.isViewModLinkEnabled) {
            return null
        }
        return (
            <Link className={styles.viewMod} to={getDetailsUrl(this.props.id)}>
                <div className={styles.viewModIcon} />
                <div className={styles.viewModLabel}>{MOD_VIEW_LABEL}</div>
            </Link>
        )
    }

    handleModClick() {
        if (!this.props.isEditLinkEnabled) {
            this.eventClickModRef.click()
        }
    }

    handlerTagClick = (history, tagId) => {
        this.eventClickTagRef.click()
        pushHistoryWithTags(history, tagId)
    }

    handleMouseDown(event) {
        if (!this.props.isEditLinkEnabled && event.button === 1) {
            this.eventClickModRef.click()
        }
    }

    handleHiddenLinkClick = (event) => {
        event.stopPropagation()
    }

    handleDownloadClick = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const isModDownloadAvailable = this.props.isModDownloadAccepted && this.props.isSingleVersionAvailable
        if (isModDownloadAvailable) {
            this._downloadLinkRef.click()
        }

        this.props.onDownloadClick(this.props.id, isModDownloadAvailable)
    }
    // кнопка отображения инфы
    renderDownload() {
        return !this.props.isDownloadDisabled ? (
            <React.Fragment>
                <div className={styles.button}>
                    <ButtonBlueSmall onClick={this.handleDownloadClick}>
                        {MOD_DOWNLOAD_BUTTON_CAPTION(formatFileSize(this.props.modSize))}
                    </ButtonBlueSmall>
                </div>
            </React.Fragment>
        ) : null
    }

    renderHiddenDownloadLink() {
        return (
            <a
                className={styles.hidden}
                ref={c => (this._downloadLinkRef = c)}
                onClick={this.handleHiddenLinkClick}
                href={this.props.modVersionFile}
                download={this.props.modVersionFileOriginalName}
            > </a>
        )
    }
  
    render() {
        const classNamesBase = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })
        const classNameBody = classNames(styles.body, {
            [styles.isDownloadDisabled]: this.props.isDownloadDisabled,
        })
        const targetUrl = this.props.isEditLinkEnabled ? getEditUrl(this.props.id) : getDetailsUrl(this.props.id)
        return (
            <>
            {/* <!-- Сетка карточек  --> */}
            {/* <div className="Gallery_mod"> */}
                <div className={classNamesBase}>
                    <div className={classNames('ev_click-mod')} ref={c => (this.eventClickModRef = c)} />
                    <div className={classNames('ev_click-tag-on-mod')} ref={c => (this.eventClickTagRef = c)} />
                    <Link to={targetUrl}
                        className={styles.inner}
                        target="_top"
                        onClick={this.handleModClick}
                        onMouseDown={this.handleMouseDown} >
                        {this.renderState()}

                        <div className={styles.header}>
                            {this.renderCover()}
                        </div>

                        <div className={classNameBody}>
                            <div className={styles.title} title={this.props.title}>
                                <LinesClamp
                                    className={styles.clampLines}
                                    text={this.props.title}
                                    lines={2}
                                />
                            </div>
                            <div className={styles.summary}>
                                {this.renderVersions()}
                                {this.renderAuthor()}
                            </div>

                            {this.renderDownload()}
                        </div>

                        <div className={styles.footer}>
                            {this.renderTags()}
                        </div>
                    </Link>
                    {this.renderViewModLink()}
                    {this.renderHiddenDownloadLink()}
                    {/* <a className={styles.hidden} href={this.props.modVersionFile} download={this.props.modVersionFileOriginalName}> </a> */}
                </div>
            {/* </div> */}
            </>
        )
    }
}
