import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { pushHistoryWithTitle, urls } from '../../utils/routing';
import { getUserRole } from '../../utils/functions';

import ButtonUserMenu from '../../containers/ButtonUserMenuWrapper/ButtonUserMenuWrapper';
import UserMenuWrapper from '../../containers/UserMenuWrapper/UserMenuWrapper';
import Search from '../Search/Search';

import './translations'; // translate nav text
import { NAV_LINK } from './translations';

import styles from './Nav.module.css';

export default class Nav extends Component {
    
    renderAdminLinks = () => {
        let userRole = getUserRole();
        
        if (userRole === 'admin') {
            return (
                <NavLink exact="true" to={urls.adminSection} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.ADMIN_SECTION}
                </NavLink>
            )
        } else if (userRole === 'moderator') {
            return (
                <NavLink exact="true" to={urls.moderatorSection} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.MODERATOR_SECTION}
                </NavLink>
            )
        } else {
            return null
        }
    }
    
    render() {
        return (
            <div className={styles.base}>
                <nav className={styles.inner}>
                    <a href="/" className={styles.logo} > </a>

                    <NavLink exact="true" to={urls.landing} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}`: styles.link) }>
                        {NAV_LINK.MAIN}
                    </NavLink>

                    <NavLink exact="true" to={urls.news} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                        {NAV_LINK.NEWS}
                    </NavLink>

                    <NavLink exact="true" to={urls.subscriptions} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                        {NAV_LINK.SUBSCRIPTIONS}
                    </NavLink>

                    <NavLink exact="true" to={urls.modderSection} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                        {NAV_LINK.MODDER_SECTION}
                    </NavLink>

                    {this.renderAdminLinks()}

                    <div className={styles.search}>
                        <Search
                            value={this.props.searchTerm}
                            onSearchContolClick={(value) => {
                                value && pushHistoryWithTitle(this.props.history, value) // value && 
                            }}
                        />
                    </div>

                    <ButtonUserMenu />

                    <UserMenuWrapper />
                </nav>
            </div>
        )
    }
}


/*import React from 'react';
import { NavLink } from 'react-router-dom';

import { urls } from '../../utils/routing';

import ButtonUserMenu from '../../containers/ButtonUserMenuWrapper/ButtonUserMenuWrapper';
import UserMenuWrapper from '../../containers/UserMenuWrapper/UserMenuWrapper';
import './translations'; // translate nav text
import { NAV_LINK } from './translations';

import styles from './Nav.module.css';

export default function Nav() {
    
    renderLinks = () => {
        //
    }

    return (
        <div className={styles.base}>
            <nav className={styles.inner}>
                <a href="/" className={styles.logo} > </a>

                <NavLink exact="true" to={urls.landing} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}`: styles.link) }>
                    {NAV_LINK.MAIN}
                </NavLink>

                <NavLink exact="true" to={urls.news} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.NEWS}
                </NavLink>

                <NavLink exact="true" to={urls.subscriptions} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.SUBSCRIPTIONS}
                </NavLink>

                <NavLink exact="true" to={urls.modderSection} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.MODDER_SECTION}
                </NavLink>

                <NavLink exact="true" to={urls.adminSection} className={ ({isActive}) => (isActive ? `${styles.link} ${styles.isActive}` : styles.link) }>
                    {NAV_LINK.ADMIN_SECTION}
                </NavLink>

                <ButtonUserMenu />

                <UserMenuWrapper />
            </nav>
        </div>
    )
}*/
