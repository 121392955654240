import React from 'react';
import LinesClamp from '../../LinesClamp/LinesClamp';

import styles from './DialogDownload.module.css';

export const DIALOG_DOWNLOAD_CAPTION = (modName) => {
    return (
        <LinesClamp
            className={styles.clampLines}
            text={`Скачать мод ${modName}`}
            lines={2}
        />
    )
}

export const DIALOG_DOWNLOAD_SELECT_CAPTION = "Выберите версию мода:"

export const DIALOG_DOWNLOAD_ALERT = "Выбранная версия мода не соответствует актуальной версии игры."

export const DIALOG_DOWNLOAD_OK_BUTTON = (size) => {
    return <React.Fragment>Скачать мод <mark>({size})</mark></React.Fragment>
}
    /*{
    return <span dangerouslySetInnerHTML={{__html: ``}}></span>
}*/

export const DIALOG_DOWNLOAD_ATTENTION = <React.Fragment>Обращаем Ваше внимание на то, что <mark>компания Lesta не несёт ответственности</mark> за использование модификаций.</React.Fragment>
/*<span dangerouslySetInnerHTML={
    {__html: `Обращаем ваше внимание на то, что компания <mark>Lesta не несёт ответственности за использование модификаций</mark>.`}
}></span>*/

export const DIALOG_DOWNLOAD_VERSION_VALUE = (modVersion, gameVersion) => {
    return <React.Fragment>{modVersion} для Мир танков {gameVersion}</React.Fragment>
}
    /*{
    return <span dangerouslySetInnerHTML={{__html: `${modVersion} для Мир танков ${gameVersion}`}}></span>
}*/
