import React from "react"

export const MOD_DETAILS_DOWNLOAD_BUTTON_CAPTION = (size) => {
    return <React.Fragment>Скачать мод <mark>({size})</mark></React.Fragment>
    //return <span dangerouslySetInnerHTML={{__html: `Скачать мод <mark>(${size})</mark>` }}></span>
} 
export const MOD_DETAILS_DOWNLOADS = (downloads) => {
    return <React.Fragment>Скачали: <mark>{downloads}</mark></React.Fragment>
    //return <span dangerouslySetInnerHTML={{__html: `Скачали: <mark>${downloads}</mark>` }}></span>
} 
export const MOD_DETAILS_MOD_VERSION = (modVersion) => {
    return <React.Fragment>Версия мода: <mark>{modVersion}</mark></React.Fragment>
    //return <span dangerouslySetInnerHTML={{__html: `Версия мода: <mark>${modVersion}</mark>` }}></span>
} 
export const MOD_DETAILS_GAME_VERSION = (gameVersion, isNewVersion) => {
    let gameVers
    if (isNewVersion) {
        gameVers = <mark>{gameVersion}</mark>
    } else {
        gameVers = <i>{gameVersion}</i>
    }
    return (
        <React.Fragment>Версия игры: {gameVers}</React.Fragment>
        //<span dangerouslySetInnerHTML={{__html: `Версия игры: ${gameVers}` }}></span>
    )
} // gameVersion: isNewVersion ? (<mark>{gameVersion}</mark>) : (<i>{gameVersion}</i>)
export const MOD_DETAILS_GAME_CREATED_AT = (createdAt) => {
    return <React.Fragment>Добавлен: <mark>{createdAt}</mark></React.Fragment>
    //return <span dangerouslySetInnerHTML={{__html: `Добавлен: <mark>${createdAt}</mark>` }}></span>
} 
export const MOD_DETAILS_GAME_UPDATED_AT = (updatedAt) => {
    return <React.Fragment>Обновлён: <mark>{updatedAt}</mark></React.Fragment>
    //return <span dangerouslySetInnerHTML={{__html: `Обновлён: <mark>${updatedAt}</mark>` }}></span>
} 
export const MOD_DETAILS_STATUS = `Проверен`;