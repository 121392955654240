import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import ButtonBlueOutlineLarge from '../../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';

import {
    DIALOG_CONFIRMATION_DRAFT_CONTENT,
    DIALOG_CONFIRMATION_DRAFT_TITLE,
    DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON,
    DIALOG_CONFIRMATION_MOD_CONTENT,
    DIALOG_CONFIRMATION_MOD_OK_BUTTON,
    DIALOG_CONFIRMATION_MOD_TITLE,
} from './translations';

import styles from './DialogConfirmationModRemove.module.css';

export default class DialogConfirmationModRemove extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        isDraftOrRejected: PropTypes.bool,

        onCloseDialog: PropTypes.func.isRequired,
        onSubmitDialog: PropTypes.func.isRequired,
    }

    handleSubmitDialog = () => {
        this.props.onSubmitDialog(this.props.id)
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>
                    {this.props.isDraftOrRejected ? DIALOG_CONFIRMATION_DRAFT_TITLE : DIALOG_CONFIRMATION_MOD_TITLE}
                </TitleDialog>
                <div className={styles.content}>
                    <p>{this.props.isDraftOrRejected ? DIALOG_CONFIRMATION_DRAFT_CONTENT : DIALOG_CONFIRMATION_MOD_CONTENT}</p>
                </div>
                <div className={styles.button}>
                    <ButtonBlueLarge onClick={this.props.onCloseDialog}>
                        {DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON}
                    </ButtonBlueLarge>
                </div>
                <div className={styles.button}>
                    <ButtonBlueOutlineLarge onClick={this.handleSubmitDialog}>
                        {DIALOG_CONFIRMATION_MOD_OK_BUTTON}
                    </ButtonBlueOutlineLarge>
                </div>
            </div>
        )
    }
}
