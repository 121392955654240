import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBlueLarge } from '../../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import TitleDialog from '../../TitleDialog/TitleDialog';

import { DIALOG_IE_ALERT_CONTENT } from './translations';

import {
    ATTENTION,
    CLOSE,
} from '../../../translations';

import styles from './DialogIEAlert.module.css';

export default class DialogIEAlert extends React.PureComponent {
    static propTypes = {
        onCloseDialog: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{ATTENTION}</TitleDialog>
                <div className={styles.content}>
                    <p>{DIALOG_IE_ALERT_CONTENT}</p>
                </div>
                <div>
                    <div className={styles.button}>
                        <ButtonBlueLarge onClick={this.props.onCloseDialog}>
                            {CLOSE}
                        </ButtonBlueLarge>
                    </div>
                </div>
            </div>
        )
    }
}
