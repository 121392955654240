import { union, map } from 'lodash';
import { batchActions } from 'redux-batched-actions';
//import queryString from 'query-string';

import { processGalleryItems } from '../utils/responseProcessing';
//import { parseIdsQueryPart } from '../utils/filtration';
import { 
    GALLERY_ITEMS_FILTERED_PER_PAGE, 
    GALLERY_ITEMS_FILTER_TYPES, 
    GALLERY_ITEMS_PER_PAGE, 
    GALLERY_ITEMS_TYPES 
} from '../components/Gallery/translations';
import store from '../store';
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import urls from '../apiUrls';

/*
    Fetching data displayed at the landing page
 */
export const INCREMENT_FETCHING_OFFSET = 'INCREMENT_FETCHING_OFFSET'
export const RESET_ITEMS = 'RESET_ITEMS'
export const RESET_FILTER = 'RESET_FILTER'
export const SET_ERROR_FETCHING_GALLERY_ITEMS = 'SET_ERROR_FETCHING_GALLERY_ITEMS'
export const START_FETCHING_GALLERY_ITEMS = 'START_FETCHING_GALLERY_ITEMS'
export const STOP_FETCHING_GALLERY_ITEMS = 'STOP_FETCHING_GALLERY_ITEMS'
export const UPDATE_GALLERY_ITEMS = 'UPDATE_GALLERY_ITEMS'

export const startFetchingGalleryItems = (itemsType) => ({
    type: START_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
    },
})

export const stopFetchingGalleryItems = (itemsType) => ({
    type: STOP_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
    },
})

export const updateGalleryItems = (itemsType, rawResponseData, state) => ({
    type: UPDATE_GALLERY_ITEMS,
    payload: {
        items: processGalleryItems(rawResponseData, state),
        count: rawResponseData.count,
        itemsType,
    },
})

export const setErrorFetchingGalleryItems = (itemsType, errorData) => ({
    type: SET_ERROR_FETCHING_GALLERY_ITEMS,
    payload: {
        itemsType,
        errorData,
    },
})

export const incrementFetchingOffset = (itemsType) => ({
    type: INCREMENT_FETCHING_OFFSET,
    payload: { itemsType },
})

export const resetGalleryItems = () => ({
    type: RESET_ITEMS,
})

export const resetGalleryFilter = () => ({
    type: RESET_FILTER,
})

const prepareQueryParamsForGallery = (data, itemsType, state) => {
    let query //= { ...query }

    switch (itemsType) {
        case GALLERY_ITEMS_TYPES.RECOMMENDED: {
            query = {
                limit: GALLERY_ITEMS_PER_PAGE[itemsType],
                offset: state.gallery[itemsType].offset,
                ordering: '-rating',
                recommended_only: 1,
                
                count: data.count, //store.rawsData.recommended.results.length,
                results: data.results.slice(state.gallery[itemsType].offset, GALLERY_ITEMS_PER_PAGE[itemsType]+state.gallery[itemsType].offset), //store.rawsData.recommended.results.slice(state.gallery[itemsType].offset, GALLERY_ITEMS_PER_PAGE[itemsType]+state.gallery[itemsType].offset),
            }
            if (state.gallery.overallFilters.gameVersion !== 'all') {
                query.game_version_id = state.gallery.overallFilters.gameVersion
            }
            break
        }
        case GALLERY_ITEMS_TYPES.NEW: {
            query = {
                limit: GALLERY_ITEMS_PER_PAGE[itemsType],
                offset: state.gallery[itemsType].offset,
                ordering: '-first_time_published_at',

                count: data.count, //store.rawsData.new.results.length,
                results: data.results.slice(state.gallery[itemsType].offset, GALLERY_ITEMS_PER_PAGE[itemsType]+state.gallery[itemsType].offset), // store.rawsData.new.results.slice(state.gallery[itemsType].offset, GALLERY_ITEMS_PER_PAGE[itemsType]+state.gallery[itemsType].offset),
            }
            break
        }
        default: { break }
    }
    
    if (state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language) {
        query = { ...query, language: state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language}
    }
    query = { ...query, realm: state.currentAccount.realm}
    return query
}

export const fetchAllGalleryItemsInitially = () => {
    return (dispatch, getState) => {
        const state = getState()

        let actions = map(GALLERY_ITEMS_TYPES, (itemsType) => startFetchingGalleryItems(itemsType))
        dispatch(batchActions(actions, START_FETCHING_GALLERY_ITEMS))

        const query = {
            language: state.persistedValues.overallLanguageFilter || state.gallery.overallFilters.language,
            [`limit_${GALLERY_ITEMS_TYPES.RECOMMENDED}`]: GALLERY_ITEMS_PER_PAGE[GALLERY_ITEMS_TYPES.RECOMMENDED],
            [`limit_${GALLERY_ITEMS_TYPES.NEW}`]: GALLERY_ITEMS_PER_PAGE[GALLERY_ITEMS_TYPES.NEW],
        }
        if (state.gallery.overallFilters.gameVersion !== 'all') {
            query.game_version_id = state.gallery.overallFilters.gameVersion
        }

        const url = 'https://tankimods.net'+urls.modsStartPage;
        const fetchPromise = fetch(url, { query }).promise 
            .then((rawResponseData) => {
                let responseData = {
                    recommended: prepareQueryParamsForGallery(rawResponseData['recommended'], 'recommended', state),
                    new: prepareQueryParamsForGallery(rawResponseData['new'], 'new', state)
                }
                actions = map(GALLERY_ITEMS_TYPES, (itemsType) =>
                    updateGalleryItems(itemsType, responseData[itemsType], state))
                actions = union(actions, map(GALLERY_ITEMS_TYPES, (itemsType) =>
                    stopFetchingGalleryItems(itemsType),
                ))
                dispatch(batchActions(actions, UPDATE_GALLERY_ITEMS))
            })
            .catch((errorData) => {
                actions = map(GALLERY_ITEMS_TYPES, (itemsType) => setErrorFetchingGalleryItems(itemsType, errorData))
                actions = union(actions, map(GALLERY_ITEMS_TYPES, (itemsType) => stopFetchingGalleryItems(itemsType)))
                dispatch(batchActions(actions, SET_ERROR_FETCHING_GALLERY_ITEMS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const fetchGalleryItemsByType = (itemsType, withSpinner = true) => {
    // Fetches items for landing section specified by `itemsType`. Invoked from Gallery component by `Load More` click
    // or by some actions.
    return (dispatch, getState) => {
        const state = getState()
        if (state.gallery[itemsType].isFetchedAll) {
            return
        }

        dispatch(startFetchingGalleryItems(itemsType))

        const url = 'https://tankimods.net'+urls.modsStartPage
        const fetchPromise = fetch(url).promise
            .then((rawResponseData) => {
                let responseData = prepareQueryParamsForGallery(rawResponseData[itemsType], itemsType, state) 
                dispatch(batchActions([
                    updateGalleryItems(itemsType, responseData, state), 
                    stopFetchingGalleryItems(itemsType),
                ], UPDATE_GALLERY_ITEMS))
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorFetchingGalleryItems(itemsType, errorData),
                    stopFetchingGalleryItems(itemsType),
                ], SET_ERROR_FETCHING_GALLERY_ITEMS))
            })
        return withSpinner ? fetchWithSpinner(dispatch, fetchPromise) : fetchPromise
    }
}



/*
    Fetching data displayed at the special pages with "filtering", e.g. search results, author's mods.
*/
export const CHANGE_GALLERY_FILTERED_ITEMS_ORDERING = 'CHANGE_GALLERY_FILTERED_ITEMS_ORDERING'
export const INCREMENT_FILTERING_OFFSET = 'INCREMENT_FILTERING_OFFSET'
export const SET_ERROR_FILTERING_GALLERY_ITEMS = 'SET_ERROR_FILTERING_GALLERY_ITEMS'
export const START_FILTERING_GALLERY_ITEMS = 'START_FILTERING_GALLERY_ITEMS'
export const STOP_FILTERING_GALLERY_ITEMS = 'STOP_FILTERING_GALLERY_ITEMS'
export const UPDATE_GALLERY_FILTERED_ITEMS = 'UPDATE_GALLERY_FILTERED_ITEMS'

export const startFilteringGalleryItems = () => ({
    type: START_FILTERING_GALLERY_ITEMS,
})

export const stopFilteringGalleryItems = () => ({
    type: STOP_FILTERING_GALLERY_ITEMS,
})

export const updateGalleryFilteredItems = (filterParams, rawResponseData, state) => ({
    type: UPDATE_GALLERY_FILTERED_ITEMS,
    payload: {
        items: processGalleryItems(rawResponseData, state),
        count: rawResponseData.count,
        filterParams,
    },
})

export const setErrorFilteringGalleryItems = (errorData) => ({
    type: SET_ERROR_FILTERING_GALLERY_ITEMS,
    payload: {
        errorData,
    },
})

export const incrementFilteringOffset = () => ({
    type: INCREMENT_FILTERING_OFFSET,
})

export const changeGalleryFilteredItemsOrdering = (ordering) => ({
    type: CHANGE_GALLERY_FILTERED_ITEMS_ORDERING,
    payload: {
        ordering,
    },
})

const prepareQueryParamsForFiltering = (filterParams, state) => {
    const { type, argument } = filterParams
    let query = {
        language: state.gallery.overallFilters.language,
    }

    if (type === GALLERY_ITEMS_FILTER_TYPES.TAGS) {
        let massive = store.rawsData.results

        query = {
            ...query,
            limit: GALLERY_ITEMS_FILTERED_PER_PAGE,
            offset: state.gallery.filtered.offset,
            ordering: filterParams.ordering,
            tag_ids: argument.join(','),

            count: massive.length, // store.rawsData.count
            results: massive.slice(state.gallery.filtered.offset, GALLERY_ITEMS_FILTERED_PER_PAGE+state.gallery.filtered.offset),
        }
    }

    if (state.gallery.overallFilters.gameVersion !== 'all') {
        query.game_version_id = state.gallery.overallFilters.gameVersion
    }

    return query
}

export const filterGalleryItems = (filterParams) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(startFilteringGalleryItems())

        const query = prepareQueryParamsForFiltering(filterParams, state)
        console.log(query)
        /*const fetchPromise = fetch(urls.mods, { query }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    updateGalleryFilteredItems(filterParams, rawResponseData, state),
                    stopFilteringGalleryItems(),
                ], UPDATE_GALLERY_FILTERED_ITEMS))
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFilteringGalleryItems(errorData),
                    stopFilteringGalleryItems(),
                ], SET_ERROR_FILTERING_GALLERY_ITEMS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)*/
    }
}



/*
    Overall filters applied for both fetched and filtered items
*/
export const CLEAR_ALREADY_RECEIVED_ITEMS = 'CLEAR_ALREADY_RECEIVED_ITEMS'
export const SET_OVERALL_GAME_VERSION_FILTER = 'SET_OVERALL_GAME_VERSION_FILTER'
export const SET_OVERALL_LANGUAGE_FILTER = 'SET_OVERALL_LANGUAGE_FILTER'

export const setOverallLanguageFilter = (language) => ({
    type: SET_OVERALL_LANGUAGE_FILTER,
    payload: {
        language,
    },
})

export const setOverallGameVersionFilter = (gameVersionId) => ({
    type: SET_OVERALL_GAME_VERSION_FILTER,
    payload: {
        gameVersionId,
    },
})

export const clearAlreadyReceivedItems = () => ({
    type: CLEAR_ALREADY_RECEIVED_ITEMS,
})


export const changeOverallLanguageFilter = (language) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setOverallLanguageFilter(language),
            clearAlreadyReceivedItems(),
        ], SET_OVERALL_LANGUAGE_FILTER))

        if (state.gallery.filtered.filterParams.type) {
            dispatch(filterGalleryItems(state.gallery.filtered.filterParams))
        } else {
            dispatch(fetchAllGalleryItemsInitially()) // fetchGalleryStartPage
        }
    }
}

export const changeOverallGameVersionFilter = (gameVersionId) => {
    return (dispatch, getState) => {
        const state = getState()

        dispatch(batchActions([
            setOverallGameVersionFilter(gameVersionId),
            clearAlreadyReceivedItems(),
        ], SET_OVERALL_GAME_VERSION_FILTER))

        if (state.gallery.filtered.filterParams.type) {
            dispatch(filterGalleryItems(state.gallery.filtered.filterParams))
        } else {
            dispatch(fetchAllGalleryItemsInitially()) // fetchGalleryStartPage
        }
    }
}