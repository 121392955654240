import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import settings from '../../settings';

import ButtonBlueSmall from '../Buttons/ButtonBlueSmall/ButtonBlueSmall';
import Notification from '../Notification/Notification';

import { GO_TO_LANDING } from '../../translations';

import { pushHistoryWithLanding, urls } from '../../utils/routing';

import './NotificationBlockedUser.css';


export const NOTIFICATION_BLOCKED_USER_CONTENT = `Вы не можете загружать и редактировать моды, так как ваш аккаунт не соответствует следующим требованиям:`;
export const NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_BATTLES_COUNT = (battlesCount) => { return <span dangerouslySetInnerHTML={{__html: `Количество боёв на аккаунте в Мир танков: <strong>не менее ${battlesCount}</strong>.`}}></span> }
export const NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_DAYS = (days) => { return <span dangerouslySetInnerHTML={{__html: `Количество дней с момента регистрации аккаунта: <strong>не менее ${days}</strong>.`}}></span> }

export default class NotificationBlockedUser extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history, // isRequired
    }

    handleClick = () => {
        if (this.props.history !== null) {
            pushHistoryWithLanding(this.props.history)
        } else {
            window.location.href = urls.landing;
        }
    }

    render() {
        const days = settings.uploadRestrictions.days
        const battlesCount = settings.uploadRestrictions.battlesCount

        return (
            <div className="NotificationBlockedUser_base">
                <Notification type="warning">
                    <div className="NotificationBlockedUser_inner">
                        <div className="NotificationBlockedUser_icon" />
                        <div className="NotificationBlockedUser_content">
                            <p>{NOTIFICATION_BLOCKED_USER_CONTENT}</p>
                            <ol>
                                <li>{NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_DAYS(days)}</li>
                                <li>{NOTIFICATION_BLOCKED_USER_CONTENT_REQUIREMENT_BATTLES_COUNT(battlesCount)}</li>
                            </ol>
                        </div>
                        <div className="NotificationBlockedUser_button">
                            <ButtonBlueSmall onClick={this.handleClick}>
                                {GO_TO_LANDING}
                            </ButtonBlueSmall>
                        </div>
                    </div>
                </Notification>
            </div>
        )
    }
}
