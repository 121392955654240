import { isEmpty } from 'lodash';
import request from 'superagent';

import { startFetching, stopFetching } from '../actions/Processing';
import settings from "../settings";

export const fetchWrapper = (url, options) => {
    const params = {...options}
    const headers = {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': settings.csrfToken,
        ...params.headers,
    }

    const method = params.method || 'GET'
    let req = request(method, url)
        .set(headers)
        //.withCredentials() //-- нужно для того чтобы нельзя было делать запросы с других юрл, только с моего одного
    if (!isEmpty(params.query)) {
        req = req.query(params.query)
    }
    if (!isEmpty(params.body)) {
        req = req.send(params.body)
    }
    return {
        req,
        promise: req
            .then(
                (response) => response.body || response.text,
                (response) => {
                    if ([401, 403, 409].includes(response.status)) {
                        const throwText = {
                            status: response.status,
                            ...response.response.body,
                        }
                        throw throwText
                    }
                    throw response
                }
            ),
    }
}

export const fetchWithSpinner = (dispatch, promise) => {
    dispatch(startFetching())
    promise
        .then(() => dispatch(stopFetching()))
        .catch((error) => {
            /* eslint-disable no-console */
            console.error(error)
            dispatch(stopFetching())
        })
    return promise
}