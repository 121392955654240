import { connect } from 'react-redux';

//import { login } from '../../actions/CurrentAccount';
import { closeMenu, fetchNotifications} from '../../actions/UserMenu';

import UserMenu from '../../components/UserMenu/UserMenu';

const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    count: state.currentAccount.count,
    notifications: state.currentAccount.notifications,
    isFetching: state.currentAccount.isFetching,

    isOpened: state.userMenu.isOpened,
})

const mapDispatchToProps = (dispatch) => ({
    /*onLoginClick() {
        //dispatch(login())
    },*/
    onCloseMenu() {
        dispatch(closeMenu())
    },
    fetchNotifications(userData) {
        dispatch(fetchNotifications(userData))
    },
    onChangeNotifyActive(userData) {
        //dispatch(onChangeNotifyActive(userData))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
