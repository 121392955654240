import { isNil } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

export const registerFormattingLocales = () => {
    numeral.register('locale', 'ru', {
        delimiters: {
            thousands: ' ',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'ТЫС',
            million: 'МЛН',
        },
        byteSuffixes: ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'],
    })

    numeral.register('format', 'bytes_l10n', {
        regexps: {
            format: /([0\s]i?c)/,
        },

        // This code was originally borrowed from https://github.com/adamwdraper/Numeral-js/blob/master/src/formats/bytes.js
        // But it was slightly modified according to our project requirements
        format: (value, format, roundingFunction) => {
            const locale = numeral.locales[numeral.options.currentLocale]
            const byteSuffixes = locale.byteSuffixes || ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
            const base = 1000

            let output
            let suffix = ' '
            let power
            let min
            let max

            format = format.replace(/\s?i?c/, '')

            for (power = 0; power <= byteSuffixes.length; power++) {
                min = Math.pow(base, power)
                max = Math.pow(base, power + 1)

                if ((value === null || value === 0) || (value >= min && value < max)) {
                    suffix += byteSuffixes[power]

                    if (min > 0) {
                        value = value / min
                    }

                    break
                }
            }

            output = numeral._.numberToFormat(value, format, roundingFunction)

            return output + suffix
        },
    })

    //moment.defineLocale('ru-modp', {
    moment.defineLocale('ru', {
        parentLocale: 'ru',
        monthsShort: {
            format: ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'],
            standalone: ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'],
        },
    })

    moment.updateLocale('ru', {
        monthsShort : [
            "ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"
        ]
    })
}

export const activateFormattingLocale = (language) => {
    const momentLanguageToLocales = {
        en: 'en',
        ru: 'ru', // ru: 'ru-modp',
    }
    moment.locale(momentLanguageToLocales[language])

    numeral.locale(language)
}

export const formatCounter = (value) => {
    if (value < 100000) {
        return value.toString()
    } else {
        if (value > 999949 && value < 1000000) {
            value *= 1000
        }
        return numeral(value).format('0.[0] a').toUpperCase()
    }
}

export const formatDate = (value, format = 'MMM, DD') => {
    return isNil(value) ? '\u2014' : moment.utc(value).local().format(format)
}

export const formatDateTime = (value, format = 'YYYY.MM.DD HH:mm') => {
    return isNil(value) ? '\u2014' : moment.utc(value).local().format(format)
}

export const formatTime = (value, format = 'HH:mm') => {
    return isNil(value) ? '\u2014' : moment.utc(value).local().format(format)
}

export const checkDate = (timeString) => {
    var dateNow = new Date();
    var thisDate = new Date(timeString);
    let dateView = '';
    function isDifferentDates(Now, This) {
        if ( Now.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) === This.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) ) {
            return 1;
        } else if ( (Now.toLocaleString('default',{ day: 'numeric' }) - This.toLocaleString('default',{ day: 'numeric' })) === 1 ) {
            return 2;
        } else {
            return 0;
        } 
    }
    isDifferentDates(dateNow, thisDate) === 1 ? dateView = 'Сегодня' : 
        isDifferentDates(dateNow, thisDate) === 2 ? dateView = 'Вчера' : dateView = thisDate.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' })
    return dateView;
}

export const formatFileSize = (value) => {
    return isNil(value) ? '\u2014' : numeral(value).format('0.[0] c')
}

export const formatWithSeparatedThousands = (value) => {
    return numeral(value).format('0,0').replace(',', ' ')
}

export const sliceStringToSymbol = (str, symb) => {
    if (!str) {
        return 'undefined'
    }
    const str1 = str.slice(0, symb)
    const str2 = str.slice(-symb)
    
    const result = str1+'...'+str2;
    
    return result;
}


// for my custom AudioPlayer
const addHeadingZero = (num) => {
    return num > 9 ? num.toString() : `0${num}`
}

export const getDisplayTimeBySeconds = (seconds, totalSeconds, timeFormat) => {
    if (!isFinite(seconds)) {
        return null
    }
  
    const min = Math.floor(seconds / 60)
    const minStr = addHeadingZero(min)
    const secStr = addHeadingZero(Math.floor(seconds % 60))
    const minStrForHour = addHeadingZero(Math.floor(min % 60))
    const hourStr = Math.floor(min / 60)
  
    const mmSs = `${minStr}:${secStr}`
    const hhMmSs = `${hourStr}:${minStrForHour}:${secStr}`
  
    if (timeFormat === 'auto') {
        if (totalSeconds >= 3600) {
            return hhMmSs
        } else {
            return mmSs
        }
    } else if (timeFormat === 'mm:ss') {
        return mmSs
    } else if (timeFormat === 'hh:mm:ss') {
        return hhMmSs
    }
}