import React, { Component } from 'react';
//import PropTypes from 'prop-types';

import styles from './InfoBlock.module.css'

export default class InfoBlock extends Component {
  
  renderItems() {
    return
  }

  render() {
    return (
      <div className={styles.section}>
        <div className={styles.header}>
          <p>Центр поддержки пользователей</p>
          <p className={styles.time}>123</p>
        </div>
        <div className={styles.section_line}>
          <div className={styles.mods_status}>
            <div className={styles.mods_status__item} onClick={() => {}}>
              <span className={styles.status_item__number}>ВСЕ</span>
              <span className={styles.status_item__type}>тикеты</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => {}}>
              <span className={styles.status_item__number}>1</span>
              <span className={styles.status_item__type}>На рассмотрении</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => {}}>
              <span className={styles.status_item__number}>2</span>
              <span className={styles.status_item__type}>Рассмотрено</span>
            </div>
          </div>
          <div className={styles.mods_actions}></div>
        </div>

        {this.renderItems()}

      </div>
    )
  }
}
