export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const openDialog = (content, isNavigatingToLandingOnClose = false) => ({
    content: content,
    type: OPEN_DIALOG,
    isNavigatingToLandingOnClose,
})

export const closeDialog = () => ({
    type: CLOSE_DIALOG,
})
