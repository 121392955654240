import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './LinesClamp.module.css';

export default class LinesClamp extends React.PureComponent {
    static propTypes = {
        text: PropTypes.node.isRequired,
        className: PropTypes.string,
        lines: PropTypes.number
    }

    render() {
        const classNameBase = classNames(style.base, {
            [style[`lines__${this.props.lines}`]]: this.props.lines,
            [this.props.className]: this.props.className
        })

        return (
            <div className={classNameBase}>{this.props.text}</div>
        )
    }
}
