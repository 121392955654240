
export const DIALOG_CONFIRMATION_MOD_TITLE = "Вы действительно хотите удалить мод?"

export const DIALOG_CONFIRMATION_DRAFT_TITLE = "Вы действительно хотите удалить черновик?"

export const DIALOG_CONFIRMATION_MOD_CONTENT = "Обратите внимание, данное действие необратимо и восстановить мод вы не сможете."

export const DIALOG_CONFIRMATION_DRAFT_CONTENT = "Обратите внимание, данное действие необратимо и восстановить черновик вы не сможете."

export const DIALOG_CONFIRMATION_MOD_OK_BUTTON = "Удалить"

export const DIALOG_CONFIRMATION_MOD_CANCEL_BUTTON = "Отмена"
