import { connect } from 'react-redux';
import { orderBy } from 'lodash';

import { closeDialog } from '../../actions/Dialog';
import { incrementDownloadCount } from '../../actions/Details';

import DialogDownload from '../../components/Dialogs/DialogDownload/DialogDownload';
import settings from '../../settings';


const mapStateToProps = (state, ownProps) => {
    return {
        lastGameVersionId: settings.gameVersions[0].id,
        modId: ownProps.modId,
        title: ownProps.title,
        versions: orderBy(ownProps.versions, ['id'], ['desc']),
    }
}

const mapDispatchToProps = (dispatch) => ({
    onSubmitDialog: (modId) => {
        dispatch(incrementDownloadCount(modId))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogDownload)
