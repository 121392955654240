import { connect } from 'react-redux';

//import { logout } from '../../actions/CurrentAccount';

import Logout from '../../components/Logout/Logout';


const mapStateToProps = (state) => ({
    spaId: state.currentAccount.spaId,
    spaUsername: state.currentAccount.spaUsername,
    isFetching: state.currentAccount.isFetching,
})

const mapDispatchToProps = (dispatch) => ({
    /*authorization: (rawResponseData) => {
        dispatch(authorization(rawResponseData))
    },*/
    logout: () => {
        //
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
