import React from 'react';
import { isNil } from 'lodash';
import { connect } from 'react-redux';

import {
    incrementDownloadCount,
    processDownloadMod,
    fetchDetails,
    voteForMod,
} from '../../actions/Details'
import { followMod, unsubscribeMod } from '../../actions/Followed';
import { openDialog } from '../../actions/Dialog';
import DialogLogin from '../DialogLoginWrapper/DialogLoginWrapper';
import DialogReportMod from '../DialogReportModWrapper/DialogReportModWrapper';

import ModPage from '../../components/ModPage/ModPage';

const mapStateToProps = (state) => ({
    data: state.details.data,
    realm: state.currentAccount.realm,
    isAuthenticated: !isNil(state.currentAccount.spaId),
    isError: state.details.isError,
    isErrorNotFound: state.details.isErrorNotFound,
    isFetching: state.details.isFetching,
    isFetched: state.details.isFetched,
    isModDownloadAccepted: state.persistedValues.isModDownloadAccepted,
    language: state.currentAccount.lang,
})

const mapDispatchToProps = (dispatch) => ({
    onDownloadClick: (isModDownloadAccepted, isModDownloaded, modId, title, versions) => {
      if (isModDownloaded) {
        dispatch(incrementDownloadCount(modId))
      } else {
        dispatch(processDownloadMod(isModDownloadAccepted, modId, title, versions))
      }
    },
    onLoginLinkClick: () => {
      dispatch(openDialog(<DialogLogin />))
    },
    onReportButtonClick: (modId) => {
      dispatch(openDialog(<DialogReportMod modId={modId} />))
    },
    onFollowMod: (modId, userName) => {
      dispatch(followMod(modId, userName))
    },
    onUnsubscribeMod: (modId, userName) => {
      dispatch(unsubscribeMod(modId, userName))
    },
    fetchDetails: (modId, userName) => {
      dispatch(fetchDetails(modId, userName))
    },
    voteForMod: (modId, value, userName) => {
      dispatch(voteForMod(modId, value, userName))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(ModPage)

/*import React from 'react'
import ModPage from '../../components/ModPage/ModPage'
import { useParams } from 'react-router-dom'

export default function ModPageWrapper(props) {
  let { modId } = useParams()

  return (
    <ModPage {...props} modId={modId} />
  )
}*/