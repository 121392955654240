import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import { formatDateTime } from '../../utils/formatting';

import Comment from './Comment/Comment';
import ButtonComment from '../Buttons/ButtonComment/ButtonComment';

import {
    BUTTON_CAPTION_ANSWER,
    BUTTON_CAPTION_REMOVE,
    BUTTON_CAPTION_REPORT
} from './translations';

import styles from './CommentsWidget.module.css';

export default class CommentsWidget extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        lang: PropTypes.string.isRequired,
        modId: PropTypes.number.isRequired,
        readonly: PropTypes.bool.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isCommentsWidgetExpanded: false,
        }
    }

    handleClickAnswer() {
        //
    }
    handleClickRemove() {
        //
    }
    handleClickReport() {
        //
    }

    renderBtnAnswer() {
        return (
            <span className={styles[`item__btn`]}>
                <ButtonComment onClick={this.handleClickAnswer}>{BUTTON_CAPTION_ANSWER}</ButtonComment>
            </span>
        )
    }
    renderBtnRemove() {
        return (
            <span className={styles[`item__btn`]}>
                <ButtonComment onClick={this.handleClickRemove}>{BUTTON_CAPTION_REMOVE}</ButtonComment>
            </span>
        )
    }
    renderBtnReport() {
        return (
            <span className={styles[`item__btn`]}>
                <ButtonComment onClick={this.handleClickReport}>{BUTTON_CAPTION_REPORT}</ButtonComment>
            </span>
        )
    }

    renderCommentBtn() {
        return (
            <span className={styles[`item__btns`]}>
                {this.renderBtnAnswer()}
                {this.renderBtnRemove()}
                {this.renderBtnReport()}
            </span>
        )
    }

    renderComments() {
        if (this.props.comments.length === 0) {
            return null
        }
        const items = this.state.isCommentsWidgetExpanded ?
            this.props.comments :
            this.props.comments.slice(0, 5)
        //const isButtonEnabled = this.props.comments.length > items.length
        return (
            <React.Fragment>
                <Comment>
                    {items.map((item, idx) => (
                        <React.Fragment key={uniqueId(`comment-item-${idx}`)}>
                            <div className={styles.item} key={uniqueId(`comment-item-${idx}`)}>
                                {/* <h4>{item.author} <span className="CommentWidget_item__createdAt">{formatDateTime(item.createdAt, 'HH:mm DD.MM.YY')}</span></h4> */}
                                <h4>
                                    {item.author}
                                    <span className={styles[`item__createdAt`]}>{formatDateTime(item.createdAt, 'DD.MM.YYYY HH:mm')}</span>
                                </h4>
                                <p className={styles.content}>{item.content}</p>
                                <input type="hidden" name="comment_id" value={idx} />
                                {this.renderCommentBtn()}
                            </div>
                        </React.Fragment>
                    ))}
                </Comment>
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className="CommentsWidget_base">
                {this.renderComments()}
            </div>
        )
    }
}
