import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

//import { getCookie, parseJwt } from '../../../utils/functions';

import TitleDialog from '../../TitleDialog/TitleDialog';
import {
    DIALOG_LOGIN_TITLE,
    DIALOG_LOGIN_CAPTION,
} from './translations';

import styles from './DialogLogin.module.css';

export default class DialogLogin extends React.PureComponent {
    static propTypes = {
        onLoginClick: PropTypes.func.isRequired,
        onCloseDialog: PropTypes.func.isRequired,
    }

    realms = [
        'lesta',
        'wargaming',
        //'ru',
        //'eu',
        //'us',
        //'sg',
    ]

    /*componentDidMount() {
        this.checkAccountData();
    }*/

    /*checkAccountData = () => {
        let datas = null
        // получаем данные из локального хранилища и из куков
        if (getCookie('mtm_jwt') && localStorage.getItem('mtm:user')) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie('mtm_jwt').split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie('mtm_jwt'))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] < Math.floor(new Date().getTime() / 1000)) {
                    // т.к. не действующий токен, устанавливаем дефолтные данные - т.е. все на null
                    console.log('jti is expired')
                } else {
                    datas = JSON.parse(localStorage.getItem('mtm:user'))
                    //console.log('jti is good')
                }
            } //  data set null
        } //  data set null
        
        if (typeof datas !== 'undefined' && datas !== null) {
            return this.props.authorization(datas)
        } else {
            return 
        }
    }*/

    handleLinkClick(realm) {
        if (realm === 'wargaming') {
            return null
        } else {
            this.props.onCloseDialog()
            this.props.onLoginClick(realm)
        }
    }

    renderClassName(realm) {
        if (realm === 'wargaming') {
            return classNames(styles.realm_lock, styles[`realm__${realm}`])
        } else {
            return classNames(styles.realm, styles[`realm__${realm}`])
        }
    }

    renderRealm() {
        return this.realms.map((realm) => (
            <div
                key={realm}
                className={this.renderClassName(realm)}
                onClick={() => this.handleLinkClick(realm)}
            >
                {DIALOG_LOGIN_CAPTION[realm]}
            </div>
        ))
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_LOGIN_TITLE}</TitleDialog>
                <div className={styles.realms}>
                    {this.renderRealm()}
                </div>
            </div>
        )
    }
}
