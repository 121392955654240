import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './NotiFyItem.module.css';

export default class NotiFyItem extends Component {
    static propTypes = {
        id: PropTypes.number,
        author: PropTypes.string,
        modId: PropTypes.number,
        title: PropTypes.string,
        spaId: PropTypes.number,
        isUpdated: PropTypes.bool,
        createdAt: PropTypes.string,
        content: PropTypes.string,
    }

    checkDate(dateTime) {
        var dateNow = new Date();
        var thisDate = new Date(dateTime);
        let dateView = '';
        function isDifferentDates(Now, This) {
            if ( Now.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) === This.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) ) {
                return 1;
            } else if ( (Now.toLocaleString('default',{ day: 'numeric' }) - This.toLocaleString('default',{ day: 'numeric' })) === 1 ) {
                return 2;
            } else {
                return 0;
            } 
        }
        isDifferentDates(dateNow, thisDate) === 1 ? dateView = `Сегодня в ${thisDate.toLocaleString('default',{ hour: 'numeric', minute: 'numeric' })}` : 
            isDifferentDates(dateNow, thisDate) === 2 ? dateView = `Вчера в ${thisDate.toLocaleString('default',{ hour: 'numeric', minute: 'numeric' })}` : dateView = thisDate.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric', hour:'numeric',minute:'numeric' })
        
        return dateView;
    }

    renderContent = () => {
        if (!!this.props.content) {
            return this.props.content
        } else {
            return <React.Fragment>
                Мод «<Link className={styles.cm_text_link} style={{}}>{this.props.title}</Link>» для Мир танков 1.25.0.0 был обновлён до версии 46.
            </React.Fragment>
        }
    }

    renderTime = () => {
        return this.checkDate(this.props.createdAt) //`Вчера в 15:10`
    }
    
    render() {
        return (
            <li key={this.props.id} className={classNames(styles.cm_notifications_item,"js-cm-notification",styles.cm_notifications_item__unread)} data-notification-id={this.props.id} data-unread-class={styles.cm_notifications_item__unread} data-hidden-class={styles.cm_notifications_item__hidden}>
                <div className={classNames(styles.cm_notifications_icon_holder,"js-cm-hint")} data-title="">
                    <div className={classNames(styles.cm_notifications_icon, styles.cm_notifications_icon__wg)}>service</div>
                    <div className={classNames(styles.cm_notifications_type, styles.cm_notifications_type__info)}>type</div>
                </div>
                <div className={styles.cm_notifications_info}>
                    <p></p>
                    <p style={{wordWrap:'break-word'}}>
                        {this.renderContent()}
                    </p>
                    <p></p> 
                    <span className={styles.cm_notifications_time}>{this.renderTime()}</span>
                </div>
                <a href="/" className={classNames(styles.cm_close, "js-cm-hint", "js-cm-notification-delete")} data-title="Скрыть уведомление">✕</a>
            </li>
        )
    }
}

/*
<div className={classNames(styles.cm_scroll, styles.cm_scroll__notifications, 'js-cm-scroll-wrapper')}>
                <ul className={classNames(styles.cm_scroll_container,"js-cm-notifications-list")} style={{overflow: 'hidden', maxHeight: '249px'}}>
                    <li className={classNames(styles.cm_notifications_item,"js-cm-notification")} data-notification-id="17092432600636939" data-unread-class={styles.cm_notifications_item__unread} data-hidden-class={styles.cm_notifications_item__hidden}>
                        <div className={classNames(styles.cm_notifications_icon_holder,"js-cm-hint")} data-title="">
                            <div className={classNames(styles.cm_notifications_icon, styles.cm_notifications_icon__wg)}>service</div>
                            <div className={classNames(styles.cm_notifications_type, styles.cm_notifications_type__info)}>type</div>
                        </div>
                        <div className={styles.cm_notifications_info}>
                            <p></p>
                            <p style={{wordWrap:'break-word'}}>Мод «<a href="https://wgmods.net/21/?utm_source=menu-notification&amp;utm_medium=link&amp;utm_content=common_menu&amp;notification_id=17092432600636939&amp;utm_campaign=modp" className={styles.cm_text_link} style={{}}>МОДПАК ПРОТАНКИ Base - Оптимальная версия</a>» для World of Tanks 1.25.0.0 был обновлён до версии 46.</p>
                            <p></p> 
                            <span className={styles.cm_notifications_time}>Вчера в 15:10</span>
                        </div>
                        <a href="/" className={classNames(styles.cm_close, "js-cm-hint", "js-cm-notification-delete")} data-title="Скрыть уведомление">✕</a>
                    </li>
                    <li className={classNames(styles.cm_notifications_item,"js-cm-notification",styles.cm_notifications_item__unread)} data-notification-id="17091992400043611" data-unread-class={styles.cm_notifications_item__unread} data-hidden-class={styles.cm_notifications_item__hidden}>
                        <div className={classNames(styles.cm_notifications_icon_holder,"js-cm-hint")} data-title="">
                        <div className={classNames(styles.cm_notifications_icon, styles.cm_notifications_icon__wg)}>service</div>
                            <div className={classNames(styles.cm_notifications_type, styles.cm_notifications_type__info)}>type</div>
                        </div>
                        <div className={styles.cm_notifications_info}>
                            <p></p>
                            <p style={{wordWrap:'break-word'}}>Мод «<a href="https://wgmods.net/20/?utm_source=menu-notification&amp;utm_medium=link&amp;utm_content=common_menu&amp;notification_id=17091992400043611&amp;utm_campaign=modp" className={styles.cm_text_link} style={{}}>Модпак Джова</a>» для World of Tanks 1.25.0.0 был обновлён до версии 81.5.</p>
                            <p></p> 
                            <span className={styles.cm_notifications_time}>Вчера в 13:18</span>
                        </div>
                        <a href="/" className={classNames(styles.cm_close, "js-cm-hint", "js-cm-notification-delete")} data-title="Скрыть уведомление">✕</a>
                    </li>
                </ul>
                <div className={classNames(styles.cm_scroll_track, "js-cm-scrollbar-track")}>
                    <div className={styles.cm_scroll_thumb}></div>
                </div>
            </div>
*/
