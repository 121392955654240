import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Textarea.module.css';

export default class Textarea extends React.PureComponent {
    static propTypes = {
        placeholder: PropTypes.string,
        rows: PropTypes.number,
        value: PropTypes.string,
        isDisabled: PropTypes.bool,
        isError: PropTypes.bool,
        isFocusOn: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    }

    static defaultProps = {
        rows: 3,
    }

    componentDidMount() {
        if (this.props.isFocusOn) {
            this._textarea.focus()
        }
    }

    handleChange = (event) => {
        const value = event.target.value
        value !== this.props.value && this.props.onChange(value)
    }

    renderValue = (text) => {
        return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    }

    render() {
        const classNameTextarea = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
            [styles.isError]: this.props.isError,
        })

        return (
            <textarea
                ref={(c) => this._textarea = c}
                className={classNameTextarea}
                //maxLength={}
                rows={this.props.rows}
                placeholder={this.props.placeholder}
                disabled={this.props.isDisabled}
                value={this.props.value ? this.props.value : ''}
                onChange={this.handleChange}
            />
        )
    }
}
