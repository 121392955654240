import { connect } from 'react-redux';

import { fetchNews, incrementFetchingNewsOffset, fetchNewsItemsByType } from '../../actions/News';
import NewsBlock from '../../components/NewsBlock/NewsBlock';

const mapStateToProps = (state) => ({ 
    count: state.news.count,
    items: state.news.items,
    page: state.news.page,
    //offset: state.news.offset,
    isFetching: state.news.isFetching,
    isFetchedAll: state.news.isFetchedAll,
    isError: state.news.isError,
})

const mapDispatchToProps = (dispatch) => ({
    fetchNews: () => {
        dispatch(fetchNews())
    },
    fetchNewsItemsMore: (itemsType) => {
        dispatch(incrementFetchingNewsOffset(itemsType))
        dispatch(fetchNewsItemsByType(false))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsBlock)