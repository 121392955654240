import apiUrls from "../apiUrls";

export const SET_ERROR_LOGIN = 'SET_ERROR_LOGIN'


export const setErrorLogin = (errorData) => ({
    type: SET_ERROR_LOGIN,
    payload: {
        errorData,
    },
})

export const login = (realm) => {
    const url = `https://tankimods.net`;

    return window.location = url+apiUrls.login[realm]

    /*return (dispatch, getState) => {
        const state = getState()

        const url = `https://tankimods.net`;

        dispatch(startLogin())

        //return window.location = url+apiUrls.login[realm] // потом изменить на apiUrls.login[realm]
        const fetchPromise = fetch(url+apiUrls.login[realm]).promise
            .then((rawResponseData) => {
                if(typeof rawResponseData.status !== 'undefined' && rawResponseData.status > 300) {
                    dispatch(batchActions([
                        setErrorLogin(rawResponseData),
                        stopLogin(),
                    ], SET_ERROR_LOGIN))
                } else {
                    dispatch(batchActions([
                        updateSpaData(rawResponseData, state),
                        stopLogin(),
                    ], UPDATE_SPA_DATA))
                }
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorLogin(errorData),
                    stopLogin(),
                ], SET_ERROR_LOGIN))
            })
        return fetchWithSpinner(dispatch, fetchPromise)//
    }*/
}
