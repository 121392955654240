let store = {
    mods: {
        recommended: {
            isError: false,
            isFetchedAll: false,
            isFetching: false,
            items: [
                {
                    id: 20, 
                    isUpdated: false,
                    downloads: 19110786,
                    authorName: "phantasm_ru",
                    cover: "", 
                    createdAt: "2017-05-17T09:36:20.631959Z",
                    updatedAt: "2024-03-06T10:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.4, 
                    modVersion: "80.4", 
                    ownerId: 793572, 
                    ownerName: "phantasm_ru", 
                    tags: [
                        {
                            caption: "Модпаки", 
                            id: 10
                        }
                    ], 
                    title: "Модпак Джова",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 67681,
                            modVersion: "80.4",
                            versionFileOriginalName: "JovesModPack_1.25.0.0_Extended_v80.4.exe", // modVersionFileOriginalName
                            versionFileSize: 486380089 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 22, 
                    isUpdated: false,
                    downloads: 130902,
                    authorName: null,
                    cover: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2017-05-22T09:03:14.648406Z",
                    updatedAt: "2024-04-08T10:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.5, 
                    modVersion: "3.7.7", 
                    ownerId: 301432, 
                    ownerName: "WGmods", 
                    tags: [
                        {
                            caption: "Другое", 
                            id: 16
                        }
                    ], 
                    title: "Менеджер Реплеев",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68258,
                            modVersion: "3.7.7",
                            versionFileOriginalName: "[1.24.1.0] ReplaysManager 3.7.7.zip", // modVersionFileOriginalName
                            versionFileSize: 716423 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 89, 
                    isUpdated: false,
                    downloads: 25769258,
                    authorName: "Команда Протанки",
                    cover: "https://wgmods.net/media/mod_files/pro.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2017-05-30T06:02:36.381852Z",
                    updatedAt: "2024-04-08T09:36:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.1, 
                    modVersion: "32", 
                    ownerId: 793477, 
                    ownerName: "ProTankiWot", 
                    tags: [
                        {
                            caption: "Модпаки", 
                            id: 10
                        }
                    ], 
                    title: "МОДПАК ПРОТАНКИ Extend - Расширенная версия RU и EU",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68262,
                            modVersion: "32",
                            versionFileOriginalName: "modpack_protanki_extend.exe", // modVersionFileOriginalName
                            versionFileSize: 698501506 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1668,
                    isUpdated: false,
                    downloads: 69026,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/6th_sound_logo_6HHPihI.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-16T22:17:00.248174Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.6,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Озвучки",
                            id: 14
                        }
                    ],
                    title: "116 -сигнал шестого чувства от Hawg",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68284,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                            versionFileSize: 25736881
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1669,
                    isUpdated: false,
                    downloads: 18321,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/Amark_logo.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-16T22:22:34.901193Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Шкурки",
                            id: 13
                        }
                    ],
                    title: "Армейские звания вместо отличительных отметок (от Hawg)",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68282,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "ArmyRankMarkOfExcellence_24.1.0.zip",
                            versionFileSize: 145935
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1683,
                    isUpdated: false,
                    downloads: 30922,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/New_Logo_9XIoGHt.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-18T09:18:19.093297Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "25  Индикаторов Попаданий",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68280,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "25_Damage_Indicators_24.1.0.zip",
                            versionFileSize: 4560215
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2030, 
                    isUpdated: false,
                    downloads: 246754,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/Pb_logo_Clasic.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2018-09-01T01:29:25.980436Z",
                    updatedAt: "2024-10-09T20:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.6, 
                    modVersion: "24.1.0", 
                    ownerId: 237525, 
                    ownerName: "_HawgJaws_", 
                    tags: [
                        {
                            caption: "Инструменты", 
                            id: 17
                        }
                    ], 
                    title: "Пэинтбол",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68281,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "PaintBall_24.1.0.zip", // modVersionFileOriginalName
                            versionFileSize: 1857106 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2087,
                    isUpdated: false,
                    downloads: 39552,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/Damage_Panel_new_Logo_jvWbrvW.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-09-04T08:56:41.639774Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "52 Стеклянная панель повреждений",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68253,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "DamagePanel_24.1.0.zip",
                            versionFileSize: 9163174
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2974,
                    isUpdated: false,
                    downloads: 22246,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/302_Tacktical.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2019-02-11T00:15:09.491087Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Озвучки",
                            id: 14
                        }
                    ],
                    title: "50 Десятисекундные тактические сигналы Шестого чувства V2",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68252,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Tatical_6th_Sounds_24.1.0.zip",
                            versionFileSize: 16176553
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 4513,
                    isUpdated: false,
                    downloads: 57889,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/fog_logo_xb6K1d6.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2020-04-24T08:39:10.485686Z",
                    updatedAt: "2024-03-18T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "Удаление тумана и бликов",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68287,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Hawgs_FogRemover_24.1.0.zip",
                            versionFileSize: 356887
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 4963,
                    isUpdated: false,
                    downloads: 1505593,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/Untitled-1.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2020-08-29T14:07:57.397748Z",
                    updatedAt: "2024-03-14T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.2,
                    modVersion: "4",
                    ownerId: 784307,
                    ownerName: "Korben",
                    tags: [
                        {
                            caption: "Модпаки",
                            id: 10
                        }
                    ],
                    title: "Модпак KorbenTeam",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68365,
                            modVersion: "4",
                            versionFileOriginalName: "KorbenTeamModpack_1.25.0.0_v4.exe",
                            versionFileSize: 23378514
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 5131, 
                    isUpdated: false,
                    downloads: 338022,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/logo_Tac_cl5wz8o_cLIkX8N.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2020-11-01T12:57:00.984274Z",
                    updatedAt: "2024-03-30T20:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.7, 
                    modVersion: "24.1.0", 
                    ownerId: 237525, 
                    ownerName: "_HawgJaws_", 
                    tags: [
                        {
                            caption: "Инструменты", 
                            id: 17
                        }
                    ], 
                    title: "10 Тактическая миникарта  (позиции для САУ, ПТ, пассивной разведки) ++",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68249,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Tactical_Maps_24.1.0.zip", // modVersionFileOriginalName
                            versionFileSize: 66521838 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 5912,
                    isUpdated: false,
                    downloads: 127561,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/25_bPfRgjH.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    gameVersion: "1.25.0.0",
                    createdAt: "2022-02-06T21:54:29.046907Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    mark: 4.7,
                    modVersion: "1.9.9",
                    ownerId: 786771,
                    ownerName: "POLIROID_6825084",
                    tags: [
                        {
                            caption: "Другое",
                            id: 16
                        }
                    ],
                    title: "Просмотр попаданий",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68257,
                            modVersion: "1.9.9",
                            versionFileOriginalName: "[1.24.1.0] BattleHits 1.9.9.zip",
                            versionFileSize: 13853908
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                }
            ]
        },
        new: {
            isError: false,
            isFetchedAll: false,
            isFetching: false,
            items: [
                {
                    id: 20, 
                    isUpdated: false,
                    downloads: 19110786,
                    authorName: "phantasm_ru",
                    cover: "", 
                    createdAt: "2017-05-17T09:36:20.631959Z",
                    updatedAt: "2024-03-06T10:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.4, 
                    modVersion: "80.4", 
                    ownerId: 793572, 
                    ownerName: "phantasm_ru", 
                    tags: [
                        {
                            caption: "Модпаки", 
                            id: 10
                        }
                    ], 
                    title: "Модпак Джова",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 67681,
                            modVersion: "80.4",
                            versionFileOriginalName: "JovesModPack_1.25.0.0_Extended_v80.4.exe", // modVersionFileOriginalName
                            versionFileSize: 486380089 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 22, 
                    isUpdated: false,
                    downloads: 130902,
                    authorName: null,
                    cover: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2017-05-22T09:03:14.648406Z",
                    updatedAt: "2024-04-08T10:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.5, 
                    modVersion: "3.7.7", 
                    ownerId: 301432, 
                    ownerName: "WGmods", 
                    tags: [
                        {
                            caption: "Другое", 
                            id: 16
                        }
                    ], 
                    title: "Менеджер Реплеев",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68258,
                            modVersion: "3.7.7",
                            versionFileOriginalName: "[1.24.1.0] ReplaysManager 3.7.7.zip", // modVersionFileOriginalName
                            versionFileSize: 716423 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 89, 
                    isUpdated: false,
                    downloads: 25769258,
                    authorName: "Команда Протанки",
                    cover: "https://wgmods.net/media/mod_files/pro.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2017-05-30T06:02:36.381852Z",
                    updatedAt: "2024-04-08T09:36:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.1, 
                    modVersion: "32", 
                    ownerId: 793477, 
                    ownerName: "ProTankiWot", 
                    tags: [
                        {
                            caption: "Модпаки", 
                            id: 10
                        }
                    ], 
                    title: "МОДПАК ПРОТАНКИ Extend - Расширенная версия RU и EU",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68262,
                            modVersion: "32",
                            versionFileOriginalName: "modpack_protanki_extend.exe", // modVersionFileOriginalName
                            versionFileSize: 698501506 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1668,
                    isUpdated: false,
                    downloads: 69026,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/6th_sound_logo_6HHPihI.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-16T22:17:00.248174Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.6,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Озвучки",
                            id: 14
                        }
                    ],
                    title: "116 -сигнал шестого чувства от Hawg",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68284,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                            versionFileSize: 25736881
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1669,
                    isUpdated: false,
                    downloads: 18321,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/Amark_logo.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-16T22:22:34.901193Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Шкурки",
                            id: 13
                        }
                    ],
                    title: "Армейские звания вместо отличительных отметок (от Hawg)",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68282,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "ArmyRankMarkOfExcellence_24.1.0.zip",
                            versionFileSize: 145935
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 1683,
                    isUpdated: false,
                    downloads: 30922,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/New_Logo_9XIoGHt.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-07-18T09:18:19.093297Z",
                    updatedAt: "2024-04-09T09:36:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "25  Индикаторов Попаданий",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68280,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "25_Damage_Indicators_24.1.0.zip",
                            versionFileSize: 4560215
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2030, 
                    isUpdated: false,
                    downloads: 246754,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/Pb_logo_Clasic.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2018-09-01T01:29:25.980436Z",
                    updatedAt: "2024-10-09T20:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.6, 
                    modVersion: "24.1.0", 
                    ownerId: 237525, 
                    ownerName: "_HawgJaws_", 
                    tags: [
                        {
                            caption: "Инструменты", 
                            id: 17
                        }
                    ], 
                    title: "Пэинтбол",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68281,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "PaintBall_24.1.0.zip", // modVersionFileOriginalName
                            versionFileSize: 1857106 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2087,
                    isUpdated: false,
                    downloads: 39552,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/Damage_Panel_new_Logo_jvWbrvW.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2018-09-04T08:56:41.639774Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "52 Стеклянная панель повреждений",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68253,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "DamagePanel_24.1.0.zip",
                            versionFileSize: 9163174
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 2974,
                    isUpdated: false,
                    downloads: 22246,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/302_Tacktical.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2019-02-11T00:15:09.491087Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Озвучки",
                            id: 14
                        }
                    ],
                    title: "50 Десятисекундные тактические сигналы Шестого чувства V2",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68252,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Tatical_6th_Sounds_24.1.0.zip",
                            versionFileSize: 16176553
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 4513,
                    isUpdated: false,
                    downloads: 57889,
                    authorName: null,
                    cover: "//wgmods.net/media/mod_files/fog_logo_xb6K1d6.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2020-04-24T08:39:10.485686Z",
                    updatedAt: "2024-03-18T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.8,
                    modVersion: "24.1.0",
                    ownerId: 237525,
                    ownerName: "_HawgJaws_",
                    tags: [
                        {
                            caption: "Интерфейс",
                            id: 15
                        }
                    ],
                    title: "Удаление тумана и бликов",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68287,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Hawgs_FogRemover_24.1.0.zip",
                            versionFileSize: 356887
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 4963,
                    isUpdated: false,
                    downloads: 1505593,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/Untitled-1.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    createdAt: "2020-08-29T14:07:57.397748Z",
                    updatedAt: "2024-03-14T20:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.2,
                    modVersion: "4",
                    ownerId: 784307,
                    ownerName: "Korben",
                    tags: [
                        {
                            caption: "Модпаки",
                            id: 10
                        }
                    ],
                    title: "Модпак KorbenTeam",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68365,
                            modVersion: "4",
                            versionFileOriginalName: "KorbenTeamModpack_1.25.0.0_v4.exe",
                            versionFileSize: 23378514
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 5131, 
                    isUpdated: false,
                    downloads: 338022,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/logo_Tac_cl5wz8o_cLIkX8N.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2020-11-01T12:57:00.984274Z",
                    updatedAt: "2024-03-30T20:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.7, 
                    modVersion: "24.1.0", 
                    ownerId: 237525, 
                    ownerName: "_HawgJaws_", 
                    tags: [
                        {
                            caption: "Инструменты", 
                            id: 17
                        }
                    ], 
                    title: "10 Тактическая миникарта  (позиции для САУ, ПТ, пассивной разведки) ++",

                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68249,
                            modVersion: "24.1.0",
                            versionFileOriginalName: "Tactical_Maps_24.1.0.zip", // modVersionFileOriginalName
                            versionFileSize: 66521838 // modSize
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                },
                {
                    id: 5912,
                    isUpdated: false,
                    downloads: 127561,
                    authorName: null,
                    cover: "https://wgmods.net/media/mod_files/25_bPfRgjH.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    gameVersion: "1.25.0.0",
                    createdAt: "2022-02-06T21:54:29.046907Z",
                    updatedAt: "2024-04-08T20:16:22.604058Z",
                    mark: 4.7,
                    modVersion: "1.9.9",
                    ownerId: 786771,
                    ownerName: "POLIROID_6825084",
                    tags: [
                        {
                            caption: "Другое",
                            id: 16
                        }
                    ],
                    title: "Просмотр попаданий",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68257,
                            modVersion: "1.9.9",
                            versionFileOriginalName: "[1.24.1.0] BattleHits 1.9.9.zip",
                            versionFileSize: 13853908
                        }
                    ],
    
                    isModDownloadAccepted: false,
                    isSingleVersionAvailable: false
                }
            ]
        },
        tags: [
            {id: 10, caption: "Модпаки"},
            {id: 11, caption: "Ангары"},
            {id: 12, caption: "Прицелы"},
            {id: 13, caption: "Шкурки"},
            {id: 14, caption: "Озвучки"},
            {id: 15, caption: "Интерфейс"},
            {id: 16, caption: "Другое"},
            {id: 17, caption: "Инструменты"}
        ],
        isModDownloadAccepted: true,
        fetchAllItems: ()=>{},
        fetchGalleryItemsMore: ()=>{},
        filterGalleryMore: ()=>{},
        onDownloadClick: ()=>{},
    },
    followed: {
        isError: false,
        isFetching: false,
        isLoggedIn: true,
        items: [
            {
                id: 1,
                isUpdated: true,
                mod: {
                    cover: "https://wgmods.net/media/mod_files/Screenshot_3_50SRZfF.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                    id: 6648,
                    mark: 3,
                    modVersion: "3",
                    title: "JustSherlock ModPack Lite - оптимальная версия модпака",
                    versions: [
                        {
                            createdAt: "2024-03-30T11:15:22.604058Z",
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                            id: 67783,
                            modVersion: "3",
                            versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                            versionFileSize: 177765021
                        },
                        {
                            createdAt: "2024-03-06T11:15:22.604058Z",
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                            id: 67783,
                            modVersion: "2",
                            versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                            versionFileSize: 177765021
                        }
                    ]
                }
            }
        ],
        onIncrementDownloadCount: ()=>{},
        onIgnoreUpdate: ()=>{},
        onShowLoginDialog: ()=>{},
        fetchItems: ()=>{},
        onUnsubscribe: ()=>{}
    },
    news: {
        count: 0,
        filterParams: {
            page: 1,
            ordering: "-news", 
            type: "news"
        },
        isError: false,
        isFetchedAll: true,
        isFetching: false,
        items: [
            {
                id: 57,
                title: "Обновление «Совершенно секретно",
                description: "6 МАРТА: ОБНОВЛЕНИЕ 1.25. Заработай награды в новом сезоне Боевого пропуска, врывайся в бой в режиме Натиск: Грозовой волк, играй в Линию фронта на технике IX уровня и побывай на Красной планете во время специальной миссии!",
                created_at: "2024-03-05",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/update-125_preview_615h346_lg_spb_mt-1.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-25-rich/",
                change_log: "",
                category: 2
            },
            {
                id: 56,
                title: "Изменения на «Игровом»: получи Т-44-100 (И) бесплатно",
                description: "Все мы со временем меняемся, «Игровой» – не исключение. Некоторые корректировки запланированы совсем скоро, 1 апреля 2024-го. Многие уже воспользовались одной из опций этого тарифа и оценили в бою VIII Т-44-100 Игровой, который доступен для пользователей «Игрового» от «Ростелеком», «Белтелеком» и Tele2.",
                created_at: "2024-02-28",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/portal_preview_615h346_lg_spb_mt1.jpg",
                url: "https://tanki.su/ru/news/special-offers/igrovoy-discount-2024/",
                change_log: "",
                category: 2
            },
            {
                id: 55,
                title: "С Новым годом, танкисты! ",
                description: "Поздравляем! С Новым годом! От всей души поздравляем вас, дорогие танкисты! Пусть в эти праздничные мгновения все мечты сбываются! Искренне ваша, Команда «Мира танков»",
                created_at: "2023-12-29",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/preview_news_615x346_0301_4.jpg",
                url: "https://tanki.su/ru/news/game-events/skoro_novyi_god/",
                change_log: "",
                category: 2
            },
            {
                id: 54,
                title: "Обновление «Наш Новый год»!",
                description: "Наряжаем красавицу-ёлку, делимся сюрпризами из новогодних контейнеров, готовимся собирать новый премиум танк в Сборочном цеху и врываемся в не по-зимнему жаркие сражения Натиска!",
                created_at: "2023-12-05",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/portal_preview_615h346_lg_spb_mt-1_4LiWKJw.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-23-rich/",
                change_log: "",
                category: 2
            },
            {
                id: 53,
                title: "Lesta Games — компания, создающая игры!",
                description: "Уважаемые игроки! С 2022 года мы оперируем проектами «Мир танков», «Мир кораблей» и «Tanks Blitz» на территории России и Беларуси. Наши проекты вне политики, и мы не имеем отношения к иным играм и проектам схожей тематики.",
                created_at: "2023-10-18",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/category_lists.png",
                url: "https://tanki.su/ru/news/notifications/tolko_igra/",
                change_log: "",
                category: 2
            },
            {
                id: 52,
                title: "С днем рождения «Танков», друзья! ",
                description: "Нашей любимой игре исполняется 12+1 лет. Это грандиозное событие! Вас ждут новый премиум танк, праздничный промокод награды Летнего фестиваля и Особой поддержки и многое другое. Готовы повеселиться, танкисты?",
                created_at: "2023-08-12",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/portal_preview_615h346_lg_spb_mt-1_qK2uJa4.jpg",
                url: "https://tanki.su/ru/news/special-offers/happy-birthday-mt-2023-rich/",
                change_log: "",
                category: 2
            },
            {
                id: 51,
                title: "«Мир танков» теперь — киберспорт!",
                description: "Федерация компьютерного спорта России начнёт выдавать киберспортивные разряды в дисциплине «Тактический трёхмерный бой» по игре «Мир танков». VK Play, ФКС России и Lesta Games проведут совместные турниры по игре «Мир танков».",
                created_at: "2023-06-23",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/fks_615-346.jpg",
                url: "https://tanki.su/ru/news/related/resf/",
                change_log: "",
                category: 2
            },
            {
                id: 50,
                title: "С Днём Победы! ",
                description: "Команда «Мира танков» поздравляет вас и ваших близких с Днём Победы! 78 лет прошло с того момента, как люди Советского Союза одержали победу над фашистской Германией. С каждым годом эта дата всё больше отдаляется от нас. Но мы никогда не должны забывать о тех героических поступках, которые совершили наши предки.",
                created_at: "2023-05-09",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/9th_may_1_615x346_lg_spb_mt.jpg",
                url: "https://tanki.su/ru/news/related/9-may-2023/",
                change_log: "",
                category: 2
            },
            {
                id: 49,
                title: "С Днём космонавтики!",
                description: "Друзья! Сегодня годовщина первого полёта человека в космос. Это прекрасный пример того, что человеческие разум, воля и смелость не знают границ. Команда «Мира танков» поздравляет вас с Днём космонавтики 🚀! Будьте решительны, любознательны и не забывайте мечтать. Помните: даже самые дерзкие мечты сбываются.",
                created_at: "2023-04-12",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/art_bg_cosmonautics_day_stream_615x346.jpg",
                url: "https://tanki.su/ru/news/notifications/den-kosmonavtiki-23/",
                change_log: "",
                category: 2
            },
            {
                id: 48,
                title: "Обновление 1.20 уже здесь! ",
                description: "В игре появится солнечная карта «Устричный залив», а ещё три боевые локации будут улучшены. Мы добавили автопереворот опрокинувшейся техники и переработали механику оглушения, чтобы сделать её более понятной и комфортной. Кроме того, был проведён ребаланс большого количества техники (в т.ч. семи машин X уровня) и расширен список товаров, доступных за боны. Оцените сами!",
                created_at: "2023-03-07",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/portal_ranked-battles_615h346_lg_spb_mt.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-20-rich/",
                change_log: "",
                category: 2
            },
            {
                id: 47,
                title: "Обновление 1.19.1: уже в игре! ",
                description: "НОВЫЙ ГОД — НОВЫЙ КОНТЕНТ! Бойцы, обновление 1.19.1 для «Мира танков» уже здесь! В игре появилась новая ветка китайских тяжёлых танков с механикой ракетных ускорителей и началась новая глава Боевого пропуска. Мы также готовим серию захватывающих активностей, в том числе новую версию Стального охотника и игровое событие на Глобальной карте. Поехали!",
                created_at: "2023-01-17",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/preview_portal_update-1191_615x346_lg_spb_mt.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-19-1-rich/",
                change_log: "",
                category: 2
            },
            {
                id: 46,
                title: "«Новогоднее наступление 2023»: всё об игровом событии ",
                description: "Праздники не за горами, а это значит, что в «Мире танков» пришло время Новогоднего наступления. Это самое ожидаемое игровое событие года, в котором вы найдёте множество наград и ярких сюрпризов! Вместо обычного Ангара вы попадёте в праздничный городок, наполненный весельем и волшебной атмосферой. Там вы сразу же обнаружите приятный подарок — новенький M16/43 Sahariano (итальянский премиум СТ III уровня). Это быстрый и манёвренный средний танк для весёлой и динамичной игры.",
                created_at: "2022-12-08",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/the-title-art-of-the-new-year%27s-23_615x346_lg_spb_mt.jpg",
                url: "https://tanki.su/ru/news/game-events/holiday-ops-2023-regulations/",
                change_log: "",
                category: 2
            },
            {
                id: 45,
                title: "Заслуженная награда 2022 ",
                description: "Приветствуем, бойцы! Ещё один год в игре — это можно сравнить с получением очередного звания. Новые игроки становятся опытными танкистами, опытные игроки становятся ветеранами, а ветераны… ну, они остаются ветеранами уже навсегда. Благодаря увлечённости и преданности наших игроков, «Мир танков» постоянно улучшается и развивается. И теперь, в знак нашей признательности, каждый танкист, начавший свой боевой путь в игре до 29 ноября 2022 года, сможет получить подарок. Этот подарок будет доступен с 30 ноября 2022 года, а получить его можно до ноября 2023 года.",
                created_at: "2022-11-30",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/well_deserved_award_615x346.jpg",
                url: "https://tanki.su/ru/news/game-events/reward-2022/",
                change_log: "",
                category: 2
            },
            {
                id: 44,
                title: "Прекращение поддержки портала WGMods",
                description: "Уважаемые игроки! С 12 октября портал WGMods больше не будет функционировать для игроков из RU-региона. Однако компания Lesta Games не намерена останавливать поддержку самих модификаций и игроков, которые их делают. Теперь информацию об актуальных модах можно будет найти в соответствующем разделе форума.",
                created_at: "2022-10-11",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/thumb_615x346_%283%29.jpg",
                url: "https://tanki.su/ru/news/notifications/wgmods-stop/",
                change_log: "",
                category: 2
            },
            {
                id: 43,
                title: "«Ваффентрагер: Наследие»: наборы с ключами и стартерами в подарок",
                description: "Приветствуем, бойцы! Готовы взять на себя управление невероятно мощной Blitzträger auf E 110 или присоединиться к отряду Гончих? Независимо от выбранной стороны в этом эпическом противостоянии, в Премиум магазине вы уже можете найти специальные наборы, посвящённые игровому событию!",
                created_at: "2022-09-26",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_white_tiger_2021._loot_boxes_banner615x346_C6HewMo.jpg",
                url: "https://tanki.su/ru/news/special-offers/waffentrager-legacy-bundles-2022/",
                change_log: "",
                category: 2
            },
            {
                id: 42,
                title: "Планы на осень 2022: Ваффентрагер, новый режим и леденящие душу сюрпризы ",
                description: "Бойцы, жара летнего сезона спала, но только не в мире танков! Готовьтесь принять участие в долгожданных игровых режимах и целой серии увлекательных событий! Этой осенью будет много акций и сюрпризов. Вот что вас ждёт в игре.",
                created_at: "2022-09-09",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/v1.18_eventful_autumn_art_615x346.jpg",
                url: "https://tanki.su/ru/news/game-events/eventful-autumn-in-wot/",
                change_log: "",
                category: 2
            },
            {
                id: 41,
                title: "«Танкам» исполняется 12 лет. Приглашаем всех! ",
                description: "В этом месяце состоится большая пляжная вечеринка в честь 12-летия «Танков»! Некоторые подарки для гостей — премиум аккаунт и новый 2D-стиль — можно получить, просто заглянув на праздник. А чтобы получить больше игровых ценностей, в том числе новый премиум танк и уникальные элементы внешнего вида, выполняйте праздничные задачи и зарабатывайте праздничные монеты. Время начинать вечеринку!",
                created_at: "2022-08-18",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/corevid-1126_wot_12th_anniversary_615x346.jpg",
                url: "https://tanki.su/ru/news/special-offers/wot-12th-anniversary/",
                change_log: "",
                category: 2
            },
            {
                id: 40,
                title: "Общий тест обновления 1.18: новая ветка машин, ребаланс техники и многое другое! ",
                description: "Общий тест обновления 1.18 уже здесь. Приготовьтесь к новым впечатлениям, бойцы! В дерево исследований Италии мы добавим целую ветку техники, включающую шесть исследуемых ПТ-САУ. Также в обновлении 1.18 появится новая карта «Застава» для Случайных боёв, и будет проведён ребаланс большого количества техники, в том числе двух машин X уровня: Kranvagn и AMX M4 mle. 54.",
                created_at: "2022-08-04",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/1_18_%282%29_615x346.jpg",
                url: "https://tanki.su/ru/news/updates/1-18-CT1/",
                change_log: "",
                category: 2
            },
            {
                id: 39,
                title: "Аркада: главное о режиме ",
                description: "Бойцы! Давайте на мгновение представим, что настройки всех машин в игре изменились до неузнаваемости! И речь не про полевую модернизацию с колебаниями 3–5% в одну сторону, а про действительно впечатляющие метаморфозы",
                created_at: "2022-07-15",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/arcade_615x346.png",
                url: "https://tanki.su/ru/news/game-events/arcade_short/",
                change_log: "",
                category: 2
            },
            {
                id: 38,
                title: "Переход на 64-битные ОС и обновление системных требований ",
                description: "Бойцы! «Мир танков» постоянно развивается и улучшается, не отстаёт и компьютерное «железо». Наряду с усилиями команды разработки глобальный технический прогресс",
                created_at: "2022-06-14",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/thumb_615x346_2n0nu99.jpg",
                url: "https://tanki.su/ru/news/notifications/32-bit-support-stop/",
                change_log: "",
                category: 2
            },
            {
                id: 37,
                title: "Обновление 1.17. Актуальная версия игры ",
                description: "Бойцы, обновление 1.17 уже доступно! Долгожданный режим «Стальной охотник» возвращается в «Мир танков» с самыми масштабными изменениями за всю историю его существования",
                created_at: "2022-06-02",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_corevid-740_sh_season_release_2022._key_art_8rmBvT4.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-17-release/",
                change_log: "",
                category: 2
            },
            {
                id: 36,
                title: "Новые танки и стили за боны ",
                description: "Танкисты! В VIII сезоне Боевого пропуска вы можете заработать до 7 350. И это отличная возможность пополнить ваши запасы — с расширенным ассортиментом товаров за боны во внутриигровом магазине",
                created_at: "2022-05-25",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/wot_bond_615x346.jpg",
                url: "https://tanki.su/ru/news/special-offers/goods-for-bonds-25may2022/",
                change_log: "",
                category: 2
            },
            {
                id: 35,
                title: "Искусство стратегии: подробно о событии",
                description: "Танкисты! Напоминаем, что событие продлится до 5 мая 0:59 (МСК). Успейте потратить накопленные Приказы и Рапорты — после завершения события они будут списаны. Также это отличная возможность",
                created_at: "2022-04-20",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/1.16.1._key_art_615.jpg",
                url: "https://tanki.su/ru/news/game-events/the-art-of-strategy-guide/",
                change_log: "",
                category: 2
            },
            {
                id: 34,
                title: "«Космическая экспедиция»: всё об игровом событии ",
                description: "Наша Солнечная система полна тайн и загадок. Это всегда привлекало настоящих исследователей. Они постоянно стремились к неизведанному и новому — несмотря на множество опасностей",
                created_at: "2022-04-13",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_portal-min.jpg",
                url: "https://tanki.su/ru/news/game-events/cosmo-event-2022/",
                change_log: "",
                category: 2
            },
            {
                id: 33,
                title: "Оперирование World of Tanks в России и Беларуси будет передано Lesta Studio ",
                description: "В течение последних недель компания Wargaming провела стратегический анализ операционной деятельности по всему миру и приняла решение, что не будет",
                created_at: "2022-04-04",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615-angar.jpg",
                url: "https://tanki.su/ru/news/notifications/world-of-tanks-lesta/",
                change_log: "",
                category: 2
            },
            {
                id: 32,
                title: "Общий тест обновления 1.16.1",
                description: "Танкисты! Мы начинаем Общий тест обновления 1.16.1. В нём вас ждёт новое игровое событие «Искусство стратегии», в котором можно попробовать себя в роли танкового Стратега или одного из семи Танкистов",
                created_at: "2022-03-25",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615h346-1-16-1.jpg",
                url: "https://tanki.su/ru/news/updates/obschiy-test-1-16-1/",
                change_log: "",
                category: 2
            },
            {
                id: 31,
                title: "Обновление 1.16 уже доступно ",
                description: "Встречайте обновлённый Боевой пропуск уже в марте, оцените изменения на игровых локациях и примите участие в завершающем сезоне Ранговых боёв 2021–2022! БАЛАНСНЫЕ ПРАВКИ КАРТ",
                created_at: "2022-03-02",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_update_1.16.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-16-release/",
                change_log: "",
                category: 2
            },
            {
                id: 30,
                title: "Общий тест обновления 1.16 ",
                description: "Танкисты! Мы начинаем Общий тест обновления 1.16. В нём вас ждут изменения третьего (финального!) сезона Ранговых боёв 2021–2022. Кроме того, вы сможете протестировать различные балансные правки на картах «Линия Фронта»",
                created_at: "2022-02-03",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_thumb-1-16.jpg",
                url: "https://tanki.su/ru/news/updates/obschiy-test-1-16/",
                change_log: "",
                category: 2
            },
            {
                id: 29,
                title: "Реферальная программа: изменения в девятом сезоне ",
                description: "Приветствуем, бойцы! 26 января начнётся девятый сезон реферальной программы, в котором для командиров и их рекрутов будут доступны новые списки премиум техники",
                created_at: "2022-01-24",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_%28thumbnail%29.jpg",
                url: "https://tanki.su/ru/news/game-events/referral-program-season-9/",
                change_log: "",
                category: 2
            },
            {
                id: 28,
                title: "Официальный аукцион «С молотка!» ",
                description: "Делайте ставки, танкисты! С 14 по 19 января у вас будет шанс получить очень редкие машины за золото либо свободный опыт. Для этого нужно сделать ставку на специальном аукционе — и выиграть",
                created_at: "2022-01-09",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346fgeghefghhwfgh.jpg",
                url: "https://tanki.su/ru/news/special-offers/under-the-hammer/",
                change_log: "",
                category: 2
            },
            {
                id: 27,
                title: "Яндекс Плюс World of Tanks — новая подписка специально для танкистов ",
                description: "Командиры! Мы рады представить вам новый тип подписки Яндекс Плюс, созданный специально для танкистов, — Яндекс Плюс World of Tanks! Новая подписка",
                created_at: "2021-12-02",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/wot_yandexdecember_615x346_logo.jpg",
                url: "https://tanki.su/ru/news/special-offers/yandex-dec2021/",
                change_log: "",
                category: 2
            },
            {
                id: 26,
                title: "Если верить — мечты сбудутся!",
                description: "Мечты не должны иметь границ! Никогда не знаешь, сбудутся они или нет. И если вы по-настоящему ждёте этого особенного момента, кто знает, быть может, капелька праздничного",
                created_at: "2021-12-01",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_Hil5DoU.jpg",
                url: "https://tanki.su/ru/news/game-events/holiday-ops-2022-announcement/",
                change_log: "",
                category: 2
            },
            {
                id: 25,
                title: "Заслуженная награда: как использовать жетоны ",
                description: "Бойцы! Совсем скоро, 25 ноября, при входе в игру вас будет ждать Заслуженная награда за ещё один год, который мы прошли плечом к плечу. На этот раз мы приготовили для вас кое-что особенное",
                created_at: "2021-11-25",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/wot_seniorityawordstokenicon2021_615x346.jpg",
                url: "https://tanki.su/ru/news/game-events/well-deserved-reward-store/",
                change_log: "",
                category: 2
            },
            {
                id: 24,
                title: "«Счастливая карта»: твои персональные акции каждый день ",
                description: "Танкист! Акция «Счастливая карта» вернулась в обновлённом формате и предлагает новый набор игровых ценностей! Каждый день с 17 по 24 ноября вытягивай по пять карт",
                created_at: "2021-11-21",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/wot_banner_cards_615x346.jpg",
                url: "https://tanki.su/ru/news/special-offers/lucky-card-nov2021/",
                change_log: "",
                category: 2
            },
            {
                id: 23,
                title: "Жаркий август ",
                description: "В этом году август принёс с собой не только жару, но и отличные акции и сюрпризы. Присоединяйтесь к празднованию 11-летия игры и участвуйте во множестве интересных игровых событий! В обновлении 1.14",
                created_at: "2021-08-10",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/update_1.14_615x346.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-14-release/",
                change_log: "",
                category: 2
            },
            {
                id: 22,
                title: "Ранговые бои: сезон X. Оцените новый формат! ",
                description: "Бойцы! Новый тестовый сезон Ранговых боёв, сезон X, начнётся 28 июня. Мы продолжаем развивать этот соревновательный режим, и вы сможете опробовать некоторые важные изменения",
                created_at: "2021-05-26",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_ic83Jd6.jpg",
                url: "https://tanki.su/ru/news/game-events/ranked-battles-2021-season-X/",
                change_log: "",
                category: 2
            },
            {
                id: 21,
                title: "«Счастливая карта: майские открытки». Персональные акции каждый день",
                description: "Танкист! Специальные предложения — это всегда отличная возможность для удачных приобретений. Ещё приятнее делать покупки по акции",
                created_at: "2021-05-18",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_kj78WWy.jpg",
                url: "https://tanki.su/ru/news/special-offers/lucky-card-may2021/",
                change_log: "",
                category: 2
            },
            {
                id: 20,
                title: "С Днём Победы! ",
                description: "Танкисты! Команда World of Tanks поздравляет вас и ваших близких с Днём Победы! 76 лет назад наши предки положили конец одной из самых страшных войн в нашей истории, одержав победу над смертоносной военной машиной",
                created_at: "2021-05-09",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615_ph7hwgg_EFcnKdP.jpg",
                url: "https://tanki.su/ru/news/related/9-may-2021/",
                change_log: "",
                category: 2
            },
            {
                id: 19,
                title: "Girls und Panzer в World of Tanks  ",
                description: "Они овладели искусством сэнся-до. Они стали победителями самых сложных турниров. Они спасли школу Оараи от закрытия. Они — это команда «Удильщик», возглавляемая легендарной Михо Нисидзуми",
                created_at: "2021-04-28",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_gup_banner._resizes_615x346_carousel.jpg",
                url: "https://tanki.su/ru/news/special-offers/girls-und-panzer-news/",
                change_log: "",
                category: 2
            },
            {
                id: 18,
                title: "IV сезон Боевого пропуска: крутые награды и много нового!",
                description: "Здравия желаем, бойцы! У нас для вас отличные новости! 15 марта в World of Tanks стартует IV сезон обновлённого Боевого пропуска — это первый запуск Боевого пропуска из трёх",
                created_at: "2021-03-11",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_battle_pass._new_season._resizes_615x346_carousel.jpg",
                url: "https://tanki.su/ru/news/game-events/battle-pass-season-4/",
                change_log: "",
                category: 2
            },
            {
                id: 17,
                title: "Чёрный рынок 2021. Завершено",
                description: "Ну что ж, ребятки, пора и честь знать. В общем, я сматываю удочки и временно залягу на дно — пусть тут пока всё малость утрясётся. Моё уважение, приятно было",
                created_at: "2021-03-11",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_Xk82imt.jpg",
                url: "https://tanki.su/ru/news/special-offers/black-market-2021/",
                change_log: "",
                category: 2
            },
            {
                id: 16,
                title: "«Песочница-2021». Фугасы: предварительные итоги",
                description: "Танкисты!  Спасибо вам за активное участие в тестировании переработанных фугасов! Количество игроков на сервере «Песочница» превзошло все ожидания — это попомогло собрать",
                created_at: "2021-02-21",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/wot-sandbox-shell-615x346.jpg",
                url: "https://tanki.su/ru/news/game-events/lunar-challenge/",
                change_log: "",
                category: 2
            },
            {
                id: 15,
                title: "«Лунная охота»: укрощение быка",
                description: "Командиры!  Восточный Новый год отмечается во всём мире и уже оброс множеством историй. По китайскому календарю символ этого года — бык. Поэтому мы решили",
                created_at: "2021-02-09",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/615x346_lunar_challenge.jpg",
                url: "https://tanki.su/ru/news/game-events/lunar-challenge/",
                change_log: "",
                category: 2
            },
            {
                id: 14,
                title: "Amway921 и KorbenDaIlas — победители «Битвы блогеров — 2021»!",
                description: "Танкисты! «Битва блогеров» завершена, и, кажется, никогда она ещё не была такой яростной и бескомпромиссной. Всю неделю команды",
                created_at: "2021-02-08",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_battle_of_bloggers_21._resizes_615x346_carousel_cis.jpg",
                url: "https://tanki.su/ru/news/game-events/bitva-blogerov-2021-zavershena/",
                change_log: "",
                category: 2
            },
            {
                id: 13,
                title: "Обновление 1.11.1: играйте прямо сейчас!",
                description: "ОБНОВЛЕНИЕ 1.11.1  Оцените новую ветку итальянских тяжёлых танков высоких уровней, а также систему «Взвод 2.0», которая позволит искать совзводных прямо в Ангаре",
                created_at: "2021-01-27",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_key-art-1.11.1-615x346_se2ljk2.jpg",
                url: "https://tanki.su/ru/news/updates/update-1-11-1-release/",
                change_log: "",
                category: 2
            },
            {
                id: 12,
                title: "«Битва блогеров — 2021». Подробности события",
                description: "Танкист! «Битва блогеров» снова возвращается в игру. Правила остаются неизменными: четыре команды игроков, возглавляемые известными блогерами, будут соревноваться",
                created_at: "2021-01-25",
                img: "//ru-wotp.lesta.ru/dcont/fb/image/%5Bwot%5D_battle_of_bloggers_21._resizes_615x346_carousel_cis.jpg",
                url: "https://tanki.su/ru/news/game-events/bitva-blogerov-2021/",
                change_log: "",
                category: 2
            },
            {
                id: 11,
                title: "Боевой путь 2020",
                description: "Командиры! 2020-й был необычным годом. Скажем так, наконец-то он закончился. В то же время, в нём было и хорошее: World of Tanks отметил свой 10-летний юбилей, в игре появился Боевой пропуск, не обошлось и без знаме",
                created_at: "2021-01-04",
                img: "//justsherlock.ru/images/holiday-ops-2021_615x346-boevouput.jpg",
                url: "https://tanki.su/ru/news/related/battle-path-2020/",
                change_log: "",
                category: 2
            },
            {
                id: 10,
                title: "С наступающим Новым годом, танкисты!",
                description: "Друзья! С наступающим Новым годом! Спасибо, что остаётесь с нами. В 2020 мы вместе путешествовали во времени, отмечая 10-летие World of Tanks, сражались в поверженном «Берлине»",
                created_at: "2020-12-30",
                img: "//justsherlock.ru/images/1280h800_yu1jk7y_615x-newyear.jpg",
                url: "https://tanki.su/ru/news/related/happy-new-year-tankers/",
                change_log: "",
                category: 2
            },
            {
                id: 9,
                title: "Реферальная программа: 6-й сезон",
                description: "Танкисты! Шестой сезон реферальной программы стартует 26 декабря и продлится до апреля. С его началом мы начислим вам два новых слота для рекрутов, а все неиспользуемые слоты",
                created_at: "2020-12-18",
                img: "//justsherlock.ru/images/tild6337-3332-4834-a565-323636303133__615x346_thumbnail.jpg",
                url: "https://tanki.su/ru/news/game-events/referal-program-season-6/",
                change_log: "",
                category: 2
            },
            {
                id: 8,
                title: "Большие коробки для Новогоднего наступления 2020",
                description: "Командиры, Новогоднее наступление началось! Прокачиваем уровень атмосферы праздника в Ангаре и получаем отличные награды. А ещё новогодняя пора — лучшее",
                created_at: "2020-12-17",
                img: "//justsherlock.ru/images/tild6231-3733-4439-a362-633264323930__615x346_preview_qgSM.jpg",
                url: "https://tanki.su/ru/news/special-offers/holiday-ops-2021-boxes/",
                change_log: "",
                category: 2
            },
            {
                id: 7,
                title: "«Стрим года». Личный зачёт",
                description: "Друзья! Традиционный «Стрим года» завершён. Сегодня стартовал личный зачёт, для участников которого мы подготовили много ценных призов",
                created_at: "2020-12-16",
                img: "//justsherlock.ru/images/tild3462-3662-4466-a362-616665656666__sg_2020_wot_615h346_.jpg",
                url: "https://tanki.su/ru/news/game-events/personal-credit/",
                change_log: "",
                category: 2
            },
            {
                id: 6,
                title: "GSOR 1008 — терминатор из коробки: больше 1200 урона за 6 секунд",
                description: "Танкисты! На VIII уровне появился новый опасный охотник — британская ПТ-САУ VIII GSOR 1008. Во время «Новогоднего наступления 2021» эта самоходка может выпасть",
                created_at: "2020-12-14",
                img: "//justsherlock.ru/images/tild3637-3437-4133-b030-373033626533__201211_gsor_1008_346.jpg",
                url: "https://tanki.su/ru/news/tank-reviews/GSOR-1008-breaker/",
                change_log: "",
                category: 2
            },
            {
                id: 5,
                title: "Особенности Новогоднего наступления 2021",
                description: "Приветствуем, бойцы! Совсем скоро стартует Новогоднее наступление 2021! Начнётся привычная для танкистов новогодняя суета, которая радует нас последние годы",
                created_at: "2020-12-14",
                img: "//justsherlock.ru/images/tild3031-3738-4261-a437-386438646166__holiday-ops-2021_615.jpg",
                url: "https://tanki.su/ru/news/game-events/holiday-ops-2021-diffs/",
                change_log: "",
                category: 2
            },
            {
                id: 4,
                title: "Обновление 1.11. Играй прямо сейчас!",
                description: "Бойцы, готовы ощутить праздничное настроение? Время чудес приближается, а Новогоднее наступление, самое грандиозное игровое событие года, возвращается в World of Tanks в обновлении 1.11",
                created_at: "2020-12-08",
                img: "//justsherlock.ru/images/tild6136-3832-4231-b932-656432613762__top685059.jpg",
                url: "https://tanki.su/ru/1.11/",
                change_log: "",
                category: 2
            },
            {
                id: 3,
                title: "«Полярная охота»: в погоне за советской звездой",
                description: "Приветствуем, танкисты! Зима близко. В это холодное время поможет согреться жаркое испытание — «Полярная охота», которая начнётся 27 ноября. Приготовьтесь пройти 10 непростых этапов в погоне",
                created_at: "2020-12-02",
                img: "//justsherlock.ru/images/tild3836-3030-4230-b961-653833643666__615x346_7NZxbEN.jpg",
                url: "https://tanki.su/ru/news/game-events/polar-challenge/",
                change_log: "",
                category: 2
            },
            {
                id: 2,
                title: "Получите заслуженную награду за 2020 год!",
                description: "Танкисты! Спасибо вам за незабываемые моменты и время, с удовольствием проведённое в нашей игре! Чтобы выразить свою искреннюю признательность, в этом году мы снова запустим",
                created_at: "2020-11-26",
                img: "//justsherlock.ru/images/tild6330-3461-4139-b231-346536333339__nagrada-615x346.jpg",
                url: "https://tanki.su/ru/well-deserved-reward-2020/",
                change_log: "",
                category: 2
            },
            {
                id: 1,
                title: "Хэллоуин 2020: всё, что нужно знать о Мирном-13",
                description: "Пришло время для самого жуткого праздника в году... Готовьтесь участвовать в новом захватывающем PvE-режиме, в котором вы будете противостоять сверхъестественному",
                created_at: "2020-10-28",
                img: "//justsherlock.ru/images/tild3161-3837-4835-b630-626533366431__615h346_hw.jpg",
                url: "https://tanki.su/ru/news/game-events/halloween-mechanics/",
                change_log: "",
                category: 2
            }
        ],
        offset: 0
    },
    modId: {
        data: {
            authorName: "_McClayn",
            changelogs: [
                {
                    content: "Обновлено под патч 1.24.0",
                    modVersion: "1"
                },
                {
                    content: "Обновлено под патч 1.23.1",
                    modVersion: "1"
                }
            ],
            cover: "",
            createdAt: "2024-01-17T13:19:17.051627Z",
            descriptions: [
                {
                    content: "<p><br><i><strong>ModPack Lite</strong> содержит только самые самые нужные и полезные моды.</i><br><i>В первую очередь обновления модпака выходят на моем сайте - </i><a href=\"https://www.justsherlock.ru/\"><i>JustSherlock ModHub</i></a><i>.</i></p><p>Перед вами новый пакет модификаций для игры World of Tanks, называемый саундпак, содержащий 15+ озвучек. <mark>Mark</mark> <br /> <hr /> <br/> <a><strong>Strong A</strong></a> ",
                    language: "ru"
                }
            ],
            downloads: 1824,
            gameVersion: "1.25.0.0",
            installationGuides: [
                {
                    content: "<ol><li>Скачать инсталлятор</li><li>Запустить установочный файл</li><li>Выбрать моды и наслаждаться процессом установки.</li></ol><p>При запуске установщика Вам покажется, что Ваш ПК завис на пару секунд. На самом деле это не так, просто процессор Вашего ПК занят распаковкой временных файлов для правильной работы установщика. Подождите немного.</p>",
                    language: "ru"
                }
            ],
            isReported: false,
            isSubscribed: false,
            mark: 4.2,
            markVotesCount: 12,
            userVote: 0,
            modVersion: "1",
            ownerId: 857532,
            ownerName: "_McClayn_EU",
            screenshots: [
                {
                    id: 21105,
                    position: 0,
                    source: "https://wgmods.net/media/mod_files/Screenshot_1_kNryqEY.jpg.1600x0_q85_crop_detail_replace_alpha-white_q2nRk3N.jpg",
                    sourceFullSize: "https://wgmods.net/media/mod_files/Screenshot_1_kNryqEY.jpg.1600x0_q85_crop_detail_replace_alpha-white_q2nRk3N.jpg.1600x0_q85_crop_detail_replace_alpha-white.jpg",
                    sourceViewPage: "https://wgmods.net/media/mod_files/Screenshot_1_kNryqEY.jpg.1600x0_q85_crop_detail_replace_alpha-white_q2nRk3N.jpg.412x218_q85_crop_detail_replace_alpha-white.jpg"
                },
                {
                    id: 21106,
                    position: 1,
                    source: "https://wgmods.net/media/mod_files/Screenshot_4_lzaGEFJ.jpg.1600x0_q85_crop_detail_replace_alpha-white_tTHnLLc.jpg",
                    sourceFullSize: "https://wgmods.net/media/mod_files/Screenshot_4_lzaGEFJ.jpg.1600x0_q85_crop_detail_replace_alpha-white_tTHnLLc.jpg.1600x0_q85_crop_detail_replace_alpha-white.jpg",
                    sourceViewPage: "https://wgmods.net/media/mod_files/Screenshot_4_lzaGEFJ.jpg.1600x0_q85_crop_detail_replace_alpha-white_tTHnLLc.jpg.412x218_q85_crop_detail_replace_alpha-white.jpg"
                },
                {
                    id: 21107,
                    position: 2,
                    source: "https://wgmods.net/media/mod_files/Screenshot_3_FGnG8rk.jpg.1600x0_q85_crop_detail_replace_alpha-white_ufsnxD0.jpg",
                    sourceFullSize: "https://wgmods.net/media/mod_files/Screenshot_3_FGnG8rk.jpg.1600x0_q85_crop_detail_replace_alpha-white_ufsnxD0.jpg.1600x0_q85_crop_detail_replace_alpha-white.jpg",
                    sourceViewPage: "https://wgmods.net/media/mod_files/Screenshot_3_FGnG8rk.jpg.1600x0_q85_crop_detail_replace_alpha-white_ufsnxD0.jpg.412x218_q85_crop_detail_replace_alpha-white.jpg"
                }
            ],
            tags: [
                {
                    caption: "Модпаки",
                    id: 10
                }
            ],
            title: "JustSherlock ModPack Lite - оптимальная версия модпака",
            updatedAt: "2024-03-09T09:27:39.691771Z",
            versions: [
                {
                    downloadUrl: "https://modp.wgcdn.co/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                    gameVersion: "1.25.0.0",
                    gameVersionId: 177,
                    id: 67783,
                    modVersion: "1",
                    versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                    versionFileSize: 177765021
                }
            ]
        },
        isAuthenticated: true,
        isError: false,
        isErrorNotFound: false,
        isFetched: true,
        isFetching: false,
        isModDownloadAccepted: true,
        language: "ru",
        fetchDetails: ()=>{},
        onDownloadClick: ()=>{},
        onFollowMod: ()=>{},
        onLoginLinkClick: ()=>{},
        onReportButtonClick: ()=>{},
        onUnsubscribeMod: ()=>{},
        voteForMod: ()=>{}
    },
    search: {
        filtered: {
            rawsData: {
                count: 2,
                results: [
                    {
                        id: 20,
                        downloads: 19110786,
                        author_name: "phantasm_ru",
                        cover_catalog: "", 
                        createdAt: "2017-05-17T09:36:20.631959Z",
                        updatedAt: "2024-03-06T10:16:22.604058Z",
                        game_version: "1.25.0.0",
                        mark: 4.4,
                        owner_id: 793572, // ownerId
                        spa_username: "phantasm_ru", // ownerName
                        tags: [10], // array of tagIds
                        title: "Модпак Джова",
                        versions: [
                            {
                                download_url: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                                //game_version: "1.25.0.0",
                                //game_version_id: 177,
                                game_version: {
                                    version: "1.25.0.0",
                                    id: 64,
                                },
                                id: 68050,
                                version: "80.4", // modVersion
                                version_file_original_name: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                                version_file_size: 486380089
                            }
                        ]
                    },
                    {
                        id: 22, 
                        downloads: 130902,
                        author_name: null,
                        cover_catalog: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                        createdAt: "2017-05-22T09:03:14.648406Z",
                        updatedAt: "2024-04-08T10:16:22.604058Z",
                        game_version: "1.25.0.0", 
                        mark: 4.5, 
                        owner_id: 301432, 
                        spa_username: "WGmods", 
                        tags: [16], // array of tagIds
                        title: "Менеджер Реплеев",
                        versions: [
                            {
                                download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // modVersionFile
                                //game_version: "1.25.0.0",
                                //game_version_id: 177,
                                game_version: {
                                    version: "1.25.0.0",
                                    id: 64,
                                },
                                id: 68258,
                                version: "3.7.7",
                                version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // modVersionFileOriginalName
                                version_file_size: 716423 // modSize
                            }
                        ]
                    }
                ],
            },
            count: 2,
            filterParams: {
                argument: 10, // передается из get - id
                ordering: "-rating", 
                type: "tags" // передается из get - tags or owner
            },
            isError: false,
            isFetchedAll: false,
            isFetching: false,
            items: [
                {
                    id: 20,
                    downloads: 19110786,
                    authorName: "phantasm_ru",
                    cover: "", 
                    createdAt: "2017-05-17T09:36:20.631959Z",
                    updatedAt: "2024-03-06T10:16:22.604058Z",
                    gameVersion: "1.25.0.0",
                    mark: 4.4,
                    modVersion: "80.4",
                    ownerId: 793572,
                    ownerName: "phantasm_ru",
                    tags: [
                        {
                            id: 10, 
                            caption: "Модпаки"
                        }
                    ],
                    title: "Модпак Джова",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68050,
                            modVersion: "80.4",
                            versionFileOriginalName: "JovesModPack_1.25.0.0_Extended_v80.4.exe",
                            versionFileSize: 486380089
                        }
                    ]
                },
                {
                    id: 22, 
                    downloads: 130902,
                    authorName: null,
                    cover: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                    createdAt: "2017-05-22T09:03:14.648406Z",
                    updatedAt: "2024-04-08T10:16:22.604058Z",
                    gameVersion: "1.25.0.0", 
                    mark: 4.5, 
                    modVersion: "3.7.7", 
                    ownerId: 301432, 
                    ownerName: "WGmods", 
                    tags: [
                        {
                            caption: "Другое", 
                            id: 16
                        }
                    ], 
                    title: "Менеджер Реплеев",
                    versions: [
                        {
                            downloadUrl: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // modVersionFile
                            gameVersion: "1.25.0.0",
                            gameVersionId: 177,
                            id: 68258,
                            modVersion: "3.7.7",
                            versionFileOriginalName: "[1.24.1.0] ReplaysManager 3.7.7.zip", // modVersionFileOriginalName
                            versionFileSize: 716423 // modSize
                        }
                    ]
                }
            ],
            offset: 0
        }
    },
    modder: {
        isBlocked: false,
        isLoggedIn: true,
        isDisabled: false,
        isError: false,
        isErrorForbidden: false,
        isRulesAccepted: true,
        isFetching: false,
        items:  
        [
            {
                cover: "https://wgmods.net/media/mod_files/Screenshot_3_50SRZfF.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                gameVersion: "1.25.0.0", 
                id: 6648,
                isHidden: false,
                mark: 3,
                modVersion: "1", 
                ownerId: 857532,
                ownerName: "_McClayn_EU",
                tags: 
                [
                    {
                        id: 10,
                        caption: "Модпаки"
                    }
                ],
                title: "JustSherlock ModPack Lite - оптимальная версия модпака",
                versions:
                [
                    {
                        gameVersion: "1.25.0.0",
                        gameVersionId: 176,
                        id: 67783,
                        modVersion: "1",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177765021
                    },
                    {
                        gameVersion: "1.23.1.0",
                        gameVersionId: 175,
                        id: 67440,
                        modVersion: "1",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177407939
                    }
                ],
                state: 3
            },
            {
                cover: "", 
                gameVersion: "1.25.0.0", 
                id: 5090,
                isHidden: false,
                mark: 2.8,
                modVersion: "2", 
                ownerId: 101935,
                ownerName: "_McClayn",
                tags: 
                [
                    {
                        id: 10,
                        caption: "Модпаки"
                    }
                ],
                title: "JustSherlock ModPack Base - расширенная версия модпака",
                versions:
                [
                    {
                        gameVersion: "1.25.0.0",
                        gameVersionId: 176,
                        id: 67783,
                        modVersion: "2",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177765021
                    },
                    {
                        gameVersion: "1.23.1.0",
                        gameVersionId: 175,
                        id: 67440,
                        modVersion: "1",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177407939
                    }
                ],
                state: 4
            },
            {
                cover: "", 
                gameVersion: "1.25.0.0", 
                id: 1030,
                isHidden: false,
                mark: 2.8,
                modVersion: "2", 
                ownerId: 101935,
                ownerName: "_McClayn",
                tags: 
                [
                    {
                        id: 14,
                        caption: "Озвучки"
                    }
                ],
                title: "Озвучка экипажа - Гусар",
                versions:
                [
                    {
                        gameVersion: "1.25.0.0",
                        gameVersionId: 176,
                        id: 67783,
                        modVersion: "2",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177765021
                    }
                ],
                state: 0
            },
            {
                cover: "", 
                gameVersion: "1.25.0.0", 
                id: 1052,
                isHidden: false,
                mark: 2.8,
                modVersion: "2", 
                ownerId: 101935,
                ownerName: "_McClayn",
                tags: 
                [
                    {
                        id: 14,
                        caption: "Озвучки"
                    }
                ],
                title: "Озвучка экипажа - Арнольд Шварценеггер",
                versions:
                [
                    {
                        gameVersion: "1.25.0.0",
                        gameVersionId: 176,
                        id: 67783,
                        modVersion: "2",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177765021
                    }
                ],
                state: 6
            },
            {
                cover: "", 
                gameVersion: "1.25.0.0", 
                id: 1123,
                isHidden: false,
                mark: 2.8,
                modVersion: "2", 
                ownerId: 101935,
                ownerName: "_McClayn",
                tags: 
                [
                    {
                        id: 14,
                        caption: "Озвучки"
                    }
                ],
                title: "Озвучка экипажа - Олег Брейн(TheBrainDit)",
                versions:
                [
                    {
                        gameVersion: "1.25.0.0",
                        gameVersionId: 176,
                        id: 67783,
                        modVersion: "2",
                        status: 1,
                        versionFile: "/media/mod_files/modpack_justsherlock_lite_eu_ZlySJ9s.exe",
                        versionFileOriginalName: "modpack_justsherlock_lite_eu.exe",
                        versionFileSize: 177765021
                    }
                ],
                state: 3
            }
        ],
        fetchItems: ()=>{},
        onShowLoginDialog: ()=>{},
        onShowRulesDialog: ()=>{}
    },

    comments: {
        isAuthenticated: true,
        //lang: "ru",
        //modId: 20,
        readonly: false,
        comments: [
            {
                content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. N",
                author: "_McClayn",
                createdAt: "2024-04-01T15:12:17.051627Z",
                isReported: false,
            },
            {
                content: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. N",
                author: "Yusha",
                createdAt: "2024-03-27T13:39:17.051627Z",
                isReported: false,
            }
        ]
    },

    // массив с данными, после они будут загружаться с сервера api
    rawsData: {
        count: 13,
        results: [
            {
                id: 20,
                downloads: 19110786,
                author_name: null,
                cover_catalog: "", 
                createdAt: "2017-05-17T09:36:20.631959Z",
                updatedAt: "2024-03-06T10:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.4,
                owner_id: 793572, // owner_id
                spa_username: "phantasm_ru", // spa_username
                tags: [10], // array of tagIds
                title: "Модпак Джова",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68050,
                        version: "80.4", // version
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        version_file_size: 486380089
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 22, 
                downloads: 130902,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-22T09:03:14.648406Z",
                updatedAt: "2024-04-08T10:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.5, 
                owner_id: 301432, 
                spa_username: "WGmods", 
                tags: [16], // array of tagIds
                title: "Менеджер Реплеев",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68258,
                        version: "3.7.7",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // version_file_original_name
                        version_file_size: 716423 // modSize
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 89, 
                downloads: 25769258,
                author_name: "Команда Протанки",
                cover_catalog: "https://wgmods.net/media/mod_files/pro.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-30T06:02:36.381852Z",
                updatedAt: "2024-04-08T09:36:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.1, 
                owner_id: 793477, 
                spa_username: "ProTankiWot", 
                tags: [10], 
                title: "МОДПАК ПРОТАНКИ Extend - Расширенная версия RU и EU",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68262,
                        version: "32",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // version_file_original_name
                        version_file_size: 698501506 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1668,
                downloads: 69026,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/6th_sound_logo_6HHPihI.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:17:00.248174Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.6,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "116 -сигнал шестого чувства от Hawg",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68284,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        version_file_size: 25736881
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1669,
                downloads: 18321,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Amark_logo.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:22:34.901193Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [13],
                title: "Армейские звания вместо отличительных отметок (от Hawg)",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68282,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        version_file_size: 145935
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1683,
                downloads: 30922,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/New_Logo_9XIoGHt.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-18T09:18:19.093297Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "25  Индикаторов Попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68280,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        version_file_size: 4560215
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2030, 
                downloads: 246754,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Pb_logo_Clasic.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2018-09-01T01:29:25.980436Z",
                updatedAt: "2024-10-09T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.6,  
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "Пэинтбол",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68281,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // version_file_original_name
                        version_file_size: 1857106 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2087,
                downloads: 39552,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Damage_Panel_new_Logo_jvWbrvW.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-09-04T08:56:41.639774Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "52 Стеклянная панель повреждений",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68253,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        version_file_size: 9163174
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2974,
                downloads: 22246,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/302_Tacktical.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2019-02-11T00:15:09.491087Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "50 Десятисекундные тактические сигналы Шестого чувства V2",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68252,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        version_file_size: 16176553
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 4513,
                downloads: 57889,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/fog_logo_xb6K1d6.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-04-24T08:39:10.485686Z",
                updatedAt: "2024-03-18T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "Удаление тумана и бликов",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68287,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        version_file_size: 356887
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 4963,
                downloads: 1505593,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Untitled-1.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-08-29T14:07:57.397748Z",
                updatedAt: "2024-03-14T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.2,
                owner_id: 784307,
                spa_username: "Korben",
                tags: [10],
                title: "Модпак KorbenTeam",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68365,
                        version: "4",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        version_file_size: 23378514
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5131, 
                downloads: 338022,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/logo_Tac_cl5wz8o_cLIkX8N.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2020-11-01T12:57:00.984274Z",
                updatedAt: "2024-03-30T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.7, 
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "10 Тактическая миникарта  (позиции для САУ, ПТ, пассивной разведки) ++",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68249,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // version_file_original_name
                        version_file_size: 66521838 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5912,
                downloads: 127561,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/25_bPfRgjH.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                game_version: "1.25.0.0",
                createdAt: "2022-02-06T21:54:29.046907Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                mark: 4.7,
                owner_id: 786771,
                spa_username: "POLIROID_6825084",
                tags: [16],
                title: "Просмотр попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68257,
                        version: "1.9.9",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        version_file_size: 13853908
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            }
        ],
        recommended: {
            count: 13,
            results: [
            {
                id: 20,
                downloads: 19110786,
                author_name: null,
                cover_catalog: "", 
                createdAt: "2017-05-17T09:36:20.631959Z",
                updatedAt: "2024-03-06T10:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.4,
                owner_id: 793572, // owner_id
                spa_username: "phantasm_ru", // spa_username
                tags: [10], // array of tagIds
                title: "Модпак Джова",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68050,
                        version: "80.4", // version
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        version_file_size: 486380089
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 22, 
                downloads: 130902,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-22T09:03:14.648406Z",
                updatedAt: "2024-04-08T10:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.5, 
                owner_id: 301432, 
                spa_username: "WGmods", 
                tags: [16], // array of tagIds
                title: "Менеджер Реплеев",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68258,
                        version: "3.7.7",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // version_file_original_name
                        version_file_size: 716423 // modSize
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 89, 
                downloads: 25769258,
                author_name: "Команда Протанки",
                cover_catalog: "https://wgmods.net/media/mod_files/pro.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-30T06:02:36.381852Z",
                updatedAt: "2024-04-08T09:36:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.1, 
                owner_id: 793477, 
                spa_username: "ProTankiWot", 
                tags: [10], 
                title: "МОДПАК ПРОТАНКИ Extend - Расширенная версия RU и EU",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68262,
                        version: "32",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // version_file_original_name
                        version_file_size: 698501506 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1668,
                downloads: 69026,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/6th_sound_logo_6HHPihI.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:17:00.248174Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.6,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "116 -сигнал шестого чувства от Hawg",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68284,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        version_file_size: 25736881
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1669,
                downloads: 18321,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Amark_logo.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:22:34.901193Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [13],
                title: "Армейские звания вместо отличительных отметок (от Hawg)",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68282,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        version_file_size: 145935
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1683,
                downloads: 30922,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/New_Logo_9XIoGHt.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-18T09:18:19.093297Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "25  Индикаторов Попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68280,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        version_file_size: 4560215
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2030, 
                downloads: 246754,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Pb_logo_Clasic.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2018-09-01T01:29:25.980436Z",
                updatedAt: "2024-10-09T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.6,  
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "Пэинтбол",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68281,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // version_file_original_name
                        version_file_size: 1857106 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2087,
                downloads: 39552,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Damage_Panel_new_Logo_jvWbrvW.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-09-04T08:56:41.639774Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "52 Стеклянная панель повреждений",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68253,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        version_file_size: 9163174
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2974,
                downloads: 22246,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/302_Tacktical.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2019-02-11T00:15:09.491087Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "50 Десятисекундные тактические сигналы Шестого чувства V2",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68252,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        version_file_size: 16176553
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 4513,
                downloads: 57889,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/fog_logo_xb6K1d6.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-04-24T08:39:10.485686Z",
                updatedAt: "2024-03-18T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "Удаление тумана и бликов",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68287,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        version_file_size: 356887
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 90, // 4963
                downloads: 1505593,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Untitled-1.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-08-29T14:07:57.397748Z",
                updatedAt: "2024-03-14T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.2,
                owner_id: 784307,
                spa_username: "Korben",
                tags: [10],
                title: "Модпак KorbenTeam",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68365,
                        version: "4",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        version_file_size: 23378514
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5131, 
                downloads: 338022,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/logo_Tac_cl5wz8o_cLIkX8N.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2020-11-01T12:57:00.984274Z",
                updatedAt: "2024-03-30T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.7, 
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "10 Тактическая миникарта  (позиции для САУ, ПТ, пассивной разведки) ++",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68249,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // version_file_original_name
                        version_file_size: 66521838 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5912,
                downloads: 127561,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/25_bPfRgjH.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                game_version: "1.25.0.0",
                createdAt: "2022-02-06T21:54:29.046907Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                mark: 4.7,
                owner_id: 786771,
                spa_username: "POLIROID_6825084",
                tags: [16],
                title: "Просмотр попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68257,
                        version: "1.9.9",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        version_file_size: 13853908
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            }
            ]
        },
        new: {
            count: 13,
            results:[
            {
                id: 20,
                downloads: 19110786,
                author_name: null,
                cover_catalog: "", 
                createdAt: "2017-05-17T09:36:20.631959Z",
                updatedAt: "2024-03-06T10:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.4,
                owner_id: 793572, // owner_id
                spa_username: "phantasm_ru", // spa_username
                tags: [10], // array of tagIds
                title: "Модпак Джова",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68050,
                        version: "80.4", // version
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/JovesModPack_1.25.0.0_Extended_v80.4.exe",
                        version_file_size: 486380089
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 22, 
                downloads: 130902,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/%D0%91%D0%B5%D0%B7%D1%8B%D0%BC%D1%8F%D0%BD%D0%BD%D1%8B%D0%B9.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-22T09:03:14.648406Z",
                updatedAt: "2024-04-08T10:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.5, 
                owner_id: 301432, 
                spa_username: "WGmods", 
                tags: [16], // array of tagIds
                title: "Менеджер Реплеев",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68258,
                        version: "3.7.7",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_ReplaysManager_3.7.7.zip", // version_file_original_name
                        version_file_size: 716423 // modSize
                    }
                ],
                
                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 89, 
                downloads: 25769258,
                author_name: "Команда Протанки",
                cover_catalog: "https://wgmods.net/media/mod_files/pro.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2017-05-30T06:02:36.381852Z",
                updatedAt: "2024-04-08T09:36:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.1, 
                owner_id: 793477, 
                spa_username: "ProTankiWot", 
                tags: [10], 
                title: "МОДПАК ПРОТАНКИ Extend - Расширенная версия RU и EU",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68262,
                        version: "32",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/modpack_protanki_extend_soeG3zW.exe", // version_file_original_name
                        version_file_size: 698501506 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1668,
                downloads: 69026,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/6th_sound_logo_6HHPihI.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:17:00.248174Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.6,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "116 -сигнал шестого чувства от Hawg",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68284,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/116_Hawg_6th_Sense_Sounds_24.1.0.zip",
                        version_file_size: 25736881
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1669,
                downloads: 18321,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Amark_logo.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-16T22:22:34.901193Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [13],
                title: "Армейские звания вместо отличительных отметок (от Hawg)",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68282,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/ArmyRankMarkOfExcellence_24.1.0.zip",
                        version_file_size: 145935
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 1683,
                downloads: 30922,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/New_Logo_9XIoGHt.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-07-18T09:18:19.093297Z",
                updatedAt: "2024-04-09T09:36:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "25  Индикаторов Попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68280,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/25_Damage_Indicators_24.1.0.zip",
                        version_file_size: 4560215
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2030, 
                downloads: 246754,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Pb_logo_Clasic.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2018-09-01T01:29:25.980436Z",
                updatedAt: "2024-10-09T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.6,  
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "Пэинтбол",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68281,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/PaintBall_24.1.0.zip", // version_file_original_name
                        version_file_size: 1857106 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2087,
                downloads: 39552,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/Damage_Panel_new_Logo_jvWbrvW.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2018-09-04T08:56:41.639774Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "52 Стеклянная панель повреждений",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68253,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/DamagePanel_24.1.0.zip",
                        version_file_size: 9163174
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 2974,
                downloads: 22246,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/302_Tacktical.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2019-02-11T00:15:09.491087Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [14],
                title: "50 Десятисекундные тактические сигналы Шестого чувства V2",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68252,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tatical_6th_Sounds_24.1.0.zip",
                        version_file_size: 16176553
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 4513,
                downloads: 57889,
                author_name: null,
                cover_catalog: "//wgmods.net/media/mod_files/fog_logo_xb6K1d6.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-04-24T08:39:10.485686Z",
                updatedAt: "2024-03-18T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.8,
                owner_id: 237525,
                spa_username: "_HawgJaws_",
                tags: [15],
                title: "Удаление тумана и бликов",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68287,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Hawgs_FogRemover_24.1.0.zip",
                        version_file_size: 356887
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 4963,
                downloads: 1505593,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/Untitled-1.jpg.302x170_q85_crop_detail_replace_alpha-white.jpg",
                createdAt: "2020-08-29T14:07:57.397748Z",
                updatedAt: "2024-03-14T20:16:22.604058Z",
                game_version: "1.25.0.0",
                mark: 4.2,
                owner_id: 784307,
                spa_username: "Korben",
                tags: [10],
                title: "Модпак KorbenTeam",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68365,
                        version: "4",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/KorbenTeamModpack_1.25.0.0_v4.exe",
                        version_file_size: 23378514
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5131, 
                downloads: 338022,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/logo_Tac_cl5wz8o_cLIkX8N.png.302x170_q85_crop_detail_replace_alpha-white.jpg", 
                createdAt: "2020-11-01T12:57:00.984274Z",
                updatedAt: "2024-03-30T20:16:22.604058Z",
                game_version: "1.25.0.0", 
                mark: 4.7, 
                owner_id: 237525, 
                spa_username: "_HawgJaws_", 
                tags: [17], 
                title: "10 Тактическая миникарта  (позиции для САУ, ПТ, пассивной разведки) ++",

                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // versionFile
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68249,
                        version: "24.1.0",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/Tactical_Maps_24.1.0.zip", // version_file_original_name
                        version_file_size: 66521838 // modSize
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            },
            {
                id: 5912,
                downloads: 127561,
                author_name: null,
                cover_catalog: "https://wgmods.net/media/mod_files/25_bPfRgjH.png.302x170_q85_crop_detail_replace_alpha-white.jpg",
                game_version: "1.25.0.0",
                createdAt: "2022-02-06T21:54:29.046907Z",
                updatedAt: "2024-04-08T20:16:22.604058Z",
                mark: 4.7,
                owner_id: 786771,
                spa_username: "POLIROID_6825084",
                tags: [16],
                title: "Просмотр попаданий",
                versions: [
                    {
                        download_url: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        //game_version: "1.25.0.0",
                        //game_version_id: 177,
                        game_version: {
                            version: "1.25.0.0",
                            id: 64,
                        },
                        id: 68257,
                        version: "1.9.9",
                        version_file_original_name: "https://modp.wgcdn.co/media/mod_files/1.24.1.0_BattleHits_1.9.9.zip",
                        version_file_size: 13853908
                    }
                ],

                isModDownloadAccepted: false,
                isSingleVersionAvailable: false
            }
            ]
        },
    }
}

export default store