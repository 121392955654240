//import { isEmpty, template } from 'lodash';
//import apiUrls from '../apiUrls';

//import { fetchWithSpinner, fetchWrapper } from 'utils/fetch'

export const LOCALE_LOADED_OK = 'LOCALE_LOADED_OK'

export function responseOk(language, messages) {
    return {
        type: LOCALE_LOADED_OK,
        language,
        messages,
    }
}

export const loadLocale = (locale) => {
    return (dispatch) => {
        console.log(locale)
        //const url = template(apiUrls.locale)({ locale })
        /*const fetchedData = fetchWrapper(url)
        const resultPromise = fetchedData.promise
            .then((data) => {
                if (isEmpty(data)) {
                    throw `data for "${locale}" is empty`
                }
                dispatch(responseOk(locale, data))
            })
            .catch(() => {
                console.warn('Failed to load locale data. Using messages without localization') // eslint-disable-line no-console
                dispatch(responseOk(locale, {slug: ''}))
            })
        return fetchWithSpinner(dispatch, resultPromise)*/
    }
}
