import { each, max } from 'lodash';


import * as actions from '../actions/ChangelogHistory';

const getInitialState = () => ({
    items: [],
})

const editChangelogHistoryItem = (changelogHistory, id, position, content, version) => { // id
    const result = []
    each(changelogHistory, (item) => {
        if (item.position === position) { // id
            result.push({ id, position, content, version }) // id
        } else {
            result.push(item)
        }
    })
    return result
}

export const changelogHistory = (state = getInitialState(), action) => {
    switch (action.type) {
        case actions.ADD_CHANGELOG_HISTORY_ITEM:
            return {
                ...state,
                items: [
                    ...state.items,
                    {
                        content: action.payload.content,
                        version: action.payload.version,
                        id: '', // add
                        position: (max(state.items.map((item) => item.position)) || 0) + 1, // id
                    },
                ],
            }
        case actions.UPDATE_CHANGELOG_HISTORY_ITEMS:
            return {
                ...state,
                items: action.payload.items,
            }
        case actions.EDIT_CHANGELOG_HISTORY_ITEM: {
            return {
                ...state,
                items: editChangelogHistoryItem(
                    state.items, action.payload.id, action.payload.position, action.payload.content, action.payload.version), // id
            }
        }
        case actions.REMOVE_CHANGELOG_HISTORY_ITEM:
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload.id), // id
            }
        case actions.SET_CHANGELOG_HISTORY_ORDERING:
            return {
                ...state,
                items: action.payload.orderedItems,
            }
        case actions.CLEAR_CHANGELOG_HISTORY_ITEMS:
            return {
                ...state,
                items: [],
            }
        default:
            return state
    }
}
