import { connect } from 'react-redux';

import { openDialogError } from '../../actions/DialogError';
import { closeDialog } from '../../actions/Dialog';
import { support } from '../../actions/Details';

import DialogHelp from '../../components/Dialogs/DialogHelp/DialogHelp';

const mapStateToProps = (state) => ({
    realm: state.currentAccount.realm,
})

const mapDispatchToProps = (dispatch) => ({
    onShowDialogError: (content) => {
        dispatch(openDialogError(content))
    },
    onSubmitDialog: (data) => {
        dispatch(support(data))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogHelp)
