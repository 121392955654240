import React from "react"

export const MODDER_SECTION_PAGE_TITLE_DEFAULT = (value) => 
{ 
    return <React.Fragment>Мои моды: {value}</React.Fragment>
}

export const MODDER_SECTION_PAGE_TITLE_EMPTY = `Мои моды`;

export const MODDER_SECTION_LOGIN_MESSAGE = `Авторизуйся, чтобы получить доступ к разделу мододела.`;

export const MODDER_SECTION_LOGIN = `Войти`;

export const MODDER_SECTION_FILTER_CAPTION = `Показывать моды со статусом:`;

export const MODDER_SECTION_ADD_MOD_MESSAGE = `Вы ещё не добавили ни одного мода`;

export const MODDER_SECTION_ADD_MOD = `+ Добавить мод`;

export const MODDER_SECTION_ALL_STATE_VALUES = `Все`;

export const messages = `Раздел мододела. Мои моды — Моды для Мир танков`;
