import { batchActions } from 'redux-batched-actions';
import { get } from "lodash";

//import { fetchWrapper as fetch } from "../utils/fetch";
import urls from '../apiUrls';
import { openDialog } from "./Dialog";
import { openDialogError } from './DialogError';
import { processModDetailsData } from "../utils/responseProcessing";
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';

import DialogDownload from '../containers/DialogDownloadWrapper/DialogDownloadWrapper';
import DialogInformation from '../containers/DialogInformationWrapper/DialogInformationWrapper';
import DialogReportModComplete from "../containers/DialogReportMCWrapper/DialogReportMCWrapper";
import DialogSupportComplete from "../containers/DialogSupportCWrapper/DialogSupportCWrapper";
import { 
    ALREADY_SENT_SUPPORT_TICKET_TITLE,
    ALREADY_SENT_SUPPORT_TICKET_DETAILS,
    ALREADY_SENT_REPORT_DETAILS, 
    ALREADY_SENT_REPORT_TITLE, 
    MESSAGE_IS_NOT_SENT, 
    MOD_WAS_DELETED 
} from "../translations";

export const showReportDialogSuccess = () => {
    return (dispatch) => {
        dispatch(openDialog(<DialogReportModComplete />))
    }
}

export const showDialogDownload = (modId, title, versions) => {
    return (dispatch) => {
        dispatch(openDialog(<DialogDownload modId={modId} title={title} versions={versions} />))
    }
}

export const showDialogInformation = (modId, title, versions) => {
    return (dispatch) => {
        dispatch(openDialog(<DialogInformation modId={modId} title={title} versions={versions} />))
    }
}

export const processDownloadMod = (isModDownloadAccepted, modId, title, versions) => {
    return (dispatch) => {
        if (isModDownloadAccepted) {
            dispatch(showDialogDownload(modId, title, versions))
        } else {
            dispatch(showDialogInformation(modId, title, versions))
        }
    }
}

// support panel
export const showSupportDialogSuccess = () => {
    return (dispatch) => {
        dispatch(openDialog(<DialogSupportComplete />))
    }
}

export const SET_ERROR_FETCHING_DETAILS = 'SET_ERROR_FETCHING_DETAILS'
export const SET_REPORT_COMPLETED = 'SET_REPORT_COMPLETED'
export const START_FETCHING_DETAILS = 'START_FETCHING_DETAILS'
export const STOP_FETCHING_DETAILS = 'STOP_FETCHING_DETAILS'
export const UPDATE_DETAILS = 'UPDATE_DETAILS'
export const UPDATE_VOTING = 'UPDATE_VOTING'

// support panel
export const SET_SUPPORT_COMPLETED = 'SET_SUPPORT_COMPLETED'

export const startFetchingDetails = () => ({
    type: START_FETCHING_DETAILS,
})

export const stopFetchingDetails = () => ({
    type: STOP_FETCHING_DETAILS,
})

export const updateDetails = (rawResponseData, state) => ({
    type: UPDATE_DETAILS,
    payload: {
        data: processModDetailsData(rawResponseData, state),
        id: rawResponseData.id, // rawResponseData.data.body.id
    },
})

export const updateVoting = (rawResponseData) => ({
    type: UPDATE_VOTING,
    payload: {
        value: rawResponseData.value,
    },
})

export const setReportCompleted = () => ({
    type: SET_REPORT_COMPLETED,
})

export const setErrorFetchingDetails = (errorData) => ({
    type: SET_ERROR_FETCHING_DETAILS,
    payload: {
        errorData,
    },
})

// support panel
export const setSupportCompleted = () => ({
    type: SET_SUPPORT_COMPLETED,
})


export const fetchDetails = (modId, userName) => {
    return (dispatch, getState) => {
        const state = getState()
        //const url = `${urls.mods}${modId}/`
        const url = `https://tankimods.net${urls.mods}?modId=${modId}/` // /api/mods/${modId}/

        dispatch(startFetchingDetails())

        const fetchPromise = fetch(url, {method: 'POST', body: userName}).promise
            .then((rawResponseData) => {
                if(rawResponseData.status === 'error') {
                    if (rawResponseData.context.msg === 'MODS_NOT_FOUND') {
                        dispatch(batchActions([
                            setErrorFetchingDetails(rawResponseData),
                            stopFetchingDetails(),
                        ], SET_ERROR_FETCHING_DETAILS))
                    } else {
                        dispatch(batchActions([
                            setErrorFetchingDetails(rawResponseData.context),
                            stopFetchingDetails(),
                        ], SET_ERROR_FETCHING_DETAILS))
                    }
                } else {
                    dispatch(batchActions([
                        updateDetails(rawResponseData, state),
                        stopFetchingDetails(),
                    ], UPDATE_DETAILS))
                }
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorFetchingDetails(errorData),
                    stopFetchingDetails(),
                ], SET_ERROR_FETCHING_DETAILS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const voteForMod = (modId, value, userName) => {
    return (dispatch) => {
        const body = {
            mod: modId,
            value: value,
            spaUsername: userName.spaUsername,
            spaId: userName.spaId,
        }

        const url = `https://tankimods.net${urls.votes}/` //  /api/votes/    urls.votes

        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                debugger
                //dispatch(updateVoting(rawResponseData))
            })
        console.log(fetchPromise)
        //return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const reportMod = ({ modId, modVersionId, problemIds, problemDescription, fileId, fileToken, userId }) => {
    return (dispatch, getState) => {
        const state = getState()

        let body = {
            description: problemDescription,
            lang: state.currentAccount.lang,
            mod: modId,
            mod_version: modVersionId,
            problem_types: problemIds,
            user_id: userId,
        }
        
        if (fileId && fileToken) {
            body = {
                ...body,
                temporary_file: fileId,
                temporary_file_access_token: fileToken,
            }
        }

        const url = `https://tankimods.net${urls.reports}` //  /api/reports-groups/reports/    urls.reports

        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    let error = get(rawResponseData, 'context.msg')
                    if (!error) {
                        error = get(rawResponseData, 'context.body')
                    }
                    if (['mod_version_is_not_active', 'mod_is_not_active'].includes(error)) {
                        dispatch(openDialogError(MOD_WAS_DELETED, MESSAGE_IS_NOT_SENT))
                    } else if (error === 'active_report_already_exists_for_this_user') {
                        dispatch(openDialogError(ALREADY_SENT_REPORT_DETAILS, ALREADY_SENT_REPORT_TITLE))
                    } else {
                        dispatch(openDialogError())
                    }
                } else {
                    dispatch(setReportCompleted())
                    dispatch(showReportDialogSuccess())
                }
            }, (errorData) => {
                let error = get(errorData, 'context.body.mod[0]')
                if (!error) {
                    error = get(errorData, 'context.body.mod_version[0]')
                }
                if (['mod_version_is_not_active', 'mod_is_not_active'].includes(error)) {
                    dispatch(openDialogError(MOD_WAS_DELETED, MESSAGE_IS_NOT_SENT))
                } else if (error === 'active_report_already_exists_for_this_user') {
                    dispatch(openDialogError(ALREADY_SENT_REPORT_DETAILS, ALREADY_SENT_REPORT_TITLE))
                } else {
                    dispatch(openDialogError())
                }
            })
        console.log(fetchPromise)
        //return fetchWithSpinner(dispatch, fetchPromise)
    }
}

// if mod dowloaded - set +1 count in bd `downloads`
export const incrementDownloadCount = (modId) => {
    return () => {
        const url = `https://tankimods.net${urls.downloadCounter}?modId=${modId}` //urls.downloadCounter.replace(/:(modId)/g, modId) // /api/mods/download/mod/:modid/
        return fetch(url).promise
            .then((rawResponseData) => {
                if(rawResponseData.status === 'error') {
                    console.log(rawResponseData);
                }
            })
            .catch((errorData) => {
                console.log(errorData);
            })
    }
}


// support panel
export const support = ({ problemIds, problemDescription, fileId, fileToken, userId }) => {
    return (dispatch, getState) => {
        const state = getState()

        let body = {
            description: problemDescription,
            lang: state.currentAccount.lang,
            problem_types: problemIds,
            user_id: userId,
        }
        
        if (fileId && fileToken) {
            body = {
                ...body,
                temporary_file: fileId,
                temporary_file_access_token: fileToken,
            }
        }

        const url = `https://tankimods.net${urls.support}` //  /api/support-groups/support/    urls.support

        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    let error = get(rawResponseData, 'context.msg')
                    if (!error) {
                        error = get(rawResponseData, 'context.body')
                    }
                    if (error === 'time_limit_refresh_left_sixty_seconds') {
                        dispatch(openDialogError(ALREADY_SENT_SUPPORT_TICKET_DETAILS, ALREADY_SENT_SUPPORT_TICKET_TITLE))
                    } else {
                        dispatch(openDialogError())
                    }
                } else {
                    dispatch(setSupportCompleted())
                    dispatch(showSupportDialogSuccess())
                }
            }, (errorData) => {
                let error = get(errorData, 'context.body.mod[0]')
                if (!error) {
                    error = get(errorData, 'context.body.mod_version[0]')
                }
                if (['mod_version_is_not_active', 'mod_is_not_active'].includes(error)) {
                    dispatch(openDialogError(MOD_WAS_DELETED, MESSAGE_IS_NOT_SENT))
                } else if (error === 'active_report_already_exists_for_this_user') {
                    dispatch(openDialogError(ALREADY_SENT_REPORT_DETAILS, ALREADY_SENT_REPORT_TITLE))
                } else {
                    dispatch(openDialogError())
                }
            })
        console.log(fetchPromise)
        //return fetchWithSpinner(dispatch, fetchPromise)
    }
}