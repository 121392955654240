import React from 'react';
import PropTypes from 'prop-types';

import {
    MOD_STATE,
} from '../../../utils/constants';

import ActionCancelMod from '../../ActionCancelMod/ActionCancelMod';
import ActionHiddenMod from '../../ActionHiddenMod/ActionHiddenMod';
import ActionVisibleMod from '../../ActionVisibleMod/ActionVisibleMod';
import Caption from '../../Caption/Caption';
import ModState from '../../ModState/ModState';

import {
    EDIT_STATE_LABEL,
} from './translations';

import styles from './EditState.module.css';
import Select from '../../Select/Select';
import { getUserRole } from '../../../utils/functions';

export default class EditState extends React.PureComponent {
    static propTypes = {
        isHidden: PropTypes.bool.isRequired,
        state: PropTypes.number.isRequired,
        onModVisibilityChange: PropTypes.func.isRequired,
        onUpdateCancel: PropTypes.func.isRequired,
    }

    handleModVisibilitySet = () => {
        this.props.onModVisibilityChange(true)
    }

    handleModVisibilityClear = () => {
        this.props.onModVisibilityChange(false)
    }

    renderActionVisibility() {
        if ([MOD_STATE.REJECTED, MOD_STATE.DRAFT, MOD_STATE.IN_REVIEW].includes(this.props.state)) {
            return null
        }

        return this.props.isHidden ? (
            <div className={styles.action}>
                <ActionVisibleMod onClick={this.handleModVisibilitySet} />
            </div>
        ) : (
            <div className={styles.action}>
                <ActionHiddenMod onClick={this.handleModVisibilityClear} />
            </div>
        )
    }

    renderActionCancelUpdate() {
        return [MOD_STATE.IN_REVIEW, MOD_STATE.PUBLISHED_UPDATE_IN_REVIEW].includes(this.props.state) ? (
            <div className={styles.action}>
                <ActionCancelMod onClick={this.props.onUpdateCancel} />
            </div>
        ) : null
    }

    handleStateModChange = () => {
        return
    }

    renderFilterSelect = () => {
        const modStateItems = [
            {value: 0, caption: "Черновик"},
            {value: 1, caption: "Проверяется"},
            {value: 2, caption: "Отклонено"},
            {value: 3, caption: "Опубликовано"},
            {value: 4, caption: "Обновление проверяется"},
            {value: 5, caption: "Обновление отклонено"},
            {value: 6, caption: "Скрыт"}
        ];
        /*
        .map((item) => ({
                            value: item.id,
                            caption: item.lang,
                        }))
                            */

        return (
            <div className={styles.fieldSelect}>
                <div className={styles.selectSelect} id={this.props.state} >
                    <Select
                        items={modStateItems}
                        selectedValue={this.props.state} 
                        onItemChange={this.handleStateModChange}
                    />
                </div>
            </div>
        )
    }

    renderActions() {
        return getUserRole() === 'adminer' ? this.renderFilterSelect() 
            : <>
            {this.renderActionVisibility()}
            {this.renderActionCancelUpdate()}
            </>
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge>
                        {EDIT_STATE_LABEL()}<ModState isHidden={this.props.isHidden} state={this.props.state} />
                        {/* {EDIT_STATE_LABEL(<ModState isHidden={this.props.isHidden} state={this.props.state} />)} */}
                    </Caption>
                </div>
                <div className={styles.field}>
                    {this.renderActions()}
                </div>
            </div>
        )
    }
}
