import { connect } from 'react-redux';
import { keys } from 'lodash';

import Categories from '../../components/Categories/Categories';

const mapStateToProps = (state) => {
    return {
        tags: keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title })),
        checkedTags: [],
        isAllTagChecked: true,
    }
}

export default connect(mapStateToProps)(Categories)
