import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getDisplayTimeBySeconds, sliceStringToSymbol } from "../../../../utils/formatting";

import ProgressRing from "../../../ProgressRing/ProgressRing";

import styles from './AudioPlayer.module.css';

class AudioPlayer extends Component {
    static propTypes = {
		timeFormat: PropTypes.string,
        title: PropTypes.string,
        src: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
        })),
    }

    constructor(props) {
        super(props)

        this.state = {
            // Get audio file in a variable
            audio: new Audio(props.src[0].url), // props.src[Math.floor(Math.random() * props.src.length)].url
            // Set initial state of song
		    isPlaying: false,
			// other parameters
            isError: false,
            progress: 0,
			currentTime: '',
			duration: '',
        }

		this.hasAddedAudioEventListener = false;
    }

	componentDidMount() {
		if (!this.state.audio) {
			this.setState({ isError: true });
		}
		this.addAudioEventListeners();
		this.state.audio.addEventListener('ended', () => this.setState({ isPlaying: false, progress: 0 }));
	}

	componentDidUpdate() {
		this.addAudioEventListeners();
	}
	  
	componentWillUnmount() {
		if (this.state && this.hasAddedAudioEventListener) {
			// currentTime
			this.audio.removeEventListener('timeupdate', this.handleAudioCurrentTimeChange)
			this.audio.removeEventListener('loadedmetadata', this.handleAudioCurrentTimeChange)
			// duration
			this.audio.removeEventListener('durationchange', this.handleAudioDurationChange)
			this.audio.removeEventListener('abort', this.handleAudioDurationChange)
			// progress
			this.audio.removeEventListener('timeupdate', this.handleAudioTimeUpdate)
		}
		this.state.audio.removeEventListener('ended', () => this.setState({ isPlaying: false, progress: 0 }));
	}

	handleAudioCurrentTimeChange = () => {
		const audio = this.state.audio
		const isLeftTime = false; // parameter False set Time from 0 to current -> True set Time from current to 0
		const timeFormat = this.props.timeFormat;
		const defaultCurrentTime = '--:--'
		//const { isLeftTime, timeFormat, defaultCurrentTime } = this.props
		
		this.setState({
		  currentTime:
		  	getDisplayTimeBySeconds(
				isLeftTime ? audio.duration - audio.currentTime : audio.currentTime,
				audio.duration,
				timeFormat
			) || defaultCurrentTime,
		})
	}

	handleAudioDurationChange = () => {
		const audio = this.state.audio
		const timeFormat = this.props.timeFormat;
		const defaultDuration = ''

		this.setState({
			duration: getDisplayTimeBySeconds(audio.duration, audio.duration, timeFormat) || defaultDuration,
		})
	}

	handleAudioTimeUpdate = () => {
		const audio = this.state.audio
		let currentProgress = 0

		currentProgress = (audio.currentTime / audio.duration) * 100 || 0 // Math.trunc((audio.currentTime * 100) / audio.duration);

		this.setState({
			progress: currentProgress // `${((audio.currentTime / audio.duration) * 100 || 0).toFixed(2)}`,
		})
	}

	addAudioEventListeners = () => {
		const { audio } = this.state
		if (audio && !this.hasAddedAudioEventListener) {
			this.audio = audio
			this.hasAddedAudioEventListener = true
			audio.addEventListener('timeupdate', this.handleAudioCurrentTimeChange)
			audio.addEventListener('loadedmetadata', this.handleAudioCurrentTimeChange)
			// duration
			audio.addEventListener('durationchange', this.handleAudioDurationChange)
			audio.addEventListener('abort', this.handleAudioDurationChange)
			// progress
			audio.addEventListener('timeupdate', this.handleAudioTimeUpdate)
		}
	}

	// Main function to handle both play and pause operations
	playPause = () => {
		// Get state of song
		let isPlaying = this.state.isPlaying;
		if (isPlaying) {
			// Pause the song if it is playing
			this.state.audio.pause();
		} else {
			// Play the song if it is paused
			this.state.audio.play();
		}
		// Change the state of song
		this.setState({ isPlaying: !isPlaying });
	};

	renderPlayerBody() {
		// узнать максимальное значение времени для аудио, чтобы рисовать правильно тракБар - прогресс проигрывания аудио
		
		let title
		// if length of title audio > 27 symbols -> slice this title to 2 part and in middle set `...`
		if (this.props.title && this.props.title.length > 27) {
			// in this function second parameter is number of symbols in start and the end
			title = sliceStringToSymbol(this.props.title, 12);
		} else {
			title = this.props.title
		}

        return (
            <div>
                <div className={styles.title} onClick={this.playPause}>
                    <div className={styles.icon}>
						<div className={styles.progressRing}>
							<ProgressRing
                                color="#3469d1" // #f9b723 #0051ff   #7aa4ff
                                radius={29}
                                stroke={3}
                                progress={this.state.progress} 
                            />
                        </div>
					</div>
                    {title}
                </div>
                <div className={styles.caption}>
					{this.state.currentTime} / {this.state.duration}
                </div>
            </div>
        )
    }

	render() {
		const classNameBase = classNames(styles.base, {
            [styles.isError]: this.state.isError,
            //"FileUpload_isUploading": this.state.isUploading,
            [styles.isUploaded]: this.state.isPlaying,
            //"FileUpload_isUpdate": this.state.isUpdate,
        })

		return (
			<div className={classNameBase}>
				{this.renderPlayerBody()}
			</div>
		);
	}
}

export default AudioPlayer;
