import React, { Component } from 'react';
import PropTypes from 'prop-types'

import NewsContent from '../NewsContent/NewsContent';
import ButtonBlueOutlineLarge from '../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';
import Paginator from '../Paginator/Paginator';
import Error from '../Error/Error';
import { LOAD_MORE } from '../../translations';

import './NewsBlock.css';


export default class NewsBlock extends Component {
  static propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    isError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFetchedAll: PropTypes.bool.isRequired,
    //offset: PropTypes.number.isRequired,
    
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      img: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      changeLog: PropTypes.string,
      category: PropTypes.number.isRequired,
    })).isRequired,

    fetchNews: PropTypes.func.isRequired,
    fetchNewsItemsMore: PropTypes.func.isRequired,
  }
  
  componentDidMount() {
    this.props.fetchNews();
  }
  
  handleLoadMoreClick() {
    return false;
  }
  
  renderItems() {
    return this.props.items.length === 0 && !this.props.isFetching ? (
        <Error>Новостей пока нет</Error>
    ) : (
      <div className="News_block">{this.props.items.map((item) => this.renderItem(item))}</div>
    )
  }
  renderItem(item) {
    return (
      <div className="News_contentBlock" key={`news-tag-${item.id}`}>
        <NewsContent 
          id={item.id}
          title={item.title}
          content={item.content}
          publishedAt={item.publishedAt}
          img={item.img}
          url={item.url}
          changeLog={item.changeLog}
          category={item.category}
        />
      </div>
    )
  }

  handleLoadMoreNewsClick = () => {
    this.props.fetchNewsItemsMore('news')
  }

  renderLoadMoreButton() {
    // if (this.props.filtered.isFetchedAll || this.props.filtered.items.length === 0) {
    if (this.props.items.length === 0 || this.props.isFetchedAll) {
        return null
    }
    return (
      <div className="News_button">
        <div className="ev_click-load-more"></div>
        <ButtonBlueOutlineLarge
          onClick={this.handleLoadMoreNewsClick}
          gtmTag={'ev_click-load-more'}
        >
          {LOAD_MORE}
        </ButtonBlueOutlineLarge>
      </div>
    )
  }
  renderPaginator() {
    return (
      <div style={{textAlign: "center", padding: "20px 0 20px 0"}}>
        <Paginator
          pages={7}
          activePage={1}
          onPageChange={()=>{}}
        />
      </div>
    )
  }
  renderMore() {
    if ( this.props.items.length === 0 && !this.props.isFetchedAll) {
      return false;
    } else {
      return this.renderLoadMoreButton()
    }
    // } else if ( this.props.items.length >= 8 && this.props.items.length < 16 ) { // 8 and 16
    //   return this.renderLoadMoreButton()
    // } else if ( this.props.items.length >= 16 ) {
    //   return this.renderPaginator()
    // } 
  }
  
  render() {
    return (
      <div className="News_base">
        <section className="News_section">
          <div className="News_filteredBody">
            <div className="News_inner">
            {this.renderItems()}
            {this.renderMore()}
            </div>
          </div>
        </section>
      </div>
    )
  }
}
