import { connect } from 'react-redux';

import { changeAdminResultItemsOrdering, fetchItemsAdminPanel } from '../../actions/AdminPanel';

import AdminPanel from '../../components/AdminPanel/AdminPanel';

const mapStateToProps = (state) => {
    const isLoggedIn = !!state.currentAccount.spaId;
    return ({
        isLoggedIn: isLoggedIn,
        spaId: state.currentAccount.spaId,
        spaUsername: state.currentAccount.spaUsername,

        isFetching: state.adminPanel.isFetching,
        isError: state.adminPanel.isError,
        filtered: state.adminPanel.filtered,
    })
}

const mapDispatchToProps = (dispatch) => ({
    changeItemsType: (filterParams) => {
        dispatch(changeAdminResultItemsOrdering(filterParams.type))
        dispatch(fetchItemsAdminPanel(filterParams))
    },
    onAuthAdmin() {
        //dispatch()
    },
    fetchItemsAdminPanel: (filterParams) => {
        //dispatch(resetSearchResultItems())
        dispatch(fetchItemsAdminPanel(filterParams))
    },
    onIncrementDownloadCount : () => {
        //
    },
    onPublished : () => {
        //
    },
    onRejected : () => {
        //
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel)
