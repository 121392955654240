import { words } from 'lodash';
import { HTML_MEDIA_ITEM_LENGHT } from './constants';

import mapKeysDeep from './mapKeysDeep';


const snakeToCamel = (string) => (
    string.replace(/(_[a-z])/g, (item) => item.toUpperCase().replace('_', ''))
)

export const snakeFieldsToCamel = (obj) => (
    mapKeysDeep(obj, (value, key) => snakeToCamel(key))
)

export const getStringLengthFromHtml = (htmlData) => {
    let length = 0
    const mediaCount = words(htmlData, '<figure').length
    const dom = document.createElement('div')
    dom.innerHTML = htmlData

    if (dom.innerText) {
        length += dom.innerText.trim().length
    }

    if (mediaCount > 0) {
        length += mediaCount * HTML_MEDIA_ITEM_LENGHT
    }

    return length
}