import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import apiUrls from '../../../apiUrls';
import { FIELD_VALIDATION_ERROR_TYPES } from '../../../utils/constants';
//import { urls } from '../../../utils/routing';

import Alert from '../../Alert/Alert';
import Caption from '../../Caption/Caption';
import EditLabelError from '../EditLabelError/EditLabelError';
import FileUploadImage from '../../FileUploadImage/FileUploadImage';
import PreviewRemove from '../PreviewRemove/PreviewRemove';

import {
    EDIT_COVER_UPLOAD_ALERT,
    EDIT_COVER_UPLOAD_FILE_FORMAT,
    EDIT_COVER_UPLOAD_FILE_DIMENSIONS,
    EDIT_COVER_UPLOAD_FILE_SIZE,
    EDIT_COVER_UPLOAD_LABEL,
} from './translations';

import {
    EDIT_UPLOAD_COMMON_ERROR,
    EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR,
    EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
    EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
} from '../../../translations';

import styles from './EditCoverUpload.module.css';


export default class EditCoverUpload extends React.PureComponent {
    static propTypes = {
        externalError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR,
            FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR,
            FIELD_VALIDATION_ERROR_TYPES.NO_FILE_SELECTED,
        ]),
        externalImageSource: PropTypes.string,

        onUploadCompleted: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            errorType: null,
            imageSource: null,
        }
    }

    componentDidMount() {
        if (this.props.externalImageSource) {
            this.setState({
                imageSource: this.props.externalImageSource,
            })
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.externalError) {
            this.setState({
                errorType: nextProps.externalError,
            })
        }
    }

    handleUploadCompleted = (data, errorType) => {
        this.setState({
            errorType: errorType || null,
            imageSource: get(data, '[0].source', null),
        })
        this.props.onUploadCompleted(get(data, '[0]', null), errorType)
    }

    handleRemoveImageClick = () => {
        this.setState({
            imageSource: null,
        })
        this.props.onUploadCompleted(null, null)
    }

    renderErrorLabel() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR]: EDIT_UPLOAD_COMMON_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR]: EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR]: EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.NOT_SUPPORTED_IMAGE_DIMENSIONS]: EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR,
        }
        return this.state.errorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.errorType]} />
        ) : null
    }

    renderFileUpload() {
        if (this.state.imageSource) {
            return
        }

        const isErrorFileFormat = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        const isErrorFileSize = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR
        const isErrorDimensions = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.NOT_SUPPORTED_IMAGE_DIMENSIONS

        const url = `https://tankimods.net${apiUrls.modCoverUpload}`// apiUrls.modCoverUpload

        return (
            <FileUploadImage
                url={url}
                fieldName="source"
                summary={(
                    <span>
                        <span className={styles.fileUploadSummary}>
                            {EDIT_COVER_UPLOAD_FILE_FORMAT(isErrorFileFormat)}
                        </span>
                        <span className={styles.fileUploadSummary}>
                            {EDIT_COVER_UPLOAD_FILE_DIMENSIONS(isErrorDimensions)}
                        </span>
                        <span className={styles.fileUploadSummary}>
                            {EDIT_COVER_UPLOAD_FILE_SIZE(isErrorFileSize)}
                        </span>
                    </span>
                )}
                isError={!!this.props.externalError}
                onUploadCompleted={this.handleUploadCompleted}
            />
        )
    }

    renderCover() {
        let reviewPage = false;
        // render of standart image if uesr not download yours
        if (!this.state.imageSource && window.location.pathname.includes('/review/')) {
            this.setState({imageSource: `https://tankimods.net/media/static/cover.jpg`}); // `${urls.media}static/cover.jpg`
        }

        if (this.state.imageSource === `https://tankimods.net/media/static/cover.jpg`) {
            reviewPage = true;
        }

        if (!this.state.imageSource) {
            return
        }

        return (
            <div className={styles.cover}>
                <img className={styles.image} src={this.state.imageSource} alt={styles.image} />
                <div className={styles.previewRemove}>
                    {reviewPage ? null : <PreviewRemove onClick={this.handleRemoveImageClick} />}
                </div>
            </div>
        )
    }

    renderAlert() {
        return this.state.imageSource ? (
            <div className={styles.alert}>
                <Alert content={EDIT_COVER_UPLOAD_ALERT} />
            </div>
        ) : null
    }

    render() {
        let cover_label = EDIT_COVER_UPLOAD_LABEL;
        if (window.location.pathname.includes('/review/')) {
            cover_label = 'Обложка';
        }
        
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge isError={!!this.props.externalError}>
                        {cover_label}
                    </Caption>
                    {this.renderErrorLabel()}
                    {this.renderAlert()}
                </div>
                <div className={styles.field}>
                    {window.location.pathname.includes('/review/') ? null : this.renderFileUpload()}
                    {this.renderCover()}
                </div>
            </div>
        )
    }
}
