//import React from 'react';
import { connect } from 'react-redux';

import { openDialog } from '../../actions/Dialog';
import { fetchItemsModderSection } from '../../actions/SectionModder';
//import { DialogLogin, DialogConfirmation } from 'containers'
import DialogLogin from '../../components/Dialogs/DialogLogin/DialogLogin';
import SectionModder from '../../components/SectionModder/SectionModder';
import { checkAccData } from '../../utils/functions';


const mapStateToProps = (state) => {
  const isLoggedIn = !!state.currentAccount.spaId && !state.sectionModder.isErrorForbidden
  return ({
    isRulesAccepted: state.currentAccount.isRulesAccepted,
    isLoggedIn: isLoggedIn,
    isFetching: state.sectionModder.isFetching,
    items: state.sectionModder.items,
    isBlocked: isLoggedIn && state.currentAccount.isBanned,
    isDisabled: isLoggedIn && !state.currentAccount.isUserStatisticsSufficient,
    isError: state.sectionModder.isError,
  })
}

const mapDispatchToProps = (dispatch) => {
  return ({
    onShowLoginDialog: () => {
      dispatch( checkAccData(openDialog(<DialogLogin />)) )
    },
    onShowRulesDialog: () => {
      //dispatch(openDialog(<DialogConfirmation />, true))
    },
    fetchItems: (userData) => {
      dispatch(fetchItemsModderSection(userData))
    },
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionModder)
