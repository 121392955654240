import { batchActions } from 'redux-batched-actions';

import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import { processAdminPanelItems, processGetUserData } from '../utils/responseProcessing';
import {
    SEARCH_RESULT_ITEMS_PER_PAGE,
    ERROR_USER_NOT_FOUND,
} from '../utils/constants';
//import urls from '../apiUrls';

import { closeDialog, openDialog } from "./Dialog";
//import { openDialogError } from './DialogError';
import DialogSupportComplete from "../containers/DialogSupportCWrapper/DialogSupportCWrapper";
import { openDialogError } from './DialogError';
import { get } from 'lodash';


export const CHANGE_ADMIN_RESULT_ITEMS_ORDERING = 'CHANGE_ADMIN_RESULT_ITEMS_ORDERING'
export const START_FETCHING_ADMIN_PANEL = 'START_FETCHING_ADMIN_PANEL';
export const SET_ERROR_FETCHING_ADMIN_PANEL = 'SET_ERROR_FETCHING_ADMIN_PANEL';
export const STOP_FETCHING_ADMIN_PANEL = 'STOP_FETCHING_ADMIN_PANEL';
export const INCREMENT_GETTING_OFFSET = 'INCREMENT_GETTING_OFFSET'
export const UPDATE_ITEMS_ADMIN_PANEL = 'UPDATE_ITEMS_ADMIN_PANEL';
export const SET_IS_FORBIDDEN = 'SET_IS_FORBIDDEN';

export const UPDATE_GET_USER_DATA = 'UPDATE_GET_USER_DATA';

export const startFetchingAdminPanel = () => ({
    type: START_FETCHING_ADMIN_PANEL,
})

export const stopFetchingAdminPanel = () => ({
    type: STOP_FETCHING_ADMIN_PANEL,
})

export const incrementSGettingOffset = () => ({
    type: INCREMENT_GETTING_OFFSET,
})

export const updateItemsAdminPanel = (rawResponseData, state) => ({
    type: UPDATE_ITEMS_ADMIN_PANEL,
    payload: {
        items: processAdminPanelItems(rawResponseData, state),
        count: rawResponseData.count,
        draft: rawResponseData.draft,
        review: rawResponseData.review,
        published: rawResponseData.published,
        rejected: rawResponseData.rejected,
    },
})

// get user data
export const updateGetUserData = (rawResponseData, state) => ({
    type: UPDATE_GET_USER_DATA,
    payload: {
        data: processGetUserData(rawResponseData, state),
        owner_id: rawResponseData.spa_id,
    },
})

export const showChangeUserDataDialogSuccess = () => {
    return (dispatch) => {
        dispatch(openDialog(<DialogSupportComplete />))
    }
}

export const setErrorFetchingAdmin = (errorData) => ({
    type: SET_ERROR_FETCHING_ADMIN_PANEL,
    payload: {
        errorData,
    },
})

export const setIsForbidden = () => ({
    type: SET_IS_FORBIDDEN,
})

export const changeAdminResultItemsOrdering = (type) => ({
    type: CHANGE_ADMIN_RESULT_ITEMS_ORDERING,
    payload: {
        type,
    },
})

const prepareQueryParamsForGetting = (filterParams, state) => {
    const { type } = filterParams
    let query
    
    query = {
        limit: SEARCH_RESULT_ITEMS_PER_PAGE,
        offset: state.adminPanel.filtered.offset,
        
        query: type, // state.adminPanel.query,
    }
    
    return query
}

export const fetchItemsAdminPanel = (filterParams) => {
    return (dispatch, getState) => {
        const state = getState()
        const url = `https://tankimods.net/api/users/adminpanel/` //urls.modsOwner

        dispatch(startFetchingAdminPanel())

        const query = prepareQueryParamsForGetting(filterParams, state)
        const fetchPromise = fetch(url, { query }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorFetchingAdmin(rawResponseData.context),
                        stopFetchingAdminPanel(),
                    ], SET_ERROR_FETCHING_ADMIN_PANEL))
                } else {
                    dispatch(batchActions([
                        updateItemsAdminPanel(rawResponseData.body, state),
                        stopFetchingAdminPanel(),
                    ], UPDATE_ITEMS_ADMIN_PANEL))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingAdmin(errorData),
                    stopFetchingAdminPanel(),
                ], SET_ERROR_FETCHING_ADMIN_PANEL))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}


// user data
export const fetchUserData = (ownerId) => {
    return (dispatch, getState) => {
        const state = getState()

        let body = {
            owner_id: ownerId,
            lang: state.currentAccount.lang,
        }

        const url = `https://tankimods.net/api/users/` //urls.modsOwner
        
        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    let error = get(rawResponseData, 'context.msg')
                    // if response not consist from context msg
                    if (!error) {
                        error = get(rawResponseData, 'context.body')
                    }
                    if (['user_not_found_in_bd', 'INVALID_DATA'].includes(error)) {
                        dispatch(closeDialog())
                        dispatch(openDialogError(ERROR_USER_NOT_FOUND(error)))
                    } else {
                        dispatch(closeDialog())
                        dispatch(openDialogError('Код ошибки: '+error))
                    }
                } else {
                    dispatch(updateGetUserData(rawResponseData, state))
                }
            }, (errorData) => {
                dispatch(closeDialog())
                dispatch(openDialogError('Код ошибки: '+errorData))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const updUserData = ({userId, adminId, typeIds, roExpires}) => {
    return (dispatch, getState) => {
        const state = getState()

        let body = {
            user_type: typeIds,
            lang: state.currentAccount.lang,
            admin_id: adminId,
            user_id: userId,
        }
        
        if (roExpires) {
            body = {
                ...body,
                ro_expires: roExpires,
            }
        }
        
        const url = `https://tankimods.net/api/users/` //urls.modsOwner

        const fetchPromise = fetch(url, { method: 'PUT', body }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    let error = get(rawResponseData, 'context.msg')
                    // if response not consist from context msg
                    if (!error) {
                        error = get(rawResponseData, 'context.body')
                    }
                    if (['user_not_found_in_bd', 'INVALID_DATA'].includes(error)) {
                        dispatch(openDialogError(ERROR_USER_NOT_FOUND(error)))
                    } else {
                        dispatch(openDialogError('Код ошибки: '+error))
                    }
                } else {
                    dispatch(openDialog('Данные пользователя обновлены!'))
                }
            }, (errorData) => {
                dispatch(openDialogError('Код ошибки: '+errorData))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}