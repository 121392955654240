import { connect } from 'react-redux';
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
//import { withRouter } from 'react-router-dom';

import { closeDialog } from '../../actions/Dialog';

import Dialog from '../../components/Dialogs/Dialog/Dialog';

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let all = []
        all = {location, navigate, params}
        return (
        <Component
            {...props}
            {...all}
        />
        );
    }

    return ComponentWithRouterProp;
}

const mapStateToProps = (state) => ({
    content: state.dialog.content,
    isNavigatingToLandingOnClose: state.dialog.isNavigatingToLandingOnClose,
    isOpened: state.dialog.isOpened,
})

const mapDispatchToProps = {
    onCloseDialog: closeDialog,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dialog))
