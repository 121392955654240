import React from 'react';
import PropTypes from 'prop-types';
import { Sticky } from 'react-sticky';

import { BREAKPOINT } from '../../../utils/constants';

import styles from './EditNotifications.module.css'

const NOTIFICATION_STICKY_OFFSET = 40

export default class EditNotifications extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    constructor() {
        super()

        this.state = {
            windowWidth: null,
        }
    }

    componentDidMount() {
        this.updateDimension()

        window.addEventListener('resize', this.updateDimension)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimension)
    }

    updateDimension = () => {
        const windowWidth = window.innerWidth

        if (windowWidth !== this.state.windowWidth) {
            this.setState({ windowWidth })
        }
    }

    render() {
        if (this.state.windowWidth < BREAKPOINT) {
            return (
                <div className={styles.base}>
                    {this.props.children}
                </div>
            )
        }

        if (this.state.windowWidth >= BREAKPOINT) {
            return (
                <div className={styles.base}>
                    <Sticky topOffset={-NOTIFICATION_STICKY_OFFSET}>
                        {({ style, isSticky }) => (
                            <div style={{ ...style, paddingTop: isSticky ? NOTIFICATION_STICKY_OFFSET : 0 }}>
                                {this.props.children}
                            </div>
                        )}
                    </Sticky>
                </div>
            )
        }
    }
}
