import React, { Component } from 'react';

import { GO_TO_LANDING } from '../../translations';
import { urls } from '../../utils/routing';

import Main from '../Main/Main';
import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';

import {
  UNAVAILABLE_SECTION,
  UNAVAILABLE_INFORMATION,
  UNAVAILABLE_PORTAL,
  UNAVAILABLE_PORTAL_INFORMATION
} from './translations';

import styles from './UnavailablePage.module.css';


export default class UnavailablePage extends Component {
  componentDidMount() {
    if (!window.__URLS__.pages.portal) {
      document.title = 'Технические работы. TANKIMODS.NET';
    }
  }

  handleClick() {
    window.location.href = urls.landing
  }
  
  render() {
    if (!window.__URLS__.pages.portal) {
      return (
        <Main>
          <div className={styles.main}>
              <div className={styles.body}>
                  <h1 className={styles.heading}>{UNAVAILABLE_PORTAL}</h1>
                  <p className={styles.text}>{UNAVAILABLE_PORTAL_INFORMATION}
                      {/* <span id="without_history">Перейдите <a href={urls.landing} className="text_link">на главную</a>.</span> */}
                  </p>
              </div>
          </div>
        </Main>
      ) 
    } else if (window.location.pathname === '/' && !window.__URLS__.pages.landing) {
      return (
        <Main>
          <div className={styles.main}>
              <div className={styles.body}>
                  <h1 className={styles.heading}>{UNAVAILABLE_SECTION}</h1>
                  <p className={styles.text}>{UNAVAILABLE_INFORMATION}
                      {/* <span id="without_history">Перейдите <a href={urls.landing} className="text_link">на главную</a>.</span> */}
                  </p>
              </div>
          </div>
        </Main>
      ) 
    } else {
      return (
        <Main>
          <div className={styles.main}>
              <div className={styles.body}>
                  <h1 className={styles.heading}>{UNAVAILABLE_SECTION}</h1>
                  <p className={styles.text}>{UNAVAILABLE_INFORMATION}
                      {/* <span id="without_history">Перейдите <a href={urls.landing} className="text_link">на главную</a>.</span> */}
                  </p>
                  <ButtonBlueLarge onClick={this.handleClick}>{GO_TO_LANDING}</ButtonBlueLarge>
              </div>
          </div>
        </Main>
      ) 
    }
  }
}
