import React from "react"
import { Link } from "react-router-dom"
import { urls } from "../../utils/routing"

export const DETAILS_TAGS_TITLE = "Категории:"

export const DETAILS_AUTHENTICATION_FAILS_FOR_ACTIONS = (onClick) => {
    return (
        <React.Fragment>
            <Link href="/" onClick={onClick}>Войдите</Link>, чтобы подписаться на обновления или сообщить о проблеме.
        </React.Fragment>
    )
}

export const DETAILS_AUTHENTICATION_FAILS_FOR_COMMENTS = (onClick) => {
    return (
        <React.Fragment>
            <Link href="/" onClick={onClick}>Войдите</Link>, чтобы оставить комментарий.
        </React.Fragment>
    )
}

export const DETAILS_AUTHENTICATED_INFO_FOR_COMMENTS = () => {
    return (
        <React.Fragment>
            В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с <Link href={urls.eula}>Правилами сайта</Link>
        </React.Fragment>
    )
}

export const DETAILS_AUTHORED_BY = "Автор:"

export const DETAILS_UPLOADED_BY = "Загрузил:"

export const DETAILS_DESCRIPTION_TITLE = "Описание мода"

export const DETAILS_DESCRIPTION_LANGUAGE_SELECT_CAPTION = "Язык описания:"

export const DETAILS_INSTALLATION_GUIDE_TITLE = "Установка мода" // "Инструкция по установке"

export const DETAILS_CHANGELOG_TITLE = "История изменений"

export const DETAILS_CHANGELOG_VERSION = (version) => {
    return <React.Fragment>Версия {version}</React.Fragment>
}

export const DETAILS_COMMENTS_LANGUAGE_SELECT_CAPTION = "Язык комментариев:"

export const DETAILS_BUTTON_LOAD_CAPTION = "Больше сообщений"

export const DETAILS_COMMENTS_TITLE = "Комментарии:"

export const messages = (modTitle) => {
    //return <React.Fragment>{modTitle} — Моды для Мир танков</React.Fragment>
    return `${modTitle} — Моды для Мир танков`
}

export const notOnlyDigits = (v) => !(/^\d+$/.test(v))