import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Input.module.css';

export default class Input extends React.PureComponent {
    static propTypes = {
        placeholder: PropTypes.node,
        value: PropTypes.string,
        type: PropTypes.string,
        isError: PropTypes.bool,
        isFocusOn: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            isPlaceholderVisible: true,
            value: '',
        }
    }

    componentDidMount() {
        if (this.props.isFocusOn) {
            this._input.focus()
        }
    }

    setFocused = (isFocused) => {
        this.setState({
            isPlaceholderVisible: !isFocused && !this.props.value,
        })
    }

    handleChange = (event) => {
        // check input write "read only"
        if (this.props.type && this.props.type === "number") {
            let value = event.target.value
            value = value.replace(/[^0-9]/g, '');

            if (value.length && (value.length >= 2 && Number(value.charAt(0)) === 0)) {
                value = "0";
            }

            if (Number(value) < 0) {
                value = "0";
            } else if (Number(value) > 5000) {
                value = "5000";
            }

            if (value !== this.state.value) {
                this.setState({ value })
                this.props.onChange(value)
            }
        } else {
            const value = event.target.value

            if (value !== this.state.value) {
                this.setState({ value })
                this.props.onChange(value)
            }
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter' && this.props.onSubmit && this.state.value) {
            this.props.onSubmit(this.state.value)
        }
    }

    render() {
        const classNamePlaceholder = classNames(styles.placeholder, {
            [styles.isVisible]: this.state.isPlaceholderVisible,
        })

        const classNameBase = classNames(styles.base, {
            [styles.isError]: this.props.isError,
        })

        return (
            <div className={classNameBase}>
                <div className={classNamePlaceholder}>{this.props.placeholder}</div>
                <input
                    ref={(c) => this._input = c}
                    className={styles.input}
                    //type={this.props.type ? this.props.type : ''}
                    //min={this.props.type ? 1 : ''}
                    //max={this.props.type ? 5000 : ''}
                    maxLength={this.props.type ? 4 : ''}
                    value={this.props.value ? this.props.value : ''}
                    onFocus={() => this.setFocused(true)}
                    onBlur={() => this.setFocused(false)}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                />
            </div>
        )
    }
}
