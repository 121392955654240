import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './NewsContent.css';
import LinesClamp from '../LinesClamp/LinesClamp';
import { checkDate } from '../../utils/formatting';


export default class NewsContent extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    publishedAt: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    changeLog: PropTypes.string,
    category: PropTypes.number
  }

  componentDidMount() {
    //
  }

  renderCover() {
    const cover = this.props.img
    return cover ? (
        <img className="Newss_cover" src={cover} alt="img"/>
    ) : (
        <div className="Newss_cover" />
    )
  }

  /*checkDate(times) {
    var dateNow = new Date();
    var thisDate = new Date(times);
    let dateView = '';
    function isDifferentDates(Now, This) {
      if ( Now.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) === This.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' }) ) {
        return 1;
      } else if ( (Now.toLocaleString('default',{ day: 'numeric' }) - This.toLocaleString('default',{ day: 'numeric' })) === 1 ) {
        return 2;
      } else {
        return 0;
      } 
    }
    isDifferentDates(dateNow, thisDate) === 1 ? dateView = 'Сегодня' : 
      isDifferentDates(dateNow, thisDate) === 2 ? dateView = 'Вчера' : dateView = thisDate.toLocaleString('default',{ day: 'numeric', month: 'numeric', year: 'numeric' })
    return dateView;
  }*/
  
  render() {
    return (
      <div className="Newss_base">
        <div className="ev_click-news"></div>
        <div className="ev_click-tag-on-news"></div>
        <a className="Newss_inner" href={this.props.url} target="_blank" rel="noreferrer">
          <div className="Newss_header">
            {this.renderCover()}
          </div>
          <div className="Newss_body">
              <div className="Newss_title" title={this.props.title}>
                <LinesClamp
                  text={this.props.title}
                  className="Newss_clampLines"
                  lines={2}
                />
                {/* <div className="clamp-lines Newss_clampLines">
                    <div>{this.props.shortName}</div>
                </div> */}
              </div>
              <div className="Newss_summary">
                <div className="Newss-date">
                  <span>Опубликовано: </span>
                  <span>{ checkDate(this.props.publishedAt) }</span>
                </div>
                <div className="Newss-desc">
                  <LinesClamp
                    text={this.props.content}
                    className="Newss_clampLines"
                    lines={4}
                  />
                </div>
              </div>
              <div className="Newss-button">
                <div className="ButtonBlueSmall-news"><span>Подробнее</span></div>
              </div>
          </div>
          {/* <div className="Newss_footer"></div> */}
        </a>
    </div>
    )
  }
}
