import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';

import { pushHistoryWithLanding } from '../../utils/routing';

import {
    ERROR_EMPTY_SUBSCRIPTIONS_MESSAGE,
    ERROR_EMPTY_SUBSCRIPTIONS_BUTTON 
} from './translations'

import styles from './ErrorEmptySubscriptions.module.css';

export default class ErrorEmptySubscriptions extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history,
        lang: PropTypes.string,
    }

    handleClick = () => {
        pushHistoryWithLanding(this.props.history)
    }

    render() {
        const classNameBase = classNames(styles.base, styles[`base__${this.props.lang}`])
        return (
            <div className={classNameBase}>
                <div>
                    <div className={styles.message}>{ERROR_EMPTY_SUBSCRIPTIONS_MESSAGE}</div>
                    <div className={styles.icon} />
                    <div>
                        <ButtonBlueLarge onClick={this.handleClick}>{ERROR_EMPTY_SUBSCRIPTIONS_BUTTON}</ButtonBlueLarge>
                    </div>
                </div>
            </div>
        )
    }
}
