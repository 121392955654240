import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getCookie, parseJwt } from '../../utils/functions';
//import uMenu from './UserMenu.module.css';

import styles from './ButtonUserMenu.module.css';

const ESC_KEY_CODE = 27

export default class ButtonUserMenu extends Component {
    static propTypes = {
        spaId: PropTypes.number,
        spaUsername: PropTypes.string,
        count: PropTypes.number,
        notifications: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            author: PropTypes.string,
            mod_id: PropTypes.number,
            title: PropTypes.string,
            spa_id: PropTypes.number,
            is_updated: PropTypes.bool,
            created_at: PropTypes.string,
            content: PropTypes.string,
        })),
        isFetching: PropTypes.bool.isRequired,

        isOpenedMenu: PropTypes.bool,

        onLoginClick: PropTypes.func.isRequired,
        onOpenMenu: PropTypes.func.isRequired,
        onCloseMenu: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.state = {
            spaId: null,
            spaUsername: null
        }

    }

    componentDidMount() {
        this.setAccountData()

        // отвечает за закрытие меню по нажатию Esc
        window.addEventListener('keydown', this.handleKeyDown, true)
        this.cmEventListener()
    }

    componentWillUnmount() {
        // отвечает за закрытие меню по нажатию Esc
        window.addEventListener('keydown', this.handleKeyDown, true)
    }
    
    componentDidUpdate() {
        //
    }

    handleKeyDown = (e) => {
        if (this.props.isOpenedMenu && e.keyCode === ESC_KEY_CODE) {
            // выключаем стили для кнопки войти
            this.setClassListToBtn()

            this.handleCloseMenu()
            e.stopImmediatePropagation()
        }
    }

    handleCloseMenu = () => {
        this.props.onCloseMenu()
    }

    // принадлежит кнопке войти
    setAccountData = () => {
        let datas = null
        // получаем данные из локального хранилища и из куков
        if (getCookie('mtm_jwt') && localStorage.getItem('mtm:user')) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie('mtm_jwt').split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie('mtm_jwt'))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] < Math.floor(new Date().getTime() / 1000)) {
                    // т.к. не действующий токен, устанавливаем дефолтные данные - т.е. все на null
                    console.log('jti is expired')
                } else {
                    datas = JSON.parse(localStorage.getItem('mtm:user'))
                    //console.log('jti is good')
                }
            } //  data set null
        } //  data set null
        
        if (typeof datas !== 'undefined' && datas !== null) {
            return this.props.authorization(datas)
        } else {
            return this.props.authorization(this.state)
        }
    }

    // принадлежит кнопке войти
    cmEventListener = () => { // функция при открытом меню, если нажать на любое место экрана закрывает меню
        const cmUserMenu = document.querySelector('#cm_user_menu');
        const btnUserMenu = document.querySelector('#user_menu');
        
        document.addEventListener( 'click', (e) => {
            const withinBoundaries = e.composedPath().includes(cmUserMenu);
            const withinBoundariesBtn = e.composedPath().includes(btnUserMenu);
            if ( ! withinBoundaries && ! withinBoundariesBtn ) {
                if (this.props.isOpenedMenu) {
                    // выключаем стили для кнопки войти
                    document.getElementById("btn_user_menu").classList.remove(styles.granica);
                    document.getElementById("user_name").classList.remove(styles.opacity__up);
                    this.handleCloseMenu()
                } else {
                    return null
                }
            }
        })
    }

    setClassListToBtn = () => {
        if ( document.getElementById("btn_user_menu").classList.contains(styles.granica) 
        && document.getElementById("user_name").classList.contains(styles.opacity__up) ) {
            document.getElementById("btn_user_menu").classList.remove(styles.granica);
            document.getElementById("user_name").classList.remove(styles.opacity__up);
        } else {
            document.getElementById("btn_user_menu").classList.add(styles.granica);
            document.getElementById("user_name").classList.add(styles.opacity__up);
        }
    }
    
    // принадлежит кнопке войти
    handleClick = () => {
        // сначала проверяем действие токена
        // если токен валидный и НЕ действующий - отправляем юзера на prolongate и обновляем токен (и заново)
        // если токен не валидный - отправляем юзера на авторизацию через Лесту (и заново)
        // получаем данные из локального хранилища и из куков
        if (getCookie('mtm_jwt')) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie('mtm_jwt').split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie('mtm_jwt'))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // Math.floor(new Date().getTime() / 1000) - to unix timestamp
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] > Math.floor(new Date().getTime() / 1000)) {
                    // действующий - ничего не делаем с данными
                } else {
                    // НЕ действующий - отправляем юзера на prolongate и обновляем токен
                    let dataFromLocal = localStorage.getItem('mtm:user');
                    let dataFromCookie = getCookie('mtm_jwt');
                    let allData = {'from_local': dataFromLocal,'from_cookie': dataFromCookie};
                    console.log('jti is expired - update it')

                    return this.props.prolongate(allData);
                }
            }
        }

        if (this.props.spaId != null && typeof this.props.spaId !== "undefined") {
            /*document.getElementById("cm_user_menu").classList.toggle(styles.show);
            document.getElementById("btn_user_menu").classList.toggle(styles.granica);
            document.getElementById("user_name").classList.toggle(styles.opacity__up);*/
            if (this.props.isOpenedMenu) {
                // выключаем стили для кнопки войти
                this.setClassListToBtn()
                this.handleCloseMenu()
            } else {
                // включаем стили для кнопки войти
                this.setClassListToBtn()
                this.props.onOpenMenu(this.props.count, this.props.notifications)
            }
        } else {
            this.returnOnLogin()
        }
    }

    // принадлежит кнопке войти
    renderUserName = () => {
        // получаем данные из локального хранилища и из куков
        if (getCookie('mtm_jwt') && localStorage.getItem('mtm:user')) {
            // проверяем валидность токена в куках 
            let cookieJwt = getCookie('mtm_jwt').split('.')
            if (cookieJwt.length === 3) {
                // достаем payload из токена в куках
                let payloadJWT_client = parseJwt(getCookie('mtm_jwt'))
                // toISOString() - 2024-05-19T14:04:11.012Z
                // +new Date() - 1716127573630
                // если валидный и действующий - устанавливаем данные из локалки
                if (payloadJWT_client['exp'] < Math.floor(new Date().getTime() / 1000)) {
                    // т.к. не действующий токен, устанавливаем дефолтные данные - т.е. все на null
                    return `Войти`
                } else {
                    return this.props.spaUsername
                }
            }
        } else {
            // если куков и в хранилище нет ничего
            return `Войти`
        }
        //return (this.props.spaUsername !== null && typeof(this.props.spaUsername) !== 'undefined') ? this.props.spaUsername : `Войти`
    }

    // принадлежит кнопке войти
    returnOnLogin = () => {
        this.props.onLoginClick()
    }

    // принадлежит юзер меню
    handleClickNotifyEmpty = () => {
        //setTimeout(() => this.setState({notificationsCount: 0, notificationsActive: false, notificationsNew: false}), 1000);
    }

    render() {
        return (
            <div id="user_menu" className={styles.base} >
                <div id="btn_user_menu" className={styles.base_menu} onClick={this.handleClick}>
                    <span className={classNames(styles.btn_sign, styles.btn_index)}>
                        <span id="user_name" className={classNames(styles.btn_sign, styles.login, styles.opacity)}>{this.renderUserName()}</span>
                    </span>
                </div>
            </div>
        )
    }
}
