import React from "react"

export const EDIT_COMMENTS_ITEM_MOD_SUMMARY_VERSION = (modVersion, gameVersion) => {
    let NBSP = `\u00a0`
    return (
        <React.Fragment>
            <mark>{modVersion}</mark>{NBSP}для{NBSP}<mark>WoT{NBSP}{gameVersion}</mark>
        </React.Fragment>
    )
}

export const EDIT_COMMENTS_MODERATOR_CAPTION = "Модератор"
