export const START_FETCHING = 'START_FETCHING'
export const STOP_FETCHING = 'STOP_FETCHING'

export const startFetching = () => ({
    type: START_FETCHING,
})

export const stopFetching = () => ({
    type: STOP_FETCHING,
})
