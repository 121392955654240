import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Error.module.css';

export default class Error extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isSmall: PropTypes.bool,
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isSmall]: this.props.isSmall,
        })

        return (
            <div className={classNameBase}>
                {this.props.children}
            </div>
        )
    }
}
