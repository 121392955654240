import { batchActions } from 'redux-batched-actions';
import { each } from 'lodash';

import settings from '../settings';

import { MOD_STATE } from '../utils/constants';
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import {
    processModEditComments,
    processModEditDetailsData,
    processModEditVersionAdd,
} from '../utils/responseProcessing';
import { pushHistoryWithModderSection } from '../utils/routing';
import urls from '../apiUrls';

import { updateChangelogHistoryItems } from '../actions/ChangelogHistory';
import { openDialogError } from '../actions/DialogError';
import { setIsForbidden } from './SectionModder';
import { getUsername } from '../utils/functions';

export const showDialogError = () => {
    return (dispatch) => {
        dispatch(openDialogError())
    }
}

export const ADD_MOD_VERSION_COMPLETED = 'ADD_MOD_VERSION_COMPLETED'
export const CHANGE_MOD_VERSION_VISIBILITY_COMPLETED = 'CHANGE_MOD_VERSION_VISIBILITY_COMPLETED'
export const REMOVE_MOD_VERSION_COMPLETED = 'REMOVE_MOD_VERSION_COMPLETED'
export const SET_ERROR_FETCHING_EDIT_COMMENTS = 'SET_ERROR_FETCHING_EDIT_COMMENTS'
export const SET_ERROR_FETCHING_EDIT_DETAILS = 'SET_ERROR_FETCHING_EDIT_DETAILS'
export const SET_MOD_VERSION_UPLOAD_FROM_VISIBILITY = 'SET_MOD_VERSION_UPLOAD_FROM_VISIBILITY'
export const START_FETCHING_EDIT_COMMENTS = 'START_FETCHING_EDIT_COMMENTS'
export const START_FETCHING_EDIT_DETAILS = 'START_FETCHING_EDIT_DETAILS'
export const STOP_FETCHING_EDIT_COMMENTS = 'STOP_FETCHING_EDIT_COMMENTS'
export const STOP_FETCHING_EDIT_DETAILS = 'STOP_FETCHING_EDIT_DETAILS'
export const UPDATE_EDIT_COMMENTS = 'UPDATE_EDIT_COMMENTS'
export const UPDATE_EDIT_DETAILS = 'UPDATE_EDIT_DETAILS'
export const UPDATE_EDIT_MOD_STATE = 'UPDATE_EDIT_MOD_STATE'
export const UPDATE_EDIT_MOD_VISIBILITY = 'UPDATE_EDIT_MOD_VISIBILITY'

export const startFetchingEditDetails = () => ({
    type: START_FETCHING_EDIT_DETAILS,
})

export const startFetchingEditComments = () => ({
    type: START_FETCHING_EDIT_COMMENTS,
})

export const stopFetchingEditDetails = () => ({
    type: STOP_FETCHING_EDIT_DETAILS,
})

export const stopFetchingEditComments = () => ({
    type: STOP_FETCHING_EDIT_COMMENTS,
})

export const updateEditDetails = (rawResponseData, state) => ({
    type: UPDATE_EDIT_DETAILS,
    payload: {
        data: processModEditDetailsData(rawResponseData, state),
        id: rawResponseData.id,
    },
})

export const updateEditComments = (rawResponseData) => ({
    type: UPDATE_EDIT_COMMENTS,
    payload: {
        comments: processModEditComments(rawResponseData),
    },
})

export const updateEditModState = (modState) => ({
    type: UPDATE_EDIT_MOD_STATE,
    payload: {
        modState,
    },
})

export const updateEditModVisibility = (isHidden) => ({
    type: UPDATE_EDIT_MOD_VISIBILITY,
    payload: {
        isHidden,
    },
})

export const setErrorFetchingEditDetails = (errorData) => ({
    type: SET_ERROR_FETCHING_EDIT_DETAILS,
    payload: {
        errorData,
    },
})

export const setErrorFetchingEditComments = (errorData) => ({
    type: SET_ERROR_FETCHING_EDIT_COMMENTS,
    payload: {
        errorData,
    },
})

export const removeModVersionCompleted = (modVersionId) => ({
    type: REMOVE_MOD_VERSION_COMPLETED,
    payload: {
        modVersionId,
    },
})

export const changeModVersionVisibilityCompleted = (rawResponseData) => ({
    type: CHANGE_MOD_VERSION_VISIBILITY_COMPLETED,
    payload: {
        modVersionId: rawResponseData.id,
        isVisible: rawResponseData.is_visible,
    },
})

export const addModVersionCompleted = (rawResponseData) => ({
    type: ADD_MOD_VERSION_COMPLETED,
    payload: {
        version: processModEditVersionAdd(rawResponseData),
    },
})

export const setModVersionUploadFormVisibility = (isVisible) => ({
    type: SET_MOD_VERSION_UPLOAD_FROM_VISIBILITY,
    payload: {
        isVisible,
    },
})

export const fetchEditDetails = (modId, ownerId, role) => {
    return (dispatch, getState) => {
        const state = getState()
        const url = `https://tankimods.net/api/mods/owner/?mod_id=${modId}&owner_id=${ownerId}&role=${role}`
        // `https://tankimods.net${urls.modsOwnerDetails.replace(/:(modId)/g, modId)}` //

        dispatch(startFetchingEditDetails())

        const fetchPromise = fetch(url, {method: 'POST', body: {modId, ownerId, role}}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorFetchingEditDetails(rawResponseData.context),
                        stopFetchingEditDetails(),
                    ], SET_ERROR_FETCHING_EDIT_DETAILS))
                } else {
                    dispatch(batchActions([
                        updateEditDetails(rawResponseData, state),
                        updateChangelogHistoryItems(rawResponseData),
                        stopFetchingEditDetails(),
                    ], UPDATE_EDIT_DETAILS))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingEditDetails(errorData),
                    stopFetchingEditDetails(),
                ], SET_ERROR_FETCHING_EDIT_DETAILS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const fetchEditComments = (modId, code) => {
    return (dispatch) => {
        const url = `https://tankimods.net${urls.modComments}?mod_id=${modId}&code=${code}`
        //`https://tankimods.net${urls.modComments}?mod_id=${modId}&code=${code}` //`${urls.modComments}?mod_id=${mod_id}`

        dispatch(startFetchingEditComments())

        const fetchPromise = fetch(url, {method: 'POST', body: {modId, code}}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(batchActions([
                        setErrorFetchingEditComments(rawResponseData.context),
                        stopFetchingEditComments(),
                    ], SET_ERROR_FETCHING_EDIT_COMMENTS))
                } else {
                    dispatch(batchActions([
                        updateEditComments(rawResponseData),
                        stopFetchingEditComments(),
                    ], UPDATE_EDIT_COMMENTS))
                }
            }, (errorData) => {
                dispatch(batchActions([
                    setErrorFetchingEditComments(errorData),
                    stopFetchingEditComments(),
                ], SET_ERROR_FETCHING_EDIT_COMMENTS))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const changeModVisibility = (modId, isVisible) => {
    return (dispatch) => {
        const url = `https://tankimods.net/api/mods/?modId=${modId}`
        //const url = urls.modDetails.replace(/:(modId)/g, modId) //- /api/mods/:modId/
        const body = {
            is_hidden: !isVisible,
        }
        const headers = {
            'X-API-Version': '2',
        }

        const fetchPromise = fetch(url, { method: 'PATCH', body, headers }).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    dispatch(showDialogError())
                } else {
                    dispatch(updateEditModVisibility(rawResponseData.is_hidden))
                }
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const changeModStatus = (modId, status) => {
    return (dispatch) => {
        const url = `https://tankimods.net/api/mods/?modId=${modId}`
        //const url = urls.modDetails.replace(/:(modId)/g, modId)

        const body = {
            status,
        }
        const headers = {
            'X-API-Version': '2',
        }

        const fetchPromise = fetch(url, { method: 'PATCH', body, headers }).promise
            .then((rawResponseData) => {
                dispatch(updateEditModState(rawResponseData.state))
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const removeModVersion = (modVersionId) => {
    return (dispatch) => {
        const url = `https://tankimods.net${urls.modVersionDetails}${modVersionId}` // urls.modVersionDetails.replace(/:(modVersionId)/g, modVersionId)

        const fetchPromise = fetch(url, { method: 'DELETE' }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    removeModVersionCompleted(rawResponseData.id),
                    updateEditModState(rawResponseData.mod_state),
                ], REMOVE_MOD_VERSION_COMPLETED))
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const changeModVersionVisibility = (modVersionId, isVisible) => {
    return (dispatch) => {
        //const url = urls.modVersionDetails.replace(/:(modVersionId)/g, modVersionId)
        const url = `https://tankimods.net${urls.modVersionDetails}${modVersionId}` // urls.modVersionDetails.replace(/:(modVersionId)/g, modVersionId)

        const body = { is_visible: isVisible }
        
        const fetchPromise = fetch(url, { method: 'PATCH', body }).promise
            .then((rawResponseData) => {
                dispatch(batchActions([
                    changeModVersionVisibilityCompleted(rawResponseData),
                ], CHANGE_MOD_VERSION_VISIBILITY_COMPLETED))
            }, () => {
                dispatch(showDialogError())
            })
        return fetchPromise
    }
}

const prepareModVersionBody = (data) => {
    const result = {
        mod: data.modId,
        game_version: data.gameVersionId,
        version: data.modVersion,
        is_visible: data.isPublished,
        temporary_version_file: data.modFileId,
        temporary_version_file_access_token: data.modFileToken,
    }

    if (data.changelog) {
        result.change_log = data.changelog
    }

    if (data.comment) {
        result.comment = data.comment
        //result.user_name = getUsername().spaUsername
    }
    result.user_name = getUsername().spaUsername

    return result
}

export const addModVersion = (modId, data) => {
    return (dispatch) => {
        const url = `https://tankimods.net${urls.modVersionList}/` // urls.modVersionList
        const body = prepareModVersionBody(data)
        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then((rawResponseData) => {
                debugger
                dispatch(batchActions([
                    addModVersionCompleted(rawResponseData),
                    updateEditModState(rawResponseData.mod_state),
                ], ADD_MOD_VERSION_COMPLETED))
                dispatch(fetchEditComments(modId))
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

const prepareLocalizationsData = (data) => {
    const result = []

    each(data.byLanguages, (item, language) => {
        if (!item.title) {
            return
        }

        result.push({
            lang: settings.portalLanguages.find((item) => item.code === language).id,
            title: item.title,
            description: item.description,
            installation_guide: item.installationGuide,
        })
    })
    
    return result
}

const prepareChangelogData = (data, state) => {
    let result = []

    if (data.modVersion && data.changelog) {
        result = [
            {
                position: data.position,
                id: data.id, // было id я назвал как позиция
                body: data.changelog,
                //date: '',
                version: data.modVersion,
            },
        ]
    }

    each(state.changelogHistory.items, (historyItem) => {
        result.push({
            position: historyItem.position,
            id: historyItem.id, // было id я назвал как позиция
            body: historyItem.content,
            //date: '',
            version: historyItem.version,
        })
    })

    return result
}

const prepareScreenshotsData = (data) => {
    const result = []
    each(data.screenshots, (item, index) => {
        if (item.accessToken) {
            result.push({
                access_token: item.accessToken,
                position: index,
                temporary_file: item.fileId,
            })
        } else {
            result.push({
                screenshot: item.screenshotId,
                source: item.source,
                position: index,
            })
        }
    })

    return result
}

const prepareVoiceoversData = (data) => {
    const result = []
    debugger
    each(data.voiceovers, (item, index) => {
        if (item.accessToken) {
            result.push({
                access_token: item.accessToken,
                position: index,
                source: item.source,
                temporary_file: item.fileId,
                title: item.sourceOriginalName,
            })
        } else {
            result.push({
                voiceover: item.voiceoverId,
                source: item.source,
                position: index,
                title: item.sourceOriginalName,
            })
        }
    })
    
    return result
}

const prepareModVersionsData = (data, state) => {
    const result = []

    if ([MOD_STATE.DRAFT, MOD_STATE.REJECTED].includes(state.editMod.data.state)) {
        const modVersion = {
            comment: data.comment,
            game_version: data.gameVersionId,
            id: data.modVersionId,
            version: data.modVersion,
        }
        if (data.modFileId && data.modFileToken) {
            modVersion.temporary_version_file = data.modFileId
            modVersion.temporary_version_file_access_token = data.modFileToken
        }
        result.push(modVersion)
    }

    return result
}

export const prepareBody = (modId, data, state) => {
    const body = {
        status: data.status,
        tags: data.categories,
    }

    if (data.coverFileId && data.coverFileToken) {
        body.temporary_cover_file = data.coverFileId
        body.temporary_cover_file_access_token = data.coverFileToken
    } else if (state.editMod.data.cover && !data.coverOriginalSource) {
        body.is_original_cover_removed = true
    }
    
    body.mod_id = modId || null // я добавил
    body.author_name = data.authorName || null
    body.change_log = prepareChangelogData(data, state)
    body.screenshots = prepareScreenshotsData(data)
    body.voiceovers = prepareVoiceoversData(data)
    body.localizations = prepareLocalizationsData(data)
    body.versions = prepareModVersionsData(data, state)
    
    return body
}

export const saveMod = (modId, data, history) => {
    return (dispatch, getState) => {
        const state = getState()
        const body = prepareBody(modId, data, state)
        
        const url = `https://tankimods.net/api/mods/?modId=${modId}`
        //const url = urls.modDetails.replace(/:(modId)/g, modId)
        
        const headers = {
            'X-API-Version': '2',
        }

        const fetchPromise = fetch(url, { method: 'PUT', body, headers }).promise
            .then((raw) => {
                debugger
                pushHistoryWithModderSection(history)
            }, (error) => {
                if (error.status === 403) {
                    Promise.resolve(
                        dispatch(setIsForbidden())
                    ).then(() => {
                        pushHistoryWithModderSection(history)
                    })
                } else {
                    dispatch(showDialogError())
                }
                // eslint-disable-next-line no-console
                console.log('Something went wrong!')
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const removeMod = (modId, history) => {
    return (dispatch) => {
        const url = urls.modDetails.replace(/:(modId)/g, modId)

        const fetchPromise = fetch(url, { method: 'DELETE' }).promise
            .then(() => {
                pushHistoryWithModderSection(history)
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

export const addComment = (modId, content) => {
    return (dispatch) => {
        const url = urls.modComments
        const body = {
            mod_id: modId,
            text: content,
        }

        const fetchPromise = fetch(url, { method: 'POST', body }).promise
            .then(() => {
                dispatch(fetchEditComments(modId))
            }, () => {
                dispatch(showDialogError())
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}
