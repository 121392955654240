import { connect } from 'react-redux';

//import { changeAdminResultItemsOrdering, fetchItemsAdminPanel } from '../../actions/AdminPanel';

import { openDialogError } from '../../actions/DialogError';
import { openDialog } from '../../actions/Dialog';

import DialogHelp from '../DialogHelpWrapper/DialogHelpWrapper';
import Support from '../../components/Support/Support';

const mapStateToProps = (state) => {
    const isLoggedIn = !!state.currentAccount.spaId;
    return ({
        isLoggedIn: isLoggedIn,
        spaId: state.currentAccount.spaId,
        spaUsername: state.currentAccount.spaUsername,

        isFetching: state.adminPanel.isFetching,
        isError: state.adminPanel.isError,
        filtered: state.adminPanel.filtered,
    })
}

const mapDispatchToProps = (dispatch) => ({
    changeItemsType: (filterParams) => {
        //dispatch(changeAdminResultItemsOrdering(filterParams.type))
        //dispatch(fetchItemsAdminPanel(filterParams))
    },
    onAuthAdmin() {
        //dispatch()
    },
    fetchItemsAdminPanel: (filterParams) => {
        //dispatch(resetSearchResultItems())
        //dispatch(fetchItemsAdminPanel(filterParams))
    },
    onIncrementDownloadCount : () => {
        //
    },
    onHelpButtonClick: (modId) => {
        dispatch(openDialog(<DialogHelp modId={modId} />))
    },
    onShowDialogError: (content) => {
        dispatch(openDialogError(content))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Support)
