import React from 'react';
import PropTypes from 'prop-types';

import ButtonBlueOutlineSmall from '../../Buttons/ButtonBlueOutlineSmall/ButtonBlueOutlineSmall';
import Caption from '../../Caption/Caption';
import EditChangelogForm from '../EditChangelogForm/EditChangelogForm';

import {
    EDIT_CHANGE_LOG_ADD_HISTORY_BUTTON,
    EDIT_CHANGE_LOG_ADD_HISTORY_LABEL,
} from './translations'

import styles from './EditChangelogAdd.module.css';

export default class EditChangelogAdd extends React.PureComponent {
    static propTypes = {
        hasHistory: PropTypes.bool,
        hasCaption: PropTypes.bool,
        isModEdit: PropTypes.bool,
        onHistoryItemAdd: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isToggleForm: false,
        }
    }

    handleAddItemClick = () => {
        if (this.state.isContentError || this.state.isVersionError) {
            return
        }
        this.props.onHistoryItemAdd(this.state.content, this.state.version)
        this.setState({
            content: '',
            isFormVisible: false,
            version: '',
        })
    }

    handleSetFormVisible = () => {
        this.setState({
            isFormVisible: !this.state.isFormVisible,
        })
    }

    handleHistoryItemSave = (id, position, version, content) => {
        this.props.onHistoryItemAdd(version, content)
        this.handleSetFormVisible()
    }

    renderButton() {
        if (this.state.isFormVisible) {
            return
        }

        return (
            <div className={styles.row}>
                <div className={styles.label}>
                    {this.props.isModEdit ? (
                        this.props.hasCaption && (
                            <Caption isLarge>
                                {EDIT_CHANGE_LOG_ADD_HISTORY_LABEL}
                            </Caption>
                        )
                    ) : (
                        <Caption>
                            {EDIT_CHANGE_LOG_ADD_HISTORY_LABEL}
                        </Caption>
                    )}
                </div>
                <div className={styles.field}>
                    <ButtonBlueOutlineSmall glyph="plus" onClick={this.handleSetFormVisible}>
                        {EDIT_CHANGE_LOG_ADD_HISTORY_BUTTON}
                    </ButtonBlueOutlineSmall>
                </div>
            </div>
        )
    }

    renderForm() {
        if (!this.state.isFormVisible) {
            return
        }

        return (
            <React.Fragment>
                {this.props.isModEdit && this.props.hasCaption ? (
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Caption isLarge>
                                {EDIT_CHANGE_LOG_ADD_HISTORY_LABEL}
                            </Caption>
                        </div>
                    </div>
                ): null}
                <EditChangelogForm
                    hasHistory={this.props.hasHistory}
                    onFormClose={this.handleSetFormVisible}
                    onHistoryItemSave={this.handleHistoryItemSave}
                />
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className={styles.base}>
                {this.renderButton()}
                {this.renderForm()}
            </div>
        )
    }
}
