import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './LanguageSelectMini.module.css';

export default class LanguageSelectMini extends React.PureComponent {
    static propTypes = {
        checkedLanguage: PropTypes.oneOf(['ru', 'en']).isRequired,
        onLanguageChange: PropTypes.func.isRequired,
    }

    handleItemChange = (value) => {
        value !== this.props.checkedLanguage && this.props.onLanguageChange(value)
    }

    render() {
        const classNameRuItem = classNames(styles.item, styles['item__ru'], {
            [styles.isChecked]: this.props.checkedLanguage === 'ru',
        })

        const classNameEnItem = classNames(styles.item, styles['item__en'], {
            [styles.isChecked]: this.props.checkedLanguage === 'en',
        })

        return (
            <div className={styles.base}>
                <div
                    className={classNameRuItem}
                    onClick={() => this.handleItemChange('ru')}
                />
                <div
                    className={classNameEnItem}
                    onClick={() => this.handleItemChange('en')}
                />
            </div>
        )
    }
}
