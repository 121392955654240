import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

import Information from '../Information/Information';

import { pushHistoryWithLanding, urls } from '../../utils/routing';

import { GO_TO_LANDING } from '../../translations';

import styles from './ErrorBlocked.module.css';


export const ERROR_BLOCKED_CONTENT = `Ваш аккаунт на портале был заблокирован администратором.`;

export default class ErrorBlocked extends React.PureComponent {
    static propTypes = {
        history: ReactRouterPropTypes.history, // isRequired
    }

    handleClick = () => {
        if (this.props.history !== null) {
            pushHistoryWithLanding(this.props.history)
        } else {
            window.location.href = urls.landing;
        }
    }

    render() {
        return (
            <div className={styles.base}>
                <div>
                    <div className={styles.icon} />
                    <Information
                        message={ERROR_BLOCKED_CONTENT}
                        buttonCaption={GO_TO_LANDING}
                        onButtonClick={this.handleClick}
                    />
                </div>
            </div>
        )
    }
}
