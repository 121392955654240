
export const ALL_MODS = 'Все'

export const DRAFT = 'Черновик'

export const REVIEW = 'Проверяется'

export const PUBLISHED = 'Опубликовано'

export const REJECTED = 'Отклонено'
