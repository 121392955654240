import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import NotiFyItem from '../NotifyItem/NotiFyItem';

import styles from './Notifys.module.css';

export default class Notifys extends Component {
    static propTypes = {
        notifications: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            author: PropTypes.string.isRequired,
            modId: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            spaId: PropTypes.number.isRequired,
            isUpdated: PropTypes.bool.isRequired,
            createdAt: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        })),
    }

    renderItem(item) {
        return (
            <NotiFyItem
                key={item.id}
                id={item.id}
                author={item.author}
                modId={item.modId}
                title={item.title}
                spaId={item.spaId}
                isUpdated={item.isUpdated}
                createdAt={item.createdAt}
                content={item.content}
            />
        )
    }

    renderNotifications = () => {
        return this.props.notifications.map((item) => this.renderItem(item))
    }
    
    render() {
        return (
            <div className={classNames(styles.cm_scroll, styles.cm_scroll__notifications, 'js-cm-scroll-wrapper')}>
                <ul className={classNames(styles.cm_scroll_container,"js-cm-notifications-list")} style={{overflow: 'hidden', maxHeight: '249px'}}>
                    {this.renderNotifications()}
                </ul>
                <div className={classNames(styles.cm_scroll_track, "js-cm-scrollbar-track")}>
                    <div className={styles.cm_scroll_thumb}></div>
                </div>
            </div>
        )
    }
}
