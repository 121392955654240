import React from 'react';
import PropTypes from 'prop-types';

import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';

import './Information.css';

export default class Information extends React.PureComponent {
    static propTypes = {
        message: PropTypes.node.isRequired,
        buttonCaption: PropTypes.node.isRequired,
        onButtonClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className="Information_base">
                <div className="Information_inner">
                    <div className="Information_message">
                        {this.props.message}
                    </div>
                    <ButtonBlueLarge onClick={this.props.onButtonClick}>
                        {this.props.buttonCaption}
                    </ButtonBlueLarge>
                </div>
            </div>
        )
    }
}
