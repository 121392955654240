import React, { Component } from 'react'

import { GO_TO_LANDING } from '../../translations';
import { urls } from '../../utils/routing';

import { ButtonBlueLarge } from '../Buttons/ButtonBlueLarge/ButtonBlueLarge';
import Main from '../Main/Main';

import styles from './Error404.module.css';


export default class Error404 extends Component {
  handleClick() {
    window.location.href = urls.landing
  }

  render() {
    return (
      <Main>
        <div className={styles.body}>
          <div className={styles.block}>
            {/* <div className={styles.error}></div> */}
            <div className={styles.title}>
              <span>Страница не найдена</span>
            </div>
            <div className={styles.content}>
              <span>Такой страницы не существует или адрес введён неверно.</span>
              {/* <span>Перейдите на <a>главную страницу.</a></span> */}
            </div>
            <ButtonBlueLarge onClick={this.handleClick}>{GO_TO_LANDING}</ButtonBlueLarge>
          </div>
        </div>
        </Main>
    )
  }
}
