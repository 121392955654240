import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AVAILABLE_LANGUAGES, LANGUAGE_CODES } from '../../../utils/i18n';
import {
    FIELD_VALIDATION_ERROR_TYPES,
    MAX_DESCRIPTION_LENGTH,
    MAX_INSTALLATION_GUIDE_LENGTH,
    MAX_TITLE_LENGTH,
    MIN_DESCRIPTION_LENGTH,
    MIN_TITLE_LENGTH,
} from '../../../utils/constants';

import Cancel from '../../Cancel/Cancel';
import Caption from '../../Caption/Caption';
import EditLabelError from '../EditLabelError/EditLabelError';
import EditLabelText from '../EditLabelText/EditLabelText';
import HtmlEditor from '../../../components/HtmlEditor/HtmlEditor';
import Input from '../../../components/Input/Input';

import {
    FIELD_MAX_LIMIT_EXCEED_ERROR,
    FIELD_MIN_LIMIT_EXCEED_ERROR,
} from '../../../translations';

import {
    EDIT_LANGUAGE_FORM_CANCEL_CAPTION,
    EDIT_LANGUAGE_FORM_DESCRIPTION_LABEL,
    EDIT_LANGUAGE_FORM_DESCRIPTION_TEXT,
    EDIT_LANGUAGE_FORM_EN,
    EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_LABEL,
    EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_TEXT,
    EDIT_LANGUAGE_FORM_LABEL,
    EDIT_LANGUAGE_FORM_NAME_LABEL,
    EDIT_LANGUAGE_FORM_RU,
} from './translations';

import styles from './EditLanguageForm.module.css';

export default class EditLanguageForm extends React.PureComponent {
    static propTypes = {
        externalTitle: PropTypes.string,
        externalTitleError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
        ]),
        externalDescription: PropTypes.string,
        externalDescriptionError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
            FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT,
        ]),
        externalInstallationGuide: PropTypes.string,
        externalInstallationGuideError: PropTypes.oneOf([
            FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT,
        ]),
        isRemoveLanguageEnabled: PropTypes.bool.isRequired,
        language: PropTypes.oneOf(AVAILABLE_LANGUAGES).isRequired,

        onDescriptionChange: PropTypes.func.isRequired,
        onInstallationGuideChange: PropTypes.func.isRequired,
        onRemoveLanguageClick: PropTypes.func.isRequired,
        onTitleChange: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            description: '',
            descriptionErrorType: null,
            installationGuide: '',
            installationGuideErrorType: null,
            title: '',
            titleErrorType: null,
        }
    }

    componentDidMount() {
        this.updateStateFromProps(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateStateFromProps(nextProps)
    }

    updateStateFromProps(props) {
        if (props.externalTitle) {
            this.setState({ title: props.externalTitle })
        }
        if (props.externalDescription) {
            this.setState({ description: props.externalDescription })
        }
        if (props.externalInstallationGuide) {
            this.setState({ installationGuide: props.externalInstallationGuide })
        }
    }

    handleCancelClick = () => {
        this.props.onRemoveLanguageClick(this.props.language)
    }

    handleRemoveLanguageClick = () => {
        this.props.onRemoveLanguageClick(this.props.language)
    }

    handleTitleChange = (value) => {
        let errorType = null

        if (value.length > MAX_TITLE_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT
        } else if (value.length < MIN_TITLE_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT
        }

        this.setState({
            title: value,
            titleErrorType: errorType,
        })
        this.props.onTitleChange(this.props.language, value, errorType)
    }

    handleDescriptionChange = (value, textLength) => {
        const valueLength = textLength === undefined ? value.length : textLength
        let errorType = null

        if (valueLength > MAX_DESCRIPTION_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT
        } else if (valueLength < MIN_DESCRIPTION_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT
        }

        this.setState({
            description: value,
            descriptionErrorType: errorType,
        })
        this.props.onDescriptionChange(this.props.language, value, errorType)
    }

    handleInstallationGuideChange = (value, textLength) => {
        const valueLength = textLength || value.length
        let errorType = null

        if (valueLength > MAX_INSTALLATION_GUIDE_LENGTH) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT
        }

        this.setState({
            installationGuide: value,
            installationGuideErrorType: errorType,
        })
        this.props.onInstallationGuideChange(this.props.language, value, errorType)
    }

    renderRemoveLanguage() {
        return this.props.isRemoveLanguageEnabled ? (
            <div className={styles.cancel}>
                <Cancel
                    caption={EDIT_LANGUAGE_FORM_CANCEL_CAPTION}
                    align="left"
                    onClick={this.handleCancelClick}
                />
            </div>
        ) : null
    }

    renderTitleError() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT]: FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_TITLE_LENGTH),
            [FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT]: FIELD_MIN_LIMIT_EXCEED_ERROR(MIN_TITLE_LENGTH),
        }

        return this.state.titleErrorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.titleErrorType]} />
        ) : null
    }

    renderDescriptionError() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT]: FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_DESCRIPTION_LENGTH),
            [FIELD_VALIDATION_ERROR_TYPES.MIN_LIMIT]: FIELD_MIN_LIMIT_EXCEED_ERROR(MIN_DESCRIPTION_LENGTH),
        }

        return this.state.descriptionErrorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.descriptionErrorType]} />
        ) : null
    }

    renderInstallationGuideError() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.MAX_LIMIT]: FIELD_MAX_LIMIT_EXCEED_ERROR(MAX_INSTALLATION_GUIDE_LENGTH),
        }

        return this.state.installationGuideErrorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.installationGuideErrorType]} />
        ) : null
    }

    render() {
        const classNameEditorField = classNames(styles.field, styles.field__editor)
        const languageTranslation = (
            <span className={classNames(styles.lang, styles[`lang__${this.props.language}`])}>
                {this.props.language === LANGUAGE_CODES.RU && EDIT_LANGUAGE_FORM_RU}
                {this.props.language === LANGUAGE_CODES.EN && EDIT_LANGUAGE_FORM_EN}
            </span>
        )

        return (
            <div className={styles.base}>
                <div className={styles.caption}>
                    <Caption isLarge>{EDIT_LANGUAGE_FORM_LABEL(languageTranslation)}</Caption>
                    {this.renderRemoveLanguage()}
                </div>

                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isRequired isError={!!this.props.externalTitleError}>
                            {EDIT_LANGUAGE_FORM_NAME_LABEL}
                        </Caption>
                        {this.renderTitleError()}
                    </div>
                    <div className={styles.field}>
                        <Input
                            isError={!!this.props.externalTitleError}
                            value={this.state.title}
                            onChange={this.handleTitleChange}
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isRequired isError={!!this.props.externalDescriptionError}>
                            {EDIT_LANGUAGE_FORM_DESCRIPTION_LABEL}
                        </Caption>
                        <EditLabelText message={EDIT_LANGUAGE_FORM_DESCRIPTION_TEXT(MAX_DESCRIPTION_LENGTH)} />
                        {this.renderDescriptionError()}
                    </div>
                    <div className={classNameEditorField}>
                        <HtmlEditor
                            isError={!!this.props.externalDescriptionError}
                            value={this.state.description}
                            onChange={this.handleDescriptionChange}
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.label}>
                        <Caption isError={!!this.props.externalInstallationGuideError}>
                            {EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_LABEL}
                        </Caption>
                        <EditLabelText message={EDIT_LANGUAGE_FORM_INSTALLATION_GUIDE_TEXT(MAX_INSTALLATION_GUIDE_LENGTH)} />
                        {this.renderInstallationGuideError()}
                    </div>
                    <div className={classNameEditorField}>
                        <HtmlEditor
                            isError={!!this.props.externalInstallationGuideError}
                            value={this.state.installationGuide}
                            onChange={this.handleInstallationGuideChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
