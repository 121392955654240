import {
    OPEN_MENU,
    CLOSE_MENU,
} from '../actions/UserMenu';

const initialState = {
    count: 0,
    notifications: [],
    isOpened: false,
}

export const userMenu = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MENU: {
            return {
                ...state,
                count: action.count,
                notifications: action.notifications,
                isOpened: true,
                isNavigatingToLandingOnClose: action.isNavigatingToLandingOnClose,
            }
        }
        case CLOSE_MENU: {
            return {
                ...state,
                count: null,
                notifications: [],
                isOpened: false,
            }
        }
        default:
            return state
    }
}
