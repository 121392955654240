import { connect } from "react-redux";
import { each, values, keys, concat } from "lodash";

import Gallery from "../../components/Gallery/Gallery";
import { GALLERY_ITEMS_TYPES } from '../../components/Gallery/translations'
import {
    changeGalleryFilteredItemsOrdering,
    fetchGalleryItemsByType,
    fetchAllGalleryItemsInitially,
    filterGalleryItems,
    incrementFetchingOffset,
    incrementFilteringOffset,
} from '../../actions/Gallery';
import { incrementDownloadCount, processDownloadMod } from "../../actions/Details";

const mapStateToProps = (state) => {
    let resultMap = {}
    each(concat(values(GALLERY_ITEMS_TYPES), 'filtered'), (itemsType) => {
        resultMap[itemsType] = {
            isError: state.gallery[itemsType].isError,
            isFetching: state.gallery[itemsType].isFetching,
            isFetchedAll: state.gallery[itemsType].isFetchedAll,
            items: state.gallery[itemsType].items,
        }
    })
    resultMap.filtered.filterParams = state.gallery.filtered.filterParams
    resultMap.isModDownloadAccepted = state.persistedValues.isModDownloadAccepted
    resultMap.tags = keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title }))
    return resultMap
}

const mapDispatchToProps = (dispatch) => ({
    fetchAllItems: () => { // location
        dispatch(fetchAllGalleryItemsInitially()) // location
    },
    fetchGalleryItemsMore: (itemsType) => {
        dispatch(incrementFetchingOffset(itemsType))
        dispatch(fetchGalleryItemsByType(itemsType, false))
    },
    
    changeGalleryFilteredItemsOrdering: (filterParams) => {
        dispatch(changeGalleryFilteredItemsOrdering(filterParams.ordering))
        dispatch(filterGalleryItems(filterParams))
    },
    filterGalleryMore: (filterParams) => {
        dispatch(incrementFilteringOffset())
        dispatch(filterGalleryItems(filterParams))
    },
    onDownloadClick: (isModDownloadAccepted, isModDownloaded, modId, title, versions) => {
        if (isModDownloaded) {
            dispatch(incrementDownloadCount(modId))
        } else {
            dispatch(processDownloadMod(isModDownloadAccepted, modId, title, versions))
        }
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery)