import React from "react";

export const FILE_UPLOAD_TITLE = `Загрузить файл`;

export const FILE_CANCEL_TITLE = `Отменить`;

export const FILE_UPDATE_TITLE = `Заменить файл`;

export const FILE_REMOVE_TITLE = `Удалить файл`;

export const FILE_UPDATE_FILE_NAME = (fileName, size) => {
    return (
        <React.Fragment>
            Загружен файл: <mark>{fileName}</mark> {size}
        </React.Fragment>
    )
} 

export const FILE_UPLOAD_PROGRESS = (value) => {
    return (
        <React.Fragment>
            Загрузка: <mark>{value}%</mark>
        </React.Fragment>
    )
} 