import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import 'react-h5-audio-player/lib/styles.css';
import styles from './Mplayer.module.css';

//import play from './images/speaker_sub.svg' //'./images/mplayer_play.png';
//import pause from './images/mplayer_pause.png';

export default class Mplayer extends Component {
    static propTypes = {
        title: PropTypes.string,
        src: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            url: PropTypes.string.isRequired,
        })),
    }

    constructor(props) {
        super(props)

        this.state = {
            audio: props.src[Math.floor(Math.random() * props.src.length)].url,//'',
            autoPlay: false,
        }
    }

    componentDidMount() {
        //this.setState({audio: this.props.src[Math.floor(Math.random() * this.props.src.length)].url})
        //this.setState({autoPlay: false})
    }

    handlePlayClick = () => {
        var randomAudio = this.props.src[Math.floor(Math.random() * this.props.src.length)]
        this.setState({audio: randomAudio.url})
        return this.state.audio
    }

    renderHeader = () => {
        return <span className={styles.player__title} title="Предпрослушивание">{this.props.title}</span>
    }

    renderCustomIconPlay() {
        // <img src={play} alt="" />  8fb1bd
        return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="m11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/></svg>
    }
    renderCustomIconPause() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5"/></svg>
    }

    render() {
        return (
            <div className={styles.base}>
                <AudioPlayer
                    className={styles.__player}
                    style={{backgroundColor: 'rgb(39 39 41 / 95%)'}}
                    autoPlay={this.state.autoPlay}
                    timeFormat="mm:ss" // hh:mm:ss
                    layout="horizontal-reverse"
                    volume={1.0}
                    progressUpdateInterval={100}
                    src={this.state.audio} // {this.props.src[Math.floor(Math.random() * this.props.src.length)].url}
                    onPlay={this.handlePlayClick}
                    //onCanPlay={this.handlePlayClick}
                    //onLoadStart={this.handlePlayClick}
                    header={this.renderHeader()}
                    //footer
                    
                    customAdditionalControls={[]} // loop loopOff style
                    customVolumeControls={[]} // volume volumeMute style
                    customProgressBarSection={[
                        RHAP_UI.PROGRESS_BAR,
                        RHAP_UI.CURRENT_LEFT_TIME,
                        /*RHAP_UI.CURRENT_TIME,
                        <div>&nbsp;/&nbsp;</div>,
                        RHAP_UI.DURATION,*/
                    ]}
                    showSkipControls={false} // previous next
                    showJumpControls={false} // rewind forward
                    customIcons={{play: this.renderCustomIconPlay(), pause: this.renderCustomIconPause()}}
                    //customIcons={ReactNode} // <Icon icon={playCircle} /> play pause rewind forward previous next loop loopOff volume volumeMute
                />
            </div>
        )
    }   
}
