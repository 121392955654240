import { isArray } from "lodash"
import { allTags } from "./constants"

export const urls = {
    contentPage: '/:slug(.*)/',
    create: '/section-modder/create/',
    details: '/:modId/',
    edit: '/:modId/edit/',
    errorNotFound: '/error-not-found/',
    landing: '/',
    modderSection: '/section-modder/',
    news: '/news/',
    info: '/info/',
    search: '/search/',
    subscriptions: '/followed-mods/',
    eula: '/eula/',
    termsofuse: '/terms-of-use/',
    problemInfo: '/support/', /* '/problem/' */
    modsRules: '/rules/',
    logout: '/logout/',

    adminSection: '/admin-panel/',
    moderatorSection: '/moderator-panel/',

    review: '/:modId/review/',

    prolongate: '/prolongate/',
    wot: 'https://worldoftanks.eu/',
    tanki: 'https://tanki.su',
    media: '/media/',
}

export const getTermsOfUse = (language) => `/pages/terms_of_use_${language}`
export const getTermsOfUsePortal = (language) => `/pages/terms_of_use_portal_${language}`

export const getDetailsUrl = (modId) => urls.details.replace(/:modId/g, modId)
export const getEditUrl = (modId) => urls.edit.replace(/:modId/g, modId)
export const getReviewUrl = (modId) => urls.review.replace(/:modId/g, modId)

export const footerLinks = {
    eula: {
        href: urls.eula, 
        isExternal: false, 
        title: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u0441\u0430\u0439\u0442\u0430"
    }, 
    termsofuse: {
        href: urls.termsofuse, 
        isExternal: false, 
        title: "\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043C\u043E\u0434\u043E\u0432"
    }, 
    problemInfo: {
        href: urls.problemInfo, 
        isExternal: false, 
        title: "\u0426\u0435\u043D\u0442\u0440 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0438"
    }, 
    tanki: {
        href: urls.tanki, 
        isExternal: true, 
        title: "\u041E\u0444\u0438\u0446\u0438\u0430\u043B\u044C\u043D\u044B\u0439 \u0441\u0430\u0439\u0442 \u0438\u0433\u0440\u044B \u041C\u0438\u0440 \u0422\u0430\u043D\u043A\u043E\u0432"
    }
}


export const pushHistoryWithLanding = (history) => {
    history.push({
        pathname: urls.landing,
        search: '',
    })
    history.go() // я добавил
}

export const pushHistoryWithTags = (history, tagId) => {
    let tagName
    if (!isArray(tagId)) {
        tagName = allTags.find(item => item.id === tagId).caption
    } else if (isArray(tagId) && tagId.length === 1) {
        tagName = allTags.find(item => item.id === tagId[0]).caption
    }

    history.push({
        pathname: urls.search,
        search: `?tags=${tagId}`,
        state: { tagName },
    },
    {
        tagName
    });
    history.go() // я добавил
    //return redirect(urls.search+`?tags=${tagId}`)
}

export const pushHistoryWithOwner = (history, ownerId, ownerName) => {
    history.push({
        pathname: urls.search,
        search: `?owner=${ownerId}`,
        state: { ownerName },
    },
    {
        ownerName
    })
    history.go() // я добавил
}

export const pushHistoryWithDetails = (history, modId) => {
    history.push({
        pathname: urls.details.replace(/:modId/g, modId),
    })
    history.go() // я добавил
}

export const pushHistoryWithReview = (history, modId) => {
    history.push({
        pathname: urls.review.replace(/:modId/g, modId),
    })
    history.go() // я добавил
}

export const pushHistoryWithCreate = (history) => {
    history.push({
        pathname: urls.create,
        search: '',
    })
    history.go() // я добавил
}

export const pushHistoryWithErrorNotFound = (history) => {
    history.replace({
        pathname: urls.errorNotFound,
        search: '',
    })
    history.go() // я добавил
}

export const pushHistoryWithModderSection = (history) => {
    history.replace({
        pathname: urls.modderSection,
        search: '',
    })
    history.go() // я добавил
}

export const pushProlongateWithData = (history, rawData) => {
    history.replace({
        pathname: urls.prolongate,
        search: '?spa_id='+rawData.spaId+'&spa_username='+rawData.spaUsername+'&access_token='+rawData.access_token+'&expires='+rawData.expires,
    })
    history.go() // я добавил
}

export const pushHistoryWithTitle = (history, searchTitle) => {
    history.push({
        pathname: urls.search,
        search: `?title=${searchTitle}`,
        state: { searchTitle },
    },
    {
        searchTitle
    })
    history.go() // я добавил
}

export const getPrivacyPolicyUrlByRealm = (realm) => {
    const mapping = {
        eu: 'http://legal.eu.wargaming.net/en/privacy-policy/',
        us: 'http://legal.na.wargaming.net/en/privacy-policy/',
        ru: 'http://legal.ru.wargaming.net/ru/privacy-policy/',
        sg: 'http://legal.asia.wargaming.net/en/privacy-policy/',
    }
    return mapping[realm] || '#'
}