import React from "react"

export const DIALOG_INFORMATION_TITLE = "Важная информация!"

export const DIALOG_INFORMATION_CONTENT = <React.Fragment>Пожалуйста, обратите внимание: <mark>моды создаются игроками Мир танков, а не компанией Lesta</mark>. Все представленные на сайте моды проходят предварительные проверки, однако <mark>мы не несём ответственности за их работоспособность, качество и безопасность</mark>.</React.Fragment>
/*<span dangerouslySetInnerHTML={
    {__html: `Пожалуйста, обратите внимание: <mark>моды создаются игроками Мир танков, а не компанией Lesta</mark>. Все представленные на портале моды проходят предварительные базовые проверки, однако <mark>мы не несём ответственности за их работоспособность, качество и безопасность</mark>.`}
}></span>*/

export const DIALOG_INFORMATION_WELCOME = "Спасибо за понимание и приятной игры!"

export const DIALOG_INFORMATION_OK_BUTTON = "Понятно"
