import React from 'react';
import PropTypes from 'prop-types';

import { ACTION_VISIBLE_MOD } from './translations';

import styles from './ActionVisibleMod.module.css';

export default class ActionVisibleMod extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func,
    }

    render() {
        return (
            <div className={styles.base} onClick={this.props.onClick}>
                {ACTION_VISIBLE_MOD}
            </div>
        )
    }
}
