export const DIALOG_LOGIN_TITLE = `Авторизоваться на сайте с помощью аккаунта:` // `Выберите регион, в котором зарегистрирован ваш аккаунт:`

export const DIALOG_LOGIN_CAPTION = {
    lesta: `Lesta ID`,
    wargaming: `Недоступно`, // `Wargaming ID`
    ru: `СНГ`,
    eu: `Европа`,
    us: `Северная америка`,
    sg: `Азия`,
}
