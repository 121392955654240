import { connect } from 'react-redux';

//import { login } from '../../actions/CurrentAccount';
//import { closeDialog } from '../../actions/Dialog';

import UnavailablePage from '../../components/UnavailablePage/UnavailablePage';

const mapStateToProps = (state) => ({
    language: state.currentAccount.lang,
    realm: state.currentAccount.realm,
})

const mapDispatchToProps = () => ({
    //onLoginClick: login,
    //onCloseDialog: closeDialog,
    /*authorization(rawResponseData) {
        dispatch(prolongate(rawResponseData))
    },*/
})

export default connect(mapStateToProps, mapDispatchToProps)(UnavailablePage)
