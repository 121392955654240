import { batchActions } from 'redux-batched-actions';

import * as CommonMenu from '../actions/CommonMenu';
import { fetchWithSpinner, fetchWrapper as fetch } from '../utils/fetch';
import { urls } from '../utils/routing';
import { processNotifications } from '../utils/responseProcessing';

export const START_FETCHING = 'CURRENT_ACCOUNT_START_FETCHING'
export const STOP_FETCHING = 'CURRENT_ACCOUNT_STOP_FETCHING'
export const SET_LANGUAGE = 'CURRENT_ACCOUNT_SET_LANGUAGE'
export const SET_IS_BANNED = 'SET_IS_BANNED'

export const START_LOGIN = 'START_LOGIN'
export const STOP_LOGIN = 'STOP_LOGIN'
export const SET_ERROR_LOGIN = 'SET_ERROR_LOGIN'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_SPA_DATA = 'UPDATE_SPA_DATA'

export const SET_MOD_DOWNLOAD_ACCEPTED = 'CURRENT_ACCOUNT_SET_MOD_DOWNLOAD_ACCEPTED'
export const SET_OVERALL_LANGUAGE_FILTER = 'CURRENT_ACCOUNT_SET_OVERALL_LANGUAGE_FILTER'
export const SET_RULES_ACCEPTED = 'CURRENT_ACCOUNT_SET_RULES_ACCEPTED'
export const UPDATE_MODS_COUNT = 'UPDATE_MODS_COUNT'
export const SET_IE_ALERT_ACCEPTED = 'SET_IE_ALERT_ACCEPTED'
export const SET_EDGE_ALERT_ACCEPTED = 'SET_EDGE_ALERT_ACCEPTED'

export const startFetching = () => ({
    type: START_FETCHING,
})

export const stopFetching = () => ({
    type: STOP_FETCHING,
})

export const startLogin = () => ({
    type: START_LOGIN,
})

export const stopLogin = () => ({
    type: STOP_LOGIN,
})

export const updateSpaData = (rawResponseData, state) => {
    return({ 
    type: UPDATE_ACCOUNT, // UPDATE_SPA_DATA
    payload: {
        spaId: rawResponseData.spaId,
        spaUsername: rawResponseData.spaUsername,
    },
})}

export const updateSpaDataWithNotify = (rawResponseData, state) => {
    return({ 
    type: UPDATE_ACCOUNT, // UPDATE_SPA_DATA
    payload: {
        isUserStatisticsSufficient: rawResponseData.isUserStatisticsSufficient, // соответствует ли аккаунт загрузке модов
        count: rawResponseData.notifications.count,
        notifications: processNotifications(rawResponseData.notifications.notes, state),
        spaId: rawResponseData.spaId,
        spaUsername: rawResponseData.spaUsername,
    },
})}

export const toProlongate = (rawResponseData) => {
    return window.location.href = urls.prolongate+'?spa_id='+rawResponseData.spaId+'&spa_username='+rawResponseData.spaUsername+'&access_token='+rawResponseData.access_token+'&expires='+rawResponseData.expires
    //pushProlongateWithData(this.props.history, rawResponseData)
}

export const logoutUser = () => {
    return window.location.href = urls.logout;
}

export const updateAccount = () => ({
    type: UPDATE_ACCOUNT,
})

export const setLanguage = (code) => ({
    type: SET_LANGUAGE,
    code,
})

export const setRulesAccepted = () => ({
    type: SET_RULES_ACCEPTED,
})

export const setModDownloadAccepted = () => ({
    type: SET_MOD_DOWNLOAD_ACCEPTED,
})

export const setOverallLanguageFilter = (language) => ({
    type: SET_OVERALL_LANGUAGE_FILTER,
    payload: {
        language,
    },
})

export const setIsBanned = () => ({
    type: SET_IS_BANNED,
})

export const updateModsCount = (count) => ({
    type: UPDATE_MODS_COUNT,
    count,
})

export const setErrorLogin = (errorData) => ({
    type: SET_ERROR_LOGIN,
    payload: {
        errorData,
    },
})

export const login = (realm) => {
    return (dispatch) => {
        dispatch(startFetching())
        CommonMenu.login(realm)
    }
}

/* get user data from server lesta id and add it to state */
export const authorization = (rawData) => {
    return (dispatch, getState) => {
        const state = getState();

        const url = `https://tankimods.net/api/users/notifications/`

        //dispatch(startFetching()) //dispatch(startLogin())
        
        if (typeof(rawData.expires) === 'undefined' || rawData.expires === null ) {
            const fetchPromise = dispatch(batchActions([
                updateSpaData(rawData, state),
                //stopFetching(),
            ], UPDATE_ACCOUNT));

            return fetchPromise;
        } else {
            const fetchPromise = fetch(url, {method: 'POST', body: rawData}).promise
                .then((rawResponseData) => {
                    if (rawResponseData.status === 'error') {
                        if (rawResponseData.context.code === 417) {
                            dispatch(batchActions([
                                setErrorLogin(rawResponseData.context),
                                //stopFetching(),
                            ], SET_ERROR_LOGIN))
                        } else {
                            dispatch(batchActions([
                                setErrorLogin(rawResponseData.context),
                                //stopFetching(),
                            ], SET_ERROR_LOGIN))
                        }
                    } else {
                        dispatch(batchActions([
                            updateSpaDataWithNotify(rawResponseData, state),
                            //stopFetching(),
                        ], UPDATE_ACCOUNT))
                    }
                })
                .catch((errorData) => {
                    dispatch(batchActions([
                        setErrorLogin(errorData),
                        //stopFetching(),
                    ], SET_ERROR_LOGIN))
                })
            return fetchWithSpinner(dispatch, fetchPromise); // fetchPromise
        }
        // 
    }
}

/* prolongate user data */
export const prolongate = (rawData) => {
    return (dispatch, getState) => {
        const state = getState();

        const url = `https://tankimods.net/auth/prolongate/`
        
        dispatch(startLogin())

        const fetchPromise = fetch(url, {method: 'POST', body: rawData}).promise
            .then((rawResponseData) => {
                if (rawResponseData.status === 'error') {
                    if (rawResponseData.context.code === 417) {
                        dispatch(batchActions([
                            setErrorLogin(rawResponseData.context),
                            logoutUser(),
                            stopLogin(),
                        ], SET_ERROR_LOGIN))
                    } else if (rawResponseData.context.msg === 'RT_JWT_NOT_FOUND_IN_BD') {
                        dispatch(batchActions([
                            setErrorLogin(rawResponseData.context),
                            logoutUser(),
                            stopLogin(),
                        ], SET_ERROR_LOGIN))
                    } else {
                        dispatch(batchActions([
                            setErrorLogin(rawResponseData.context),
                            stopLogin(),
                        ], SET_ERROR_LOGIN))
                    }
                } else {
                    dispatch(batchActions([
                        updateSpaData(rawResponseData, state),
                        toProlongate(rawResponseData),
                        stopLogin(),
                    ], UPDATE_ACCOUNT))
                }
            })
            .catch((errorData) => {
                dispatch(batchActions([
                    setErrorLogin(errorData),
                    stopLogin(),
                ], SET_ERROR_LOGIN))
            })
        return fetchWithSpinner(dispatch, fetchPromise)
    }
}

/* fetching notifications user menu */

export const setIEAlertAccepted = () => ({
    type: SET_IE_ALERT_ACCEPTED,
})

export const setEdgeAlertAccepted = () => ({
    type: SET_EDGE_ALERT_ACCEPTED,
})
