import { concat, get } from 'lodash';

import * as actions from '../actions/News';
import { NEWS_ITEMS_PER_PAGE } from '../actions/News';

const initialState = {
    count: 0,
    initialized: true,
    isError: false,
    isFetching: false,
    isFetchedAll: false,
    items: [],
    page: 1,
    offset: 0,
}

export const news = (state = initialState, action) => {
    const payload = get(action, 'payload')
    switch (action.type) {
        case actions.START_FETCHING_NEWS:
            return {
                ...state,
                isError: false,
                isFetching: true,
            }
        case actions.SET_ERROR_FETCHING_NEWS:
            return {
                ...state,
                isFetching: false,
                isError: true,
            }
        case actions.STOP_FETCHING_NEWS:
            return {
                ...state,
                isFetching: false,
            }
        case actions.UPDATE_ITEMS_NEWS:
            return {
                ...state,
                isError: false,
                items: state.offset ?
                    concat(state.items, action.payload.items) :
                    action.payload.items,
                //concat(state.items, action.payload.items),
                isFetchedAll: (
                    state.offset + NEWS_ITEMS_PER_PAGE >= action.payload.count
                ),
                count: action.payload.count,
            }
        case actions.INCREMENT_FETCHING_NEWS_OFFSET:
            return {
                ...state,
                offset: state.isFetchedAll ?
                    state.offset :
                    state.offset + NEWS_ITEMS_PER_PAGE,
            }
        case actions.CHANGE_NEWS_PAGE:
            return {
                ...state,
                page: payload.page,
            }
        default:
            return state
    }
}
