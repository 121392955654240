import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AddMod.module.css';

export default class AddMod extends React.PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }

    handleClick = () => {
        this.gtmTagRef.click()
        this.props.onClick()
    }

    render() {
        const addModClasses = classNames(styles.base, {
            [styles.isDisabled]: this.props.isDisabled,
        })

        return (
            <div className={addModClasses}>
                <div className={classNames('ev_click-add-mod') } ref={c => (this.gtmTagRef = c)} />
                <div className={styles.body} onClick={this.handleClick} />
            </div>
        )
    }
}
