import React from "react";
import { Link } from "react-router-dom";
import { urls } from "../../../utils/routing";
import { checkDate, formatDateTime } from "../../../utils/formatting";

export const DIALOG_SHOW_USER_DATA_TITLE = (ownerId) => {
    return <React.Fragment>Просмотр аккаунта Lesta ID {ownerId}</React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_NICKNAME = (ownerName) => {
    return <React.Fragment>Игровой ник: <span style={{textTransform: "none"}}>{ownerName}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_ID = (bdId) => {
    return <React.Fragment>ID (на сайте): <span style={{textTransform: "none"}}>{bdId}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_CLIENT = (client) => {
    return <React.Fragment>Игровой клиент: <span style={{textTransform: "none"}}>{client}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_LAST_VISIT = (data) => {
    let new_date = checkDate(data.replace('.', '-').slice(0, 11));
    let new_time = data.slice(11);

    return <React.Fragment>Активность на сайте: <span style={{textTransform: "none"}}>{new_date} в {new_time}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_ROLE = `Роль аккаунта:`

export const DIALOG_SHOW_USER_DATA_REGISTRATION = (data) => {
    return <React.Fragment>Дата регистрации (Lesta): <span style={{textTransform: "none"}}>{data}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_BIRTHDAY = (year) => {
    let birthDateString = formatDateTime(year).replace('.', '').slice(0, 8); //this.props.data.birthdayAcc;
    const today = new Date();
    const birthDate = new Date(
        birthDateString.slice(0, 4),
        birthDateString.slice(4, 6)-1,
        birthDateString.slice(6)
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
            age--;
    }
    let goda = /\d*1\d$/.test(age) || /[05-9]$/.test(age) ? 'лет' : ( /1$/.test(age) ? 'год' : 'года');
    
    //return <React.Fragment>{age} {goda} в игре</React.Fragment>
    return `${age} ${goda} в игре`
}

export const DIALOG_SHOW_USER_DATA_MODS = (count, ownerId) => {
    return count !== 0 ? <React.Fragment>
            Загружено модов: <Link to={`${urls.search}?owner=${ownerId}`} target="_blank" title="Посмотреть моды">{count}</Link>
        </React.Fragment> 
        : <React.Fragment>Загружено модов: <span style={{textTransform: "none"}}>{count}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_TICKETS = (count) => {
    return count !== 0 ? <React.Fragment>Тикеты в поддержку: {count}</React.Fragment> 
        : <React.Fragment>Тикеты в поддержку: <span style={{textTransform: "none"}}>{count}</span></React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_COMMENTS = (count) => {
    return count !== 0 ? <React.Fragment>Комментарии на сайте: {count}</React.Fragment>
        : <React.Fragment>Комментарии на сайте: <span style={{textTransform: "none"}}>{count}</span></React.Fragment>
}

export const MAX_ITEMS_TO_CHECK = 1;

export const DIALOG_SHOW_USER_DATA_PROBLEMS_CAPTION = (checkbox, time) => {
    return time ? <React.Fragment>Режим «read only»: {checkbox} <span style={{textTransform: "none"}}>до {time}</span></React.Fragment> 
        : <React.Fragment>Режим «read only»: {checkbox}</React.Fragment>
}

export const DIALOG_SHOW_USER_DATA_ROLE_TYPE_USER = `Пользователь`;

export const DIALOG_SHOW_USER_DATA_ROLE_TYPE_MODER = `Модератор`;

export const DIALOG_SHOW_USER_DATA_ROLE_TYPE_ADMIN = `Администратор`;

export const DIALOG_SHOW_USER_DATA_ROLE_TYPE_ROBOT = `Робот`;

export const ROLE_CATEGORIES = [
    {
        id: 1,
        tag: 'light',
        value: 'user',
        caption: DIALOG_SHOW_USER_DATA_ROLE_TYPE_USER,
    },
    {
        id: 2,
        tag: 'blue',
        value: 'moder',
        caption: DIALOG_SHOW_USER_DATA_ROLE_TYPE_MODER,
    },
    {
        id: 3,
        tag: 'warning',
        value: 'admin',
        caption: DIALOG_SHOW_USER_DATA_ROLE_TYPE_ADMIN,
    },
    {
        id: 4,
        tag: 'yellow',
        value: 'robot',
        caption: DIALOG_SHOW_USER_DATA_ROLE_TYPE_ROBOT,
    },
];

export const DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_NO_TYPE = `Пожалуйста выберите роль пользователя на сайте`;

export const DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_EXPECTED_TIME = `Необходимо ввести срок действия режима`;

export const DIALOG_SHOW_USER_DATA_ATTENTION = `В целях безопасности не указывайте здесь личную информацию о себе или о ком-либо другом, так как мы не сможем защитить её в соответствии с Правилами сайта`;

export const DIALOG_SHOW_USER_DATA_BUTTON_OK = `Сохранить изменения`;
