import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { toggleList } from '../../../utils/filtration';
import { getUserId, getUserRole } from '../../../utils/functions';
import { formatDateTime } from '../../../utils/formatting';

import Caption from '../../Caption/Caption';
import EditLabelError from '../../Edit/EditLabelError/EditLabelError';
import Tag from '../../Tag/Tag';
import TitleDialog from '../../TitleDialog/TitleDialog';
import ButtonBlueOutlineLarge from '../../Buttons/ButtonBlueOutlineLarge/ButtonBlueOutlineLarge';

import Checkbox from '../../Checkbox/Checkbox';
import Input from '../../Input/Input';

import { 
    //DIALOG_SHOW_USER_DATA_ATTENTION,
    DIALOG_SHOW_USER_DATA_BUTTON_OK,
    DIALOG_SHOW_USER_DATA_PROBLEMS_CAPTION,
    DIALOG_SHOW_USER_DATA_TITLE,
    MAX_ITEMS_TO_CHECK,
    ROLE_CATEGORIES,
    DIALOG_SHOW_USER_DATA_NICKNAME,
    DIALOG_SHOW_USER_DATA_ID,
    DIALOG_SHOW_USER_DATA_CLIENT,
    DIALOG_SHOW_USER_DATA_LAST_VISIT,
    DIALOG_SHOW_USER_DATA_ROLE,
    DIALOG_SHOW_USER_DATA_REGISTRATION,
    DIALOG_SHOW_USER_DATA_BIRTHDAY,
    DIALOG_SHOW_USER_DATA_MODS,
    DIALOG_SHOW_USER_DATA_TICKETS,
    DIALOG_SHOW_USER_DATA_COMMENTS,
    DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_EXPECTED_TIME,
    DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_NO_TYPE,
} from './translations';

import styles from './DialogShowUserData.module.css';

export default class DialogShowUserData extends React.Component {
    static propTypes = {
        ownerId: PropTypes.number,
        realm: PropTypes.string,

        data: PropTypes.shape({
            id: PropTypes.number,
            spaUsername: PropTypes.string,
            spaId: PropTypes.number,
            client: PropTypes.string,
            birthdayAcc: PropTypes.string, // time lesta
            lastVisit: PropTypes.string, // time
            role: PropTypes.string,

            countMods: PropTypes.number,
            countTickets: PropTypes.number,
            countComments: PropTypes.number,

            readOnly: PropTypes.number, // on OR off
            roExpires: PropTypes.string, // time
        }),

        onComponentWillMount: PropTypes.func,
        onShowDialogError: PropTypes.func,
        onSubmitDialog: PropTypes.func,
    }

    constructor() {
        super()

        this.state = {
            errorMessage: null,
            isErrorProblemType: false,
            isErrorReadOnly: false,
            typeIds: [],
            isCheckedAll: false,
            readOnly: "0", // default - not readOnly from bd - this for Input
            ro_expires: null, // default
            isBoxChecked: false, // for read only
            isBoxDisabled: false, // for read only
        }
    }

    componentDidMount() {
        this.props.onComponentWillMount(this.props.ownerId)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data.readOnly) { // nextProps.data.roExpires
            this.setState({
                ro_expires: nextProps.data.roExpires,
                isBoxChecked: true,
                isBoxDisabled: false,
            })
        }

        if (nextProps.data.role) {
            let userRole = [];
            userRole.push(ROLE_CATEGORIES.find(el => el.value === nextProps.data.role).id)

            this.setState({
                typeIds: userRole,
                isCheckedAll: userRole.length >= MAX_ITEMS_TO_CHECK,
                isErrorProblemType: false,
            })
        }

        /*if (nextProps.data.role === "admin" || nextProps.data.role === "robot") {
            this.setState({
                isBoxChecked: false,
                isBoxDisabled: true,
            })
        }*/
    }

    handleDialogSubmit = () => {
        let errorMessage = null
        let hasError = false
        if (isEmpty(this.state.typeIds)) {
            errorMessage = DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_NO_TYPE;
            this.setState({
                isErrorProblemType: true,
                errorMessage,
            })
            hasError = true
        }

        // check if click checkBox On and dont have in props roExpires
        if (this.state.isBoxChecked && !this.state.ro_expires) {
            errorMessage = DIALOG_SHOW_USER_DATA_ERROR_MESSAGE_EXPECTED_TIME;
            this.setState({
                isErrorReadOnly: true,
                errorMessage,
            })
            hasError = true
        }

        if (hasError) {
            return
        }
        
        const data = { 
            ...this.state, 
            roExpires: this.state.ro_expires !== this.props.data.roExpires ? this.state.ro_expires : null, 
            userId: this.props.data.spaId, 
            adminId: getUserId() 
        }
        this.props.onSubmitDialog(data)
        
    }

    handleUserTypeClick = (typeId) => {
        if(getUserRole() === 'admin') {
            const checkedItems = toggleList(this.state.typeIds, typeId)
            //const errorType = checkedItems.length === 0 ? FIELD_VALIDATION_ERROR_TYPES.EMPTY_VALUES : false
            this.setState({
                typeIds: checkedItems,
                isCheckedAll: checkedItems.length >= MAX_ITEMS_TO_CHECK,
                isErrorProblemType: false,
                errorMessage: null,
            })
        } else {
            return
        }
    }

    handleCheckBoxChecked = () => {
        this.setState({
            isBoxChecked: !this.state.isBoxChecked,
            isBoxDisabled: false,
            isErrorReadOnly: false,
            errorMessage: null,
        })
    }

    handleValueChange = (value) => {
        // update state ro_expires, add `value` day to dateNow
        let currentDate = new Date();
        let newRoExpires;
        
        if (this.props.data.roExpires) {
            currentDate = new Date(this.props.data.roExpires);
            newRoExpires = new Date(currentDate.setDate(currentDate.getDate() + Number(value)));
        } else {
            newRoExpires = new Date(currentDate.setDate(currentDate.getDate() + Number(value)));
        }

        this.setState({
            readOnly: value,
            ro_expires: newRoExpires.toISOString(),
            isErrorReadOnly: false,
            errorMessage: null,
        })
    }

    renderErrorMessage() {
        return this.state.errorMessage ? (
            <div className={styles.fileError}>
                <EditLabelError message={this.state.errorMessage} />
            </div>
        ) : null
    }

    renderTags() {
        return ROLE_CATEGORIES.map((item, index) => {
            const isDisabled = this.state.isCheckedAll && !this.state.typeIds.includes(item.id)
            return (
                <div className={styles.tag} key={`role-tag-${item.value}-${index}`}>
                    <Tag
                        caption={item.caption}
                        id={item.id}
                        isChecked={this.state.typeIds.includes(item.id)}
                        isDisabled={isDisabled}
                        isError={this.state.isErrorProblemType}
                        type={item.tag}
                        onClick={this.handleUserTypeClick}
                    />
                </div>
            )
        })
    }

    renderCheckBox() {
        const isChecked = this.state.isBoxChecked;
        const isDisabled = this.state.isBoxDisabled; 

        return (
            <span className={styles.checkbox}>
                <Checkbox
                    caption={isChecked === true ? 'On' : 'Off'}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    onChange={this.handleCheckBoxChecked} // () => {this.props.onModVersionVisibilityChange(item.id, !item.isVisible)
                />
            </span>
        )
    }

    renderReadOnlyCheckBox() {
        return this.state.isBoxChecked ? DIALOG_SHOW_USER_DATA_PROBLEMS_CAPTION(this.renderCheckBox(), formatDateTime(this.state.ro_expires))
            : DIALOG_SHOW_USER_DATA_PROBLEMS_CAPTION(this.renderCheckBox())
    }

    renderIputReadOnly() {
        return this.state.isBoxChecked ? 
            <>
            <span style={{margin: "auto 10px auto 0"}}>Включить режим на </span>
            <div className={styles.readOnly_input}>
                <Input
                    value={this.state.readOnly}
                    type="number"
                    isError={!!this.state.isErrorReadOnly}
                    onChange={this.handleValueChange}
                />
            </div>
            <span style={{margin: "auto 0 auto 10px"}}> дней</span>
            </> : null
    }

    render() {
        return (
            <div className={styles.base}>
                <TitleDialog>{DIALOG_SHOW_USER_DATA_TITLE(this.props.data.spaId)}</TitleDialog>
                <div className={styles.form}>
                    {/* <hr className={styles.hr} /> */}
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_ID(this.props.data.id)}</Caption>
                    </div>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_NICKNAME(this.props.data.spaUsername)}</Caption>
                    </div>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_CLIENT(this.props.data.client)}</Caption>
                    </div>
                    <div className={styles.caption} title={DIALOG_SHOW_USER_DATA_BIRTHDAY(this.props.data.birthdayAcc)}>
                        <Caption>{DIALOG_SHOW_USER_DATA_REGISTRATION(formatDateTime(this.props.data.birthdayAcc))}</Caption> 
                    </div>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_LAST_VISIT(formatDateTime(this.props.data.lastVisit))}</Caption> 
                    </div>
                    <div className={styles.caption}>
                        <Caption isRequired isError={this.state.isErrorProblemType}>{DIALOG_SHOW_USER_DATA_ROLE}</Caption>
                    </div>
                    <div className={styles.tags}>
                        {this.renderTags()}
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_MODS(this.props.data.countMods, this.props.data.spaId)}</Caption> 
                    </div>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_TICKETS(this.props.data.countTickets)}</Caption> 
                    </div>
                    <div className={styles.caption}>
                        <Caption>{DIALOG_SHOW_USER_DATA_COMMENTS(this.props.data.countComments)}</Caption> 
                    </div>
                    <hr className={styles.hr} />
                    <div className={styles.caption}>
                        <Caption isError={this.state.isErrorReadOnly}>
                            {this.renderReadOnlyCheckBox()}
                        </Caption>
                    </div>
                    <div className={styles.readOnly}>
                        {this.renderIputReadOnly()}
                    </div>
                    {this.renderErrorMessage()}
                    {/* <p className={styles.attention}>
                        {DIALOG_SHOW_USER_DATA_ATTENTION}
                    </p> */}
                    <ButtonBlueOutlineLarge onClick={this.handleDialogSubmit}>
                        {DIALOG_SHOW_USER_DATA_BUTTON_OK}
                    </ButtonBlueOutlineLarge>
                </div>
            </div>
        )
    }
}
