export const TERMSOFUSE_TITLE = `Правила модов`;

export const CHOOSE_CATEGORY = `Выберите категорию`;

export const CATEGORY_ACCOUNT = `Аккаунт`;

export const CATEGORY_TECHNICAL = `Технические вопросы`;

export const SOLUTION_NOT_FOUND = `Не нашли решение?`;

export const SOLUTION_INFO = `Опишите возникшую ситуацию — и мы с радостью поможем Вам.`;

export const BUTTON_SUPPORT = `Обратиться в поддержку`;

export const PLEASE_LOGIN = `Чтобы продолжить, пожалуйста, авторизуйтесь на сайте`;

export const SUPPORT_ISSUES = [
    {
        id: 1,
        category: CATEGORY_ACCOUNT,
        url: "/support/account/",
        img: `https://tankimods.net/documents/img/account_management_.svg`, // https://cdn-kbms.gcdn.co/documents/account_management_fsQapEL.svg
    }, 
    {
        id: 2,
        category: CATEGORY_TECHNICAL,
        url: "/support/technical/",
        img: `https://tankimods.net/documents/img/technical_issues_.svg`, // https://cdn-kbms.gcdn.co/documents/technical_issues_Iiyfaab.svg
    }
]