import React from "react"

export const SUBSCRIPTIONS_PAGE_TITLE = (count) => {
    return <React.Fragment>Мои подписки: <mark>{count}</mark></React.Fragment>
}

export const SUBSCRIPTIONS_PAGE_TITLE_WITHOUT_COUNTER = () => {
    return `Мои подписки`
}

export const SUBSCRIPTIONS_PAGE_UNAUTHORIZED_MESSAGE = `Список любимых модификаций доступен только авторизованному пользователю. Войди, чтобы увидеть твои подписки.`

export const SUBSCRIPTIONS_PAGE_LOGIN = `Войти`

export const SUBSCRIPTIONS_PAGE_LAST_UPDATED_TITLE = (count) => { 
    return <React.Fragment>Последние обновления: <mark>{count}</mark></React.Fragment>
}

export const SUBSCRIPTIONS_PAGE_FAVORITES_TITLE = (count) => { 
    return <React.Fragment>Избранные: <mark>{count}</mark></React.Fragment>
}