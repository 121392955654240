import React from 'react';
import PropTypes from 'prop-types';
//import ReactRouterPropTypes from 'react-router-prop-types';

import Tag from '../Tag/Tag';
import { urls } from '../../utils/routing';

import {ALL_CAPTION} from '../../translations';

//import { toggleList } from '../../utils/filtration';
import { pushHistoryWithTags } from '../../utils/routing';

import styles from './Categories.module.css';


export const CATEGORIES_CAPTION = 'Категории:';

export default class Categories extends React.PureComponent {
    static propTypes = {
        tags: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            caption: PropTypes.string.isRequired,
        }).isRequired).isRequired,
        //history: ReactRouterPropTypes.history.isRequired,
        checkedTags: PropTypes.array,
        isAllTagChecked: PropTypes.bool, // отвечает за выбранный tag `ВСЕ` категории
    }
    
    handleTagChange = (checkedTag) => {
        // ниже нужно для того чтобы соритровать несколько тэгов, т.е. модпаки и озвучки и т.д.
        //const checkedTags = toggleList(this.props.checkedTags, checkedTag)
        
        pushHistoryWithTags(this.props.history, checkedTag)
        /*if (checkedTags.length === 0 || checkedTags.length === this.props.tags.length) {
            pushHistoryWithLanding(this.props.history)
        } else {
            pushHistoryWithTags(this.props.history, checkedTags)
        }*/
    }

    handleResetClick = () => {
        //pushHistoryWithLanding(this.props.history)
    }

    handleClickTag = () => {
        if ( !this.props.isAllTagChecked ) {
            return window.location.href = urls.landing
            //pushHistoryWithLanding(this.props.history)
        } else {
            return false;
        }
    }

    renderTags() {
        return this.props.tags.map((tag, index) => {
            const isChecked = this.props.checkedTags.includes(tag.id)
            return (
                <div className={styles.tag} key={`mod-tag-${tag.id}-${index}`}>
                    <Tag
                        id={tag.id}
                        caption={tag.caption}
                        isChecked={isChecked}
                        onClick={this.handleTagChange} // (tag.id)
                        //onClick={ () => {return window.location.href = '/search/?tags='+tag.id} }
                    />
                </div>
            )
        })
    }

    render() {
        return (
            <div className={styles.base}>
                <span className={styles.caption}>{CATEGORIES_CAPTION}</span>
                <div className={styles.tag}>
                    <Tag
                        id="all"
                        caption={ALL_CAPTION}
                        isChecked={this.props.isAllTagChecked}
                        onClick={this.handleClickTag} // {this.handleResetClick}
                    />
                </div>
                {this.renderTags()}
            </div>
        )
    }
}
