import React from 'react';
import PropTypes from 'prop-types';

import styles from './EditLabelText.module.css';

export default class EditLabelText extends React.PureComponent {
    static propTypes = {
        message: PropTypes.node.isRequired,
    }

    render() {
        return (
            <div className={styles.base}>
                {this.props.message}
            </div>
        )
    }
}
