import { connect } from 'react-redux';
import { union, keys } from 'lodash';

import {
  changeSearchResultItemsOrdering,
  fetchItems,
  incrementSearchingOffset,
  resetSearchResultItems,
} from '../../actions/SearchPage';
import {
  incrementDownloadCount,
  processDownloadMod,
} from '../../actions/Details';

import SearchPage from '../../components/SearchPage/SearchPage';
import settings from '../../settings';

import { ALL_GAME_VERSIONS } from '../../translations';

const mapStateToProps = (state) => {
  let resultMap = {}
  resultMap.gameVersions = union(settings.gameVersions, [ALL_GAME_VERSIONS])
  resultMap.filtered = state.searchPage.filtered
  resultMap.isModDownloadAccepted = state.persistedValues.isModDownloadAccepted
  resultMap.overallFilters = state.gallery.overallFilters
  resultMap.tags = keys(state.tags).map((tagId) => ({ id: +tagId, caption: state.tags[tagId].title }))
  
  return resultMap
}

const mapDispatchToProps = (dispatch) => ({
  changeItemsOrdering: (filterParams) => {
    dispatch(changeSearchResultItemsOrdering(filterParams.ordering))
    dispatch(fetchItems(filterParams))
  },
  fetchItems: (filterParams) => {
    dispatch(resetSearchResultItems())
    dispatch(fetchItems(filterParams))
  },
  loadMoreItems: (filterParams) => {
    dispatch(incrementSearchingOffset())
    dispatch(fetchItems(filterParams))
  },
  onDownloadClick: (isModDownloadAccepted, isModDownloaded, modId, title, versions) => {
    if (isModDownloaded) {
      dispatch(incrementDownloadCount(modId))
    } else {
      dispatch(processDownloadMod(isModDownloadAccepted, modId, title, versions))
    }
  },
  resetComponent: () => {
    dispatch(resetSearchResultItems())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
