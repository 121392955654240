import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ActionSubscribe.module.css';

export const ACTION_SUBSCRIBE = `Подписаться на обновления`;
export const ACTION_SUBSCRIBE_ACTIVE = `Вы подписаны на обновления`;


export default class ActionSubscribe extends React.PureComponent {
    static propTypes = {
        isSubscribed: PropTypes.bool,
        isDisabled: PropTypes.bool,

        onFollowMod: PropTypes.func,
        onUnsubscribeMod: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            isSubscribed: this.props.isSubscribed,
        }
    }

    handleClick = () => {
        if (!this.props.isDisabled) {
            if (this.state.isSubscribed) {
                this.props.onUnsubscribeMod()
            } else {
                this.props.onFollowMod()
            }
            this.setState({ isSubscribed: !this.state.isSubscribed })
        }
    }

    renderCaption() {
        if (this.props.isDisabled) {
            return
        }
        return !this.state.isSubscribed ? ACTION_SUBSCRIBE : ACTION_SUBSCRIBE_ACTIVE
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isSubscribed]: this.state.isSubscribed,
            [styles.isDisabled]: this.props.isDisabled,
        })

        return (
            <div className={classNameBase} onClick={this.handleClick}>
                {this.renderCaption()}
            </div>
        )
    }
}
