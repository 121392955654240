import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ActionReport.module.css';

export const ACTION_REPORT = `Сообщить о проблеме`;
export const ACTION_REPORT_COMPLETED = `Вы сообщили о проблеме`;


export default class ActionReport extends React.PureComponent {
    static propTypes = {
        isCompleted: PropTypes.bool,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func,
    }

    handleClick = () => {
        if (this.props.onClick && !this.props.isCompleted && !this.props.isDisabled) {
            this.props.onClick()
        }
    }

    renderCaption() {
        if (this.props.isDisabled) {
            return
        }

        return this.props.isCompleted ? ACTION_REPORT_COMPLETED : ACTION_REPORT
    }

    render() {
        const classNameBase = classNames(styles.base, {
            [styles.isCompleted]: this.props.isCompleted,
            [styles.isDisabled]: this.props.isDisabled,
        })

        return (
            <div className={classNameBase} onClick={this.handleClick}>
                {this.renderCaption()}
            </div>
        )
    }
}
