import React from 'react';

import FileUploadCaption from '../../FileUploadCaption/FileUploadCaption';

export const EDIT_UPLOAD_MOD_LABEL = "Загрузите файл мода"

export const EDIT_UPLOAD_MOD_FILE_FORMAT = (isError) => {
    let ext0 = (<FileUploadCaption isError={isError} caption="zip" />)
    let ext1 = (<FileUploadCaption isError={isError} caption="rar" />)
    let ext2 = (<FileUploadCaption isError={isError} caption="7z" />)
    let ext3 = (<FileUploadCaption isError={isError} caption="wotmod" />)
    return (
        <React.Fragment>
            Формат файла: {ext0}, {ext1}, {ext2} или {ext3}
        </React.Fragment>
    )
}

export const EDIT_UPLOAD_MOD_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption={"Не более 2GB"} />
    return (
        <React.Fragment>
            Размер: {size}
        </React.Fragment>
    )
}

export const EDIT_UPLOAD_MOD_INVALID_FILE_TYPE_ERROR = "Вы загружаете файл неверного формата"

export const EDIT_UPLOAD_MOD_INVALID_FILE_SIZE_ERROR = "Вы загружаете слишком большой файл"

export const EDIT_UPLOAD_MOD_COMMON_ERROR = "Не удалось загрузить файл. Попробуйте ещё раз."

export const EDIT_UPLOAD_MOD_NO_FILE_SELECTED = "Необходимо загрузить файл мода"
