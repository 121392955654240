import { connect } from 'react-redux';

import { openDialogError } from '../../actions/DialogError';
import { closeDialog } from '../../actions/Dialog';
import { fetchUserData, updUserData } from '../../actions/AdminPanel';
//import { support } from '../../actions/Details';

import DialogShowUserData from '../../components/Dialogs/DialogShowUserData/DialogShowUserData';

const mapStateToProps = (state) => ({
    realm: state.currentAccount.realm,
    data: state.adminPanel.data,
})

const mapDispatchToProps = (dispatch) => ({
    onComponentWillMount: (ownerId) => {
        dispatch(fetchUserData(ownerId))
    },

    onShowDialogError: (content) => {
        dispatch(openDialogError(content))
    },
    onSubmitDialog: (data) => {
        dispatch(updUserData(data))
        dispatch(closeDialog())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogShowUserData)
