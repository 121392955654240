import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { Link } from 'react-router-dom';
//import ClampLines from 'react-clamp-lines';
//import Image from 'react-image-file-resizer';
import { first, get, includes } from 'lodash';

import { formatDate, formatFileSize } from '../../utils/formatting';
import { getUsername } from '../../utils/functions';
import { getReviewUrl } from '../../utils/routing';
import { MOD_STATE } from '../../utils/constants';

import LinesClamp from '../LinesClamp/LinesClamp';
import ButtonBlueSmall from '../Buttons/ButtonBlueSmall/ButtonBlueSmall';
import { ActionPublished } from '../ActionPublished/ActionPublished';
import { ActionRejected } from '../ActionRejected/ActionRejected';
import ModState from '../ModState/ModState';
import Select from '../Select/Select';

import {
    SUBSCRIPTIONS_ITEM_VERSION,
    SUBSCRIPTIONS_ITEM_UDATED_DATE,
    SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL
} from './translations';

import './ModderItem.css';

export default class ModderItem extends React.PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        mod: PropTypes.shape({
            cover: PropTypes.string,
            id: PropTypes.number.isRequired,
            state: PropTypes.number,
            mark: PropTypes.number.isRequired,
            modVersion: PropTypes.string.isRequired,
            status: PropTypes.number,
            title: PropTypes.string.isRequired,
            versions: PropTypes.arrayOf(PropTypes.shape({
                createdAt: PropTypes.string.isRequired,
                downloadUrl: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                versionFileOriginalName: PropTypes.string.isRequired,
                versionFileSize: PropTypes.number,
            })).isRequired,
        }),
        isUpdated: PropTypes.bool,
        isNew: PropTypes.bool,

        fetchItems: PropTypes.func.isRequired,
        onIncrementDownloadCount: PropTypes.func.isRequired,
        onPublished: PropTypes.func.isRequired,
        onRejected: PropTypes.func.isRequired,
    }

    handleDownloadClick = () => {
        this._downloadLinkRef.click()
        if (this.props.isUpdated) {
            Promise.resolve(
                this.props.onIncrementDownloadCount(this.props.mod.id),
            ).then(() => {
                this.props.fetchItems()
            })
        }
    }

    handleRejected = () => {
        let userName = getUsername()
        this.props.onRejected(this.props.mod.id, userName)
    }

    handlePublished = () => {
        let userName = getUsername()
        this.props.onPublished(this.props.mod.id, userName, this.props.id)

        // Notification on Windows from Browser from our site
        /*Notification.requestPermission()
            .then(permission => {
                if (permission === 'granted') {
                    new Notification('Hello world!')
                }
            })*/
    }

    renderPublishedButton() {
        // DEFAULT: -1, DRAFT: 0, IN_REVIEW: 1, REJECTED: 2, PUBLISHED: 3, PUBLISHED_UPDATE_IN_REVIEW: 4, PUBLISHED_UPDATE_REJECTED: 5, HIDDEN: 6 
        /*if (!this.props.isUpdated) {
            return
        }*/
        if (this.props.mod.state === 0 || this.props.mod.state === 3 || this.props.mod.state === 6) {
            return
        }

        return (
            <span className="ModderItem_button" title="Опубликовать">
                <ActionPublished onClick={this.handlePublished} />
            </span>
        )
    }

    // render other edit buttons
    renderButtons = () => {
        return (
            <div className="ModderItem_buttons">
                {this.renderPublishedButton()}
                <span className="ModderItem_button" title="Отклонить">
                    <ActionRejected onClick={this.handleRejected} />
                </span>
            </div>
        )
    }

    // render view status mode
    renderState() {
        const stateValues = Object.values(MOD_STATE)
        if (!includes(stateValues, this.props.mod.state)) {
            return null
        }
        return (
            <div className="ModderItem_statusMod">
                <ModState isBlock state={this.props.mod.state} />
            </div>
        )
    }

    handleClickSelector = () => {
        // position: absolute;

        if (document.getElementById(this.props.mod.id).classList.contains("ModderItem_posAbsolute")) {
            document.getElementById(this.props.mod.id).classList.remove("ModderItem_posAbsolute");
        } else {
            document.getElementById(this.props.mod.id).classList.add("ModderItem_posAbsolute");
        }
    }

    handleStateModChange = () => {
        //
    }

    renderFilterSelect = () => {
        const modStateItems = [
            {id: 0, lang: "Черновик", state: 0},
            {id: 1, lang: "Проверяется", state: 1},
            {id: 2, lang: "Отклонено", state: 2},
            {id: 3, lang: "Опубликовано", state: 3},
            {id: 4, lang: "Обновление проверяется", state: 4},
            {id: 5, lang: "Обновление отклонено", state: 5},
            {id: 6, lang: "Скрыт", state: 6}
        ];

        return (
            <div className="ModderItem_field" onClick={ () => {this.handleClickSelector()} }>
                <div className="ModderItem_select" id={this.props.mod.id} >
                    <Select
                        items={modStateItems.map((item) => ({
                            value: item.id,
                            caption: item.lang,
                        }))}
                        selectedValue={this.props.mod.state} // this.state.gameVersionId
                        onItemChange={this.handleStateModChange}
                    />
                </div>
            </div>
        )
    }

    // render download block
    renderDownload = (version) => {
        return get(version, 'downloadUrl') ? (
            <div className="ModderItem_download">
                <div className="ModderItem_downloadButton">
                    <ButtonBlueSmall
                        isWide
                        onClick={this.handleDownloadClick}
                    >
                        {SUBSCRIPTIONS_ITEM_DOWNLOAD_LABEL(formatFileSize(get(version, 'versionFileSize', 0)))}
                    </ButtonBlueSmall>
                    <a
                        className="ModderItem_hidden"
                        ref={c => (this._downloadLinkRef = c)}
                        href={get(version, 'downloadUrl')}
                        download={get(version, 'versionFileOriginalName')}
                    > </a>
                </div>
            </div>
        ) : null
    }

    render() {
        const classNameModDataItem = className("ModderItem_modDataItem", {
            "ModderItem_isNew": this.props.isNew,
        })
        const version = first(this.props.mod.versions)
        return (
            <div className="ModderItem_inner">
                <div className="ModderItem_base">

                    {this.renderState()}

                    <Link to={getReviewUrl(this.props.mod.id)} className="ModderItem_mod">
                        <div className="ModderItem_cover">
                            {this.props.mod.cover ? (
                                /*<Image
                                    src={prosp.mod.cover}
                                    width={151}
                                    height={85}
                                />*/
                                <img src={this.props.mod.cover} width={151} height={85} alt="Cover" />
                            ) : null}
                        </div>
                        <div className="ModderItem_title" title={this.props.mod.title}>
                            <LinesClamp 
                                text={this.props.mod.title} 
                                lines={2}
                            />
                            {/* <ClampLines
                                className="ModderItem_clampLines"
                                text={this.props.mod.title}
                                lines={2}
                                buttons={false}
                            /> */}
                        </div>
                    </Link>

                    {/* <div className="ModderItem_rate"><Rating mark={this.props.mod.mark} isLight /></div> */}
                    {/* <div className="ModderItem_rate"><span>{this.props.mod.mark}</span></div> */}

                    <div className="ModderItem_modData">
                        <span className="ModderItem_modDataItem">
                            {SUBSCRIPTIONS_ITEM_VERSION(get(version, 'modVersion', ''))}
                        </span>
                        <span className={classNameModDataItem}>
                            {SUBSCRIPTIONS_ITEM_UDATED_DATE(formatDate(get(version, 'createdAt', null), 'DD.MM.YY'))}
                        </span>
                    </div>

                    {/* {this.renderFilterSelect()} */}

                    {this.renderDownload(version)}

                    {this.renderButtons()}

                </div>
            </div>
        )
    }
}
