import React, { Component } from 'react';
import { values, keys } from 'lodash';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import { ADMIN_MOD_STATUS_TYPES, SEARCH_RESULT_EMPTY } from '../../../utils/constants';

import ModderItem from '../../ModderItem/ModderItem';
import Error from '../../Error/Error';

import { COMMON_ERROR } from '../../../translations';
import { 
  //ALL_MODS,
  DRAFT, 
  REVIEW, 
  PUBLISHED, 
  REJECTED 
} from './translations';

import styles from './ModderBlock.module.css';


export default class ModderBlock extends Component {
  static propTypes = {
    filtered: PropTypes.shape({
      draft: PropTypes.number,
      review: PropTypes.number,
      published: PropTypes.number,
      rejected: PropTypes.number,

      filterParams: PropTypes.shape({
        type: PropTypes.oneOf(values(ADMIN_MOD_STATUS_TYPES)),
      }).isRequired,

      isError: PropTypes.bool.isRequired,
      isFetching: PropTypes.bool.isRequired,
      isFetchedAll: PropTypes.bool.isRequired,
      count: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        state: PropTypes.number,
        mod: PropTypes.shape({
          id: PropTypes.number.isRequired,
          cover: PropTypes.string,
          mark: PropTypes.number,
          modVersion: PropTypes.string,
          title: PropTypes.string.isRequired,
          versions: PropTypes.arrayOf(PropTypes.shape({
              createdAt: PropTypes.string.isRequired,
              downloadUrl: PropTypes.string,
              id: PropTypes.number.isRequired,
              versionFileOriginalName: PropTypes.string,
              versionFileSize: PropTypes.number,
          })).isRequired,
        }),
        isUpdated: PropTypes.bool,
        isNew: PropTypes.bool,
      })).isRequired,
    }),

    location: ReactRouterPropTypes.location.isRequired,

    fetchItemsAdminPanel: PropTypes.func.isRequired,
    changeItemsType: PropTypes.func.isRequired,

    onIncrementDownloadCount: PropTypes.func.isRequired,
    onPublished: PropTypes.func.isRequired,
    onRejected: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchItemsAdminPanel(this.getFilterParams(this.props))
  }

  getFilterParams(props) {
    const queryStrings = queryString.parse(props.filtered.filterParams)
    const type = keys(queryStrings)[0]

    return {
        //argument: queryStrings[type],
        type,
    }
  }

  getDate = () => {
    var now = new Date();
    var options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      //year: "numeric",
    };

    setInterval(function() {
      now = new Date();
    }, 5000);

    return now.toLocaleString('ru-RU', options);
  }

  handleTypeChanged = (type) => {
    const filterParams = {
      ...this.props.filtered.filterParams,
      type,
    }
    this.props.changeItemsType(filterParams)
    //this.setState({isFiltered: !this.state.isFiltered})
  }

  renderItems = () => {
    if (this.props.filtered.isError) {
      return <Error>{COMMON_ERROR}</Error>
    }

    return this.props.filtered.items.length === 0 && !this.props.filtered.isFetching ? (
      <Error>{SEARCH_RESULT_EMPTY}</Error>
    ) : (
      <div className={classNames(styles.boxes, "jsGridView")}>
          {/* {this.props.filtered.items.map((item)) => { this.renderItem(item)) }} */}
          {this.props.filtered.items.map((item) => this.renderItem(item))}
      </div>
    )
  }

  renderItem = (item) => {
    const isUpdated = false;

    return (
      <ModderItem
          isNew={isUpdated}
          isUpdated={isUpdated}
          mod={item.mod}
          key={item.id}
          id={item.id}
          fetchItems={this.props.fetchItemsAdminPanel}
          onIncrementDownloadCount={this.props.onIncrementDownloadCount} 
          onPublished={this.props.onPublished}
          onRejected={this.props.onRejected} 
      />
    )
  }
  
  render() {
    return (
      <div className={styles.section}>
        <div className={styles.header}>
          <p>Секция для модератора</p>
          <p className={styles.time}>{this.getDate()}</p>
        </div>
        <div className={styles.section_line}>
          <div className={styles.mods_status}>
            <div className={styles.mods_status__item} onClick={() => this.handleTypeChanged(-1)}>
              <span className={styles.status_item__number}>ВСЕ</span>
              <span className={styles.status_item__type}>моды</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => this.handleTypeChanged(0)}>
              <span className={styles.status_item__number}>{this.props.filtered.draft}</span>
              <span className={styles.status_item__type}>{DRAFT}</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => this.handleTypeChanged(1)}>
              <span className={styles.status_item__number}>{this.props.filtered.review}</span>
              <span className={styles.status_item__type}>{REVIEW}</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => this.handleTypeChanged(2)}>
              <span className={styles.status_item__number}>{this.props.filtered.published}</span>
              <span className={styles.status_item__type}>{PUBLISHED}</span>
            </div>
            <div className={styles.mods_status__item} onClick={() => this.handleTypeChanged(3)}>
              <span className={styles.status_item__number}>{this.props.filtered.rejected}</span>
              <span className={styles.status_item__type}>{REJECTED}</span>
            </div>
          </div>
          <div className={styles.mods_actions}></div>
        </div>

        {this.renderItems()}

      </div>
    )
  }
}
