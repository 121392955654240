import React from 'react';
import PropTypes from 'prop-types';

import styles from './ActionPublished.module.css';

export  class ActionPublished extends React.PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={styles.base} onClick={this.props.onClick} />
        )
    }
}
