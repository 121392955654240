import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../../Image/Image' // react-image-resizer   -/- reactjs-image-resizer
import { cloneDeep, each } from 'lodash';
import {arrayMoveImmutable as arrayMove} from 'array-move';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import apiUrls from '../../../apiUrls';
import { FIELD_VALIDATION_ERROR_TYPES } from '../../../utils/constants';

import Caption from '../../Caption/Caption';
import EditLabelError from '../EditLabelError/EditLabelError';
import EditLabelText from '../EditLabelText/EditLabelText';
import FileUploadImage from '../../FileUploadImage/FileUploadImage';
import PreviewRemove from '../PreviewRemove/PreviewRemove';

import {
    EDIT_UPLOAD_COMMON_ERROR,
    EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR,
    EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
    EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
} from '../../../translations';

import {
    EDIT_SCREENSHOT_FILE_FORMAT,
    EDIT_SCREENSHOT_FILE_SIZE,
    EDIT_SCREENSHOTS_LABEL_TEXT,
    EDIT_SCREENSHOTS_LABEL,
} from './translations'

import styles from './EditScreenshots.module.css';


const MAX_SCREENSHOT_ITEMS = 5 // 5

export default class EditScreenshots extends React.PureComponent {
    static propTypes = {
        externalItems: PropTypes.arrayOf(PropTypes.shape({
            position: PropTypes.number.isRequired,
            screenshotId: PropTypes.number.isRequired,
            source: PropTypes.string.isRequired,
        })),
        onScreenshotsChange: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        this.state = {
            errorType: null,
            items: [],
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.externalItems) {
            this.setState({
                items: this.props.externalItems,
            })
        }
    }

    handleRemoveScreenshot = (position) => {
        const items = this.state.items
            .filter((item) => item.position !== position)
            .map((item, index) => ({ ...item, position: index }))
        this.setState({ items })
        this.props.onScreenshotsChange(items)
    }

    handleSort = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }))

        this.props.onScreenshotsChange(this.state.items)
    }

    handleUploadCompleted = (data, errorType) => {
        const items = cloneDeep(this.state.items)
        each(data, (dataItem) => {
            if (items.length >= MAX_SCREENSHOT_ITEMS) {
                return
            }
            items.push({
                accessToken: dataItem.access_token,
                fileId: dataItem.id,
                position: items ? items.length : 0,
                source: dataItem.source,
            })
        })

        this.setState({ errorType, items })
        this.props.onScreenshotsChange(items)
    }

    renderScreenshot(position, source, isDraggable) {
        const DragHandle = sortableHandle(({ children }) => (
            <div>{children}</div>
        ))

        const classNameScreenshot = classNames(styles.screenshot, {
            [styles.isDraggable]: isDraggable,
        })

        return (
            <div className={classNameScreenshot} key={position}>
                <DragHandle>
                    <div className={styles.image}>
                        <Image
                            src={source}
                            width={176}
                            height={99}
                        />
                    </div>
                </DragHandle>
                <div className={styles.previewRemove}>
                    <PreviewRemove onClick={() => {this.handleRemoveScreenshot(position)}} />
                </div>
            </div>
        )
    }

    renderErrorLabel() {
        const errorToMessagesMapping = {
            [FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR]: EDIT_UPLOAD_COMMON_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR]: EDIT_UPLOAD_INVALID_FILE_SIZE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR]: EDIT_UPLOAD_INVALID_FILE_TYPE_ERROR,
            [FIELD_VALIDATION_ERROR_TYPES.NOT_SUPPORTED_IMAGE_DIMENSIONS]: EDIT_UPLOAD_INVALID_FILE_DIMENSION_ERROR,
        }
        return this.state.errorType ? (
            <EditLabelError message={errorToMessagesMapping[this.state.errorType]} />
        ) : null
    }

    renderScreenshots() {
        const SortableItem = sortableElement(({ value }) => (
            <div className={styles.item}>{value}</div>
        ))

        const SortableContainer = sortableContainer(({ children }) => (
            <div className={styles.screenshots}>{children}</div>
        ))

        const isDraggable = this.state.items.length > 1

        const items = this.state.items.map((item) => (
            this.renderScreenshot(item.position, item.source, isDraggable)
        ))
        
        return (
            <SortableContainer
                helperClass={styles.dragged} // "dragged"
                axis="xy"
                useDragHandle
                onSortEnd={this.handleSort}
            >
                {items.map((value, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        value={value}
                        disabled={!isDraggable}
                    />
                ))}
            </SortableContainer>
        )
    }

    renderFileUpload() {
        if (window.location.pathname.includes('/review/')) {
            return null //<div>Вы не можете загружать скриншоты</div>
        }

        if (this.state.items.length >= MAX_SCREENSHOT_ITEMS) {
            return null
        }

        const isErrorFileFormat = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        const isErrorFileSize = this.state.errorType === FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR

        const url = `https://tankimods.net${apiUrls.modScreenshotUpload}`// apiUrls.modScreenshotUpload

        return (
            <FileUploadImage
                isReplacementDisabled
                isMultiple
                fieldName="source"
                summary={(
                    <span>
                        <span className={styles.fileUploadSummary}>
                            {EDIT_SCREENSHOT_FILE_FORMAT(isErrorFileFormat)}
                        </span>
                        <span className={styles.fileUploadSummary}>
                            {EDIT_SCREENSHOT_FILE_SIZE(isErrorFileSize)}
                        </span>
                    </span>
                )}
                url={url}
                onUploadCompleted={this.handleUploadCompleted}
            />
        )
    }

    render() {
        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge>
                        {EDIT_SCREENSHOTS_LABEL}
                    </Caption>
                    <EditLabelText message={EDIT_SCREENSHOTS_LABEL_TEXT} />
                    {this.renderErrorLabel()}
                </div>
                <div className={styles.field}>
                    {this.renderScreenshots()}
                    {this.renderFileUpload()}
                </div>
            </div>
        )
    }
}
