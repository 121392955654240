import React from 'react';
import PropTypes from 'prop-types';

import { AVAILABLE_LANGUAGES } from '../../../utils/i18n';

import ButtonBlueOutlineSmall from '../../Buttons/ButtonBlueOutlineSmall/ButtonBlueOutlineSmall';
import Caption from '../../Caption/Caption';
import Select from '../../Select/Select';

import {
    EDIT_LANGUAGE_ADD_BUTTON,
    EDIT_LANGUAGE_ADD_EN,
    EDIT_LANGUAGE_ADD_LABEL,
    EDIT_LANGUAGE_ADD_RU,
} from './translations';

import styles from './EditLanguageAdd.module.css';

export default class EditLanguageAdd extends React.PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        selectedValue: PropTypes.oneOf(AVAILABLE_LANGUAGES).isRequired,
        onAddLanguageClick: PropTypes.func.isRequired,
    }

    static languagesMapping = {
        en: EDIT_LANGUAGE_ADD_EN,
        ru: EDIT_LANGUAGE_ADD_RU,
    }

    constructor() {
        super()

        this.state = {
            selectedValue: null,
        }
    }

    componentDidMount() {
        this.setState({
            selectedValue: this.props.selectedValue,
        })
    }

    handleSelectedValueChange = (selectedValue) => {
        this.setState({ selectedValue })
    }

    handleAddLanguageClick = () => {
        this.props.onAddLanguageClick(this.state.selectedValue)
    }

    render() {
        const itemsForSelect = AVAILABLE_LANGUAGES.map((language) => ({
            value: language,
            caption: EditLanguageAdd.languagesMapping[language],
        }))

        return (
            <div className={styles.base}>
                <div className={styles.label}>
                    <Caption isLarge>
                        {EDIT_LANGUAGE_ADD_LABEL}
                    </Caption>
                </div>
                <div className={styles.field}>
                    <div className={styles.select}>
                        <Select
                            items={itemsForSelect}
                            selectedValue={this.state.selectedValue}
                            onItemChange={this.handleSelectedValueChange}
                            isDisabled={this.props.isDisabled}
                            isLanguage
                        />
                    </div>
                </div>
                <div className={styles.field}>
                    <ButtonBlueOutlineSmall glyph="plus" onClick={this.handleAddLanguageClick}>
                        {EDIT_LANGUAGE_ADD_BUTTON}
                    </ButtonBlueOutlineSmall>
                </div>
            </div>
        )
    }
}
