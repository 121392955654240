import { thunk } from 'redux-thunk'; // thunkMiddleware
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import settings from '../settings';

export const history = createBrowserHistory({
    basename: settings.basename,
})


const loggerMiddleware = createLogger({
    collapsed: true,
})

const middlewares = [
    thunk,
    routerMiddleware(history),
    loggerMiddleware,
]

export default middlewares
