import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { currentAccount } from './CurrentAccount';
import { userMenu } from './UserMenu';
import { dialog } from './Dialog';
import { dialogError } from './DialogError';
import { locale } from './Locale';
import { followed } from './Followed';
import { persistedValues } from './PersistedValues';
import { processing } from './Processing';

import { changelogHistory } from './ChangelogHistory';
import { details } from './Details';
import { editMod } from './EditMod';
import { gallery } from './Gallery';
import { sectionModder } from './SectionModder';
import { adminPanel } from './AdminPanel';
import { news } from './News';
import { searchPage } from './SearchPage';


export const tags = (state = {}) => state

export default combineReducers({
    currentAccount,
    userMenu,
    dialog,
    dialogError,
    locale,
    followed,
    persistedValues,
    processing,
    
    changelogHistory,
    details,
    editMod,
    gallery,
    sectionModder,
    adminPanel,
    news,
    searchPage,

    tags,

    routing: routerReducer,
})