import React from 'react';

import FileUploadCaption from '../../FileUploadCaption/FileUploadCaption';

export const EDIT_SCREENSHOTS_LABEL = "Скриншоты"

export const EDIT_SCREENSHOTS_LABEL_TEXT = "Не более пяти"

export const EDIT_SCREENSHOT_FILE_FORMAT = (isError) => {
    let ext0 = <FileUploadCaption isError={isError} caption="PNG" />
    let ext1 = <FileUploadCaption isError={isError} caption="JPG" />
    return (
        <React.Fragment>
            Формат файла: {ext0} или {ext1}
        </React.Fragment>
    )
}

export const EDIT_SCREENSHOT_FILE_SIZE = (isError) => {
    let size = <FileUploadCaption isError={isError} caption="Не более 4MB для одного файла" />
    return (
        <React.Fragment>
            Размер: {size}
        </React.Fragment>
    )
}
