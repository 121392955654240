import React, { PureComponent } from 'react';
import classNames from 'classnames';

import styles from './ScrollToTop.module.css';


const SCROLL_STEP_IN_PX = 50;
const DELAY_IN_MS = 10;
const OFFSET_SCROLL = 100;

export default class ScrollToTop extends PureComponent {
  constructor() {
    super()
    this.state = {
        intervalId: 0,
        isVisible: false,
    }
  }
  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll = () => {
      const isVisible = window.scrollY > OFFSET_SCROLL
      if (this.state.isVisible !== isVisible) {
          this.setState({ isVisible })
      }
  }
  handleClick = () => {
      let intervalId = setInterval(this.scrollStep.bind(this), DELAY_IN_MS)
      this.setState({ intervalId })
  }
  scrollStep = () => {
      window.scrollY === 0 && clearInterval(this.state.intervalId)
      window.scroll(0, window.scrollY - SCROLL_STEP_IN_PX)
  }
    
  render() {
    const classNameBase = classNames(styles.base, {
      [styles.isVisible]: this.state.isVisible,
    })

    return (
      <div className={classNameBase} onClick={this.handleClick} />
    )
  }
}
