import { get, concat } from 'lodash';

import * as actions from '../actions/AdminPanel';

import {
    //SEARCH_RESULT_ITEMS_ORDERING,
    SEARCH_RESULT_ITEMS_PER_PAGE,
    //SEARCH_RESULT_LANGUAGES,
} from '../utils/constants';

const initialState = {
    isError: false,
    isFetching: false,
    isErrorForbidden: false,
    filtered: {
        filterParams: {
            type: -1,
        },
        isError: false,
        isDisabled: false,
        isFetchedAll: false,
        isFetching: false,

        draft: 0,
        review: 0,
        rejected: 0,
        published: 0,

        offset: 0,
        count: 0, 
        items: [], 
    },
    data: {}, // for get and show user data
}

export const adminPanel = (state = initialState, action) => {
    const payload = get(action, 'payload')
    switch (action.type) {
        case actions.START_FETCHING_ADMIN_PANEL:
            return {
                ...state,
                isError: false,
                isErrorForbidden: false,
                isFetching: true,
                filtered: {
                    ...state.filtered,
                    isError: false,
                    isFetching: true,
                },
            }
        case actions.SET_ERROR_FETCHING_ADMIN_PANEL:
            return {
                ...state,
                isFetching: false,
                isError: get(payload, 'errorData.status') !== 403,
                isErrorForbidden: get(payload, 'errorData.status') === 403,
                filtered: {
                    ...state.filtered,
                    isError: true,
                },
            }
        case actions.STOP_FETCHING_ADMIN_PANEL:
            return {
                ...state,
                isFetching: false,
                filtered: {
                    ...state.filtered,
                    isFetching: false,
                },
            }
        case actions.INCREMENT_GETTING_OFFSET:
            return {
                ...state,
                filtered: {
                    ...state.filtered,
                    offset: state.filtered.isFetchedAll ?
                        state.filtered.offset :
                        state.filtered.offset + SEARCH_RESULT_ITEMS_PER_PAGE,
                },
            }
        case actions.CHANGE_ADMIN_RESULT_ITEMS_ORDERING:
            return {
                ...state,
                filtered: {
                    draft: 0,
                    review: 0,
                    published: 0,
                    rejected: 0,

                    filterParams: {
                        ...state.filtered.filterParams,
                    },

                    isError: false,
                    isFetchedAll: false,
                    isFetching: false,
                    count: 0,
                    items: [],
                    offset: 0,
                },
            }
        case actions.UPDATE_ITEMS_ADMIN_PANEL:
            return {
                ...state,
                //filtered: payload.filtered,
                filtered: {
                    ...state.filtered,
                    filterParams: {
                        ...state.filtered.filterParams,
                        ...action.payload.filterParams,
                    },
                    isError: false,
                    isFetchedAll: (
                        state.filtered.offset + SEARCH_RESULT_ITEMS_PER_PAGE >= payload.count
                    ),

                    draft: payload.draft,
                    review: payload.review,
                    rejected: payload.rejected,
                    published: payload.published, 

                    items: state.filtered.offset ?
                        concat(state.filtered.items, payload.items) :
                        payload.items,
                    count: payload.count,
                },
            }
        case actions.UPDATE_GET_USER_DATA:
            return {
                ...state,
                data: payload.data,
            }
        case actions.SET_IS_FORBIDDEN:
            return {
                ...state,
                isErrorForbidden: true,
            }
        default:
            return state
    }
}
