import { BREAKPOINT } from '../utils/constants';

export const getGalleryItemsCountPerRow = () => {
    return window.innerWidth > BREAKPOINT ? 4 : 3
}

export const browserName = {
    ie10: window.navigator.userAgent.indexOf('Trident/6.0') > -1,
    ie11: window.navigator.userAgent.indexOf('Trident/7.0') > -1,
    edge: window.navigator.userAgent.indexOf('Edge') > -1,
    safari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
}