import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isArray, isEmpty } from 'lodash';
import FileUploadProgress from 'react-fileupload-progress';

import { FIELD_VALIDATION_ERROR_TYPES } from '../../utils/constants';
import { formatFileSize } from '../../utils/formatting';
import settings from '../../settings';

import ProgressRing from '../ProgressRing/ProgressRing';

import {
    FILE_UPLOAD_TITLE,
    FILE_CANCEL_TITLE,
    FILE_UPDATE_TITLE,
    FILE_REMOVE_TITLE,
    FILE_UPDATE_FILE_NAME,
    FILE_UPLOAD_PROGRESS
} from './translations';

import styles from './FileUploadImage.module.css';


export default class FileUploadImage extends React.PureComponent {
    static propTypes = {
        externalFileName: PropTypes.string,
        externalFileSize: PropTypes.number,
        fieldName: PropTypes.string.isRequired,
        isError: PropTypes.bool,
        isMultiple: PropTypes.bool,
        isReplacementDisabled: PropTypes.bool,
        summary: PropTypes.node.isRequired,
        url: PropTypes.string.isRequired,

        onUploadCompleted: PropTypes.func.isRequired,
    }

    constructor() {
        super()

        //this.form = React.createRef();

        this.state = {
            isUploading: false,
            isUploaded: false,
            progress: 0,
            fileNames: [],
            fileSizes: [],
        }
    }

    componentDidMount() {
        if (this.props.externalFileName) {
            this.setState({
                externalFileName: this.props.externalFileName,
                isUploaded: true,
            })
        }
        if (this.props.externalFileSize) {
            this.setState({
                externalFileSize: this.props.externalFileSize,
            })
        }
    }

    handleUploadClick = () => {
        this._fileUploadInputRef.click()
    }

    handleCancelClick = () => {
        this.request.abort()
        this.setState({
            isUploading: false,
            progress: 0,
            fileNames: [],
            fileSizes: [],
        })
        this.props.onUploadCompleted(null)
        this._fileUploadInputRef.value = null
    }

    handleRemoveClick = () => {
        this.request && this.request.abort()
        this.setState({
            isUploading: false,
            isUploaded: false,
            progress: 0,
            fileNames: [],
            fileSizes: [],
            externalFileName: null,
            externalFileSize: null,
        })
        this.props.onUploadCompleted(null)
        this._fileUploadInputRef.value = null
    }

    handleFileUploadAbort = () => {
        this.setState({
            isUploading: false,
            progress: 0,
            fileNames: [],
            fileSizes: [],
        })
    }

    handleFileUploadError = (e, request) => {
        debugger
        let errorType = FIELD_VALIDATION_ERROR_TYPES.COMMON_ERROR
        if (request.status === 406) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.FILE_TYPE_ERROR
        } else if (request.status === 413) {
            errorType = FIELD_VALIDATION_ERROR_TYPES.FILE_SIZE_ERROR
        } else if (request.status === 400) {
            const errorData = JSON.parse(request.responseText)
            if (errorData.code === FIELD_VALIDATION_ERROR_TYPES.NOT_SUPPORTED_IMAGE_DIMENSIONS) {
                errorType = FIELD_VALIDATION_ERROR_TYPES.NOT_SUPPORTED_IMAGE_DIMENSIONS
            }
        }

        this.setState({
            isUploading: false,
            progress: 0,
            fileNames: [],
            fileSizes: [],
        })
        this.props.onUploadCompleted(null, errorType)
        this._fileUploadInputRef.value = null
    }

    handleFileUploadLoad = (e, request) => {
        if (request.status === 201) {
            const rawData = JSON.parse(request.responseText)
            const data = isArray(rawData) ? rawData : [rawData]
            this.props.onUploadCompleted(data)
            this.setState({
                isUploading: false,
                isUploaded: true,
                progress: 0,
                fileNames: data.map((item) => item.source_original_name),
                fileSizes: data.map((item) => item.source_size),
                externalFileName: null,
                externalFileSize: null,
            })
        } else {
            this.props.onUploadCompleted(null)
            this._fileUploadInputRef.value = null
        }
    }

    handleFileUploadProgress = (e, request, progress) => {
        if (!this._fileUploadInputRef.value) {
            request.abort()
            return
        }
        this.setState({
            isUploading: true,
            progress,
        })
    }

    fileUploadBeforeSend = (request) => {
        this.request = request
        request.setRequestHeader('X-CSRFToken', settings.csrfToken)
        // мой заголовок
        //request.setRequestHeader('Connection', 'keep-alive')
        
        return request
    }

    fileUploadFormRenderer = (submitHandler) => {
        const classNameBase = classNames(styles.base, {
            [styles.isError]: this.props.isError,
            [styles.isUploading]: this.state.isUploading,
            [styles.isUploaded]: this.state.isUploaded,
            [styles.isUpdate]: this.state.isUpdate,
        })

        const formRef = 'form' // раньше было так, но варнинг сказал переделать
        //const formRef = React.createRef('form')

        return (
            <form ref={formRef} method="post" encType="multipart/form-data">
                <div className={classNameBase}>
                    {this.renderUpload()}
                    {this.renderCancel()}
                    {this.renderUpdate()}

                    <input
                        ref={c => (this._fileUploadInputRef = c)}
                        className={styles.input}
                        type="file"
                        accept=".jpg, .png"
                        name={this.props.fieldName}
                        multiple={this.props.isMultiple}
                        onChange={submitHandler}
                    />
                </div>
            </form>
        )
    }

    fileUploadProgressRenderer = () => {
        return null
    }

    renderUpload() {
        if (
            this.state.isUploading ||
            (!isEmpty(this.state.fileNames) && !this.props.isReplacementDisabled) ||
            this.state.externalFileName
        ) {
            return null
        }

        return (
            <div>
                <div className={styles.title} onClick={this.handleUploadClick}>
                    <div className={styles.icon} />
                    {FILE_UPLOAD_TITLE}
                </div>
                <div className={styles.caption}>
                    {this.props.summary}
                </div>
            </div>
        )
    }

    renderCancel() {
        if (!this.state.isUploading) {
            return null
        }

        return (
            <div>
                <div className={styles.title} onClick={this.handleCancelClick}>
                    <div className={styles.icon}>
                        <div className={styles.progressRing}>
                            <ProgressRing
                                color="#0051ff" // #f9b723
                                radius={29}
                                stroke={3}
                                progress={this.state.progress}
                            />
                        </div>
                    </div>
                    {FILE_CANCEL_TITLE}
                </div>
                <div className={styles.caption}>
                    {FILE_UPLOAD_PROGRESS(this.state.progress)}
                </div>
            </div>
        )
    }

    renderUpdate() {
        if (
            this.state.isUploading ||
            !((!isEmpty(this.state.fileNames) || this.state.externalFileName) && !this.props.isReplacementDisabled)
        ) {
            return null
        }

        const fileName = this.state.externalFileName || this.state.fileNames[0]
        const fileSize = this.state.externalFileSize || this.state.fileSizes[0]

        return (
            <div>
                <div className={styles.title} onClick={this.handleUploadClick}>
                    <div className={styles.icon} />
                    {FILE_UPDATE_TITLE}
                </div>
                <div className={styles.title} onClick={this.handleRemoveClick}>{FILE_REMOVE_TITLE}</div>
                <div className={styles.caption}>
                    {FILE_UPDATE_FILE_NAME(
                        (<span className={styles.fileName} title={fileName}>{fileName}</span>),
                        (<span className={styles.fileSize}>{formatFileSize(fileSize)}</span>),
                    )}
                </div>
            </div>
        )
    }

    render() {
        return (
            <FileUploadProgress
                url={this.props.url}
                method="POST" // раньше не было этого пункта
                beforeSend={this.fileUploadBeforeSend}
                formRenderer={this.fileUploadFormRenderer}
                progressRenderer={this.fileUploadProgressRenderer}

                onAbort={this.handleFileUploadAbort}
                onError={this.handleFileUploadError}
                onLoad={this.handleFileUploadLoad}
                onProgress={this.handleFileUploadProgress}
            />
        )
    }
}
